import * as React from 'react';

const { _t } = window.loadTranslations(__filename);

const AcceptSubscriptionActionV0 = ({ action }: any) => {
  return (
    <span>
      {_t('element.content', {
        planName: action.planName,
        plate: action.plate,
      })}
    </span>
  );
};

export default AcceptSubscriptionActionV0;
