import * as React from 'react';

import { HistoryAction } from './types';

const { _t } = window.loadTranslations(__filename);

type CreateParkingRightRequestActionV0Type = {
  plate: string;
  parkingRightDefinitionName: string;
};

const CreateParkingRightRequestActionV0 = ({
  action: { plate, parkingRightDefinitionName },
}: HistoryAction<CreateParkingRightRequestActionV0Type>) => {
  return (
    <span>{_t('element.content', { parkingRightDefinitionName, plate })}</span>
  );
};

export default CreateParkingRightRequestActionV0;
