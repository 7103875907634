import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { TaoSubscriberSearchRequestDTO } from '@cvfm-front/tefps-types';

import {
  ListBody,
  ListBottom,
  ListWrapper,
} from '../../../commons/ListWrappers';
import useWatcher from '../../../commons/hooks/useWatcher';
import BoButton from '../../../facade/BoButton';
import services from '../../../commons/services';
import { getApiState, InternalApiState } from '../../../api/duck';
import AddTaoSubscriber from '../creation/AddTaoSubscriber';

import TaoSubscriberTableSidebar from './TaoSubscriberTableSidebar';
import TaoSubscriberTable from './TaoSubscriberTable';
import '../../../tefps/Subscription/SubscriberTablePage.css';

const { _tg } = window.loadTranslations(__filename);

type TaoSubscriberTablePageReduxProps = {
  canWrite: boolean;
};

type TaoSubscriberTablePageProps = TaoSubscriberTablePageReduxProps;

const TaoSubscriberTablePage = ({
  canWrite,
}: TaoSubscriberTablePageProps): JSX.Element => {
  const request: Partial<TaoSubscriberSearchRequestDTO> = useWatcher(
    services.taoSubscriberSearch.watchRequest,
    {}
  );
  const subscribers = useWatcher(
    services.taoSubscriberSearch.watchSubscribers,
    []
  );
  const [openCreate, setOpenCreate] = useState<boolean>(false);

  useEffect(() => {
    services.taoSubscriberSearch.searchTaoSubscriber();
  }, [request]);

  return (
    <div className="subscriber-page_container">
      <TaoSubscriberTableSidebar />
      <div className="subscriber-page_content">
        <div className="subscriber-page_table">
          <ListWrapper
            style={{
              padding: '0px 32px',
              margin: 0,
              maxWidth: '100%',
              maxHeight: '95%',
            }}
          >
            <ListBody loading={false}>
              <TaoSubscriberTable pagedSubscribers={subscribers} />
            </ListBody>
            <ListBottom style={{ margin: '1% 3% 0%', display: 'flex' }}>
              {canWrite && (
                <BoButton
                  style={{ marginRight: 10 }}
                  label={_tg('action.create')}
                  onClick={() => {
                    setOpenCreate(true);
                  }}
                  primary
                />
              )}
            </ListBottom>
          </ListWrapper>
        </div>
        {canWrite && openCreate && (
          <AddTaoSubscriber closeModal={() => setOpenCreate(false)} />
        )}
      </div>
    </div>
  );
};

function mapStateToProps(
  state: InternalApiState
): TaoSubscriberTablePageReduxProps {
  const { userInfo } = getApiState(state);
  return {
    canWrite: !!userInfo && userInfo.rights.includes('SUBSCRIBER_WRITE'),
  };
}

export default connect(mapStateToProps)(TaoSubscriberTablePage);
