import React, { CSSProperties } from 'react';

import FakeInputBlock from 'commons/FakeInputBlock';
import RapoAddressRenderer from 'commons/Address/RapoAddressRenderer';
import { CcspRecourseDTO } from 'api/ccsp/types';
import Separator from 'commons/Separator';

const STYLE_CONTAINER: CSSProperties = {
  margin: '30px 0',
  display: 'flex',
  flexDirection: 'column',
};

type Props = {
  ccsp: CcspRecourseDTO;
};

const UserBlock = ({ ccsp }: Props): JSX.Element => (
  <div style={STYLE_CONTAINER}>
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <FakeInputBlock
        title="Nom"
        value={ccsp.user.lastName}
        containerStyle={{ width: '45%' }}
      />
      <Separator />
      <FakeInputBlock
        title="Prénom"
        value={ccsp.user.firstName}
        containerStyle={{ width: '45%' }}
      />
    </div>
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <FakeInputBlock
        title="Email"
        value={ccsp.user.email}
        containerStyle={{ width: '45%' }}
      />
      <Separator />
      <FakeInputBlock
        title="Téléphone"
        containerStyle={{ width: '45%' }}
        value={ccsp.user.phoneNumber}
      />
    </div>
    <FakeInputBlock
      title="Adresse"
      value={<RapoAddressRenderer user={ccsp.user} />}
      containerStyle={{ alignItems: 'baseline' }}
      inputStyle={{ maxWidth: 'auto', width: 400 }}
      labelStyle={{ flex: 'none', marginRight: 30 }}
    />
  </div>
);

export default UserBlock;
