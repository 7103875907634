import * as React from 'react';

const { _t, Trans } = window.loadTranslations(__filename);

const EditEmailAction = (action: any) => {
  return (
    <Trans
      i18nKey="element.content"
      components={[<span style={{ fontWeight: 'bold' }} />]}
      tOptions={{
        oldEmail: action.action.oldEmail,
        newEmail: action.action.newEmail,
      }}
    />
  );
};

export default EditEmailAction;
