import React, { Component, CSSProperties } from 'react';
import FileSaver from 'file-saver';
import moment from 'moment';
import Dialog from 'material-ui/Dialog';
import TextField from 'material-ui/TextField';
import { MenuItem, SelectField } from 'material-ui';
import CreatableSelect from 'react-select/creatable';
import { connect } from 'react-redux';

import BoButton from 'facade/BoButton';
import { BKG_LIGHT_BLUE, STYLE_ERROR_WRAPPER } from 'theme';
import { CancellableFpsMap, FpsUpdateDTOResponse } from 'api/fps/types';
import { patchFps, updateFps } from 'api/fps';
import { FpsCancelProposalReason, getConfigState } from 'config/duck';
import ErrorBlock from 'commons/ErrorBlock';

import FpsToDeleteList from './FpsToDeleteList';

const STYLE_INPUTS: CSSProperties = {
  borderColor: BKG_LIGHT_BLUE,
  color: BKG_LIGHT_BLUE,
};
const STYLE_SELECT_CONTAINER: CSSProperties = {
  height: 90,
};

const REACT_SELECT_STYLE = {
  menu: (base: any) => ({
    ...base,
    zIndex: 100,
  }),
  placeholder: (styles: any) => ({
    ...styles,
    color: ' rgba(0, 0, 0, 0.3)',
  }),
  option: (styles: any, state: any) => ({
    ...styles,
    backgroundColor: state.isSelected ? 'rgb(65, 183, 195)' : 'white',
    '&:hover': {
      backgroundColor: 'rgb(65, 183, 195)',
    },
  }),
  valueContainer: (styles: any) => ({
    ...styles,
    padding: 0,
  }),
  control: (styles: any, state: any) => ({
    ...styles,
    height: 60,
    border: 0,
    boxShadow: 'none',
    borderRadius: 0,
    borderBottom: state.isFocused
      ? '2px solid rgb(65, 183, 195)'
      : '1px solid #d0cfcf',
    '&:hover': {
      borderBottom: '2px solid rgb(65, 183, 195)',
    },
  }),
};
const { _tg } = window.loadTranslations(__filename);

type State = {
  open: boolean;
  openDialog: boolean;
  cancellableFpsMap: CancellableFpsMap;
  comment: string | null;
  reason: string | null;
  error: string | null;
  administrativeCertificate: string | null;
};

type Props = {
  agentId: string;
  toggleCancelProposal: Function;
  reportError: (error: Error) => void;
  fetchAllFpsId: Function;
  canUpdateFps: boolean | null | undefined;
  selectAllFps: boolean;
  reloadFpsList: Function;
  updateCancelList: Function;
  cancelList: Array<string> | null | undefined;
  fpsCancelProposalReasons: Array<FpsCancelProposalReason>;
  fpsComments: Array<string>;
};

class CancelProposal extends Component<Props, State> {
  state: State = {
    open: false,
    openDialog: false,
    cancellableFpsMap: {},
    comment: null,
    reason: null,
    error: null,
    administrativeCertificate: null,
  };

  selectComment = (selectedComment: string | undefined): void => {
    if (selectedComment) {
      this.setState({ comment: selectedComment });
    }
  };

  onChangeComment = (
    _e: React.ChangeEvent<HTMLInputElement>,
    comment: string
  ): void => this.setState({ comment });

  onChangeReason = (
    e: React.ChangeEvent<HTMLInputElement>,
    i: number,
    value: string
  ) => {
    this.setState({ reason: value });
  };

  onChangeAdministrativeCertificate = (
    e: React.ChangeEvent<HTMLInputElement>,
    newAdminCert: string
  ) => {
    this.setState({ administrativeCertificate: newAdminCert });
  };

  toggleCancelProposal = () => {
    const { toggleCancelProposal } = this.props;
    const { open } = this.state;
    if (!open) {
      toggleCancelProposal();
      this.setState({ open: true });
    } else {
      this.setState({ openDialog: true });
    }
  };

  closeDialog = () => {
    this.props.toggleCancelProposal();
    this.setState({ open: false, openDialog: false });
  };

  selectAllFps = () => {
    const {
      selectAllFps,
      fetchAllFpsId,
      cancelList,
      updateCancelList,
    } = this.props;
    if (!selectAllFps) {
      fetchAllFpsId();
    } else if (cancelList) {
      updateCancelList([]);
    }
  };

  closeAndClean = () => {
    const { updateCancelList, reloadFpsList } = this.props;
    updateCancelList([]);
    this.setState({ cancellableFpsMap: {} });
    reloadFpsList();
    this.closeDialog();
  };

  createCancelProposal = async () => {
    const { canUpdateFps, cancelList, agentId } = this.props;
    const { comment, reason, administrativeCertificate } = this.state;
    if (!cancelList) {
      return;
    }

    if (!canUpdateFps && !reason) {
      this.setState({ error: "Le champ raison n'a pas été rempli" });
      return;
    }

    const body = [
      {
        op: 'add',
        path: '/cancelProposal/-',
        value: {
          agentId,
          reason,
          comment,
          administrativeCertificate,
          email: null, // no need for this situation
          status: 'PENDING',
        },
      },
    ];

    // if the agent has the right, he will cancel directly fpses
    const fpsMap = {};
    let resFpsUpdate: FpsUpdateDTOResponse | null | undefined;

    for (let i = 0; i < cancelList.length; i += 1) {
      try {
        if (canUpdateFps) {
          // cancel if he has the right, else we create a cancelProposal for each fpses
          // Les requêtes doivent être synchrones et exécutées les unes à la suite des autres
          // eslint-disable-next-line no-await-in-loop
          resFpsUpdate = await updateFps(
            cancelList[i],
            {
              amount: 0,
              comment,
              administrativeCertificate,
            },
            resFpsUpdate ? resFpsUpdate.lastAgentShortId : undefined
          );
        } else {
          // eslint-disable-next-line no-await-in-loop
          await patchFps(cancelList[i], body);
        }

        fpsMap[cancelList[i]] = {
          status: canUpdateFps ? 'annulé' : "demande d'annulation",
          errorMessage: null,
          newFpsId: resFpsUpdate ? resFpsUpdate.newFpsId : '',
        };
      } catch (error) {
        fpsMap[cancelList[i]] = {
          status: 'erreur',
          errorMessage: error.json.message,
          newFpsId: '',
        };
      } finally {
        this.setState({
          cancellableFpsMap: fpsMap,
          error: null,
        });
      }
    }
    this.toggleCancelProposal();
  };

  rejectCancelProposal = async () => {
    const { canUpdateFps, reportError, cancelList, agentId } = this.props;
    const { comment, administrativeCertificate, reason } = this.state;
    const body = [
      {
        op: 'add',
        path: '/cancelProposal/-',
        value: {
          agentId,
          reason,
          comment,
          email: null, // no need for this situation
          administrativeCertificate,
          status: 'REFUSED',
        },
      },
    ];
    try {
      if (canUpdateFps && cancelList) {
        for (let i = 0; i < cancelList.length; i += 1) {
          // eslint-disable-next-line no-await-in-loop
          await patchFps(cancelList[i], body);
        }
      }
    } catch (error) {
      reportError(error);
    }
    this.closeAndClean();
  };

  exportResult = () => {
    const { cancellableFpsMap, comment } = this.state;
    const { cancelList } = this.props;

    if (!cancelList) {
      return;
    }

    const lines = [
      "Id Fps annulé;Id Fps d'annulation;Statut;Message d'erreur (optionnel);Commentaire",
    ];

    for (let i = 0; i < cancelList.length; i += 1) {
      const cancellableFps = cancellableFpsMap[cancelList[i]];
      lines.push(
        `${cancelList[i]};` +
          `${cancellableFps.newFpsId || ''};` +
          `${cancellableFps.status};` +
          `${cancellableFps.errorMessage || ''};` +
          `${comment || ''}`
      );
    }

    FileSaver.saveAs(
      new Blob([lines.join('\n')], {
        type: 'text/csv;charset=utf-8',
      }),
      `export-annulation-fps-${moment().format('DD-MM-YYYY')}.csv`
    );
  };

  render() {
    const {
      open,
      openDialog,
      cancellableFpsMap,
      comment,
      reason,
      error,
      administrativeCertificate,
    } = this.state;
    const {
      cancelList,
      canUpdateFps,
      selectAllFps,
      fpsCancelProposalReasons,
      fpsComments,
    } = this.props;

    if (!cancelList) {
      return null;
    }

    const nbFpsInProposal = cancelList.length;
    const nbFpsCancelled = Object.keys(cancellableFpsMap).length;

    const options = fpsComments?.map((value, index) => ({
      label: value,
      value: index,
    }));

    let titleText;
    if (cancelList.length === nbFpsCancelled) {
      titleText = canUpdateFps
        ? 'Annulation terminée'
        : "Demande d'annulation terminée";
    } else if (nbFpsCancelled > 0) {
      titleText = `Progression: ${nbFpsCancelled} sur ${nbFpsInProposal}`;
    } else {
      titleText = canUpdateFps
        ? "Confirmer l'annulation"
        : "Confirmer la demande d'annulation";
    }

    const actions = [];
    if (nbFpsCancelled === 0) {
      actions.push(
        <BoButton
          key="annuler"
          style={{ marginRight: 10 }}
          label="Annuler"
          onClick={this.closeDialog}
        />
      );
    }
    if (canUpdateFps && nbFpsCancelled === 0) {
      actions.push(
        <BoButton
          key="rejeter"
          style={{ marginRight: 10 }}
          label="Rejeter la proposition"
          primary
          onClick={this.rejectCancelProposal}
        />
      );
    }
    if (nbFpsCancelled === 0) {
      actions.push(
        <BoButton
          key="valider"
          style={{ marginRight: 10 }}
          label="Valider"
          primary
          disabled={!this.state.comment}
          onClick={this.createCancelProposal}
        />
      );
    }
    if (nbFpsCancelled > 0) {
      actions.push(
        <BoButton
          key="export csv"
          label="Export CSV"
          disabled={cancelList.length !== nbFpsCancelled}
          style={{ marginRight: 10 }}
          onClick={this.exportResult}
        />
      );
    }
    if (nbFpsCancelled > 0) {
      actions.push(
        <BoButton
          key="fermer"
          style={{ marginRight: 10 }}
          label="Fermer"
          disabled={cancelList.length !== nbFpsCancelled}
          primary
          onClick={this.closeAndClean}
        />
      );
    }

    let label = 'Proposer la modification';
    if (canUpdateFps && open) {
      label = "Arbitrer l'annulation";
    } else if (nbFpsInProposal && canUpdateFps) {
      label = `${nbFpsInProposal} propositions à valider`;
    } else if (!open) {
      label = 'Modification multiple';
    }

    return (
      <div>
        {open && (
          <span>
            <BoButton
              label="Annuler"
              onClick={this.closeDialog}
              style={{ marginRight: 10 }}
            />
            <BoButton
              label={!selectAllFps ? 'Tout sélectionner' : 'Tout retirer'}
              onClick={this.selectAllFps}
              primary
              style={{ marginRight: 10 }}
            />
          </span>
        )}

        <BoButton label={label} onClick={this.toggleCancelProposal} secondary />
        <Dialog
          title={titleText}
          actions={actions}
          modal
          autoScrollBodyContent
          open={openDialog}
        >
          {nbFpsCancelled === 0 && (
            <div>
              {!canUpdateFps && (
                <SelectField
                  underlineFocusStyle={STYLE_INPUTS}
                  value={reason}
                  floatingLabelText="Raison"
                  fullWidth
                  onChange={this.onChangeReason}
                >
                  {fpsCancelProposalReasons &&
                    fpsCancelProposalReasons.map(item => (
                      <MenuItem
                        key={item.key}
                        value={item.label}
                        primaryText={item.label}
                      />
                    ))}
                </SelectField>
              )}

              {fpsComments && fpsComments.length > 0 ? (
                <div style={STYLE_SELECT_CONTAINER}>
                  <CreatableSelect
                    isClearable
                    placeholder="Selectionner un commentaire"
                    id="comment"
                    options={options}
                    onChange={selectedOption => {
                      this.selectComment(selectedOption?.label);
                    }}
                    styles={REACT_SELECT_STYLE}
                    maxMenuHeight={200}
                    minMenuHeight={50}
                    menuPosition="absolute"
                    formatCreateLabel={userInput => `Autre: ${userInput}`}
                  />
                </div>
              ) : (
                <TextField
                  underlineFocusStyle={STYLE_INPUTS}
                  floatingLabelFocusStyle={STYLE_INPUTS}
                  onChange={this.onChangeComment}
                  value={comment || ''}
                  floatingLabelText="Commentaire"
                  fullWidth
                  multiLine
                  rows={3}
                  rowsMax={3}
                  required
                />
              )}
              <TextField
                underlineFocusStyle={STYLE_INPUTS}
                floatingLabelFocusStyle={STYLE_INPUTS}
                value={administrativeCertificate || ''}
                onChange={this.onChangeAdministrativeCertificate}
                floatingLabelText={_tg(
                  'tefps.fps.list.cancelProposal.index.administrativeCertificate'
                )}
                fullWidth
                multiLine
              />
            </div>
          )}
          {nbFpsCancelled > 0 && (
            <FpsToDeleteList
              open={this.state.open}
              cancellableFpsMap={cancellableFpsMap}
              fpsIds={cancelList}
            />
          )}
          {error && (
            <div style={STYLE_ERROR_WRAPPER}>
              <ErrorBlock error={{ message: error }} />
            </div>
          )}
        </Dialog>
      </div>
    );
  }
}

export default connect(state => {
  const { fpsConfigurationDTO } = getConfigState(state);
  return {
    fpsComments: fpsConfigurationDTO?.fpsComments || [],
  };
})(CancelProposal);
