import React from 'react';
import Dialog from 'material-ui/Dialog';
import CircularProgress from 'material-ui/CircularProgress';

import BoButton from 'facade/BoButton';
import { SubscriberCreationOrUpdateDTO } from '@cvfm-front/tefps-types';
import FormComponent from 'commons/FormComponent';
import withZoning, {
  ZoningProps,
} from 'tefps/Beneficiaries/commons/withZoning';

import SubscriberInformation from './SubscriberInformation';

const { _t, _tg } = window.loadTranslations(__filename);

type OwnProps = {
  createNewSubscriber: (
    formValue: SubscriberCreationOrUpdateDTO
  ) => Promise<void>; // eslint-disable-line react/no-unused-prop-types
  closeModal: () => void;
};

type AddSubscriberProps = OwnProps & ZoningProps;

type AddSubscriberState = {
  loading: boolean;
};

class AddSubscriber extends React.Component<
  AddSubscriberProps,
  AddSubscriberState
> {
  form: any = null;
  state: AddSubscriberState = {
    loading: false,
  };

  componentDidMount() {
    this.props.fetchZoning();
  }

  attachRef = (node: any) => {
    this.form = node;
  };

  addNewSubscriber = async () => {
    const { form } = this;
    if (form && form.isValid()) {
      const value = form.getFormEntries();
      this.setState({ loading: true });
      await this.props.createNewSubscriber(value);
      this.setState({ loading: false });
    }
  };

  render() {
    const { zones } = this.props;
    const { loading } = this.state;
    const actions = [
      <BoButton
        key="annuler"
        style={{ marginRight: 10 }}
        label={_tg('action.cancel')}
        primary
        onClick={this.props.closeModal}
      />,
      <BoButton
        key="creer"
        style={{ marginRight: 10 }}
        label={_tg('action.create')}
        primary
        onClick={this.addNewSubscriber}
      />,
    ];

    return (
      <Dialog
        title={_t('element.dialog.title')}
        actions={actions}
        modal
        autoScrollBodyContent
        open
      >
        <FormComponent ref={this.attachRef}>
          {loading && <CircularProgress />}
          <SubscriberInformation zones={zones || []} />
        </FormComponent>
      </Dialog>
    );
  }
}

export default withZoning<OwnProps>(AddSubscriber);
