import React, { CSSProperties } from 'react';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import CircularProgress from 'material-ui/CircularProgress';
import SuccessIcon from 'material-ui/svg-icons/navigation/check';

import { importSubscribers } from 'api/subscribers';
import { BKG_GREEN } from 'theme';
import CsvImportDropZone from 'commons/CsvImportDropZone';

import SubscriberFilterService from '../../Subscription/SubscriberFilterService';

const { _t, _tg } = window.loadTranslations(__filename);

type State = {
  openModal: boolean;
  loading: boolean;
  success: boolean;
  errors: Array<string>;
};

type Props = {
  style: CSSProperties | undefined;
  refresh?: () => Promise<any>;
};

class ImportSubscribers extends React.Component<Props, State> {
  state: State = {
    openModal: false,
    loading: false,
    success: false,
    errors: [],
  };

  onDrop = (files: Array<File>) => {
    if (files.length) {
      this.submit(files[0]);
    } else {
      this.setState({
        loading: false,
        openModal: true,
        errors: [_tg('feedback.error.CSVFileFormat')],
      });
    }
  };

  handleClose = () => this.setState({ errors: [], openModal: false });

  submit = async (file: File) => {
    this.setState({ loading: true, openModal: true, success: false });
    const { refresh } = this.props;
    const result = await importSubscribers(file);
    if (result.success) {
      this.setState({ success: true });
      setTimeout(() => {
        if (refresh) {
          void refresh(); // ADV1
        } else {
          void SubscriberFilterService.loadSubscribers(); // ADV2
        }
      }, 3000);
    }
    this.setState({ errors: result.errors, loading: false });
  };

  static renderError = (errors: Array<string>) => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: 10,
        }}
      >
        <ul style={{ marginTop: 20 }}>
          {errors.map(error => (
            <li key={error} style={{ marginBottom: 2 }}>
              {_tg('characters.dot')} {error}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  static renderLoading = () => {
    return (
      <div
        style={{
          height: 150,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </div>
    );
  };

  static renderSuccess = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <SuccessIcon color={BKG_GREEN} style={{ width: 100, height: 100 }} />
        <div>{_t('feedback.success.accountImported')}</div>
        <div>{_t('feedback.warning.pageRefresh')}</div>
      </div>
    );
  };

  static renderContent = (
    loading: boolean,
    success: boolean,
    errors: Array<string>
  ) => {
    if (loading) {
      return ImportSubscribers.renderLoading();
    }
    if (success) {
      return ImportSubscribers.renderSuccess();
    }
    return ImportSubscribers.renderError(errors);
  };

  render() {
    const { openModal, loading, errors, success } = this.state;
    return (
      <div>
        <CsvImportDropZone
          buttonStyle={this.props.style}
          onDrop={this.onDrop}
          buttonLabel={_t('element.importAccount.buttonLabel')}
        />

        <Dialog
          title={_t('element.dialog.title')}
          onRequestClose={this.handleClose}
          modal
          open={openModal}
          autoScrollBodyContent
          actions={[
            <FlatButton
              label={_tg('action.close')}
              onClick={this.handleClose}
              disabled={loading}
            />,
          ]}
        >
          {ImportSubscribers.renderContent(loading, success, errors)}
        </Dialog>
      </div>
    );
  }
}

export default ImportSubscribers;
