import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';
import IntlPolyfill from 'intl';
import Dialog from 'material-ui/Dialog';
import TextField from 'material-ui/TextField';
import Checkbox from 'material-ui/Checkbox';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import DatePicker from 'material-ui/DatePicker';
import CircularProgress from 'material-ui/CircularProgress';
import moment, { Moment } from 'moment';

import BoButton from 'facade/BoButton';
import { InternalAgent } from 'api/auth/types';
import { getApiState } from 'api/duck';
import { PatchObject } from 'api/commonTypes';
import ErrorBlock from 'commons/ErrorBlock';
import {
  BKG_CYAN,
  BKG_PINK,
  STYLE_ERROR_WRAPPER,
  STYLE_LOADING_WRAPPER,
} from 'theme';
import { buildControlAgent } from 'commons/Utils/agentUtil';
import {
  convertToCents,
  formatCentsToEuro,
  translatePaymentMode,
  positivePrice,
} from 'commons/Utils/paymentUtil';
import { formatDate, toHours, toMinutes } from 'commons/Utils/dateUtil';
import { isRefund } from 'tefps/Beneficiaries/utils';

const { _t, _tg } = window.loadTranslations(__filename);

const STYLE_TITLE: CSSProperties = {
  backgroundColor: BKG_PINK,
  color: '#ffffff',
  fontWeight: 'bold',
};

const STYLE_REFUND: CSSProperties = {
  ...STYLE_TITLE,
  backgroundColor: BKG_CYAN,
};

const STYLE_CONTENT_WRAPPER: CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignContent: 'center',
};

const STYLE_PRICE_INPUT: CSSProperties = {
  color: BKG_PINK,
  fontWeight: 'bold',
  fontSize: 40,
};

const STYLE_PRICE_REFUND: CSSProperties = {
  ...STYLE_PRICE_INPUT,
  color: BKG_CYAN,
};

const STYLE_INPUTS: CSSProperties = {
  borderColor: BKG_PINK,
  color: BKG_PINK,
};

type PaymentType = 'PAYMENT' | 'REFUND';

type AddPaymentProps = {
  isOpen: boolean;
  amountToPay: number;
  paymentType: PaymentType;
  proratedAmount?: number;
  close: () => any;
  userInfo: InternalAgent;
  patchResource: (body: PatchObject<unknown>) => void;
};

type AddPaymentState = {
  loading: boolean;
  error: string | null | undefined;
  amount: number;
  origin: string;
  mode: string;
  date: Moment;
  comment: string | undefined;
  useProratedAmount: boolean;
};

const getInitialState = (amountToPay: number) => ({
  loading: false,
  error: null,
  amount: Math.max(0, formatCentsToEuro(amountToPay)),
  origin: 'DESK',
  mode: 'CARD',
  date: moment(),
  comment: '',
  useProratedAmount: false,
});

const PAYMENT_ORIGIN = {
  PAYMENT: ['DESK', 'MAIL'],
  REFUND: ['DESK', 'MAIL', 'INTERNET'],
};

const translatePaymentOriginLabels = (): { [key: string]: string } => {
  return {
    DESK: _tg('field.payment.origins.DESK'),
    MAIL: _tg('field.payment.origins.MAIL'),
    INTERNET: _tg('field.payment.origins.INTERNET'),
  };
};

const ORIGIN_PAYMENT_MODE = {
  DESK: ['CARD', 'CHECK', 'CASH', 'RECURRING_DEBIT', 'TRANSFERT'],
  MAIL: ['CHECK', 'CASH', 'RECURRING_DEBIT', 'TRANSFERT'],
  INTERNET: ['TRANSFERT'],
};

const getOrigins = (paymentType: PaymentType) => {
  const paymentOriginLabels = translatePaymentOriginLabels();
  return PAYMENT_ORIGIN[paymentType].map((key: string) => (
    <MenuItem key={key} value={key} primaryText={paymentOriginLabels[key]} />
  ));
};

const getLabel = (type: PaymentType) =>
  isRefund(type)
    ? _tg('field.payment.refund').toLowerCase()
    : _tg('field.payment.payment').toLowerCase();

class AddPayment extends React.Component<AddPaymentProps, AddPaymentState> {
  constructor(props: AddPaymentProps) {
    super(props);
    this.state = getInitialState(props.amountToPay);
  }

  onClose = (): void => {
    const { close, amountToPay } = this.props;
    close();
    this.setState({
      ...getInitialState(amountToPay),
    });
  };

  onClickValidate = (): void => {
    const { patchResource, userInfo, paymentType } = this.props;
    const { date, origin, amount, mode, comment } = this.state;
    const body = {
      op: 'add',
      path: '/payments',
      value: {
        paymentDatetime: moment(date),
        paymentChannel: origin,
        paymentAmount:
          convertToCents(amount) * (isRefund(paymentType) ? -1 : 1),
        paymentMode: mode,
        comment,
        agent: buildControlAgent(userInfo),
      },
    };

    this.setState({ loading: true, error: null });
    try {
      patchResource(body);
      this.setState({ loading: false });
    } catch (error) {
      this.setState({ loading: false, error: _tg('commons.error') });
    }
  };

  onChangeComment = (
    e: React.ChangeEvent<HTMLInputElement>,
    comment: string
  ): void => {
    this.setState({ comment });
  };

  onChangeDate = (useless: any, newDate: Date) => {
    const { date } = this.state;
    const newDateTime = moment(newDate);
    newDateTime.hours(date.hours());
    newDateTime.minutes(date.minutes());
    this.setState({ date: newDateTime });
  };

  onChangeMinutes = (
    e: React.ChangeEvent<HTMLInputElement>,
    minutes: string
  ) => {
    const { date } = this.state;
    this.setState({
      date: date.clone().minutes(toMinutes(minutes)),
    });
  };

  onChangeHours = (e: React.ChangeEvent<HTMLInputElement>, hours: string) => {
    const { date } = this.state;
    this.setState({ date: date.clone().hours(toHours(hours)) });
  };

  changeAmount = (e: React.ChangeEvent<HTMLInputElement>, amount: string) => {
    this.setState({ amount: positivePrice(amount) });
  };

  changeOrigin = (
    e: React.SyntheticEvent<unknown>,
    i: number,
    origin: string
  ) => {
    // eslint-disable-next-line
    this.setState({ origin, mode: ORIGIN_PAYMENT_MODE[origin][0] });
  };

  changeMode = (
    e: React.SyntheticEvent<unknown>,
    i: number,
    mode: string
  ): void => {
    this.setState({ mode });
  };

  handleUseProratedAmount = (): void => {
    const { proratedAmount } = this.props;
    if (proratedAmount !== undefined) {
      const { useProratedAmount } = this.state;
      this.setState({ useProratedAmount: !useProratedAmount });
      const { amountToPay } = this.props;
      if (!useProratedAmount) {
        this.setState({
          amount: positivePrice(
            formatCentsToEuro(amountToPay - proratedAmount).toString()
          ),
        });
      } else {
        this.setState({ amount: Math.max(0, formatCentsToEuro(amountToPay)) });
      }
    }
  };

  render() {
    const {
      loading,
      error,
      amount,
      date,
      mode,
      comment,
      origin,
      useProratedAmount,
    } = this.state;
    const { isOpen, paymentType, proratedAmount } = this.props;

    const actions = [
      <BoButton
        style={{ marginRight: 10 }}
        key={1}
        label={_tg('action.cancel')}
        onClick={this.onClose}
      />,
      <BoButton
        key={2}
        label={_tg('action.add')}
        primary
        keyboardFocused
        onClick={this.onClickValidate}
        disabled={amount === 0 || isNaN(amount)}
      />,
    ];

    const modes = (ORIGIN_PAYMENT_MODE[
      origin
    ] as string[]).map((key: string) => (
      <MenuItem
        key={key}
        value={key}
        primaryText={translatePaymentMode()[key]}
      />
    ));

    return (
      <Dialog
        actions={actions}
        title={_t('element.dialog.title', {
          paymentType: getLabel(paymentType),
        })}
        open={isOpen}
        onRequestClose={this.onClose}
        titleStyle={isRefund(paymentType) ? STYLE_REFUND : STYLE_TITLE}
      >
        {loading && (
          <div style={STYLE_LOADING_WRAPPER}>
            <CircularProgress />
          </div>
        )}
        <div style={STYLE_CONTENT_WRAPPER}>
          <TextField
            underlineFocusStyle={STYLE_INPUTS}
            floatingLabelFocusStyle={STYLE_INPUTS}
            value={amount}
            onChange={this.changeAmount}
            floatingLabelText={_t('element.amount.floatingLabelText', {
              paymentType: getLabel(paymentType),
            })}
            type="number"
            min={0}
            step={0.01}
            inputStyle={
              isRefund(paymentType) ? STYLE_PRICE_REFUND : STYLE_PRICE_INPUT
            }
            style={{ height: 100 }}
          />
        </div>
        {proratedAmount !== undefined && (
          <div style={STYLE_CONTENT_WRAPPER}>
            <Checkbox
              label={_t('element.dialog.useProratedAmount')}
              checked={useProratedAmount}
              onCheck={this.handleUseProratedAmount}
            />
          </div>
        )}
        <div style={STYLE_CONTENT_WRAPPER}>
          <SelectField
            underlineFocusStyle={STYLE_INPUTS}
            /* floatingLabelFocusStyle={STYLE_INPUTS} */ value={origin}
            onChange={this.changeOrigin}
            floatingLabelText={_tg('field.payment.origin')}
          >
            {getOrigins(paymentType)}
          </SelectField>
          <div style={{ display: 'flex', alignItems: 'baseline' }}>
            <DatePicker
              DateTimeFormat={IntlPolyfill.DateTimeFormat}
              locale="fr"
              autoOk
              textFieldStyle={{ width: 125, marginRight: 5 }}
              value={date.toDate()}
              onChange={this.onChangeDate}
              floatingLabelText={_tg('field.date.date')}
              container="inline"
              formatDate={formatDate}
            />
            <TextField
              underlineFocusStyle={STYLE_INPUTS}
              floatingLabelFocusStyle={STYLE_INPUTS}
              min={0}
              max={23}
              step={1}
              floatingLabelText={_tg('field.date.hours')}
              type="number"
              style={{ width: 50, marginRight: 5 }}
              value={date.hours()}
              onChange={this.onChangeHours}
            />
            :
            <TextField
              underlineFocusStyle={STYLE_INPUTS}
              floatingLabelFocusStyle={STYLE_INPUTS}
              min={0}
              max={59}
              step={1}
              floatingLabelText={_tg('field.date.min_plural')}
              type="number"
              style={{ width: 50, marginLeft: 5 }}
              value={date.minutes()}
              onChange={this.onChangeMinutes}
            />
          </div>
        </div>
        <div style={STYLE_CONTENT_WRAPPER}>
          <SelectField
            underlineFocusStyle={STYLE_INPUTS}
            /* floatingLabelFocusStyle={STYLE_INPUTS} */ value={mode}
            onChange={this.changeMode}
            floatingLabelText={_tg('field.payment.mode')}
          >
            {modes}
          </SelectField>
        </div>
        <TextField
          underlineFocusStyle={STYLE_INPUTS}
          floatingLabelFocusStyle={STYLE_INPUTS}
          value={comment}
          onChange={this.onChangeComment}
          floatingLabelText={_tg('field.comment')}
          fullWidth
          multiLine
          rows={3}
          rowsMax={3}
        />
        {error && (
          <div style={STYLE_ERROR_WRAPPER}>
            <ErrorBlock error={{ message: error }} />
          </div>
        )}
      </Dialog>
    );
  }
}

export default connect(state => {
  const { userInfo } = getApiState(state);
  return { userInfo };
})(AddPayment);
