import React, { CSSProperties } from 'react';
import CircularProgress from 'material-ui/CircularProgress';
import FlatButton from 'material-ui/FlatButton';
import IconButton from 'material-ui/IconButton';
import AddNew from 'material-ui/svg-icons/content/add-box';
import { connect } from 'react-redux';
import Snackbar from 'material-ui/Snackbar';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import * as H from 'history';

import { BKG_CYAN_SELECTED } from 'theme';
import Content from 'commons/Content';
import ErrorBlock from 'commons/ErrorBlock';
import FlexCenter from 'commons/FlexCenter';
import SectionTitle from 'commons/SectionTitle';
import TimelinePoint from 'commons/TimelinePoint';
import {
  deleteCcspRecourse,
  fetchCcspRecourseByNumber,
  getCcspSummaryPdfUrl,
  getDirectUploadLink,
  insertCcspVersion,
} from 'api/ccsp';
import { openNewAuthentifiedTab } from 'api/helpers';
import { CcspRecourseDTO, CcspVersionDTO } from 'api/ccsp/types';
import { FpsCaseDTO } from 'api/fps/types';
import Watchings from 'commons/Watchings';
import { computeSurcharge, getLastVersion } from 'tefps/Fps/Detail/helpers';
import { DirectUploadDTO } from 'api/mediaupload/types';
import { InternalAgent } from 'api/auth/types';
import { getApiState } from 'api/duck';
import EditUserInfoButton from 'commons/Recourses/EditUserInfo/Button';
import EditUserInfoModal from 'commons/Recourses/EditUserInfo/Modal';
import EditRecourseInfoButton from 'commons/Recourses/EditRecourseInfo/Button';
import EditRecourseInfoModal from 'commons/Recourses/EditRecourseInfo/Modal';

import ConfirmAction from '../../../commons/ConfirmAction';

import FpsBlock from './FpsBlock';
import CcspInfoBlock from './CcspInfoBlock';
import DecisionBlock from './DecisionBlock';
import VersionManager from './VersionManager';
import {
  findVersion,
  isCcspRecourseAccepted,
  isCcspRecourseCompleted,
  shouldShowFpsWarning,
} from './helpers';
import CcspVersionInfoBlock from './CcspVersionInfoBlock';
import UserBlock from './UserBlock';

const { _t, _tg } = window.loadTranslations(__filename);

const STYLE_PAGE: CSSProperties = {
  width: '85%',
  margin: '0 auto',
  marginBottom: 40,
};

type Props = {
  match: {
    params: {
      ccspNumber: string;
    };
  };
  userInfo: InternalAgent;
  canDelete: boolean;
} & RouteComponentProps;

type State = {
  message: string;
  error: Error | null | undefined;
  ccsp: CcspRecourseDTO | null | undefined;
  fps: FpsCaseDTO | null | undefined;
  fpsVersionWarning: boolean;
  addCcspVersion: boolean;
  editUserType: string | null | undefined;
  isConfirmDeleteOpen: boolean;
  isEditRecourseInfoOpen: boolean;
};

class RecourseDetail extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      message: '',
      ccsp: null,
      fps: null,
      fpsVersionWarning: true,
      addCcspVersion: false,
      error: null,
      editUserType: null,
      isConfirmDeleteOpen: false,
      isEditRecourseInfoOpen: false,
    };
  }

  componentDidMount(): void {
    const { match } = this.props;
    void this.fetchRecourse(match.params.ccspNumber, true);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: Props): void {
    void this.fetchRecourse(nextProps.match.params.ccspNumber, true);
  }

  applyChanges = (ccsp: CcspRecourseDTO): void => {
    this.setState({ ccsp });
  };

  applyChangesOnCcspInfo = (ccsp: CcspRecourseDTO): void => {
    const { history } = this.props;
    history.push(`/ccsp/detail/${ccsp.ccspNumber}`);
  };

  onChangeEditUserType = (type: string | null | undefined): void =>
    this.setState({ editUserType: type });

  onCloseEditRecourseInfo = (): void =>
    this.setState({ isEditRecourseInfoOpen: false });

  openSnackBar = (message: string): void => this.setState({ message });
  closeErrorSnackBar = (): void => this.setState({ message: '' });

  getDirectUploadLink = async (mediaType: string): Promise<DirectUploadDTO> => {
    const { ccsp } = this.state;

    if (!ccsp) {
      throw Error;
    }

    return getDirectUploadLink(ccsp?.id, mediaType);
  };

  fetchRecourse = async (ccspNumber: string, reset = false): Promise<void> => {
    if (reset) {
      this.setState({ ccsp: null, fps: null, error: null });
    }

    try {
      const { recourse, fps } = await fetchCcspRecourseByNumber(ccspNumber);
      const latestFpsVersion = getLastVersion(fps.versions, undefined).id;
      const fpsVersionWarning = shouldShowFpsWarning(
        recourse,
        latestFpsVersion
      );
      this.setState({ ccsp: recourse, fps, error: null, fpsVersionWarning });
    } catch (error) {
      this.setState({
        error: error as Error,
        ccsp: null,
        fps: null,
        fpsVersionWarning: true,
      });
    }
  };

  openCcspSummaryPdf = (): void => {
    const { ccsp } = this.state;

    // Guard
    if (!ccsp) return;
    openNewAuthentifiedTab(getCcspSummaryPdfUrl(ccsp.id));
  };

  insertVersion = async (newVersion: CcspVersionDTO): Promise<void> => {
    const { ccsp } = this.state;
    if (!ccsp) return;

    try {
      await insertCcspVersion(ccsp.id, newVersion);
      void this.fetchRecourse(ccsp.ccspNumber, false);
    } catch (error) {
      this.setState({ error: error as Error, ccsp: null, fps: null });
    }
  };

  openAddCcspVersionModal = (): void => this.setState({ addCcspVersion: true });
  closeAddCcspVersionModal = (): void =>
    this.setState({ addCcspVersion: false });

  computeIsAntaiCanceled = (fpsCase: FpsCaseDTO): boolean => {
    if (fpsCase.antaiFps) {
      return fpsCase.antaiFps.fps.debtRecoveryList.some(debtRecoveryItem => {
        return debtRecoveryItem.titles.some(
          title => title.type === 'ANNULATION'
        );
      });
    }
    return false;
  };

  onDelete = async (history: H.History): Promise<void> => {
    const { ccsp } = this.state;
    try {
      await deleteCcspRecourse(ccsp ? ccsp.id : '');
      history.push({ pathname: '/tsp/list' });
    } catch (e) {
      this.openSnackBar(
        "Une erreur s'est produite pendant la suppression du TSP"
      );
    }
  };

  render(): React.ReactNode {
    const { userInfo, canDelete, history } = this.props;
    const {
      message,
      ccsp,
      fps,
      fpsVersionWarning,
      addCcspVersion,
      error,
      editUserType,
      isConfirmDeleteOpen,
      isEditRecourseInfoOpen,
    } = this.state;

    if (error) {
      return (
        <Content>
          <div style={{ padding: '25px 0px 0px 70px' }}>
            <FlatButton
              href="#/tsp/list"
              label={_t('element.button.backToList')}
            />
          </div>
          <FlexCenter>
            <ErrorBlock
              message={_tg('feedback.error.fetchCcsp_plural')}
              error={error}
            />
          </FlexCenter>
        </Content>
      );
    }

    if (!ccsp || !fps) {
      return (
        <Content>
          <div style={{ padding: '25px 0px 0px 70px' }}>
            <FlatButton
              href="#/tsp/list"
              label={_t('element.button.backToList')}
            />
          </div>
          <FlexCenter>
            <CircularProgress />
          </FlexCenter>
        </Content>
      );
    }

    const fpsVersionConcernedByClaim = findVersion(fps, ccsp.fpsId);
    if (!fpsVersionConcernedByClaim) {
      return <span>{_t('feedback.error.fetchFps')}</span>;
    }

    let currentTotalSize = 0;
    if (ccsp.versions) {
      currentTotalSize = ccsp.versions.reduce((acc, version) => {
        const files = version.files
          ? version.files.filter(f => !f.deleted)
          : [];
        return acc + files.reduce((acc2, file) => acc2 + file.size, 0);
      }, 0);
    }

    const canModifyCcsp = userInfo.rights.includes('CCSP_UPDATE');

    return (
      <Content>
        <div style={{ padding: '25px 0px 0px 70px', marginBottom: -10 }}>
          <FlatButton
            href="#/tsp/list"
            label={_t('element.button.backToList')}
          />
          |{' '}
          <FlatButton
            label={_t('element.button.exportPdf')}
            onClick={this.openCcspSummaryPdf}
          />
          {canDelete && !isCcspRecourseCompleted(ccsp.status) && (
            <span>
              |{' '}
              <FlatButton
                label={_t('element.button.delete')}
                onClick={() => this.setState({ isConfirmDeleteOpen: true })}
              />
            </span>
          )}
        </div>

        <div style={STYLE_PAGE}>
          <FpsBlock
            fps={fps}
            version={fpsVersionConcernedByClaim}
            showWarning={fpsVersionWarning}
          />
          <SectionTitle
            title={
              <span>
                {_t('element.title.userInfo')}{' '}
                {canModifyCcsp && (
                  <EditUserInfoButton
                    onClick={this.onChangeEditUserType}
                    userType="user"
                    title="Modifier les informations de l'usager"
                  />
                )}
              </span>
            }
          />
          <UserBlock ccsp={ccsp} />
          <SectionTitle
            title={
              <span>
                {_t('element.title.ccspInfo')}{' '}
                {canModifyCcsp && !isCcspRecourseCompleted(ccsp.status) && (
                  <EditRecourseInfoButton
                    onClick={() =>
                      this.setState({ isEditRecourseInfoOpen: true })
                    }
                    title="Modifier les informations de la saisine"
                  />
                )}
              </span>
            }
          />
          <CcspInfoBlock ccsp={ccsp} />
          <SectionTitle title={_t('element.title.ccspLogs')} />
          {ccsp.versions.map((version, index) => (
            <div style={{ display: 'flex', width: '100%' }}>
              <TimelinePoint
                isFirst={index === 0}
                isLast={index === ccsp.versions.length - 1}
                style={{ flex: 1 }}
              />
              <CcspVersionInfoBlock
                ccspVersion={version}
                ccspId={ccsp.id}
                ccspNumber={ccsp.ccspNumber}
                fetchRecourse={this.fetchRecourse}
              />
            </div>
          ))}
          {!isCcspRecourseCompleted(ccsp.status) && (
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              <IconButton
                tooltip={_t('element.tooltip.update')}
                style={{ padding: 0, marginBottom: 16 }}
                iconStyle={{
                  color: BKG_CYAN_SELECTED,
                  height: 50,
                  width: 50,
                }}
                onClick={this.openAddCcspVersionModal}
              >
                <AddNew />
              </IconButton>
              {addCcspVersion && (
                <VersionManager
                  ccspId={ccsp.id}
                  ccspNumber={ccsp.ccspNumber}
                  fpsId={ccsp.fpsId}
                  getDirectUploadLink={this.getDirectUploadLink}
                  currentTotalSize={currentTotalSize}
                  closeModal={this.closeAddCcspVersionModal}
                  onSave={this.insertVersion}
                />
              )}
            </div>
          )}
          <SectionTitle title={_t('element.decision')} />
          <div style={{ marginTop: 14, marginBottom: 30 }}>
            {_t('element.ccspDecision')}
          </div>
          <DecisionBlock
            ccspId={ccsp.id}
            fpsId={ccsp.fpsId}
            isAccepted={isCcspRecourseAccepted(ccsp.status)}
            initialPrice={ccsp.initialPrice}
            initialMajoration={
              ccsp.initialMajoration != null
                ? ccsp.initialMajoration
                : computeSurcharge(fps.antaiFps)
            }
            newFpsPrice={ccsp.newFpsPrice}
            newMajoration={ccsp.newMajoration}
            changeDecision={this.insertVersion}
            isAntaiCanceled={this.computeIsAntaiCanceled(fps)}
          />
        </div>
        <EditUserInfoModal
          type={editUserType}
          onChangeEditUserType={this.onChangeEditUserType}
          recourse={ccsp}
          applyChanges={this.applyChanges}
          showMessage={this.openSnackBar}
        />
        <EditRecourseInfoModal
          isOpen={isEditRecourseInfoOpen}
          onClose={this.onCloseEditRecourseInfo}
          recourse={ccsp}
          applyChanges={this.applyChangesOnCcspInfo}
          showMessage={this.openSnackBar}
        />
        <span title={_t('element.deleteModal.title')}>
          <ConfirmAction
            action={() => this.onDelete(history)}
            isOpen={isConfirmDeleteOpen}
            message={_t('element.deleteModal.message')}
            enabled
          />
        </span>

        {/* We use the FPS as the watched resource so that we can be notified if someone is watching the FPS */}
        <Watchings resourceType="ccsp" resourceId={ccsp.id} />
        <Snackbar
          open={!!message}
          message={message}
          autoHideDuration={4000}
          onRequestClose={this.closeErrorSnackBar}
        />
      </Content>
    );
  }
}

export default connect(state => {
  const { userInfo } = getApiState(state);
  return {
    userInfo,
    canDelete: userInfo && userInfo.rights.includes('CCSP_DELETE'),
  };
})(withRouter(RecourseDetail));
