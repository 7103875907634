import React, { CSSProperties } from 'react';

import FakeInputBlock from 'commons/FakeInputBlock';
import Date from 'commons/Date';
import Separator from 'commons/Separator';
import { CcspRecourseDTO } from 'api/ccsp/types';

import { translateCcspVersionStatus } from './helpers';

const { _t, _tg } = window.loadTranslations(__filename);

const STYLE_CONTAINER: CSSProperties = {
  margin: '30px 0 10px 0',
  display: 'flex',
  justifyContent: 'space-around',
};

const CcspInfoBlock = ({ ccsp }: { ccsp: CcspRecourseDTO }): JSX.Element => (
  <span>
    <div style={STYLE_CONTAINER}>
      <FakeInputBlock
        containerStyle={{ width: '45%' }}
        title={_tg('field.ccsp.ccspNumber')}
        value={ccsp.ccspNumber}
      />
      <Separator />
      <FakeInputBlock
        containerStyle={{ width: '45%' }}
        title={_t('field.ccspDate')}
        value={<Date datetime={ccsp.openingDate} />}
      />
    </div>
    <div style={{ ...STYLE_CONTAINER, margin: '0 0 5px 0' }}>
      <FakeInputBlock
        containerStyle={{ width: '45%' }}
        title={_t('field.creationDatetime')}
        value={<Date datetime={ccsp.creationDatetime} />}
      />
      <Separator />
      <FakeInputBlock
        containerStyle={{ width: '45%' }}
        title={_t('field.transmissionDate')}
        value={<Date datetime={ccsp.transmissionDate} />}
      />
    </div>
    <div style={{ ...STYLE_CONTAINER, margin: '10px 0 30px 0' }}>
      <FakeInputBlock
        containerStyle={{ width: '45%' }}
        title={_t('field.ccspDeadline')}
        value={<Date datetime={ccsp.deadline} />}
      />
      <Separator />
      <FakeInputBlock
        containerStyle={{ width: '45%' }}
        title={_tg('field.status')}
        value={translateCcspVersionStatus()[ccsp.status]}
      />
    </div>
  </span>
);

export default CcspInfoBlock;
