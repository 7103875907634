import { WatchFunctionType } from '@cvfm-front/commons-types';
import { TaoSubscriberDTO } from '@cvfm-front/tefps-types';
import { Watcher } from '@cvfm-front/commons-utils';

import { apiGet } from '../../../api/helpers';

export interface TaoSubscriberFetchServiceInterfaceFactory {
  (): TaoSubscriberFetchServiceInterface;
}

export interface TaoSubscriberFetchServiceInterface {
  fetchTaoSubscriber: (
    subscriberId: string
  ) => Promise<TaoSubscriberDTO | undefined>;
  watchIsLoading: WatchFunctionType<boolean>;
  watchHasLoadingError: WatchFunctionType<Error | undefined>;
}

const ROOT_API = '/api/proxy/tao/api/v1/{cityId}/subscriber';

const TaoSubscriberFetchService: TaoSubscriberFetchServiceInterfaceFactory = () => {
  const { setValue: setIsLoading, watchValue: watchIsLoading } = Watcher(false);

  const {
    setValue: setHasLoadingError,
    watchValue: watchHasLoadingError,
  } = Watcher(undefined);

  const fetchTaoSubscriber: TaoSubscriberFetchServiceInterface['fetchTaoSubscriber'] = async subscriberId => {
    try {
      setIsLoading(true);
      setHasLoadingError(undefined);
      return await apiGet<TaoSubscriberDTO>(`${ROOT_API}/${subscriberId}`);
    } catch (error) {
      setHasLoadingError(error);
      return undefined;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    fetchTaoSubscriber,
    watchIsLoading,
    watchHasLoadingError,
  };
};

export default TaoSubscriberFetchService;
