import { Setter, WatchFunctionType } from '@cvfm-front/commons-types';
import { Watcher } from '@cvfm-front/commons-utils';
import {
  TaoProductDTO,
  TaoProductSearchRequestDTO,
} from '@cvfm-front/tefps-types';
import { apiPost } from 'api/helpers';

export interface TaoProductSearchServiceInterfaceFactory {
  (): TaoProductSearchServiceInterface;
}

export interface TaoProductSearchServiceInterface {
  getProducts: () => void;
  watchIsLoading: WatchFunctionType<boolean>;
  watchHasLoadingError: WatchFunctionType<Error | undefined>;
  watchRequest: WatchFunctionType<Partial<TaoProductSearchRequestDTO>>;
  watchProducts: WatchFunctionType<TaoProductDTO[]>;
  setRequest: Setter<Partial<TaoProductSearchRequestDTO>>;
  resetRequest: () => void;
  watchTotalHits: WatchFunctionType<number>;
}

const ROOT_API = '/api/proxy/tao/api/v1/{cityId}/taoProduct/search';

const TaoProductSearchService: TaoProductSearchServiceInterfaceFactory = () => {
  const { setValue: setIsLoading, watchValue: watchIsLoading } = Watcher(false);

  const {
    setValue: setHasLoadingError,
    watchValue: watchHasLoadingError,
  } = Watcher(undefined);

  const { watchValue: watchProducts, setValue: setProducts } = Watcher<
    TaoProductDTO[]
  >([]);

  const {
    watchValue: watchRequest,
    setValue: setRequest,
    getValue: getRequest,
  } = Watcher<Partial<TaoProductSearchRequestDTO>>({});

  const { watchValue: watchTotalHits, setValue: setTotalHits } = Watcher<
    number
  >(0);

  const getProducts: TaoProductSearchServiceInterface['getProducts'] = async () => {
    const request = getRequest();
    try {
      setIsLoading(true);
      setHasLoadingError(undefined);
      const response = await apiPost<TaoProductDTO[]>(ROOT_API, request);
      setProducts(response);
      setTotalHits(response.length);
    } catch (error) {
      setHasLoadingError(error);
      setProducts([]);
    } finally {
      setIsLoading(false);
    }
  };

  const resetRequest = () => {
    setRequest({});
  };

  return {
    getProducts,
    watchIsLoading,
    watchHasLoadingError,
    watchRequest,
    watchProducts,
    setRequest,
    resetRequest,
    watchTotalHits,
  };
};
export default TaoProductSearchService;
