import React, { CSSProperties, useState } from 'react';
import FlatButton from 'material-ui/FlatButton';

import Watchings from 'commons/Watchings';
import SeparatorWithTitle from 'commons/SeparatorWithTitle';
import Content from 'commons/Content';
import { InternalAgent } from 'api/auth/types';
import { FpsCaseDTO } from 'api/fps/types';
import { BKG_PINK } from 'theme';
import { getLast } from 'commons/Utils/arrayUtils';

import CessionFpsLink from './CessionFpsLink';
import MainBlock from './MainBlock';
import AddEvent from './AddEvent';
import TimelineBlock from './TimelineBlock';
import {
  canAddPayment,
  canAddRecourse,
  computeAmountToPay,
  findInitialVersion,
  getLastVersion,
  TimelineItem,
} from './helpers';
import FpsMapButtonBar from './FpsMapButtonBar';
import TransferredFpsLink from './TransferredFpsLink';
import FpsInconsistentBlock from './FpsInconsistentBlock';
import FpsExportTypeModal from './FpsExportTypeModal';

const STYLE_HISTORIQUE_TITLE: CSSProperties = {
  width: '100%',
  padding: '0 30px',
  margin: '65px 0px 50px 0px',
};

const STYLE_WRAPPER: CSSProperties = {
  padding: '10px 50px 50px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

type Props = {
  fps: FpsCaseDTO;
  userInfo: InternalAgent;
  items: Array<TimelineItem>;
  securityPeriodForRecordingPayment: number;
  reloadFps: () => any;
  onExport: (exportWithPictures: boolean) => void;
};

export const FpsPage = (props: Props) => {
  const {
    fps,
    userInfo,
    items,
    securityPeriodForRecordingPayment,
    reloadFps,
    onExport,
  } = props;

  const initialVersion = findInitialVersion(fps);
  const amountToPay = computeAmountToPay(fps);
  const latestVersion = getLastVersion(fps.versions);
  const optReducedDatetime = latestVersion.reducedDatetime;
  const reducedDatetime = optReducedDatetime
    ? new Date(optReducedDatetime)
    : undefined;
  const currentReducedDatetime = latestVersion.reducedDatetime
    ? new Date(new Date(latestVersion.reducedDatetime))
    : undefined;
  const debtRecoveryList = fps.antaiFps?.fps?.debtRecoveryList;
  const titles = getLast(debtRecoveryList ?? [])?.titles;
  const currentSurcharge = getLast(titles ?? [])?.surcharge;
  const canUpdateFpsConsistency =
    userInfo && userInfo.rights.includes('FPS_CONSISTENCY_UPDATE');

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const openModal = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setIsOpen(true);
  };

  return (
    <Content>
      <div style={{ padding: '30px 0px 5px 70px' }}>
        <FlatButton href="#/fps/list" label="Retour à la liste de FPS" />|{' '}
        <FlatButton label="Exporter" onClick={openModal} />
      </div>
      {initialVersion && initialVersion.parent && (
        <CessionFpsLink fpsId={initialVersion.parent} />
      )}
      {fps.hasBeenTransferredTo && (
        <TransferredFpsLink fpsId={fps.hasBeenTransferredTo} />
      )}
      <div style={STYLE_WRAPPER}>
        <MainBlock
          fps={fps}
          securityPeriodForRecordingPayment={securityPeriodForRecordingPayment}
        />
        <FpsInconsistentBlock
          fps={fps}
          canUpdateFpsConsistency={canUpdateFpsConsistency}
          reloadFps={reloadFps}
        />
        <AddEvent
          isAntaiSync={
            !!initialVersion && initialVersion.authTransferDatetime != null
          }
          // TODO: flow-to-ts disabled, useless content ? plate={fps.licensePlate ? fps.licensePlate.plate : ''}
          rights={userInfo.rights}
          rootFpsId={initialVersion ? initialVersion.id : ''}
          fpsId={latestVersion.id}
          statementDatetime={fps.statementDatetime}
          currentPrice={latestVersion.finePrice}
          currentReducedPrice={latestVersion.reducedFinePrice}
          currentReducedDatetime={currentReducedDatetime}
          currentSurcharge={currentSurcharge}
          paymentStatus={fps.paymentStatus}
          responsibleOfReducedPeriod={fps.responsibleOfReducedPeriod}
          reloadFps={reloadFps}
          amountToPay={amountToPay}
          canAddPayment={canAddPayment(
            fps,
            amountToPay,
            securityPeriodForRecordingPayment
          )}
          canAddRecourse={canAddRecourse(fps, latestVersion.finePrice)}
          isFpsReducedDatetimeExpired={
            reducedDatetime ? reducedDatetime.valueOf() < Date.now() : null
          }
        />
        <FpsMapButtonBar fpsId={fps.versions[0].id} />
        <SeparatorWithTitle
          style={STYLE_HISTORIQUE_TITLE}
          title="Historique"
          color={BKG_PINK}
          titleSize={20}
        />
        {items.map((item: TimelineItem, index: number) => (
          <TimelineBlock
            key={index}
            isFirst={index === 0}
            isLast={index === items.length - 1}
            item={item}
          />
        ))}
      </div>
      <Watchings resourceType="fps" resourceId={fps.versions[0].id} />
      <FpsExportTypeModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onExport={onExport}
      />
    </Content>
  );
};

export default FpsPage;
