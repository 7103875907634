import React, { CSSProperties, useState } from 'react';
import { CircularProgress } from 'material-ui';

import SimpleTable from 'commons/SimpleTable';
import Date from 'commons/Date';
import useProducts from 'commons/hooks/useProducts';
import useEligibilityRights from 'commons/hooks/useEligibilityRights';
import { renderPlates } from 'commons/Utils/plateUtils';

import { EligibilityOverviewDTO } from './types';

const { _tg } = window.loadTranslations(__filename);

type Props = {
  loadMoreRows: (arg0: { startIndex: number }) => Promise<void>;
  onUpdateSort: (colNumber: number, increasingOrder: boolean) => void;
  remoteRowCount?: number;
  colSorted?: number;
  sortOrder?: boolean;
  onClick: (id: string) => void;
};

const STYLE_LINK: CSSProperties = {
  height: 50,
  width: '100%',
};

const STYLE_HOVERED: CSSProperties = {
  ...STYLE_LINK,
  color: 'white',
  cursor: 'pointer',
};

const EligibilityTable = ({
  loadMoreRows,
  onUpdateSort,
  colSorted,
  sortOrder,
  onClick,
}: Props): JSX.Element => {
  const products = useProducts();
  const eligibilities = useEligibilityRights();

  const [hovered, setHovered] = useState<string | null | undefined>(null);

  const handleMouseLeave = () => setHovered(null);
  const handleMouseEnter = ({
    currentTarget,
  }: React.MouseEvent<HTMLElement>) => {
    setHovered(currentTarget.dataset.eligibilityId);
  };
  const handleClick = ({ currentTarget }: React.MouseEvent<any>) =>
    onClick(currentTarget.dataset.eligibilityId);

  const tableCols = [
    { label: _tg('field.eligibility.id'), width: 330, onSort: true },
    {
      label: _tg('field.eligibility.eligibilityName'),
      width: 200,
      grow: 3,
      onSort: true,
    },
    { label: _tg('field.date.startValidity'), width: 200, onSort: true },
    { label: _tg('field.date.endValidity'), width: 200, onSort: true },
    { label: _tg('field.vehicle.plates'), width: 300, onSort: true },
    { label: _tg('field.address.validityZones'), width: 300, onSort: true },
  ];

  function getStyle(
    id: string,
    hovered: string | null | undefined
  ): CSSProperties {
    return id === hovered ? STYLE_HOVERED : STYLE_LINK;
  }

  if (!products || !eligibilities) {
    return <CircularProgress />;
  }

  return (
    <SimpleTable
      style={{ marginTop: 25 }}
      cols={tableCols}
      rowHeight={50}
      header
      onSort={onUpdateSort}
      colSorted={colSorted}
      sortOrder={sortOrder}
      loadMoreRows={loadMoreRows}
      remoteRowCount={eligibilities.totalHits}
      itemsRenderer={(eligibility: EligibilityOverviewDTO) => [
        <span
          onClick={handleClick}
          style={getStyle(eligibility.eligibilityId, hovered)}
          data-eligibility-id={eligibility.eligibilityId}
          onMouseLeave={handleMouseLeave}
          onMouseEnter={handleMouseEnter}
        >
          {eligibility.eligibilityId}
        </span>,
        <span>
          {products[eligibility.productId]
            ? products[eligibility.productId].name
            : eligibility.productId}
        </span>,
        <Date datetime={eligibility.startDateOfValidity} />,
        <Date datetime={eligibility.endDateOfValidity} />,
        <span>{renderPlates(eligibility.hashedPlates, 3)}</span>,
        <span>{eligibility.zoneIdsOfValidity?.join(', ') || []}</span>,
      ]}
      items={eligibilities.results || []}
    />
  );
};

export default EligibilityTable;
