import { VehicleCategory } from '@cvfm-front/tefps-types';
import { Version } from '@cvfm-front/tefps-ui';
import { ControlVersionDTO } from 'api/control/types';
import { formatDatetime } from 'commons/Date';
import services from 'commons/services';
import { ExemptionReason } from 'config/duck';

const { _tg } = window.loadTranslations(__filename);

export function transformControlVersionDtoToVersion(
  controlVersion: ControlVersionDTO,
  index: number,
  exemptionReasonsConfigurations: ExemptionReason[] | undefined,
  isCreationDateTimeDisplay: boolean
): Version {
  const versionToDisplay: Version = {
    title: `Version ${index + 1}`,
    fields: [],
  };

  if (isCreationDateTimeDisplay) {
    versionToDisplay.fields.push({
      id: `date-${index}`,
      type: 'string',
      label: _tg('field.date.creation'),
      value: formatDatetime({ datetime: controlVersion.creationDatetime }),
    });
  }

  versionToDisplay.fields.push({
    id: `control-status-${index}`,
    type: 'ControlStatus',
    label: _tg('field.status'),
    value: controlVersion.controlStatus,
  });

  if (controlVersion.exemptionReason) {
    versionToDisplay.fields.push({
      id: `exemption-reason-${index}`,
      type: 'string',
      label: _tg('field.control.exemptionReason'),
      value:
        exemptionReasonsConfigurations?.find(
          obj => obj.key === controlVersion.exemptionReason
        )?.label || controlVersion.exemptionReason,
    });
  }

  versionToDisplay.fields.push({
    id: `plate-${index}`,
    type: 'string',
    label: _tg('field.vehicle.plate'),
    value:
      controlVersion.licensePlate?.plate ||
      controlVersion.licensePlate?.hashedPlate ||
      '',
  });
  versionToDisplay.fields.push({
    id: `zone-${index}`,
    type: 'string',
    label: _tg('field.address.zone'),
    value: services.zoning.getZoneNameFromId(controlVersion.zoneId),
  });

  if (controlVersion.patrolZoneId) {
    versionToDisplay.fields.push({
      id: `patrolZone-${index}`,
      type: 'string',
      label: _tg('field.control.patrolZone'),
      value: services.patrolZoneService.getPatrolZoneNameFromId(
        controlVersion.patrolZoneId
      ),
    });
  }

  function isVehicleCategory(key: any): key is keyof typeof VehicleCategory {
    return key in VehicleCategory;
  }
  if (
    controlVersion.vehicleCategory &&
    isVehicleCategory(controlVersion.vehicleCategory)
  ) {
    versionToDisplay.fields.push({
      id: `vehicle-category-${index}`,
      type: 'string',
      label: _tg('field.vehicle.category'),
      value: VehicleCategory[controlVersion.vehicleCategory],
    });
  }

  if (controlVersion.statementLocation) {
    versionToDisplay.fields.push({
      id: `gps-${index}`,
      type: 'FnmsLocation',
      label: _tg('field.control.position'),
      value: controlVersion.statementLocation,
    });
  }

  if (controlVersion.statementAddress) {
    versionToDisplay.fields.push({
      id: `address-${index}`,
      type: 'Address',
      label: _tg('field.address.address'),
      value: controlVersion.statementAddress,
    });
  }

  if (controlVersion.treated) {
    versionToDisplay.fields.push({
      id: `treated-${index}`,
      type: 'boolean',
      label: _tg('field.control.treated'),
      value: controlVersion.treated,
    });
  }

  if (controlVersion.treatmentAgent) {
    versionToDisplay.fields.push({
      id: `treatment-agent-${index}`,
      type: 'ControlAgent',
      label: _tg('field.agent.reviewAgent'),
      labelAgentId: _tg('field.agent.reviewAgentId'),
      value: controlVersion.treatmentAgent,
    });
  }

  if (controlVersion.fineLegalId) {
    versionToDisplay.fields.push({
      id: `fine-legal-id-${index}`,
      type: 'string',
      label: _tg('field.control.fpsId'),
      value: controlVersion.fineLegalId,
    });
  }

  return versionToDisplay;
}
