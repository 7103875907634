import * as React from 'react';

import FakeInputBlock from 'commons/FakeInputBlock';
import Separator from 'commons/Separator';
import Date from 'commons/Date';
import { ApaMailDTO, ApaMailStatus, ApaMailType } from 'api/antai/fps';
import { DataBoxItem, DataBoxItemWrapper } from 'commons/DataBox';

const { _tg } = window.loadTranslations(__filename);

type Props = {
  mail: ApaMailDTO;
};

const MAIL_TYPE_LABEL: { [key in ApaMailType]: string } = {
  E_APA: 'APA Electronique',
  APA: 'APA',
  RECEIPT: 'Reçu',
  NOT_YET_DETERMINED: 'Indéterminé',
};

const MAIL_STATUS_LABEL: { [key in ApaMailStatus]: string } = {
  TO_BE_SENT: 'À envoyer',
  POSTED: 'Posté',
  PUBLISHED: 'Publié',
  PND: 'Pli non distribué',
  CONSULTED: 'Consulté',
  NOT_CONSULTED: 'Non consulté',
};

const Mail = ({ mail }: Props) => {
  return (
    <div style={{ width: '100%' }}>
      <DataBoxItemWrapper>
        <DataBoxItem>
          <FakeInputBlock
            title={_tg('tefps.fps.detail.content.mail.sendingDate')}
            value={<Date datetime={mail.postingDatetime} withTime={false} />}
          />
          <FakeInputBlock
            title={_tg('tefps.fps.detail.content.mail.sendingTime')}
            value={<Date datetime={mail.postingDatetime} withDate={false} />}
          />
        </DataBoxItem>
        <Separator />
        <DataBoxItem>
          <FakeInputBlock
            title={_tg('tefps.fps.detail.content.mail.mailType')}
            value={MAIL_TYPE_LABEL[mail.type]}
          />
          <FakeInputBlock
            title={_tg('tefps.fps.detail.content.mail.status')}
            value={MAIL_STATUS_LABEL[mail.status]}
          />
        </DataBoxItem>
      </DataBoxItemWrapper>
    </div>
  );
};

export default Mail;
