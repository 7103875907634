import * as React from 'react';

import { HistoryAction } from '@cvfm-front/tefps-types';

const { _t, _tg } = window.loadTranslations(__filename);

const SendSubscriptionRenewalReminderActionV2 = ({
  action,
}: {
  action: HistoryAction;
}) => {
  return (
    <span>
      {_t('element.content', {
        smsNotification: action.smsNotification
          ? _tg('field.human.sms')
          : _tg('field.human.email'),
        planName: action.planName,
        plates: (action.plates || []).join(', '),
      })}
    </span>
  );
};

export default SendSubscriptionRenewalReminderActionV2;
