import React, { CSSProperties } from 'react';

import SimpleTable from 'commons/SimpleTable';
import FpsNumber from 'commons/FpsNumber';
import Date from 'commons/Date';
import { CcspOverviewDTO } from 'api/ccsp/types';

import { getStatusesFilterOptions } from './utils';

const { _t, _tg } = window.loadTranslations(__filename);

const STYLE_LINK: CSSProperties = {
  height: 50,
  width: '100%',
};

const STYLE_HOVERED: CSSProperties = {
  ...STYLE_LINK,
  color: 'white',
};

type CcspRecourseTableProps = {
  items: Array<CcspOverviewDTO>;
  loadMoreRows: (arg0: { startIndex: number }) => Promise<void>;
  onUpdateSort: (colNumber: number, increasingOrder: boolean) => void;
  remoteRowCount?: number;
  colSorted?: number;
  sortOrder?: boolean;
};

type CcspRecourseTableState = {
  hovered: string | null | undefined;
};

const getTableCols = () => [
  { label: _t('table.fpsmNumber'), width: 330, grow: 3, onSort: true },
  {
    label: _tg('field.ccsp.ccspNumber'),
    width: 200,
    onSort: true,
  },
  { label: _tg('field.ccsp.openingDate'), width: 200, onSort: true },
  {
    label: _t('table.deadline'),
    width: 200,
    onSort: true,
  },
  { label: _tg('field.status'), width: 125 },
];

function getStyle(id: string, hovered: string | null | undefined) {
  return id === hovered ? STYLE_HOVERED : STYLE_LINK;
}

class CcspRecourseTable extends React.Component<
  CcspRecourseTableProps,
  CcspRecourseTableState
> {
  state: CcspRecourseTableState = {
    hovered: null,
  };

  handleMouseLeave = () => this.setState({ hovered: null });
  handleMouseEnter = ({ currentTarget }: React.MouseEvent<any>) =>
    this.setState({ hovered: currentTarget.dataset.ccspId });

  render() {
    const { hovered } = this.state;
    const {
      items,
      loadMoreRows,
      remoteRowCount,
      colSorted,
      sortOrder,
      onUpdateSort,
    } = this.props;

    return (
      <SimpleTable
        style={{ marginTop: 25 }}
        cols={getTableCols()}
        rowHeight={50}
        header
        loadMoreRows={loadMoreRows}
        remoteRowCount={remoteRowCount}
        onSort={onUpdateSort}
        colSorted={colSorted}
        sortOrder={sortOrder}
        itemsRenderer={(ccsp: CcspOverviewDTO) => [
          <a
            style={getStyle(ccsp.fpsOrFpsmId, hovered)}
            data-ccsp-id={ccsp.fpsOrFpsmId}
            onMouseLeave={this.handleMouseLeave}
            onMouseEnter={this.handleMouseEnter}
            href={`#/tsp/detail/${ccsp.ccspNumber}`}
          >
            {ccsp.fpsOrFpsmId.length > 18 ? (
              <FpsNumber fpsId={ccsp.fpsOrFpsmId} />
            ) : (
              ccsp.fpsOrFpsmId
            )}
          </a>,
          <span>{ccsp.ccspNumber}</span>,
          <Date datetime={ccsp.openingDate} />,
          <Date datetime={ccsp.deadline} />,
          <span>
            {
              (
                getStatusesFilterOptions().find(s => s.value === ccsp.status) ||
                {}
              ).label
            }
          </span>,
        ]}
        items={items}
      />
    );
  }
}

export default CcspRecourseTable;
