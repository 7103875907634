import React, { CSSProperties, useState } from 'react';
import { connect } from 'react-redux';
import FileIcon from 'material-ui/svg-icons/content/content-paste';
import ClearRoundedIcon from 'material-ui/svg-icons/content/clear';
import DeleteIcon from 'material-ui/svg-icons/action/delete';
import { IconButton } from 'material-ui';
import Snackbar from 'material-ui/Snackbar';

import { CcspFileDTO } from 'api/ccsp/types';
import { deleteCcspFile } from 'api/ccsp';
import { ICON_RED, TEXT_COLOR_LIGHT } from 'theme';
import { formatNumber } from 'commons/Utils/numberUtil';
import { formatDate } from 'commons/Utils/dateUtil';
import ConfirmAction from 'commons/ConfirmAction';
import { getApiState } from 'api/duck';

const { _tg } = window.loadTranslations();

const STYLE_CONTAINER: CSSProperties = {
  flex: 1,
  display: 'flex',
  fontSize: 10,
  justifyContent: 'center',
  margin: 6,
};

const STYLE_NAME_CONTAINER: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  color: TEXT_COLOR_LIGHT,
  overflow: 'hidden',
};

const STYLE_FILE_NAME: CSSProperties = {
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  width: 90,
};

const STYLE_ICON: CSSProperties = {
  width: 40,
  height: 50,
};

type CcspFileBlockProps = {
  file: CcspFileDTO;
  ccspId: string;
  ccspNumber: string;
  fetchRecourse: (ccspNumber: string, reset: boolean) => void;
  canDelete: boolean;
};

const STYLE_ACTIONS: CSSProperties = {
  margin: '0px 0px 0px 15px',
  width: 20,
  height: 20,
  cursor: 'pointer',
  paddingBottom: 2,
  color: '#aaa',
};

const displayErrorMessage = (message: string): JSX.Element => {
  return (
    <Snackbar open={!!message} message={message} autoHideDuration={4000} />
  );
};

const CcspFileBlock = ({
  file,
  ccspId,
  ccspNumber,
  fetchRecourse,
  canDelete,
}: CcspFileBlockProps): JSX.Element | null => {
  if (!file) return null;

  const [isOpen, setIsOpen] = useState(false);

  const handleClickConfirm = async (ccspId: string, urn: string) => {
    await deleteCcspFile(ccspId, urn)
      .then(() => fetchRecourse(ccspNumber, true))
      .catch(() => displayErrorMessage("Une erreur s'est produite"));
  };

  function onConfirmDeleteCancel() {
    setIsOpen(false);
  }

  function onDeleteRequest(): void {
    setIsOpen(true);
  }

  return (
    <div style={STYLE_CONTAINER}>
      <a
        href={file.deleted ? '' : file.contentUrl}
        target="_blank"
        rel="noopener noreferrer"
        style={{ display: 'flex', width: '100%' }}
        title={_tg('action.downloadAttachment')}
      >
        {file.deleted ? (
          <ClearRoundedIcon style={STYLE_ICON} color={TEXT_COLOR_LIGHT} />
        ) : (
          <FileIcon style={STYLE_ICON} color={ICON_RED} />
        )}
        <div
          style={{ paddingTop: 5, marginLeft: 10, lineHeight: '12px', flex: 1 }}
        >
          <div style={STYLE_NAME_CONTAINER}>
            <div style={STYLE_FILE_NAME}>{file.name}</div>
            <div>
              <span style={{ textTransform: 'uppercase' }}>
                {file.deleted ? 'Expiré' : file.name.split('.').slice(-1)[0]}
              </span>
              <span> | </span>
              {file.deleted
                ? formatDate(new Date(file.deleted))
                : formatNumber(file.size, 'filesize')}
            </div>
          </div>
        </div>
      </a>
      {canDelete && !file.deleted ? (
        <ConfirmAction
          message="Voulez-vous vraiment supprimer cette pièce ?"
          enabled
          isOpen={isOpen}
          action={() => handleClickConfirm(ccspId, file.urn)}
          onClose={onConfirmDeleteCancel}
        >
          <IconButton onClick={onDeleteRequest} style={STYLE_ACTIONS}>
            <DeleteIcon />
          </IconButton>
        </ConfirmAction>
      ) : null}
    </div>
  );
};

export default connect(state => {
  const { userInfo } = getApiState(state);
  return {
    canDelete: userInfo && userInfo.rights.includes('CCSP_UPDATE'),
  };
})(CcspFileBlock);
