import moment from 'moment';

import { LOGOUT } from 'api/duck';

import { SubscriptionApplicationsSearchCriteria } from './types';

const SET_FILTERS = 'SubscriptionApplications/List/SET_FILTERS';

type State = {
  filters: SubscriptionApplicationsSearchCriteria;
};

export const initialFilters = (): SubscriptionApplicationsSearchCriteria => {
  return {
    firstName: null,
    lastName: null,
    subscriptionPlanIds: new Set(),
    zoneIds: new Set(),
    subscriberId: null,
    plate: null,
    customFields: null,
    subscriptionStatus: ['AWAITING_VALIDATION', 'AWAITING_PAYMENT'],
    dates: {
      from: moment()
        .subtract(1, 'years')
        .toDate(),
      to: undefined,
    },
  };
};

const initialState = (): State => ({
  // subscriptionPlans: {},
  filters: initialFilters(),
});

export default function subscriptionApplicationListReducer(
  state: State = initialState(),
  action: any
): State {
  switch (action.type) {
    case SET_FILTERS:
      return {
        ...state,
        filters: action.filters,
      };
    case LOGOUT:
      return initialState();
    default:
      return state;
  }
}

export function getSubscriptionApplicationsSearchState(state: any): State {
  return state.subscriptionApplicationsSearchCriteria;
}

export function setFilters(
  filters: SubscriptionApplicationsSearchCriteria
): any {
  return {
    type: SET_FILTERS,
    filters,
  };
}
