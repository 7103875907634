import * as React from 'react';
import { connect } from 'react-redux';

import { ResponsibleOfReducedPeriod } from 'api/fps/types';
import { PaymentStatus } from 'api/commonTypes';
import { getConfigState } from 'config/duck';
import AddCancelProposal from 'tefps/Fps/Detail/AddContents/AddCancelProposal';
import { AgentRight } from '@cvfm-front/tefps-types';

import AddPayment from './AddContents/AddPayment';
import AddCorrection from './AddContents/AddCorrection';
import AddRefund from './AddContents/AddRefund';
import AddRecourse from './AddContents/AddRecourse';
import AddFpsTransfer from './AddContents/AddFpsTransfer';
import ActionButtons from './ActionButtons';

type Props = {
  rootFpsId: string;
  fpsId: string;
  paymentStatus: PaymentStatus;
  currentPrice: number;
  currentReducedPrice: number | null | undefined;
  currentReducedDatetime: Date | null | undefined;
  currentSurcharge: number | undefined;
  statementDatetime: string;
  amountToPay: number;
  responsibleOfReducedPeriod: ResponsibleOfReducedPeriod;
  reloadFps: () => any;
  rights: Array<AgentRight>;
  cvfmRapoPartner: boolean;
  isAntaiSync: boolean;
  canAddPayment: boolean;
  canAddRecourse: boolean;
  isFpsReducedDatetimeExpired: boolean | null | undefined;
  fpsComments: Array<string>;
};

type State = {
  content: string | null | undefined;
};

class AddEvent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      content: null,
    };
  }

  close = () => this.setState({ content: null });
  open = (name: string) => () => this.setState({ content: name });

  render() {
    const {
      rights,
      cvfmRapoPartner,
      rootFpsId,
      fpsId,
      isAntaiSync,
      canAddPayment,
      canAddRecourse,
      amountToPay,
      paymentStatus,
      currentPrice,
      currentReducedPrice,
      currentReducedDatetime,
      currentSurcharge,
      statementDatetime,
      isFpsReducedDatetimeExpired,
      responsibleOfReducedPeriod,
      fpsComments,
      reloadFps,
    } = this.props;
    const { content } = this.state;

    if (currentPrice === 0 && amountToPay === 0) {
      return <div />;
    }

    return (
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <ActionButtons
          openModal={this.open}
          amountToPay={amountToPay}
          canAddRecourse={canAddRecourse}
          canAddPayment={canAddPayment}
          currentPrice={currentPrice}
          rights={rights}
          cvfmRapoPartner={cvfmRapoPartner}
        />
        <AddRefund
          isOpen={content === 'refund'}
          close={this.close}
          fpsId={fpsId}
          isAntaiSync={isAntaiSync}
          amountToPay={amountToPay}
          reloadFps={reloadFps}
        />
        <AddPayment
          isOpen={content === 'payment'}
          close={this.close}
          fpsId={fpsId}
          amountToPay={amountToPay}
          reloadFps={reloadFps}
          isAntaiSync={isAntaiSync}
          isFpsReducedDatetimeExpired={isFpsReducedDatetimeExpired}
        />
        <AddCorrection
          isOpen={content === 'correction'}
          close={this.close}
          statementDatetime={statementDatetime}
          paymentStatus={paymentStatus}
          currentPrice={currentPrice}
          currentReducedPrice={currentReducedPrice}
          currentReducedDatetime={currentReducedDatetime}
          currentSurcharge={currentSurcharge}
          responsibleOfReducedPeriod={responsibleOfReducedPeriod}
          fpsId={fpsId}
          reloadFps={reloadFps}
          fpsComments={fpsComments}
        />
        <AddFpsTransfer
          isOpen={content === 'fpsTransfer'}
          close={this.close}
          rootFpsId={rootFpsId}
        />
        <AddRecourse
          isOpen={content === 'recourse'}
          close={this.close}
          rootFpsId={rootFpsId}
          fpsStatementDatetime={statementDatetime}
          reloadFps={reloadFps}
        />
        <AddCancelProposal
          isOpen={content === 'cancel'}
          close={this.close}
          fpsId={fpsId}
          reloadFps={reloadFps}
        />
      </div>
    );
  }
}

export default connect(state => {
  const { cvfmRapoPartner, fpsConfigurationDTO } = getConfigState(state);
  return { cvfmRapoPartner, fpsComments: fpsConfigurationDTO?.fpsComments };
})(AddEvent);
