import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';
import IntlPolyfill from 'intl';
import Dialog from 'material-ui/Dialog';
import TextField from 'material-ui/TextField';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import DatePicker from 'material-ui/DatePicker';
import CircularProgress from 'material-ui/CircularProgress';
import moment, { Moment } from 'moment';

import BoButton from 'facade/BoButton';
import { patchFps } from 'api/fps/';
import { InternalAgent } from 'api/auth/types';
import { getApiState } from 'api/duck';
import ErrorBlock from 'commons/ErrorBlock';
import { BKG_PINK, STYLE_ERROR_WRAPPER, STYLE_LOADING_WRAPPER } from 'theme';
import {
  convertToCents,
  formatCentsToEuro,
  translatePaymentMode,
  positivePrice,
} from 'commons/Utils/paymentUtil';
import { buildControlAgent } from 'commons/Utils/agentUtil';
import { formatDate, toHours, toMinutes } from 'commons/Utils/dateUtil';
import ConfirmAction from 'commons/ConfirmAction';

import { getConfigState } from '../../../../config/duck';

const STYLE_TITLE: CSSProperties = {
  backgroundColor: BKG_PINK,
  color: '#ffffff',
  fontWeight: 'bold',
};

const STYLE_CONTENT_WRAPPER: CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignContent: 'center',
};

const STYLE_PRICE_INPUT: CSSProperties = {
  color: BKG_PINK,
  fontWeight: 'bold',
  fontSize: 40,
};

const STYLE_INPUTS: CSSProperties = {
  borderColor: BKG_PINK,
  color: BKG_PINK,
};

const STYLE_ALERT: CSSProperties = {
  marginTop: '20px',
  color: BKG_PINK,
};

type AddPaymentProps = {
  isOpen: boolean;
  fpsId: string; // eslint-disable-line react/no-unused-prop-types
  amountToPay: number;
  close: () => any;
  reloadFps: () => any;
  isAntaiSync: boolean;
  paymentConfirmationEnabled: boolean;
  isFpsReducedDatetimeExpired: boolean | null | undefined;
  userInfo: InternalAgent;
};

type AddPaymentState = {
  loading: boolean;
  error: string | null | undefined;
  amount: number;
  origin: string;
  mode: string;
  date: Moment;
  comment: string | null | undefined;
};

const getInitialState = (amountToPay: number) => ({
  loading: false,
  error: null,
  amount: Math.max(0, formatCentsToEuro(amountToPay)),
  origin: 'DESK',
  mode: 'CARD',
  date: moment(),
  comment: '',
});

const PAYMENT_ORIGIN = {
  DESK: 'Guichet',
  MAIL: 'Courrier',
};

const ORIGIN_PAYMENT_MODE = {
  DESK: ['CARD', 'CHECK', 'CASH', 'RECURRING_DEBIT', 'TRANSFERT'],
  MAIL: ['CHECK', 'CASH', 'RECURRING_DEBIT', 'TRANSFERT'],
};

const origins = Object.keys(PAYMENT_ORIGIN).map((key: string) => (
  <MenuItem key={key} value={key} primaryText={PAYMENT_ORIGIN[key]} />
));

class AddPayment extends React.Component<AddPaymentProps, AddPaymentState> {
  state: AddPaymentState = getInitialState(this.props.amountToPay);

  onClose = () => {
    this.props.close();
    this.setState({ ...getInitialState(this.props.amountToPay) });
  };

  onClickConfirm = async () => {
    const { fpsId, reloadFps, userInfo } = this.props;
    const body = [
      {
        op: 'add',
        path: '/payments/-',
        value: {
          paymentDatetime: moment(this.state.date),
          paymentChannel: this.state.origin,
          paymentAmount: convertToCents(this.state.amount),
          paymentMode: this.state.mode,
          comment: this.state.comment,
          agent: buildControlAgent(userInfo),
        },
      },
    ];
    this.setState({ loading: true, error: null });
    try {
      await patchFps(fpsId, body);
      await reloadFps();
    } catch (error) {
      this.setState({ loading: false, error: 'Une erreur est survenue.' });
    }
  };

  onClickValidate = () => {
    const { isAntaiSync, paymentConfirmationEnabled, amountToPay } = this.props;
    const { amount } = this.state;
    if (
      amount === formatCentsToEuro(amountToPay) &&
      (!paymentConfirmationEnabled || !isAntaiSync)
    ) {
      this.onClickConfirm();
    }
  };

  onChangeComment = (e: React.ChangeEvent<HTMLInputElement>, comment: string) =>
    this.setState({ comment });

  onChangeDate = (useless: any, newDate: Date) => {
    const { date } = this.state;
    const newDateTime = moment(newDate);
    newDateTime.hours(date.hours());
    newDateTime.minutes(date.minutes());
    this.setState({ date: newDateTime });
  };
  onChangeMinutes = (
    e: React.ChangeEvent<HTMLInputElement>,
    minutes: string
  ) => {
    this.setState({
      date: this.state.date.clone().minutes(toMinutes(minutes)),
    });
  };
  onChangeHours = (e: React.ChangeEvent<HTMLInputElement>, hours: string) => {
    this.setState({ date: this.state.date.clone().hours(toHours(hours)) });
  };

  changeMode = (
    e: React.ChangeEvent<HTMLInputElement>,
    i: number,
    mode: string
  ) => this.setState({ mode });

  changeAmount = (e: React.ChangeEvent<HTMLInputElement>, amount: string) =>
    this.setState({ amount: positivePrice(amount) });

  changeOrigin = (
    e: React.ChangeEvent<HTMLInputElement>,
    i: number,
    origin: string
  ) =>
    this.setState({
      origin,
      mode: ORIGIN_PAYMENT_MODE[origin][0],
    });

  render() {
    const { loading, error, amount, date, mode, comment, origin } = this.state;
    const {
      isOpen,
      isAntaiSync,
      paymentConfirmationEnabled,
      isFpsReducedDatetimeExpired,
      amountToPay,
    } = this.props;

    const messageIsFpsReducedDatetimeExpired = isFpsReducedDatetimeExpired
      ? 'La période de minoration est écoulée.<br />'
      : '';
    const messageForAmountDifference =
      amount !== formatCentsToEuro(amountToPay)
        ? 'Êtes-vous sûr de vouloir ajouter ce paiement ? Son montant est différent du montant restant à payer du FPS. <br />'
        : '';
    const messageForPaymentConfirmationAndAnTaiSync =
      paymentConfirmationEnabled && isAntaiSync
        ? "Êtes-vous sûr de vouloir ajouter ce paiement ? Ce FPS a déjà été transmis à l'ANTAI qui ne prendra pas en compte ce paiement. <br />"
        : '';

    const actions = [
      <BoButton
        style={{ marginRight: 10 }}
        key={1}
        label="Annuler"
        onClick={this.onClose}
      />,
      <ConfirmAction
        enabled={
          (paymentConfirmationEnabled && isAntaiSync) ||
          amount !== formatCentsToEuro(amountToPay)
        }
        message={`${messageForPaymentConfirmationAndAnTaiSync}\r\n${messageIsFpsReducedDatetimeExpired}\r\n${messageForAmountDifference}`}
        action={this.onClickConfirm}
      >
        <BoButton
          key={2}
          label="Ajouter"
          primary
          keyboardFocused
          onClick={this.onClickValidate}
          disabled={amount === 0 || isNaN(amount)}
        />
      </ConfirmAction>,
    ];

    const alert = isAntaiSync
      ? "Attention, l'usager doit tout de même s'acquitter du montant du FPS à l'ANTAI."
      : "Attention, en cas d'envoi à l'ANTAI, l'usager devra payer la totalité du montant du FPS.";

    const modes = ORIGIN_PAYMENT_MODE[origin].map((key: string) => (
      <MenuItem
        key={key}
        value={key}
        primaryText={translatePaymentMode()[key]}
      />
    ));

    return (
      <Dialog
        actions={actions}
        title="Ajouter un paiement"
        open={isOpen}
        onRequestClose={this.onClose}
        titleStyle={STYLE_TITLE}
      >
        {loading && (
          <div style={STYLE_LOADING_WRAPPER}>
            <CircularProgress />
          </div>
        )}
        <p style={STYLE_ALERT}>{alert}</p>
        <div style={STYLE_CONTENT_WRAPPER}>
          <TextField
            underlineFocusStyle={STYLE_INPUTS}
            value={amount}
            onChange={this.changeAmount}
            floatingLabelText="Montant du paiement (€)"
            type="number"
            min={0}
            step={0.01}
            inputStyle={STYLE_PRICE_INPUT}
            style={{ height: 100 }}
          />
        </div>
        <div style={STYLE_CONTENT_WRAPPER}>
          <SelectField
            underlineFocusStyle={STYLE_INPUTS}
            value={origin}
            onChange={this.changeOrigin}
            floatingLabelText="Origine du paiement"
          >
            {origins}
          </SelectField>
          <div style={{ display: 'flex', alignItems: 'baseline' }}>
            <DatePicker
              DateTimeFormat={IntlPolyfill.DateTimeFormat}
              locale="fr"
              autoOk
              textFieldStyle={{ width: 125, marginRight: 5 }}
              value={date.toDate()}
              onChange={this.onChangeDate}
              floatingLabelText="Date de paiement"
              container="inline"
              formatDate={formatDate}
            />
            <TextField
              underlineFocusStyle={STYLE_INPUTS}
              floatingLabelFocusStyle={STYLE_INPUTS}
              min={0}
              max={23}
              step={1}
              floatingLabelText="Heures"
              type="number"
              style={{ width: 50, marginRight: 5 }}
              value={date.hours()}
              onChange={this.onChangeHours}
            />
            :
            <TextField
              underlineFocusStyle={STYLE_INPUTS}
              floatingLabelFocusStyle={STYLE_INPUTS}
              min={0}
              max={59}
              step={1}
              floatingLabelText="Minutes"
              type="number"
              style={{ width: 50, marginLeft: 5 }}
              value={date.minutes()}
              onChange={this.onChangeMinutes}
            />
          </div>
        </div>
        <div style={STYLE_CONTENT_WRAPPER}>
          <SelectField
            underlineFocusStyle={STYLE_INPUTS}
            value={mode}
            onChange={this.changeMode}
            floatingLabelText="Moyen de paiement"
          >
            {modes}
          </SelectField>
        </div>
        <TextField
          underlineFocusStyle={STYLE_INPUTS}
          floatingLabelFocusStyle={STYLE_INPUTS}
          value={comment || undefined}
          onChange={this.onChangeComment}
          floatingLabelText="Commentaire"
          fullWidth
          multiLine
          rows={3}
          rowsMax={3}
        />
        {error && (
          <div style={STYLE_ERROR_WRAPPER}>
            <ErrorBlock error={{ message: error }} />
          </div>
        )}
      </Dialog>
    );
  }
}

export default connect(state => {
  const { userInfo } = getApiState(state);
  const { paymentConfirmationEnabled } = getConfigState(state);
  return {
    userInfo,
    paymentConfirmationEnabled,
  };
})(AddPayment);
