import React, { CSSProperties } from 'react';

import { FpsCaseDTO, FpsVersionDTO } from 'api/fps/types';
import { BKG_CYAN, TXT_BLACK } from 'theme';
import FakeInputBlock from 'commons/FakeInputBlock';
import Separator from 'commons/Separator';
import Price from 'commons/Price';
import FpsNumber from 'commons/FpsNumber';
import Date from 'commons/Date';
import FpsSyncStatus from 'commons/FpsSyncStatus';
import FpsPictures from 'commons/FpsPictures';
import { DataBoxItem, DataBoxItemWrapper } from 'commons/DataBox';
import FpsAddressRenderer from 'commons/Address/FpsAddressRenderer';
import {
  computePaymentKey,
  isLastVersionAndSynced,
  FpsPaymentKeyType,
} from 'tefps/Fps/Detail/helpers';
import { Flex } from '@cvfm-front/commons-ui';

const { _tg } = window.loadTranslations(__filename);

const STYLE_TEXT: CSSProperties = {
  fontSize: 12,
  fontWeight: 'bold',
  color: TXT_BLACK,
  flex: 1,
};

const QuestionMark = (
  <span
    style={{
      display: 'inline-block',
      fontWeight: 'bold',
      textAlign: 'center',
      borderRadius: '50%',
      marginLeft: 'auto',
      border: '1px solid',
      borderColor: BKG_CYAN,
      height: '1.4em',
      width: '1.4em',
    }}
  >
    ?
  </span>
);

type FpsInitialProps = {
  fps: FpsCaseDTO;
  version: FpsVersionDTO;
  zone?: string;
};

const FpsInitial = ({ fps, version, zone }: FpsInitialProps) => {
  const lastAndSynced = isLastVersionAndSynced(fps, version);
  const isTestingFps = version.type === 'TEST';

  return (
    <div style={{ width: '100%' }}>
      {!isTestingFps && (
        <FpsSyncStatus
          version={version}
          nextAntaiSyncDatetime={fps.nextAntaiSyncDatetime}
        />
      )}
      <div style={{ ...STYLE_TEXT, textAlign: 'center', marginBottom: 20 }}>
        <span style={{ marginRight: 10 }}>
          {_tg('tefps.fps.detail.content.fpsInitial.initialFps')}
        </span>
        <FpsNumber fpsId={version.id} />
      </div>
      <DataBoxItemWrapper>
        <DataBoxItem>
          <FakeInputBlock
            title="Date"
            value={<Date datetime={fps.statementDatetime} withTime={false} />}
          />
          <FakeInputBlock
            title="Heure"
            value={<Date datetime={fps.statementDatetime} withDate={false} />}
          />
          <FakeInputBlock
            title="Autorité d'émission"
            value={(version.agent.worksFor || { name: undefined }).name}
          />
          <FakeInputBlock title="ID Agent" value={version.agent.agentId} />
        </DataBoxItem>
        <Separator />
        <DataBoxItem>
          <FakeInputBlock
            title="Lieu"
            value={<FpsAddressRenderer fpsAddress={fps.statementAddress} />}
          />
          {zone && <FakeInputBlock title="Zone" value={zone} />}
          {fps.controlLocationCode && (
            <div>
              <FakeInputBlock
                title="Zone résident"
                value={
                  <Flex>
                    <span>{fps.controlLocationCode}</span>
                    {fps.controlLocationUncertain && QuestionMark}
                  </Flex>
                }
              />
            </div>
          )}
          {version.reducedDatetime && (
            <FakeInputBlock
              title="Fin de minoration"
              value={<Date datetime={version.reducedDatetime} />}
            />
          )}
        </DataBoxItem>
        <Separator />
        <DataBoxItem>
          <FakeInputBlock
            title="Montant initial"
            value={<Price price={version.finePrice} />}
          />
          {version.reducedFinePrice && (
            <FakeInputBlock
              title="Montant minoré"
              value={<Price price={version.reducedFinePrice} />}
            />
          )}
          {lastAndSynced && (
            <FakeInputBlock
              title="Clé de paiement"
              value={computePaymentKey(version.id, FpsPaymentKeyType.FPS)}
            />
          )}
        </DataBoxItem>
      </DataBoxItemWrapper>
      <FpsPictures version={version} mediaUpload={fps.mediaUpload} />
    </div>
  );
};

export default FpsInitial;
