import React, { useMemo } from 'react';

import Sidebar from '../../../commons/SidebarV2';
import BarTitle from '../../../commons/SidebarV2/Components/BarTitle';
import HitsCounter from '../../../commons/SidebarV2/Components/HitsCounter';
import Input from '../../../commons/SidebarV2/Components/Input';
import services from '../../../commons/services';
import useWatcher from '../../../commons/hooks/useWatcher';

const { _tg } = window.loadTranslations(__filename);

const TaoSubscribersTableSideBar = (): JSX.Element => {
  const taoSubscriberSearch = useMemo(() => services.taoSubscriberSearch, []);
  const request = useWatcher(services.taoSubscriberSearch.watchRequest, {});
  const totalHits = useWatcher(services.taoSubscriberSearch.watchTotalHits, 0);

  const onChange = (id: string, value: unknown) => {
    taoSubscriberSearch.setRequest({
      ...request,
      [id]: value,
    });
  };

  return (
    <Sidebar>
      <BarTitle resetFilters={taoSubscriberSearch.resetRequest} />
      <HitsCounter hits={totalHits} />
      <Input
        id="subscriberId"
        title={_tg('field.id')}
        placeholder=""
        onChange={onChange}
        value={request.subscriberId || ''}
      />
      <Input
        id="lastName"
        title={_tg('field.human.lastname_short')}
        placeholder=""
        onChange={onChange}
        value={request.lastName || ''}
      />
      <Input
        id="firstName"
        title={_tg('field.human.firstname')}
        placeholder=""
        onChange={onChange}
        value={request.firstName || ''}
      />
      <Input
        id="email"
        title={_tg('field.human.email')}
        placeholder=""
        onChange={onChange}
        value={request.email || ''}
      />
    </Sidebar>
  );
};

export default TaoSubscribersTableSideBar;
