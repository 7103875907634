import * as React from 'react';
import Snackbar from 'material-ui/Snackbar';

import { fetchZoning } from 'api/pricing';
import { ZoneDTO } from '@cvfm-front/tefps-types';
import { FETCH_LIGHT_ZONING_CONFIG } from 'commons/FetchZoningConfigs';

const { _tg } = window.loadTranslations(__filename);

type ZoningState = {
  zones: Array<ZoneDTO> | null | undefined;
  zoningId: string | null | undefined;
  message: string;
};

export type ZoningProps = {
  fetchZoning: () => Promise<void>;
  zoningId: string | null | undefined;
  zones: Array<ZoneDTO> | null | undefined;
};

// eslint-disable-next-line
function withZoning<TProps extends object>(
  WrappedComponent: React.ComponentType<TProps>
) {
  // ...and returns another component...
  return class extends React.Component<TProps, ZoningState> {
    constructor(props: TProps) {
      super(props);
      this.state = { zones: null, message: '', zoningId: null };
    }

    fetchZoning = async () => {
      try {
        const zoning = await fetchZoning(FETCH_LIGHT_ZONING_CONFIG);
        this.setState({
          zones: zoning.zones,
          zoningId: zoning.id,
          message: '',
        });
      } catch (err) {
        this.setState({ message: _tg('feedback.error.fetchZones') });
      }
    };

    render() {
      const { zones, zoningId, message } = this.state;
      return (
        <div>
          <WrappedComponent
            fetchZoning={this.fetchZoning}
            zoningId={zoningId}
            zones={zones}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...this.props}
          />
          <Snackbar
            open={!!message}
            message={message}
            autoHideDuration={4000}
          />
        </div>
      );
    }
  };
}

export default withZoning;
