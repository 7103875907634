import React from 'react';

import Date from 'commons/Date';

import './ControlStatusHistoryElement.css';
import { ControlStatusHistoryElementType } from './types';

const { _t } = window.loadTranslations(__filename);

interface Props {
  date: string;
  controlStatusHistorySource: string;
  controlStatusHistoryElements?: ControlStatusHistoryElementType[];
}

const sourceToString = (controlStatusHistorySource: string) => {
  switch (controlStatusHistorySource) {
    case 'INITIAL':
      return _t('source.INITIAL');
    case 'LAPI_REVIEW':
      return _t('source.LAPI_REVIEW');
    case 'FPS_COHERENCE_TASK':
      return _t('source.FPS_COHERENCE_TASK');
    case 'EXPIRED_WAITING_REVIEW':
      return _t('source.EXPIRED_WAITING_REVIEW');
    case 'PLATE_DOUBLE_CHECK':
      return _t('source.PLATE_DOUBLE_CHECK');
    default:
      return controlStatusHistorySource;
  }
};

const executionTimeToString = (controlExecutionTime: string) => {
  switch (controlExecutionTime) {
    case 'IN_TIME':
      return _t('executionTime.INITIAL');
    case 'GRACE_PERIOD':
      return _t('executionTime.GRACE_PERIOD');
    case 'POSTERIORI_PERIOD':
      return _t('executionTime.POSTERIORI_PERIOD');
    default:
      return controlExecutionTime;
  }
};

const ControlStatusHistoryElement = (props: Props) => {
  const {
    date,
    controlStatusHistoryElements = [],
    controlStatusHistorySource,
  } = props;
  return (
    <div className="control-status-history-element">
      <span className="control-status-history-element__header">
        {sourceToString(controlStatusHistorySource)}: <Date datetime={date} />
      </span>
      {controlStatusHistoryElements.map(replay => (
        <div className="control-status-history-element__item__wrapper">
          <span className="control-status-history-element__item">
            {executionTimeToString(replay.controlExecutionTime)}
          </span>
          <span className="control-status-history-element__item">
            <Date datetime={replay.datetime} />
          </span>
          <span className="control-status-history-element__item">
            {replay.controlStatus}
          </span>
        </div>
      ))}
    </div>
  );
};

export default ControlStatusHistoryElement;
