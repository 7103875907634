import * as React from 'react';

import TimelinePoint from 'commons/TimelinePoint';

import { TimelineItem } from '../helpers';

import Box from './Box';
import Comments from './Comments';

type Props = {
  isFirst?: boolean;
  isLast?: boolean;
  item: TimelineItem;
};

const TimelineBlock = ({ isFirst, isLast, item }: Props) => {
  return (
    <div style={{ width: '100%' }}>
      <div style={{ display: 'flex', width: '100%' }}>
        <TimelinePoint isFirst={isFirst} isLast={isLast} style={{ flex: 1 }} />
        <Box item={item} style={{ flex: 10 }} />
        <div style={{ flex: 1 }} />
      </div>
      <div style={{ display: 'flex', width: '100%' }}>
        <TimelinePoint filler isLast={isLast} style={{ flex: 1 }} />
        <Comments comment={item.comment} fpsComments={item.fpsComments} />
        <div style={{ flex: 1 }} />
      </div>
    </div>
  );
};

export default TimelineBlock;
