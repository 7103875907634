import React, { Component } from 'react';
import Toggle from 'material-ui/Toggle';

import attachToForm from 'commons/FormComponent/attachToForm';

type Props = {
  name: string;
  value: boolean | null | undefined;
  validateField: (
    value: boolean | null | undefined
  ) => string | null | undefined;
  onValueChange: (newValue: boolean | null | undefined) => void;
  label?: string;
};

class ToggleField extends Component<Props> {
  onChange = (_: any, newValue: boolean) => {
    const { onValueChange } = this.props;
    onValueChange(newValue);
  };

  render() {
    const { name, label, value, ...otherProps } = this.props;
    return (
      <Toggle
        {...otherProps}
        key={name}
        toggled={value || false}
        // TODO: flow-to-ts really errorText is really used ?
        onToggle={this.onChange}
        label={label} /* errorText={error} */
      />
    );
  }
}

const AttachedForm = attachToForm((p: any) => <ToggleField {...p} />);
export default AttachedForm;
