import React, { useEffect } from 'react';

import { ListBody, ListWrapper } from 'commons/ListWrappers';
import useWatcher from 'commons/hooks/useWatcher';
import services from 'commons/services';
import { TaoArticleSearchRequestDTO } from '@cvfm-front/tefps-types';

import { MarcheNavigation } from '../../MarcheNavigation';

import ArticleTable from './ArticleTable';
import ArticleTableSidebar from './ArticleTableSidebar';

const ArticleTablePage = (): JSX.Element => {
  const request: Partial<TaoArticleSearchRequestDTO> = useWatcher(
    services.taoArticleSearchService.watchRequest,
    {}
  );
  const articles = useWatcher(
    services.taoArticleSearchService.watchArticles,
    []
  );
  const isLoading = useWatcher(
    services.taoArticleSearchService.watchIsLoading,
    false
  );
  useEffect(() => {
    services.taoArticleSearchService.getArticles();
  }, [request]);
  return (
    <div className="article-page_container">
      <ArticleTableSidebar />
      <div className="article-page_content">
        <MarcheNavigation />
        <div className="article-page_table">
          <ListWrapper
            style={{
              padding: '0px 32px',
              margin: 0,
              maxWidth: '100%',
              maxHeight: '95%',
            }}
          >
            <ListBody loading={isLoading}>
              <ArticleTable pagedArticles={articles} />
            </ListBody>
          </ListWrapper>
        </div>
      </div>
    </div>
  );
};

export default ArticleTablePage;
