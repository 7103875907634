import { FormFieldType } from '@cvfm-front/commons-types';

const AddressFields: FormFieldType[] = [
  {
    id: 'addressCountry',
    group: 'address',
    label: 'Identifiant pays',
    type: 'text',
    validator: (v: string) => /^[A-Z]{2}$/.test(v),
    required: true,
  },
  {
    id: 'addressRegion',
    group: 'address',
    label: 'Région',
    type: 'text',
    required: true,
  },
  {
    id: 'addressSubRegion',
    group: 'address',
    label: 'Numéro de département',
    type: 'text',
    required: true,
    validator: (v: string) => /^(2A|2B|\d{1,3})$/.test(v),
  },
  {
    id: 'addressLocality',
    group: 'address',
    label: 'Ville',
    type: 'text',
    required: true,
  },
  {
    id: 'postalCode',
    group: 'address',
    label: 'Code postal',
    type: 'text',
    required: true,
  },
  {
    id: 'streetNumber',
    group: 'address',
    label: "Numéro de l'adresse",
    type: 'text',
    required: true,
  },
  {
    id: 'streetNumberBis',
    group: 'address',
    label: 'Complément du numéro',
    type: 'text',
  },
  {
    id: 'streetType',
    group: 'address',
    label: 'Type de voie',
    type: 'text',
    required: true,
  },
  {
    id: 'streetName',
    group: 'address',
    label: 'Nom de la voie',
    type: 'text',
    required: true,
  },
  { id: 'specialPlace', group: 'address', label: 'Lieu-dit', type: 'text' },
  {
    id: 'postOfficeBoxNumber',
    group: 'address',
    label: 'Numéro de boîte postale',
    type: 'text',
  },
];

export default AddressFields;
