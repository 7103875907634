import React from 'react';
import ModeIcon from 'material-ui/svg-icons/editor/mode-edit';

import { TaoSubscriberDTO } from '@cvfm-front/tefps-types';
import BoButton from 'facade/BoButton';
import { BKG_CYAN } from 'theme';
import { DetailButtonLabel } from '@cvfm-front/commons-ui';
import './TaoSubscriberDetail.css';

import TaoSubscriberDetailSummary from './TaoSubscriberDetailSummary';

const { _tg } = window.loadTranslations(__filename);

type TaoSubscriberDetailProps = {
  subscriber: TaoSubscriberDTO;
  openUpdateSubscriber: () => void;
};

const TaoSubscriberDetail = ({
  subscriber,
  openUpdateSubscriber,
}: TaoSubscriberDetailProps): JSX.Element => {
  return (
    <>
      <TaoSubscriberDetailSummary subscriber={subscriber} />
      <div className="tao-subscriber-detail-container">
        <div className="tao-subscriber-detail-inner-container">
          <BoButton
            backgroundColor={BKG_CYAN}
            label={
              <DetailButtonLabel
                icon={
                  <ModeIcon
                    className="tao-subscriber-detail-button-icon"
                    color="#ffffff"
                  />
                }
                title={_tg('action.edit')}
              />
            }
            onClick={openUpdateSubscriber}
            labelColor="#ffffff"
            className="tao-subscriber-detail-button"
          />
        </div>
      </div>
    </>
  );
};

export default TaoSubscriberDetail;
