import React, { Component } from 'react';
import FileSaver from 'file-saver';
import moment from 'moment';
import Dialog from 'material-ui/Dialog';

import BoButton from 'facade/BoButton';
import { STYLE_ERROR_WRAPPER } from 'theme';
import { EditableFpsMap } from 'api/fps/types';
import { FpsVehicleConsistencyStatus } from '@cvfm-front/tefps-types';
import ErrorBlock from 'commons/ErrorBlock';
import { updateFpsConsistencyStatus } from 'api/fps';

import FpsToProcessList from './FpsToProcessList';

const { _tg } = window.loadTranslations(__filename);

type State = {
  open: boolean;
  openDialog: boolean;
  editableFpsMap: EditableFpsMap;
  error: string | null;
};

type Props = {
  toggleModal: Function;
  reportError: (error: Error) => void;
  fetchAllFpsId: Function;
  canUpdateFpsConsistency: boolean | null | undefined;
  selectAllFps: boolean;
  reloadFpsList: Function;
  updateProcessList: Function;
  processList: Array<string> | null | undefined;
};

class VehicleConsistency extends Component<Props, State> {
  state: State = {
    open: false,
    openDialog: false,
    editableFpsMap: {},
    error: null,
  };

  onToggleModal = () => {
    const { toggleModal } = this.props;
    const { open } = this.state;
    if (!open) {
      toggleModal();
      this.setState({ open: true });
    } else {
      this.setState({ openDialog: true });
    }
  };

  onCloseDialog = () => {
    this.props.toggleModal();
    this.setState({ open: false, openDialog: false });
  };

  onSelectAllFps = () => {
    const {
      selectAllFps,
      fetchAllFpsId,
      processList,
      updateProcessList,
    } = this.props;
    if (!selectAllFps) {
      fetchAllFpsId();
    } else if (processList) {
      updateProcessList([]);
    }
  };

  onCloseAndClean = () => {
    const { updateProcessList, reloadFpsList } = this.props;
    updateProcessList([]);
    this.setState({ editableFpsMap: {} });
    reloadFpsList();
    this.onCloseDialog();
  };

  onExportResult = () => {
    const { editableFpsMap } = this.state;
    const { processList } = this.props;

    if (!processList) {
      return;
    }

    const lines = [
      _tg('tefps.fps.detail.vehicleConsistency.outputLinesHeader'),
    ];

    for (let i = 0; i < processList.length; i += 1) {
      const processableList = editableFpsMap[processList[i]];
      lines.push(
        `${processList[i]};` +
          `${processableList.status};` +
          `${processableList.errorMessage || ''};`
      );
    }

    FileSaver.saveAs(
      new Blob([lines.join('\n')], {
        type: 'text/csv;charset=utf-8',
      }),
      `export-annulation-fps-${moment().format('DD-MM-YYYY')}.csv`
    );
  };

  update = (status: FpsVehicleConsistencyStatus): void => {
    const { canUpdateFpsConsistency, processList } = this.props;
    if (!processList || !canUpdateFpsConsistency) {
      return;
    }
    const fpsMap = {};
    for (let i = 0; i < processList.length; i += 1) {
      updateFpsConsistencyStatus(processList[i], status)
        .then(status => {
          fpsMap[processList[i]] = {
            status,
            errorMessage: null,
          };
        })
        .catch(err => {
          fpsMap[processList[i]] = {
            status: 'UNKNOWN',
            errorMessage: err?.json.message,
          };
        })
        .finally(() => {
          this.setState({
            editableFpsMap: fpsMap,
            error: null,
          });
        });
    }
    this.onToggleModal();
  };

  onConfirmSivBrand = (): void => {
    this.update('INCONSISTENT');
  };
  onIgnoreSivBrand = (): void => {
    this.update('CONSISTENT');
  };

  render() {
    const { open, openDialog, editableFpsMap, error } = this.state;
    const { processList, canUpdateFpsConsistency, selectAllFps } = this.props;

    if (!processList) {
      return null;
    }

    const nbFpsInList = processList.length;
    const nbFpsProcessed = Object.keys(editableFpsMap).length;

    let titleText;
    if (processList.length === nbFpsProcessed) {
      titleText = _tg('tefps.fps.detail.vehicleConsistency.title3');
    } else if (nbFpsProcessed > 0) {
      titleText = _tg('tefps.fps.detail.vehicleConsistency.title2', {
        nbFpsProcessed,
        nbFpsInList,
      });
    } else {
      titleText = _tg('tefps.fps.detail.vehicleConsistency.title1');
    }

    const actions = [
      <BoButton
        key={_tg('action.close')}
        style={{ marginRight: 10 }}
        label={_tg('action.close')}
        primary
        onClick={this.onCloseAndClean}
      />,
    ];

    if (nbFpsProcessed === 0) {
      actions.push(
        <BoButton
          key={_tg('tefps.fps.detail.vehicleConsistency.ignoreSiv')}
          style={{ marginRight: 10 }}
          label={_tg('tefps.fps.detail.vehicleConsistency.ignoreSiv')}
          onClick={this.onIgnoreSivBrand}
        />
      );
    }
    if (nbFpsProcessed === 0) {
      actions.push(
        <BoButton
          key={_tg('tefps.fps.detail.vehicleConsistency.confirmSiv')}
          style={{ marginRight: 10 }}
          label={_tg('tefps.fps.detail.vehicleConsistency.confirmSiv')}
          primary
          onClick={this.onConfirmSivBrand}
        />
      );
    }
    if (nbFpsProcessed > 0) {
      actions.push(
        <BoButton
          key={_tg('tefps.fps.detail.vehicleConsistency.exportCsv')}
          label={_tg('tefps.fps.detail.vehicleConsistency.exportCsv')}
          disabled={processList.length !== nbFpsProcessed}
          style={{ marginRight: 10 }}
          onClick={this.onExportResult}
        />
      );
    }

    let label = _tg('tefps.fps.detail.vehicleConsistency.multiSelect');
    if (canUpdateFpsConsistency && open) {
      label = _tg('tefps.fps.detail.vehicleConsistency.pursuitToDecision');
    } else if (nbFpsInList && canUpdateFpsConsistency) {
      label = _tg('tefps.fps.detail.vehicleConsistency.fpsesToConfirm', {
        nbFpsInList,
      });
    }

    return (
      <div>
        {open && (
          <span>
            <BoButton
              label="Annuler"
              onClick={this.onCloseDialog}
              style={{ marginRight: 10 }}
            />
            <BoButton
              label={
                !selectAllFps
                  ? _tg('tefps.fps.detail.vehicleConsistency.selectAll')
                  : _tg('tefps.fps.detail.vehicleConsistency.deselectAll')
              }
              onClick={this.onSelectAllFps}
              primary
              style={{ marginRight: 10 }}
            />
          </span>
        )}

        <BoButton
          label={label}
          onClick={this.onToggleModal}
          secondary
          disabled={
            !canUpdateFpsConsistency || (open && processList.length === 0)
          }
        />
        <Dialog
          title={titleText}
          actions={actions}
          modal
          autoScrollBodyContent
          open={openDialog}
        >
          <div>
            {_tg(
              'tefps.fps.detail.vehicleConsistency.multiConfirmationMessage'
            )}
          </div>

          {nbFpsProcessed > 0 && (
            <FpsToProcessList
              open={open}
              editableFpsMap={editableFpsMap}
              fpsIds={processList}
            />
          )}
          {error && (
            <div style={STYLE_ERROR_WRAPPER}>
              <ErrorBlock error={{ message: error }} />
            </div>
          )}
        </Dialog>
      </div>
    );
  }
}

export default VehicleConsistency;
