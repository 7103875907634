import moment from 'moment';

import { Pager, SortParameters } from 'api/commonTypes';

import { CcspSearchCriterias, FiltersParam } from './types';

const { _t, _tg } = window.loadTranslations(__filename);

export const getStatusesFilterOptions = (): Array<{
  value: string;
  label: string;
}> => [
  { value: 'MEMOIRE_NON_NECESSAIRE', label: _tg('field.ccsp.notNecessary') },
  {
    value: 'EN_ATTENTE_DE_PROPOSITION',
    label: _tg('field.ccsp.waitingProposition'),
  },
  {
    value: 'EN_ATTENTE_DE_VALIDATION',
    label: _tg('field.ccsp.waitingValidation'),
  },
  { value: 'MEMOIRE_VALIDE', label: _tg('field.ccsp.validated') },
  { value: 'EN_ATTENTE_DE_DECISION', label: _tg('field.ccsp.waitingDecision') },
  {
    value: 'EN_ATTENTE_DENREGISTREMENT',
    label: _tg('field.ccsp.waitingRegistration'),
  },
  { value: 'SAISINE_REJETEE', label: _tg('field.ccsp.ccspRejected') },
  { value: 'SAISINE_ACCEPTEE', label: _tg('field.ccsp.ccspAccepted') },
  {
    value: 'SAISINE_ACCEPTEE_PARTIELLEMENT',
    label: _tg('field.ccsp.ccspPartialAccepted'),
  },
  {
    value: 'SAISINE_ACCEPTEE_AUTRE',
    label: _tg('field.ccsp.ccspOtherAccepted'),
  },
];

export const EXPORT_COL: Array<{
  key: string;
  label: string;
  checked?: boolean;
}> = [
  { key: 'ccspNumber', label: _tg('field.ccsp.ccspNumber'), checked: true },
  { key: 'fpsId', label: _tg('tefps.fpsNumber_short'), checked: true },
  { key: 'openingDate', label: _t('table.openingDate'), checked: true },
  { key: 'deadline', label: _t('table.deadline'), checked: true },
  { key: 'status', label: _tg('field.status'), checked: true },
  { key: 'fpsmId', label: _t('table.fpsmNumber'), checked: false },
  { key: 'newFpsPrice', label: _t('table.newFpsPrice'), checked: false },
  {
    key: 'majoration',
    label: _tg('field.payment.newSurchage'),
    checked: false,
  },
  { key: 'closingDate', label: _t('table.closingDate'), checked: false },
  {
    key: 'transmissionDate',
    label: _t('table.transmissionDate'),
    checked: false,
  },
  {
    key: 'creationDatetime',
    label: _t('table.creationDatetime'),
    checked: false,
  },
  {
    key: 'awaitingDecisionDatetime',
    label: _t('table.awaitingDecisionDatetime'),
    checked: false,
  },
  { key: 'statusHistory', label: _t('table.statusHistory'), checked: false },
  { key: 'userFirstName', label: _t('table.firstName'), checked: false },
  { key: 'userLastName', label: _t('table.lastName'), checked: false },
];

function getSortFieldText(sortField: number) {
  switch (sortField) {
    case 0:
      return 'fpsmNumber';
    case 1:
      return 'ccspNumber';
    case 2:
      return 'openingDate';
    case 3:
      return 'deadline';
    default:
      return 'openingDate';
  }
}

export const initialFilters = (): CcspSearchCriterias => {
  const from = moment()
    .subtract(3, 'years')
    .startOf('day')
    .toDate();
  return {
    statuses: new Set(),
    openingDate: { from, to: undefined },
    responseLimitDate: { from, to: undefined },
    ccspIds: new Set(),
    ccspNumbers: new Set(),
    fpsOrFpsmId: null,
    userFirstName: '',
    userLastName: '',
  };
};

export const filtersToRequest = (
  filters: CcspSearchCriterias,
  pager: Pager,
  sort: SortParameters
): FiltersParam => ({
  statuses: Array.from(filters.statuses),
  openingStart:
    filters.openingDate && !filters.openingDate.from
      ? undefined
      : moment(filters.openingDate.from).unix(),
  openingEnd:
    filters.openingDate && !filters.openingDate.to
      ? undefined
      : moment(filters.openingDate.to).unix(),
  deadlineStart:
    filters.responseLimitDate && !filters.responseLimitDate.from
      ? undefined
      : moment(filters.responseLimitDate.from).unix(),
  deadlineEnd:
    filters.responseLimitDate && !filters.responseLimitDate.to
      ? undefined
      : moment(filters.responseLimitDate.to).unix(),
  ccspIds: Array.from(filters.ccspIds),
  ccspNumbers: Array.from(filters.ccspNumbers),
  fpsOrFpsmId: filters.fpsOrFpsmId,
  userFirstName: filters.userFirstName,
  userLastName: filters.userLastName,
  page: pager.page,
  maxRecords: pager.maxRecords,
  sortOrder: sort && sort.increasingOrder ? 'ASC' : 'DESC',
  sortField: sort ? getSortFieldText(sort.sortField) : 'openingDate',
});
