import moment from 'moment';

import { FpsCaseDTO, FpsVersionDTO } from 'api/fps/types';
import { CcspRecourseDTO, CcspRecourseStatus } from 'api/ccsp/types';

const { _tg } = window.loadTranslations();

export const findVersion = (
  fps: FpsCaseDTO,
  fpsId: string
): FpsVersionDTO | undefined => {
  return fps.versions.find(v => v.id === fpsId);
};

export const isCcspRecourseAccepted = (
  status: CcspRecourseStatus
): boolean | null => {
  if (status === 'SAISINE_ACCEPTEE') return true;
  if (status === 'SAISINE_REJETEE') return false;
  return null;
};

export const isCcspRecourseCompleted = (
  status: CcspRecourseStatus
): boolean => {
  return isCcspRecourseAccepted(status) !== null;
};

export const translateCcspVersionLimitedStatus = (): {
  [key in CcspRecourseStatus]?: string;
} => {
  return {
    MEMOIRE_NON_NECESSAIRE: _tg('field.ccsp.notNecessary'),
    EN_ATTENTE_DE_PROPOSITION: _tg('field.ccsp.waitingProposition'),
    EN_ATTENTE_DE_VALIDATION: _tg('field.ccsp.waitingValidation'),
    MEMOIRE_VALIDE: _tg('field.ccsp.validated'),
    EN_ATTENTE_DE_DECISION: _tg('field.ccsp.waitingDecision'),
    EN_ATTENTE_DENREGISTREMENT: _tg('field.ccsp.waitingRegistration'),
  };
};

export const translateCcspVersionStatus = (): {
  [key in CcspRecourseStatus]?: string;
} => {
  const limitedStatus = translateCcspVersionLimitedStatus();

  return {
    ...limitedStatus,
    SAISINE_ACCEPTEE: _tg('field.ccsp.ccspShortAccepted'),
    SAISINE_REJETEE: _tg('field.ccsp.ccspRejected'),
  };
};

export const computeAmountPaid = (fps: FpsCaseDTO): number => {
  /**
   * We go through each payment and add it to the total
   * If a date is given and the payment date is later, we skip it by only returning the total instead of
   * adding the paiment.
   */
  const versionAmountPaid = fps.versions.reduce(
    (amountPaid, version) =>
      amountPaid +
      version.payments.reduce(
        (paymentPaid, payment) =>
          moment(payment.paymentDatetime).isAfter(moment())
            ? paymentPaid
            : paymentPaid + payment.paymentAmount,
        0
      ),
    0
  );

  const antaiAmountPaid = fps.antaiFps
    ? fps.antaiFps.fps.cityMessages.reduce(
        (amountPaid, apa) =>
          amountPaid +
          apa.payments.reduce(
            (apaPaid, payment) =>
              moment(payment.datetime).isAfter(moment())
                ? apaPaid
                : apaPaid + payment.amount,
            0
          ),
        0
      )
    : 0;

  return versionAmountPaid + antaiAmountPaid;
};

export const shouldShowFpsWarning = (
  ccsp: CcspRecourseDTO,
  latestFpsVersion: string
): boolean => {
  return (
    latestFpsVersion !== ccsp.fpsId && !isCcspRecourseAccepted(ccsp.status)
  );
};
