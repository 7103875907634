import React, { useState } from 'react';

import BoButton from 'facade/BoButton';
import SectionTitle from 'commons/SectionTitle';

import './ControlStatusHistory.css';
import ControlStatusHistoryElement from './ControlStatusHistoryElement';
import { ControlStatusHistoryType } from './types';

const { _t } = window.loadTranslations(__filename);

interface Props {
  controlStatusHistoryList: ControlStatusHistoryType[];
}

const ControlStatusHistory = ({ controlStatusHistoryList = [] }: Props) => {
  return (
    <>
      <SectionTitle title={_t('history.title')} />

      <div className="control-status-history-list">
        {controlStatusHistoryList.map(e => (
          <ControlStatusHistoryElement
            key={e.datetime}
            date={e.datetime}
            controlStatusHistorySource={e.controlStatusHistorySource}
            controlStatusHistoryElements={e.controlStatusHistoryElements}
          />
        ))}
      </div>
    </>
  );
};

export default ControlStatusHistory;
