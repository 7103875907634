import React from 'react';
import Dialog from 'material-ui/Dialog';

import BoButton from 'facade/BoButton';

const { _tg } = window.loadTranslations(__filename);

type Props = {
  onExport: (exportWithPictures: boolean) => void;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
};

const FpsExportTypeModal = ({
  onExport,
  isOpen,
  setIsOpen,
}: Props): JSX.Element => {
  const exportWithoutPictures = () => {
    onExport(false);
    setIsOpen(false);
  };
  const exportWithPictures = () => {
    onExport(true);
    setIsOpen(false);
  };

  const actions = [
    <BoButton
      style={{ marginRight: 10 }}
      key={0}
      secondary
      label="Annuler"
      onClick={() => setIsOpen(false)}
    />,
    <BoButton
      style={{ marginRight: 10 }}
      key={1}
      primary
      label={_tg('tefps.fps.detail.fpsExportTypeModal.exportWithoutPictures')}
      onClick={exportWithoutPictures}
    />,
    <BoButton
      key={2}
      label={_tg('tefps.fps.detail.fpsExportTypeModal.exportWithPictures')}
      primary
      onClick={exportWithPictures}
    />,
  ];

  return (
    <Dialog
      open={isOpen}
      title={_tg('tefps.fps.detail.fpsExportTypeModal.exportationOptions')}
      actions={actions}
    />
  );
};

export default FpsExportTypeModal;
