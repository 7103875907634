import React, { CSSProperties } from 'react';
import { Link } from 'react-router-dom';

import { BKG_CYAN, BKG_FLASHY, BRD_GREY } from 'theme';
import { FpsCaseDTO, FpsVersionDTO } from 'api/fps/types';
import FpsNumber from 'commons/FpsNumber';
import Price from 'commons/Price';
import FakeInputBlock from 'commons/FakeInputBlock';
import FpsAddressRenderer from 'commons/Address/FpsAddressRenderer';
import Date from 'commons/Date';
import SeparatorWithTitle from 'commons/SeparatorWithTitle';

import LinkBottomContent from '../../RecoursesV2/pages/Detail/FpsBlock/LinkBottomContent';

import { computeAmountPaid } from './helpers';

const { _t, _tg } = window.loadTranslations(__filename);

const STYLE_CONTAINER: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  margin: '30px 0px 50px 0px',
  fontFamily: 'Roboto',
};

const STYLE_HEADER: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: 50,
  backgroundColor: BKG_CYAN,
  borderRadius: '5px 5px 0 0',
  color: 'white',
  fontSize: 20,
  padding: '0 20px',
};

const STYLE_CONTENT: CSSProperties = {
  border: `1px solid ${BRD_GREY}`,
  borderTop: 'none',
  borderRadius: '0 0 5px 5px',
  padding: 20,
};

const STYLE_WARNING: CSSProperties = {
  width: '100%',
  textAlign: 'center',
  color: BKG_FLASHY,
  marginBottom: 10,
};

const STYLE_BOTTOM_BLOCK: CSSProperties = {
  width: '20%',
  margin: '0 10px',
  justifyContent: 'center',
};

const STYLE_FPS_DETAIL_BUTTON: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: BKG_CYAN,
  color: 'white',
  borderRadius: '5px',
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%) translateY(20%)',
  height: 30,
  fontSize: 17,
  padding: '0px 40px',
};

const TopContent = ({
  fps,
  version,
}: {
  fps: FpsCaseDTO;
  version: FpsVersionDTO;
}) => (
  <div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
    <div style={{ height: '100%', width: '30%' }}>
      <FakeInputBlock title="ID Agent" value={version.agent.agentId} />
      <FakeInputBlock
        title={_t('element.field.ticketPaidPrice')}
        value={<Price price={computeAmountPaid(fps)} />}
      />
      <FakeInputBlock
        title={_t('element.field.fpsPrice')}
        value={<Price price={version.finePrice} />}
      />
    </div>
    <div
      style={{
        width: 1,
        height: 80,
        backgroundColor: BRD_GREY,
        margin: '5px 30px',
      }}
    />
    <div style={{ flex: 1, height: '100%' }}>
      <FakeInputBlock
        title={_t('element.field.statementDatetime')}
        value={<Date datetime={fps.statementDatetime} />}
      />
      <FakeInputBlock
        containerStyle={{ alignItems: 'auto' }}
        inputStyle={{ width: '60%', maxWidth: 'auto', height: 75 }}
        inputScrollbar
        title={_t('element.field.statementAddress')}
        value={<FpsAddressRenderer fpsAddress={fps.statementAddress} />}
      />
    </div>
  </div>
);

const BottomContent = ({ fps }: { fps: FpsCaseDTO }) => (
  <div
    style={{ display: 'flex', marginTop: 20, justifyContent: 'space-around' }}
  >
    <FakeInputBlock
      title={_tg('field.vehicle.license')}
      value={fps.licensePlate.plate}
      containerStyle={{ ...STYLE_BOTTOM_BLOCK, width: '30%' }}
    />
    <FakeInputBlock
      title={_tg('field.vehicle.country')}
      value={fps.licensePlate.plateCountry}
      containerStyle={STYLE_BOTTOM_BLOCK}
    />
    <FakeInputBlock
      title={_t('element.field.brand')}
      value={fps.vehicle.brand || ''}
      containerStyle={STYLE_BOTTOM_BLOCK}
    />
  </div>
);

const FpsBlock = ({
  fps,
  version,
  showWarning,
}: {
  fps: FpsCaseDTO;
  version: FpsVersionDTO;
  showWarning: boolean;
}): JSX.Element => (
  <div style={STYLE_CONTAINER}>
    <div style={STYLE_HEADER}>
      <div style={{ fontWeight: 'bold' }}>{_tg('commons.fps')}</div>
      <div style={{ fontSize: 14 }}>
        <span>{_t('element.fpsNumber')}</span>
        <span style={{ fontWeight: 'bold' }}>
          <FpsNumber fpsId={version.id} />
        </span>
      </div>
    </div>
    <div style={STYLE_CONTENT}>
      {showWarning && (
        <div style={STYLE_WARNING}>
          {_t('feedback.warning.modifiedAfterRecourse')}
        </div>
      )}
      <TopContent fps={fps} version={version} />
      <SeparatorWithTitle title={_t('element.title.vehicle')} />
      <BottomContent fps={fps} />
      <LinkBottomContent
        plate={fps.licensePlate.plate}
        date={fps.statementDatetime}
      />
      <br />
      <Link to={`/fps/detail/${version.id}`}>
        <div style={STYLE_FPS_DETAIL_BUTTON}>{_t('element.seeDetails')}</div>
      </Link>
    </div>
  </div>
);

export default FpsBlock;
