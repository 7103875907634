import { FieldsMap, AttachedField } from 'commons/FormComponent/types';
import { isPlateValid } from 'commons/Utils/plateUtils';

import {
  FINE_PRICE,
  LICENSE_PLATE,
  PLATE_COUNTRY,
  STATEMENT_DATETIME,
} from './fieldNames';

export function plateCountryValidator(value: string, fields: FieldsMap) {
  const plateField = fields.get(LICENSE_PLATE);
  if (plateField) {
    plateField.validate(plateField.getValue());
  }
  if (value && !/^[a-zA-Z]{2}$/.test(value)) {
    return 'Le champs doit être composé de 2 lettres';
  }
  return undefined;
}

export function beforeNow(value: Date) {
  if (value > new Date()) {
    return 'Impossible de créer un FPS initial dans le futur';
  }
  return undefined;
}

function afterStatementDatetime(
  value: Date,
  fields: Map<string, AttachedField>,
  message: string
) {
  const statementDate = fields.get(STATEMENT_DATETIME);
  if (statementDate && value <= statementDate.getValue()) {
    return message;
  }
  return undefined;
}

export function validityAfterStatementDatetime(
  value: Date,
  fields: FieldsMap
): string | null | undefined {
  return afterStatementDatetime(
    value,
    fields,
    "La date de validité d'un FPS ne peut pas être antérieure à la date de constatation"
  );
}

export function reducedAfterStatementDatetime(
  value: Date,
  fields: Map<string, AttachedField>
) {
  return afterStatementDatetime(
    value,
    fields,
    'La date de fin de minoration ne peut pas être antérieure à la date de constatation'
  );
}

export function greaterThanZero(value: string) {
  if (Number(value) <= 0) {
    return 'Impossible de créer un FPS initial avec un montant zéro';
  }
  return undefined;
}

export function lowerThanFinePrice(
  value: string,
  fields: Map<string, AttachedField>
) {
  const finePrice = fields.get(FINE_PRICE);
  if (!finePrice || Number(value) > Number(finePrice.getValue())) {
    return 'Le prix réduit ne peut pas être supérieur au prix du FPS';
  }
  return undefined;
}

export function plateValidator(
  value: string,
  fields: FieldsMap
): string | undefined {
  const plateCountryField = fields.get(PLATE_COUNTRY);
  const plateCountry = plateCountryField ? plateCountryField.getValue() : '';
  if (!plateCountry || plateCountry.toUpperCase() !== 'FR') {
    return undefined;
  }
  if (!isPlateValid(value.toUpperCase())) {
    return "La plaque d'immatriculation ne respecte pas les formats francais";
  }
  return undefined;
}

export function isAddressSubRegionValid(value: string) {
  if (!/^(2A|2B|\d{1,3})$/.test(value)) {
    return 'Le numéro de département est invalide';
  }
  return undefined;
}
