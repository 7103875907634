import { CSSProperties } from 'react';

import { BKG_GREY_LIGHT } from 'theme';

export const STYLE_LINK: CSSProperties = {
  fontSize: '1em',
  textDecoration: 'underline',
  fontStyle: 'oblique',
  cursor: 'pointer',
};

export const STYLE_INPUTS_CONTAINER: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  flex: 1,
  padding: 5,
  marginTop: 10,
  fontFamily: 'Roboto',
};

export const STYLE_ITEM: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  flex: 1,
};

export const STYLE_FAKE_INPUT: CSSProperties = {
  marginLeft: 5,
  padding: '5px 10px',
  backgroundColor: BKG_GREY_LIGHT,
  display: 'flex',
  flexGrow: 1,
  whiteSpace: 'normal',
};

export const STYLE_ITEM_NAME: CSSProperties = {
  marginLeft: 5,
  padding: '5px 10px',
  display: 'flex',
  whiteSpace: 'nowrap',
};

export const STYLE_FIELD: CSSProperties = {
  minWidth: 200,
  flexGrow: 1,
  marginLeft: 20,
};

export const STYLE_FIELD_LARGE: CSSProperties = {
  ...STYLE_FIELD,
  width: '95%',
};

export const STYLE_TITLE: CSSProperties = {
  marginTop: 20,
};

export const STYLE_UPPERCASE: CSSProperties = {
  textTransform: 'uppercase',
};

export const STYLE_LOADING_WRAPPER: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  padding: 20,
};

export const STYLE_CONTENT: CSSProperties = {
  display: 'flex',
  height: '100%',
};

export const STYLE_PLAN_INPUTS_CONTAINER: CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
};

export const BUTTON_STYLE: CSSProperties = { marginRight: 10, marginTop: 10 };

export const BUTTON_CONTAINER: CSSProperties = {
  justifyContent: 'center',
  display: 'flex',
  marginTop: 10,
};
