import moment, { Duration as MomentDuration } from 'moment';
import { v4 as uuidv4 } from 'uuid';

import {
  Actions,
  Reminder,
  RemindersState,
  RemoveReminderAction,
  SetMailReminderAction,
  SetPeriodReminderAction,
  SetPostReminderAction,
  SetSmsReminderAction,
} from './types';

const { _t } = window.loadTranslations(__filename);

const INITIAL_STATE = {
  reminders: [],
  errorCounter: 0,
};

const isPeriodAbiggerThanB = (a: MomentDuration, b: MomentDuration) => {
  return a.asDays() > b.asDays();
};

const getPresenceError = (period: string) => {
  if (!period) {
    return _t('feedback.error.emptyField');
  }
  return null;
};

const getDurationError = (period: string, validityPeriod: string) => {
  const reminderConsistent = isPeriodAbiggerThanB(
    moment.duration(validityPeriod),
    moment.duration(period)
  );
  if (!reminderConsistent) {
    return _t('feedback.error.sentReminderBeforeSubscription');
  }
  return null;
};

const computeErrors = (reminder: Reminder, validityPeriod: string) => {
  reminder.errorText = getPresenceError(reminder.period) || '';
  if (!reminder.post && reminder.reminderType === 'SUBSCRIPTION_EXPIRATION') {
    reminder.errorText =
      getDurationError(reminder.period, validityPeriod) || reminder.errorText;
  }
  reminder.error = reminder.errorText !== '';
};

const doesActionMatchId = (
  reminder: Reminder,
  action: SetMailReminderAction | SetSmsReminderAction | SetPostReminderAction
) => {
  return reminder.id === action.id;
};

const setPeriodReminder = (action: SetPeriodReminderAction) => (
  reminder: Reminder
) => {
  const { period, maxPeriod } = action;
  if (reminder.id === action.id) {
    const newReminder: Reminder = { ...reminder, period };
    computeErrors(newReminder, maxPeriod);
    return newReminder;
  }
  return reminder;
};

function removeReminder(
  action: RemoveReminderAction
): (arg0: Reminder) => boolean {
  return e => e.id !== action.id;
}

export const reminderReducer = (
  state: RemindersState = INITIAL_STATE,
  action: Actions
) => {
  switch (action.type) {
    case 'ADD_REMINDER':
      return {
        reminders: [
          ...state.reminders,
          {
            id: uuidv4(),
            before: true,
            period: '',
            isMail: false,
            isSms: false,
            reminderType: action.reminder,
            error: true,
            errorText: getPresenceError(''),
          },
        ],
      };
    case 'REMOVE_REMINDER':
      return {
        reminders: state.reminders.filter(removeReminder(action)),
      };
    case 'SET_PERIOD_REMINDER':
      return {
        ...state,
        reminders: state.reminders.map<Reminder>(setPeriodReminder(action)),
      };
    case 'SET_MAIL_REMINDER':
      return {
        ...state,
        reminders: state.reminders.map<Reminder>(e => {
          // Need to cast to any since flow doesn't determine type of action here
          return doesActionMatchId(e, action as any)
            ? { ...e, mail: !e.mail }
            : e;
        }),
      };
    case 'SET_SMS_REMINDER':
      return {
        ...state,
        reminders: state.reminders.map<Reminder>(e => {
          // Need to cast to any since flow doesn't determine type of action here
          return doesActionMatchId(e, action as any)
            ? { ...e, sms: !e.sms }
            : e;
        }),
      };
    case 'SET_POST_REMINDER':
      return {
        ...state,
        reminders: state.reminders.map<Reminder>(e => {
          // Need to cast to any since flow doesn't determine type of action here
          return doesActionMatchId(e, action as any)
            ? { ...e, post: !e.post }
            : e;
        }),
      };
    case 'INIT_STATE':
      return action.state || INITIAL_STATE;
    default:
      return state;
  }
};

export const getReminderState = (state: {
  renewalReminders: RemindersState;
}): RemindersState => {
  return state.renewalReminders;
};

export default reminderReducer;
