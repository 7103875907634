export const FINE_PRICE = 'finePrice';
export const REDUCED_FINE_PRICE = 'reducedFinePrice';

export const STATEMENT_DATETIME = 'statementDatetime';
export const REDUCED_DATETIME = 'reducedDatetime';
export const VALIDITY_DATETIME = 'validityDatetime';
export const ZONE = 'zoneId';

export const LICENSE_PLATE = 'licensePlate.plate';
export const PLATE_COUNTRY = 'licensePlate.plateCountry';

export const BRAND = 'vehicle.brand';
export const MODEL = 'vehicle.model';

export const STREET_NUMBER = 'statementAddress.streetNumber';
export const STREET_NUMBER_BIS = 'statementAddress.streetNumberBis';
export const STREET_TYPE = 'statementAddress.streetType';
export const STREET_NAME = 'statementAddress.streetName';
export const SPECIAL_PLACE = 'statementAddress.specialPlace';
export const POST_OFFICE_BOX_NUMBER = 'statementAddress.postOfficeBoxNumber';
export const POSTAL_CODE = 'statementAddress.postalCode';
export const ADDRESS_SUB_REGION = 'statementAddress.addressSubRegion';
export const ADDRESS_REGION = 'statementAddress.addressRegion';
export const ADDRESS_LOCALITY = 'statementAddress.addressLocality';
export const ADDRESS_COUNTRY = 'statementAddress.addressCountry';
export const LOCATION_LATITUDE = 'statementLocation.latitude';
export const LOCATION_LONGITUDE = 'statementLocation.longitude';

export type FieldNames =
  | typeof STATEMENT_DATETIME
  | typeof REDUCED_DATETIME
  | typeof VALIDITY_DATETIME
  | typeof FINE_PRICE
  | typeof REDUCED_FINE_PRICE
  | typeof LICENSE_PLATE
  | typeof PLATE_COUNTRY
  | typeof BRAND
  | typeof MODEL
  | typeof STREET_NUMBER
  | typeof STREET_NUMBER_BIS
  | typeof STREET_TYPE
  | typeof STREET_NAME
  | typeof POST_OFFICE_BOX_NUMBER
  | typeof SPECIAL_PLACE
  | typeof POSTAL_CODE
  | typeof ADDRESS_SUB_REGION
  | typeof ADDRESS_REGION
  | typeof ADDRESS_LOCALITY
  | typeof ADDRESS_COUNTRY
  | typeof ZONE;
