import moment from 'moment';

import { LOGOUT } from 'api/duck';

import { ParkingRightRequestsSearchCriteria } from './types';

const SET_FILTERS = 'ParkingRightRequests/List/SET_FILTERS';

type State = {
  filters: ParkingRightRequestsSearchCriteria;
};

export const initialFilters = (): ParkingRightRequestsSearchCriteria => {
  return {
    firstName: null,
    lastName: null,
    subscriberId: null,
    subscriptionPlanIds: new Set(),
    zoneIds: new Set(),
    parkingRightDefinitionIds: new Set(),
    parkingRightRequestStatus: ['AWAITING_PAYMENT', 'ACCEPTED'],
    plate: null,
    validityStartDate: {
      from: undefined,
      to: undefined,
    },
    validityEndDate: {
      from: moment().toDate(),
      to: undefined,
    },
  };
};

const initialState = (): State => ({
  filters: initialFilters(),
});

export default function subscriptionsListReducer(
  state: State = initialState(),
  action: any
): State {
  switch (action.type) {
    case SET_FILTERS:
      return {
        ...state,
        filters: action.filters,
      };
    case LOGOUT:
      return initialState();
    default:
      return state;
  }
}

export function getParkingRightRequestsSearchCriteriaState(state: any) {
  return state.parkingRightRequestsSearchCriteria;
}

export function setFilters(filters: ParkingRightRequestsSearchCriteria): any {
  return {
    type: SET_FILTERS,
    filters,
  };
}
