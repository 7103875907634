import React, { CSSProperties } from 'react';
import moment, { Moment } from 'moment';
import IntlPolyfill from 'intl';
import {
  Checkbox,
  CircularProgress,
  DatePicker,
  Dialog,
  TextField,
} from 'material-ui';
import CreatableSelect from 'react-select/creatable';
import { Trans } from 'react-i18next';

import {
  PaymentStatus,
  PENDING_AND_INCOMPLETE_PAYMENT_STATUSES,
} from 'api/commonTypes';
import { updateFps } from 'api/fps/';
import { ResponsibleOfReducedPeriod } from 'api/fps/types';
import ErrorBlock from 'commons/ErrorBlock';
import {
  BKG_LIGHT_BLUE,
  STYLE_ERROR_WRAPPER,
  STYLE_LOADING_WRAPPER,
} from 'theme';
import {
  convertToCents,
  formatCentsToEuro,
  positivePrice,
} from 'commons/Utils/paymentUtil';
import { formatDate, toHours, toMinutes } from 'commons/Utils/dateUtil';
import BoButton from 'facade/BoButton';

import { formatDateTime } from '../helpers';

const { _tg } = window.loadTranslations(__filename);

const ERROR_REDUCED_PRICE_SUPERIOR_TO_PRICE =
  'Le montant minoré doit être inférieur au montant du FPS.';

const STYLE_TITLE: CSSProperties = {
  backgroundColor: BKG_LIGHT_BLUE,
  color: '#ffffff',
  fontWeight: 'bold',
};

const STYLE_CONTENT_WRAPPER: CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignContent: 'center',
};

const STYLE_PRICE_INPUT: CSSProperties = {
  color: BKG_LIGHT_BLUE,
  fontWeight: 'bold',
  fontSize: 40,
};

const STYLE_REDUCED_PRICE: CSSProperties = {
  color: BKG_LIGHT_BLUE,
  fontWeight: 'bold',
  fontSize: 30,
};

const STYLE_REDUCED_DATETIME: CSSProperties = {
  color: BKG_LIGHT_BLUE,
  fontWeight: 'bold',
  fontSize: 26,
};

const STYLE_DATETIME_BLOCK: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  height: 80,
  width: 400,
};

const STYLE_ERROR_BLOCK: CSSProperties = {
  marginTop: '2px',
  padding: '5px',
  fontSize: '12px',
  color: 'rgb(216 29 29)',
};

const STYLE_SELECT_CONTAINER: CSSProperties = {
  height: 90,
};

const STYLE_INPUTS: CSSProperties = {
  borderColor: BKG_LIGHT_BLUE,
  color: BKG_LIGHT_BLUE,
};

const REACT_SELECT_STYLE = {
  menu: (base: any) => ({
    ...base,
    zIndex: 100,
  }),
  placeholder: (styles: any) => ({
    ...styles,
    color: ' rgba(0, 0, 0, 0.3)',
  }),
  option: (styles: any, state: any) => ({
    ...styles,
    backgroundColor: state.isSelected ? 'rgb(65, 183, 195)' : 'white',
    '&:hover': {
      backgroundColor: 'rgb(65, 183, 195)',
    },
  }),
  valueContainer: (styles: any) => ({
    ...styles,
    padding: 0,
  }),
  input: (styles: any, state: any) => ({
    ...styles,
    width: '100%',
    gridTemplateColumns: '0 minmax(min-content, 1fr)',
  }),
  control: (styles: any, state: any) => ({
    ...styles,
    height: 60,
    border: 0,
    boxShadow: 'none',
    borderRadius: 0,
    borderBottom: state.isFocused
      ? '2px solid rgb(65, 183, 195)'
      : '1px solid #d0cfcf',
    '&:hover': {
      borderBottom: '2px solid rgb(65, 183, 195)',
    },
  }),
};

type AddCorrectionProps = {
  isOpen: boolean;
  statementDatetime: string;
  paymentStatus: PaymentStatus;
  currentPrice: number;
  currentReducedPrice: number | null | undefined;
  currentReducedDatetime: Date | null | undefined; // eslint-disable-line react/no-unused-prop-types
  currentSurcharge: number | undefined;
  responsibleOfReducedPeriod: ResponsibleOfReducedPeriod;
  fpsId: string; // eslint-disable-line react/no-unused-prop-types
  close: () => any;
  reloadFps: () => any;
  fpsComments: Array<string>;
};

type AddCorrectionState = {
  loading: boolean;
  errors: AddCorrectionErrors;
  amount: number;
  reducedPrice: number;
  reducedDatetime: Moment;
  hasReducedPayment: boolean;
  surcharge: number;
  origin: string;
  comment: string | undefined;
  confirmModal: boolean;
  cannotRectifyModal: boolean;
  administrativeCertificate: string;
};

const initialState = {
  loading: false,
  errors: {},
  hasReducedPayment: true,
  origin: 'DESK',
  comment: undefined,
  confirmModal: false,
  cannotRectifyModal: false,
};

const getInitialState = (props: AddCorrectionProps) => ({
  ...initialState,
  hasReducedPayment: props.currentReducedDatetime !== null,
  amount: formatCentsToEuro(props.currentPrice),
  reducedPrice: props.currentReducedPrice
    ? formatCentsToEuro(props.currentReducedPrice)
    : 0,
  reducedDatetime: props.currentReducedDatetime
    ? moment(props.currentReducedDatetime)
    : moment(),
  surcharge: props.currentSurcharge
    ? formatCentsToEuro(props.currentSurcharge)
    : 0,
  administrativeCertificate: '',
});

interface AddCorrectionErrors {
  increasedPrice?: string | undefined;
  reducedPrice?: string | undefined;
  reducedDatetime?: string | undefined;
  patchFailed?: string | undefined;
  comment?: string | undefined;
}

class AddCorrection extends React.Component<
  AddCorrectionProps,
  AddCorrectionState
> {
  constructor(props: AddCorrectionProps) {
    super(props);
    this.state = getInitialState(this.props);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(newProps: AddCorrectionProps): void {
    this.setState(getInitialState(newProps));
  }

  onClose = (): void => {
    const { close } = this.props;
    close();
    this.setState({ ...initialState });
  };

  onClickValidate = async (): Promise<void> => {
    const {
      confirmModal,
      amount,
      comment,
      hasReducedPayment,
      reducedDatetime,
      reducedPrice,
      surcharge,
      administrativeCertificate,
    } = this.state;
    const { responsibleOfReducedPeriod } = this.props;

    const errors = this.validate();

    if (errors && Object.keys(errors).length) {
      this.setState({ errors });
      return;
    }

    if (!confirmModal) {
      this.onClickConfirm();
      return;
    }

    const { fpsId, reloadFps, currentSurcharge } = this.props;
    const hasSurcharge = currentSurcharge !== undefined;

    const body = {
      amount: convertToCents(amount),
      comment,
      administrativeCertificate,
      reducedPrice:
        hasReducedPayment && responsibleOfReducedPeriod === 'CITY'
          ? convertToCents(reducedPrice)
          : null,
      reducedDatetime:
        hasReducedPayment && responsibleOfReducedPeriod === 'CITY'
          ? reducedDatetime
          : null,
      newSurcharge: hasSurcharge ? convertToCents(surcharge) : null,
    };

    this.setState({ loading: true });
    try {
      await updateFps(fpsId, body);
      await reloadFps();
    } catch (error) {
      this.setState({
        errors: {
          patchFailed:
            "Une erreur est survenue lors de l'envoi de la correction",
        },
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  onClickConfirm = (): void => {
    this.setState({ confirmModal: true });
  };

  onChangeReducedDate = (_useless: any, newReducedDate: Date): void => {
    const { reducedDatetime } = this.state;
    const newDate = moment(newReducedDate);
    newDate.hours(reducedDatetime.hours());
    newDate.minutes(reducedDatetime.minutes());
    this.setState({ reducedDatetime: newDate });
  };

  onChangeHours = (
    _e: React.ChangeEvent<HTMLInputElement>,
    hours: string
  ): void => {
    const { reducedDatetime } = this.state;
    this.setState({
      reducedDatetime: reducedDatetime.clone().hours(toHours(hours)),
    });
  };

  onChangeMinutes = (
    e: React.ChangeEvent<HTMLInputElement>,
    minutes: string
  ): void => {
    const { reducedDatetime } = this.state;
    this.setState({
      reducedDatetime: reducedDatetime.clone().minutes(toMinutes(minutes)),
    });
  };

  onChangeComment = (
    _e: React.ChangeEvent<HTMLInputElement>,
    comment: string
  ): void => {
    this.setState({ comment, errors: { comment: undefined } });
  };

  onChangeAdministrativeCertificate = (
    _e: React.ChangeEvent<HTMLInputElement>,
    adminCertif: string
  ): void => {
    this.setState({ administrativeCertificate: adminCertif });
  };

  selectComment = (selectedComment: string | undefined) => {
    this.setState({
      comment: selectedComment,
      errors: { comment: undefined },
    });
  };

  isPriceUnchanged = (
    currentPrice: number | null | undefined,
    newPrice: number
  ): boolean => {
    const current = currentPrice || 0;
    return current === Math.floor(newPrice * 100);
  };

  validate = (): AddCorrectionErrors | undefined => {
    const {
      currentPrice,
      currentReducedPrice,
      currentSurcharge,
      paymentStatus,
      statementDatetime,
      currentReducedDatetime,
      responsibleOfReducedPeriod,
    } = this.props;
    const {
      amount,
      hasReducedPayment,
      reducedDatetime,
      reducedPrice,
      surcharge,
      comment,
    } = this.state;

    if (
      this.isPriceUnchanged(currentPrice, amount) &&
      this.isPriceUnchanged(currentReducedPrice, reducedPrice) &&
      this.isPriceUnchanged(currentSurcharge, surcharge) &&
      (!currentReducedDatetime ||
        new Date(currentReducedDatetime).toISOString() ===
          reducedDatetime.toISOString())
    ) {
      this.setState({ cannotRectifyModal: true });
      return undefined;
    }
    const errors: AddCorrectionErrors = {};
    // On interdit l'augmentation du montant (et du montant réduit) du fps s'il est déjà (sur)payé (ou abandonné)
    const reducedPriceIncreased =
      (currentReducedPrice &&
        reducedPrice &&
        100 * reducedPrice > currentReducedPrice) ||
      (!currentReducedPrice && reducedPrice);
    const priceHasIncreased = 100 * amount > currentPrice;
    if (!comment || !comment.trim()) {
      errors.comment = 'Ce champ est obligatoire.';
    }
    if (
      !PENDING_AND_INCOMPLETE_PAYMENT_STATUSES.includes(paymentStatus) &&
      (priceHasIncreased || reducedPriceIncreased)
    ) {
      if (priceHasIncreased) {
        errors.increasedPrice =
          "Il n'est pas possible d'augmenter le montant d'un FPS déjà payé.";
      }
      if (reducedPriceIncreased) {
        errors.reducedPrice =
          "Il n'est pas possible d'augmenter le montant minoré d'un FPS déjà payé.";
      }
    }
    if (hasReducedPayment && responsibleOfReducedPeriod === 'CITY') {
      if (reducedPrice > amount) {
        errors.reducedPrice = errors.reducedPrice
          ? `${errors.reducedPrice} ${ERROR_REDUCED_PRICE_SUPERIOR_TO_PRICE}`
          : ERROR_REDUCED_PRICE_SUPERIOR_TO_PRICE;
      }
      if (reducedDatetime.isBefore(statementDatetime)) {
        errors.reducedDatetime = `La fin de la minoration ne peut pas être avant la date de constat (${formatDateTime(
          statementDatetime
        )})`;
      }
    }
    return errors;
  };

  handleCheck = (
    _e: React.MouseEvent<any>,
    hasReducedPayment: boolean
  ): void => {
    const { amount } = this.state;
    this.setState({
      hasReducedPayment: hasReducedPayment && !!amount,
    });
  };

  changeReducedPrice = (
    _e: React.ChangeEvent<HTMLInputElement>,
    reducedPrice: string
  ): void => this.setState({ reducedPrice: positivePrice(reducedPrice, 0.01) });

  changeSurcharge = (
    _e: React.ChangeEvent<HTMLInputElement>,
    newSurcharge: string
  ): void => this.setState({ surcharge: positivePrice(newSurcharge, 0) });

  changeAmount = (
    _e: React.ChangeEvent<HTMLInputElement>,
    amount: string
  ): void => {
    const { hasReducedPayment } = this.state;
    // Un FPS annulé n'a pas de période de minoration
    const newPrice = positivePrice(amount);
    this.setState({
      amount: newPrice,
      hasReducedPayment: hasReducedPayment && newPrice > 0,
    });
  };

  render(): JSX.Element {
    const {
      loading,
      errors,
      confirmModal,
      amount,
      reducedDatetime,
      reducedPrice,
      hasReducedPayment,
      cannotRectifyModal,
      comment,
      surcharge,
      administrativeCertificate,
    } = this.state;
    const {
      isOpen,
      responsibleOfReducedPeriod,
      fpsComments,
      currentSurcharge,
    } = this.props;
    const displaySurcharge = currentSurcharge !== undefined;

    const cancel = [
      <BoButton
        style={{ marginRight: 10 }}
        key={1}
        label="Annuler"
        onClick={this.onClose}
      />,
    ];

    const actions = cancel.concat([
      <BoButton
        key={2}
        label={confirmModal ? 'Confirmer' : 'Modifier'}
        primary
        keyboardFocused
        onClick={this.onClickValidate}
      />,
    ]);

    const options = fpsComments?.map((value, index) => ({
      label: value,
      value: index,
    }));

    return (
      <Dialog
        actions={cannotRectifyModal ? cancel : actions}
        title={confirmModal ? 'Confirmation' : 'Modifier le FPS'}
        open={isOpen}
        onRequestClose={this.onClose}
        titleStyle={STYLE_TITLE}
      >
        {loading && (
          <div style={STYLE_LOADING_WRAPPER}>
            <CircularProgress />
          </div>
        )}
        {!confirmModal && !cannotRectifyModal && (
          <div style={{ height: 350 }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'baseline',
                gap: 10,
              }}
            >
              <TextField
                underlineFocusStyle={STYLE_INPUTS}
                floatingLabelFocusStyle={STYLE_INPUTS}
                value={amount}
                errorText={errors.increasedPrice}
                onChange={this.changeAmount}
                floatingLabelText="Nouveau montant du FPS (€)"
                type="number"
                min={0}
                step={0.01}
                inputStyle={STYLE_PRICE_INPUT}
                style={{ height: 100, width: 260 }}
              />
              {amount === 0 && (
                <span style={{ marginRight: '10%' }}>
                  <Trans
                    i18nKey={_tg(
                      'tefps.fps.detail.addContents.addCorrection.info'
                    )}
                    components={[<br />]}
                  />
                </span>
              )}
            </div>
            {!!amount && responsibleOfReducedPeriod === 'CITY' && (
              <>
                <Checkbox
                  label="Minoration"
                  checked={hasReducedPayment}
                  onCheck={this.handleCheck}
                  style={{ width: 256, marginTop: '1.7em' }}
                />
                {hasReducedPayment && (
                  <div style={STYLE_CONTENT_WRAPPER}>
                    <TextField
                      underlineFocusStyle={STYLE_INPUTS}
                      floatingLabelFocusStyle={STYLE_INPUTS}
                      value={reducedPrice}
                      errorText={errors.reducedPrice}
                      onChange={this.changeReducedPrice}
                      floatingLabelText="Nouveau montant minoré du FPS (€)"
                      type="number"
                      min={0.01}
                      step={0.01}
                      inputStyle={STYLE_REDUCED_PRICE}
                      style={{ height: 80, width: 260 }}
                    />
                    <div style={STYLE_DATETIME_BLOCK}>
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <DatePicker
                          DateTimeFormat={IntlPolyfill.DateTimeFormat}
                          locale="fr"
                          underlineFocusStyle={STYLE_INPUTS}
                          /* floatingLabelFocusStyle={STYLE_INPUTS} */ inputStyle={
                            STYLE_REDUCED_DATETIME
                          }
                          autoOk
                          textFieldStyle={{
                            height: 80,
                            width: 200,
                            marginRight: 5,
                          }}
                          value={reducedDatetime.toDate()}
                          onChange={this.onChangeReducedDate}
                          floatingLabelText="Date de fin de minoration"
                          container="inline"
                          formatDate={formatDate}
                        />
                        <TextField
                          underlineFocusStyle={STYLE_INPUTS}
                          floatingLabelFocusStyle={STYLE_INPUTS}
                          inputStyle={STYLE_REDUCED_DATETIME}
                          min={0}
                          max={23}
                          step={1}
                          floatingLabelText="Heures"
                          type="number"
                          style={{
                            height: 80,
                            width: 80,
                            marginLeft: 10,
                            marginRight: 5,
                          }}
                          value={reducedDatetime.hours()}
                          onChange={this.onChangeHours}
                        />
                        :
                        <TextField
                          underlineFocusStyle={STYLE_INPUTS}
                          floatingLabelFocusStyle={STYLE_INPUTS}
                          inputStyle={STYLE_REDUCED_DATETIME}
                          min={0}
                          max={59}
                          step={1}
                          floatingLabelText="Minutes"
                          type="number"
                          style={{ height: 80, width: 80, marginLeft: 5 }}
                          value={reducedDatetime.minutes()}
                          onChange={this.onChangeMinutes}
                        />
                      </div>
                      <div
                        style={{
                          color: '#F44336',
                          fontSize: '0.8em',
                          textAlign: 'center',
                        }}
                      >
                        {errors.reducedDatetime}
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
            {displaySurcharge && (
              <div style={STYLE_CONTENT_WRAPPER}>
                <TextField
                  underlineFocusStyle={STYLE_INPUTS}
                  floatingLabelFocusStyle={STYLE_INPUTS}
                  value={surcharge}
                  onChange={this.changeSurcharge}
                  floatingLabelText="Nouveau montant majoré du FPS (€)"
                  type="number"
                  min={0}
                  step={0.01}
                  inputStyle={STYLE_REDUCED_PRICE}
                  style={{ height: 80, width: 260 }}
                />
              </div>
            )}
            {fpsComments && fpsComments.length > 0 ? (
              <div style={STYLE_SELECT_CONTAINER}>
                <CreatableSelect
                  isClearable={comment !== undefined}
                  placeholder="Selectionner un commentaire"
                  id="comment"
                  options={options}
                  onChange={selectedOption => {
                    this.selectComment(selectedOption?.label);
                  }}
                  onInputChange={(input, action) => {
                    if (action.action === 'input-change') {
                      this.selectComment(input);
                    }
                  }}
                  styles={REACT_SELECT_STYLE}
                  maxMenuHeight={200}
                  minMenuHeight={50}
                  menuPosition="absolute"
                  noOptionsMessage={() => null}
                  isValidNewOption={() => false}
                />

                {errors.comment && (
                  <span style={STYLE_ERROR_BLOCK}>{errors.comment}</span>
                )}
              </div>
            ) : (
              <TextField
                style={{ marginTop: errors.reducedPrice ? 16 : 0 }}
                underlineFocusStyle={STYLE_INPUTS}
                floatingLabelFocusStyle={STYLE_INPUTS}
                value={comment}
                onChange={this.onChangeComment}
                floatingLabelText="Commentaire (obligatoire)"
                errorText={errors.comment}
                fullWidth
                multiLine
                rows={3}
                rowsMax={3}
                required
              />
            )}
            {amount === 0 && (
              <div style={STYLE_CONTENT_WRAPPER}>
                <TextField
                  underlineFocusStyle={STYLE_INPUTS}
                  floatingLabelFocusStyle={STYLE_INPUTS}
                  value={administrativeCertificate || ''}
                  onChange={this.onChangeAdministrativeCertificate}
                  floatingLabelText={_tg(
                    'tefps.fps.detail.addContents.addCancelProposal.adminCertificate'
                  )}
                  fullWidth
                  multiLine
                />
              </div>
            )}
          </div>
        )}
        {confirmModal && !cannotRectifyModal && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: 30,
            }}
          >
            <p>
              {_tg('tefps.fps.detail.addContents.addCorrection.confirmEdition')}
            </p>
          </div>
        )}
        {cannotRectifyModal && (
          <div
            style={{ display: 'flex', flexDirection: 'column', marginTop: 30 }}
          >
            <p>{_tg('tefps.fps.detail.addContents.addCorrection.samePrice')}</p>
          </div>
        )}
        {errors && errors.patchFailed && (
          <div style={STYLE_ERROR_WRAPPER}>
            <ErrorBlock error={{ message: errors.patchFailed }} />
          </div>
        )}
      </Dialog>
    );
  }
}

export default AddCorrection;
