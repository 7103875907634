import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Services from 'commons/services';

import { getControl } from '../helpers';

import Loading from './Loading';
import ErrorPage from './ErrorPage';
import ReturnButton from './ReturnButton';
import ControlPage from './ControlPage';

const { _tg } = window.loadTranslations(__filename);

const ControlDetail = (): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error>();

  const { controlId } = useParams<{ controlId: string }>();

  useEffect(() => {
    void getControl(controlId, setError, setLoading);
  }, [controlId]);

  if (error) {
    return (
      <ErrorPage
        message={_tg('feedback.error.fetchControl')}
        error={error}
        navContent={
          <ReturnButton
            href="#/controls/list"
            label={_tg('action.backToList')}
          />
        }
      />
    );
  }

  if (!loading) {
    return <ControlPage />;
  }

  return <Loading />;
};

export default ControlDetail;
