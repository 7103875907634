import * as React from 'react';

import { HistoryAction, SubscriberDTO } from '@cvfm-front/tefps-types';
import UserNotificationDialog from 'tefps/Beneficiaries/commons/UserNotificationDialog';

const SendEmailActionV1 = ({
  action,
  subscriber,
}: {
  action: HistoryAction;
  subscriber: SubscriberDTO;
}): JSX.Element => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span>{action.text}</span>
      <UserNotificationDialog action={action} subscriber={subscriber} />
    </div>
  );
};

export default SendEmailActionV1;
