import React from 'react';
import { useHistory } from 'react-router-dom';
import FlatButton from 'material-ui/FlatButton';
import NavigationArrowBack from 'material-ui/svg-icons/navigation/arrow-back';

const { _tg } = window.loadTranslations();

type ReturnButtonProps = {
  href?: string;
  label?: string;
};

const ReturnButton = ({ href, label }: ReturnButtonProps): JSX.Element => {
  const history = useHistory();

  return (
    <FlatButton
      href={href}
      onClick={href ? undefined : () => history.goBack()}
      label={label ?? _tg('action.back')}
      icon={<NavigationArrowBack />}
    />
  );
};

export default ReturnButton;
