import * as Sentry from '@sentry/react';

export const init = (
  dsn: string,
  env: string,
  enabled: boolean,
  release: string,
  buildSha: string
) => {
  if (!enabled) {
    return;
  }
  Sentry.init({
    dsn,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.captureConsoleIntegration({ levels: ['error', 'assert'] }),
    ],
    environment: env,
    release,
    dist: buildSha,
    tracesSampleRate: 0.05,
    replaysSessionSampleRate: 0.05,
    replaysOnErrorSampleRate: 0.05,
  });
};
