import * as React from 'react';

import BoButton from 'facade/BoButton';
import { Claim } from 'api/fps/types';
import FakeInputBlock from 'commons/FakeInputBlock';
import Separator from 'commons/Separator';
import { DataBoxItem, DataBoxItemWrapper } from 'commons/DataBox';
import Date from 'commons/Date';

type Props = {
  claim: Claim;
  fpsId: string;
  isLastCcsp?: boolean;
  isLastRapo: boolean;
  cvfmRapoPartner: boolean;
};

const FpsRecourse = ({
  claim,
  fpsId,
  isLastCcsp,
  isLastRapo,
  cvfmRapoPartner,
}: Props) => {
  return (
    <div style={{ width: '100%' }}>
      <DataBoxItemWrapper>
        <DataBoxItem>
          <FakeInputBlock
            title="Date"
            value={<Date datetime={claim.claimDateModified} withTime={false} />}
          />
        </DataBoxItem>
        <Separator />
        <DataBoxItem>
          <FakeInputBlock
            title="Heure"
            value={<Date datetime={claim.claimDateModified} withDate={false} />}
          />
        </DataBoxItem>
        {(isLastRapo || isLastCcsp) &&
          cvfmRapoPartner && [
            <Separator key="separator" />,
            <DataBoxItem key="databox">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  height: '100%',
                  justifyContent: 'center',
                }}
              >
                {claim.fpsId && (
                  <BoButton
                    label={`CONSULTER ${isLastCcsp ? 'TSP' : 'RAPO'}`}
                    href={`#/${
                      isLastCcsp ? 'tsp' : 'recourses'
                    }/redirect/fps/${fpsId}`}
                    primary
                  />
                )}
              </div>
            </DataBoxItem>,
          ]}
      </DataBoxItemWrapper>
    </div>
  );
};

export default FpsRecourse;
