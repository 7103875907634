import moment from 'moment';

import { Pager, SortParameters } from 'api/commonTypes';
import { ValueLabel } from '@cvfm-front/commons-types';
import { ControlFiltersParam } from '@cvfm-front/tefps-types';

import { ControlSearchCriterias } from './types';

const { _t, _tg } = window.loadTranslations(__filename);

// The key should be the same as the id in ControlExportColumnsConfig in the back.
export function getExportCol(
  hashPartnerPlateEnabled: boolean,
  qualityControlEnabled: boolean,
  doubleCheckPlateReadingEnabled: boolean,
  useCorrectedCoordinates: boolean,
  enableSiv: boolean,
  canExportParkingSpaceDetails: boolean,
  plannerEnabled: boolean,
  fetchControlActivated: boolean
): Array<{ key: string; label: string; checked?: boolean }> {
  const exportCol = [
    { key: 'controlId', label: _t('table.controlId'), checked: true },
    {
      key: 'agentId',
      label: _t('table.agentId'),
      checked: true,
    },
    { key: 'agentName', label: _t('table.agentName'), checked: true },
    {
      key: 'statementDatetime',
      label: _tg('field.control.controlDate'),
      checked: true,
    },
    { key: 'zoneId', label: _tg('field.address.zone') },
    { key: 'organizationId', label: _tg('field.organisation.organisation') },
    {
      key: 'deviceType',
      label: _t('table.deviceType'),
    },
    { key: 'nbMedias', label: _t('table.nbMedias') },
    { key: 'latitude', label: _t('table.latitude') },
    {
      key: 'longitude',
      label: _t('table.longitude'),
    },
    { key: 'status', label: _tg('field.status'), checked: true },
    {
      key: 'exemptionReason',
      label: _tg('field.control.exemptionReason'),
    },
    { key: 'fineLegalId', label: _tg('field.control.fpsId') },
    { key: 'terminalId', label: _tg('field.control.terminalId') },
    { key: 'plate', label: _tg('field.vehicle.license') },
    {
      key: 'pricingCategory',
      label: _t('table.pricingCategory'),
    },
    { key: 'statementAddress', label: _t('table.controlAddress') },
    {
      key: 'statementAddressDistance',
      label: _t('table.addressDistance'),
    },
    {
      key: 'reviewAgentId',
      label: _t('table.reviewAgentId'),
    },
    {
      key: 'reviewAgentName',
      label: _t('table.reviewAgentName'),
    },
    {
      key: 'reviewDatetime',
      label: _t('table.reviewDatetime'),
    },
    {
      key: 'tags',
      label: _t('table.tags'),
    },
    {
      key: 'treatmentAgentHashedPlate',
      label: _t('table.treatmentAgentHashedPlate'),
    },
    {
      key: 'treatmentAgentVehicleBrand',
      label: _t('table.treatmentAgentVehicleBrand'),
    },
    {
      key: 'treatmentAgentVehicleModel',
      label: _t('table.treatmentAgentVehicleModel'),
    },
    {
      key: 'treatmentAgentFullStatementAddress',
      label: _t('table.treatmentAgentFullStatementAddress'),
    },
    {
      key: 'reviewVehicleType',
      label: _t('table.reviewVehicleType'),
    },
    {
      key: 'plateCountry',
      label: _t('table.plateCountry'),
    },
    {
      key: 'reviewReason',
      label: _t('table.reviewReason'),
    },
    {
      key: 'vehicleType',
      label: _t('table.vehicleType'),
    },
    {
      key: 'presumedVehicleType',
      label: _t('table.presumedVehicleType'),
    },
    {
      key: 'comment',
      label: _t('table.comment'),
    },
    {
      key: 'initialPlate',
      label: _t('table.initialPlate'),
    },
  ];
  if (hashPartnerPlateEnabled) {
    exportCol.push({
      key: 'hashedPartnerPlate',
      label: _t('table.hashedPartnerPlate'),
    });
  }

  if (qualityControlEnabled) {
    exportCol.push(
      {
        key: 'qualityControlAgentId',
        label: _t('table.qualityControlAgentId'),
      },
      {
        key: 'qualityControlAgentName',
        label: _t('table.qualityControlAgentName'),
      },
      {
        key: 'qualityControlDatetime',
        label: _t('table.qualityControlDatetime'),
      },
      {
        key: 'qualityControlTags',
        label: _t('table.qualityControlTags'),
      },
      {
        key: 'qualityControlStatusDecision',
        label: _t('table.qualityControlStatusDecision'),
      },
      {
        key: 'qualityControlExemptionMotif',
        label: _t('table.qualityControlExemptionMotif'),
      },
      {
        key: 'qualityControlVehicleType',
        label: _t('table.qualityControlVehicleType'),
      },
      {
        key: 'qualityControlHashedPlate',
        label: _t('table.qualityControlHashedPlate'),
      },
      {
        key: 'qualityControlVehicleBrand',
        label: _t('table.qualityControlVehicleBrand'),
      },
      {
        key: 'qualityControlVehicleModel',
        label: _t('table.qualityControlVehicleModel'),
      },
      {
        key: 'qualityControlFullStatementAddress',
        label: _t('table.qualityControlFullStatementAddress'),
      },
      {
        key: 'reviewStatementAddressComparative',
        label: _t('table.reviewStatementAddressComparative'),
      },
      {
        key: 'isComplex',
        label: _t('table.isComplex'),
      },
      {
        key: 'reliabilityScore',
        label: _tg('field.control.reliabilityScore'),
      }
    );
  }

  exportCol.push(
    {
      key: 'esLAPIControlState',
      label: _t('table.esLAPIControlState'),
    },
    {
      key: 'modificationDatetime',
      label: _t('table.modificationDatetime'),
    }
  );

  if (doubleCheckPlateReadingEnabled) {
    exportCol.push({
      key: 'doubleCheckPlateReading',
      label: _t('table.doubleCheckPlateReading'),
    });
  }

  if (useCorrectedCoordinates) {
    exportCol.push({
      key: 'initialLatitude',
      label: _t('table.initialLatitude'),
    });
    exportCol.push({
      key: 'initialLongitude',
      label: _t('table.initialLongitude'),
    });
  }
  if (enableSiv) {
    exportCol.push({
      key: 'critAir',
      label: _t('table.critAir'),
    });
  }
  exportCol.push({
    key: 'parkingSpaceId',
    label: _tg('field.control.parkingSpaceId'),
  });
  if (canExportParkingSpaceDetails) {
    exportCol.push({
      key: 'parkingSpaceRegime',
      label: _t('table.parkingSpaceRegime'),
    });
    exportCol.push({
      key: 'parkingSpaceType',
      label: _t('table.parkingSpaceType'),
    });
  }
  if (fetchControlActivated) {
    exportCol.push({
      key: 'lapiReviewFetchabilityFailReasons',
      label: _tg('field.control.ControlLapiReviewFetchabilityFailReason.title'),
    });
  }

  if (plannerEnabled) {
    exportCol.push({
      key: 'patrolZoneId',
      label: _t('table.patrolZoneId'),
    });
  }

  return exportCol;
}

export const translateCombinedExportCols = (): Array<{
  key: string;
  label: string;
  checked?: boolean;
}> => [
  { key: 'type', label: 'Nature', checked: true },
  {
    key: 'id',
    label: _tg('field.id'),
    checked: true,
  },
  { key: 'statementDatetime', label: 'Date', checked: true },
  {
    key: 'latitude',
    label: _t('table.latitude'),
    checked: true,
  },
  { key: 'longitude', label: _t('table.longitude'), checked: true },
  {
    key: 'agentId',
    label: _t('table.agentId'),
    checked: true,
  },
  { key: 'agentName', label: _t('table.agentName'), checked: true },
  {
    key: 'statementAuthority',
    label: _t('table.authority'),
    checked: true,
  },
  {
    key: 'exemptionReason',
    label: _tg('field.control.exemptionReason'),
    checked: true,
  },
];

export const translateLAPIStatesFilterOptions = (): Array<{
  value: string;
  label: string;
}> => [
  { value: 'DONE_LAPI', label: _t('filter.DONE_LAPI') },
  { value: 'WAIT_LAPI', label: _t('filter.WAIT_LAPI') },
  { value: 'EXPIRED_LAPI', label: _t('filter.EXPIRED_LAPI') },
  { value: 'SKIP_LAPI', label: _t('filter.SKIP_LAPI') },
  { value: 'NOT_LAPI', label: _t('filter.NOT_LAPI') },
];

export const translateReviewReasonFilterOptions = (): Array<{
  value: string;
  label: string;
}> => [
  { value: 'vehicleType', label: _t('filter.vehicleType') },
  { value: 'pricingCategory', label: _t('filter.pricingCategory') },
];

export const translateStatusesFilterOptions = (): Array<{
  value: string;
  label: string;
}> => [
  { value: 'OK', label: _t('filter.OK') },
  { value: 'KO', label: _t('filter.KO') },
  { value: 'WAITING_REVIEW', label: _t('filter.WAITING_REVIEW') },
  {
    value: 'EXEMPTION',
    label: _t('filter.EXEMPTION'),
  },
  { value: 'EMPTY', label: _t('filter.EMPTY') },
  { value: 'UNKNOWN', label: _t('filter.UNKNOWN') },
];

export const translateMediasOptions = (): Array<{
  value: string;
  label: string;
}> => [
  { value: 'WITH', label: _t('medias.WITH') },
  { value: 'WITHOUT', label: _t('medias.WITHOUT') },
];

export const translateDeviceTypeFilterOptions = (
  esControlDeviceTypes: KeyBucketDTO[] | undefined,
  allTypes: boolean
): Array<{
  value: string;
  label: string;
}> => {
  const types = [
    { value: 'LAPI', label: _t('filter.LAPI') },
    { value: 'AGENT', label: _t('filter.AGENT') },
  ];
  if (allTypes) {
    types.push({ value: 'PDA_REVIEW', label: _t('filter.PDA_REVIEW') });
  } else {
    esControlDeviceTypes?.forEach((type: KeyBucketDTO) => {
      if (type.key === 'PDA_REVIEW' && type.value > 0) {
        types.push({ value: 'PDA_REVIEW', label: _t('filter.PDA_REVIEW') });
      }
    });
  }
  return types;
};

export const translateFPSFromControlFilterOptions = (): ValueLabel[] => [
  { value: 'WITH', label: _t('filter.WITH') },
  { value: 'WITHOUT', label: _t('filter.WITHOUT') },
];

export const translateHasParkingSpaceFilterOptions = (): ValueLabel[] => [
  { value: 'hasParkingSpaceId', label: _t('filter.WITH') },
  { value: 'missingParkingSpaceId', label: _t('filter.WITHOUT') },
];

export const translateQualityControlStatesFilterOptions = (): Array<{
  value: string;
  label: string;
}> => [
  { value: 'PENDING_QUALITY', label: _t('filter.PENDING_QUALITY') },
  { value: 'EQUAL_QUALITY', label: _t('filter.EQUAL_QUALITY') },
  { value: 'DIFFERENT_QUALITY', label: _t('filter.DIFFERENT_QUALITY') },
];

function getSortFieldText(sortField: number) {
  switch (sortField) {
    case 0:
      return 'controlId';
    case 2:
      return 'agentName';
    case 3:
      return 'terminalId';
    case 5:
      return 'zoneId';
    default:
      return 'statementDatetime';
  }
}

export const filtersToRequest = (
  filters: ControlSearchCriterias,
  sort?: SortParameters,
  pager?: Pager
): ControlFiltersParam => {
  const {
    esLAPIControlStates,
    controlStatuses,
    esControlDeviceTypes,
    fpsCreationStatuses,
    exemptionReasons,
    tags,
    lapiReviewFetchabilityFailReasons,
    statementDatetime,
    times,
    controlId,
    fineId,
    zoneId,
    patrolZoneId,
    agentId,
    agentReviewId: agentReviewIdOrName,
    terminalId,
    parkingSpaceId,
    plate,
    organizationShortId,
    organizationIds,
    mediaStatuses,
    qualityControlComparativeStates,
    reviewReasons,
    vehicleTypes,
    presumedVehicleTypes,
    hasParkingSpaceId,
  } = filters;

  const { page, maxRecords } = pager || { page: 0, maxRecords: 0 };
  const { from, to } = statementDatetime || {};

  let filterOnHasParkingSpaceId;
  if (
    hasParkingSpaceId.has('hasParkingSpaceId') &&
    !hasParkingSpaceId.has('missingParkingSpaceId')
  ) {
    filterOnHasParkingSpaceId = true;
  } else if (
    !hasParkingSpaceId.has('hasParkingSpaceId') &&
    hasParkingSpaceId.has('missingParkingSpaceId')
  ) {
    filterOnHasParkingSpaceId = false;
  }

  return {
    esLAPIControlStates: Array.from(esLAPIControlStates),
    controlStatuses: Array.from(controlStatuses),
    esControlDeviceTypes: Array.from(esControlDeviceTypes),
    fpsCreationStatuses: Array.from(fpsCreationStatuses),
    controlExemptionReasons: Array.from(exemptionReasons),
    tags: Array.from(tags),
    lapiReviewFetchabilityFailReasons: Array.from(
      lapiReviewFetchabilityFailReasons
    ),
    statementStartDatetime: from ? moment(from).toISOString() : null,
    statementEndDatetime: to ? moment(to).toISOString() : null,
    startTime: times.from,
    endTime: times.to,
    controlId,
    fineId,
    agentIds: agentId ? [agentId] : null,
    agentReviewIds: agentReviewIdOrName ? [agentReviewIdOrName] : null,
    zoneId,
    patrolZoneId,
    terminalId,
    parkingSpaceId,
    plate,
    reviewReasons: Array.from(reviewReasons),
    vehicleTypes: Array.from(vehicleTypes),
    presumedVehicleTypes: Array.from(presumedVehicleTypes),
    page,
    organizationShortId,
    organizationIds: Array.from(organizationIds),
    maxRecords,
    order: sort?.increasingOrder ? 'ASC' : 'DESC',
    sortField: sort ? getSortFieldText(sort.sortField) : 'statementDatetime',
    mediaStatuses: Array.from(mediaStatuses),
    qualityControlComparativeStates: Array.from(
      qualityControlComparativeStates
    ),
    hasParkingSpaceId: filterOnHasParkingSpaceId,
  };
};
