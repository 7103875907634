import React, { CSSProperties } from 'react';

import { BKG_PINK } from 'theme';

const STYLE_HEADER_BUTTON: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '#FFF',
  color: BKG_PINK,
  border: `1px solid ${BKG_PINK}`,
  borderRadius: '5px',
  height: 30,
  width: 'max-content',
  fontSize: 14,
  padding: '0px 50px',
  margin: '0px 16px 0px 16px',
};

const STYLE_SELECTED: CSSProperties = {
  ...STYLE_HEADER_BUTTON,
  backgroundColor: BKG_PINK,
  color: '#FFF',
  borderColor: BKG_PINK,
};
const STYLE_UNSELECTED: CSSProperties = {
  ...STYLE_HEADER_BUTTON,
  backgroundColor: '#FFF',
  color: BKG_PINK,
  borderColor: BKG_PINK,
};

type PropsStatus = {
  active: boolean;
  title: string;
  value: string;
};

type StateStatus = {
  hovered: boolean;
};

class StatusButton extends React.Component<PropsStatus, StateStatus> {
  constructor(props: PropsStatus) {
    super(props);
    this.state = {
      hovered: false,
    };
  }

  handleHover = (): void => {
    const { hovered } = this.state;
    this.setState({ hovered: !hovered });
  };

  render(): JSX.Element {
    const { title, active, value } = this.props;
    const { hovered } = this.state;

    if (!active) {
      const style = hovered || active ? STYLE_SELECTED : STYLE_UNSELECTED;
      return (
        <div
          title={title}
          style={{ ...style, cursor: 'pointer' }}
          onMouseEnter={this.handleHover}
          onMouseLeave={this.handleHover}
        >
          {value}
        </div>
      );
    }
    return (
      <div title="" style={STYLE_SELECTED}>
        {value}
      </div>
    );
  }
}

export default StatusButton;
