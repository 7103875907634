import moment from 'moment';

const { _t } = window.loadTranslations(__filename);

export function validateCcspNumber(ccspNumber: string | null | undefined) {
  if (!ccspNumber || !/^[0-9]{8}$/.test(ccspNumber)) {
    return _t('feedback.error.ccspFormat');
  }
  return undefined;
}

export function validateFpsId(
  fpsId: string | null | undefined,
  recovery: boolean
) {
  if (!recovery && (!fpsId || !/^[0-9]{26}$/.test(fpsId))) {
    return _t('feedback.error.fpsFormat');
  }
  if (recovery && (!fpsId || !/^[0-9AB]{18}$/i.test(fpsId))) {
    return _t('feedback.error.surchargedFpsFormat');
  }
  return undefined;
}

export function validateDeadline(deadline: Date, openingDate: Date) {
  if (
    moment(deadline, 'YYYY-MM-DD').isBefore(moment(openingDate, 'YYYY-MM-DD'))
  ) {
    return _t('feedback.error.deadlineDate');
  }
  return undefined;
}
