import React, { useCallback, useMemo, useState } from 'react';
import './OverviewBlock.css';
import { Card } from 'material-ui';
import { connect } from 'react-redux';

import useControl from 'commons/hooks/useControl';
import FakeInputBlock from 'commons/FakeInputBlock';
import Date from 'commons/Date';
import { HorizontalTabs, VersionsDisplayer } from '@cvfm-front/tefps-ui';
import { ExemptionReason, getConfigState } from 'config/duck';
import CopyValueButton from 'commons/CopyValueButton';
import { ControlDTO } from 'api/control/types';
import BoButton from 'facade/BoButton';
import {
  CameraPositionControl,
  ControlProjectionParametersV1,
  ControlProjectionParametersV2,
  ControlProjectionParametersV3,
} from '@cvfm-front/tefps-types';

import ControlStatusHistory from '../List/ControlDetail/ControlStatusHistory';

import { transformControlVersionDtoToVersion } from './MapControlVersionForDisplay';
import ControlDetailMap from './ControlDetailMap';
import ControlTicketInformation from './ControlTicketInformation';
import OverviewBlockProjectionV1 from './OverviewBlockProjectionV1';
import OverviewBlockProjectionV2 from './OverviewBlockProjectionV2';
import OverviewBlockProjectionV3 from './OverviewBlockProjectionV3';

const { _tg } = window.loadTranslations(__filename);

type Props = {
  exemptionReasonsConfigurations: ExemptionReason[] | undefined;
};

const OverviewBlock = ({
  exemptionReasonsConfigurations,
}: Props): JSX.Element => {
  const controlDTO = useControl();

  const [tab, setTab] = useState<string>('detail');

  const [showStatusHistoryList, setShowStatusHistoryList] = useState(false);
  const [
    showControlTicketInformation,
    setShowControlTicketInformation,
  ] = useState(false);

  const toggleControlStatusHistory = useCallback(() => {
    setShowStatusHistoryList(prev => !prev);
  }, []);

  const toggleControlTicketInformation = useCallback(() => {
    setShowControlTicketInformation(prev => !prev);
  }, []);

  const versions = useMemo(() => {
    if (controlDTO === null) {
      return [];
    }

    return [
      {
        ...transformControlVersionDtoToVersion(
          controlDTO.lastVersion,
          0,
          exemptionReasonsConfigurations,
          false
        ),
        title: 'Dernière version',
      },
    ];
  }, [controlDTO]);

  const renderDetail = (dto: ControlDTO) => {
    return (
      <Card>
        <div className="control-overview-block__header">
          <div className="text-bold">
            {_tg(
              'tefps.Control.List.ControlDetail.index.element.controlSynthesis'
            )}
          </div>
          <div>
            {dto.controlId}
            <CopyValueButton
              value={dto.controlId}
              iconStyle={{ height: 15, color: 'white' }}
              spanStyle={{ marginLeft: 5 }}
            />
          </div>
        </div>

        <div className="control-overview-block">
          <FakeInputBlock
            title={_tg(
              'tefps.Control.List.ControlDetail.index.element.dateTimeControl'
            )}
            value={<Date datetime={dto.statementDatetime} />}
          />

          {dto?.agent?.name && (
            <FakeInputBlock
              title={_tg(
                'tefps.Control.List.ControlDetail.index.element.controlAgent'
              )}
              value={dto.agent.name}
            />
          )}

          {!!dto?.lapiReviewFetchabilityFailReasons?.length && (
            <FakeInputBlock
              title={_tg(
                'field.control.ControlLapiReviewFetchabilityFailReason.title'
              )}
              value={dto.lapiReviewFetchabilityFailReasons
                .map(reason =>
                  _tg(
                    `field.control.ControlLapiReviewFetchabilityFailReason.${reason}`
                  )
                )
                .join(', ')}
            />
          )}

          {dto?.versions && (
            <FakeInputBlock
              title={_tg('field.date.creation')}
              value={<Date datetime={dto.versions[0].creationDatetime} />}
            />
          )}

          <VersionsDisplayer versions={versions} />

          <div className="inline-buttons">
            <BoButton
              primary
              className="control-rights-tickets-button"
              onClick={toggleControlStatusHistory}
              label={
                !showStatusHistoryList
                  ? _tg(
                      'tefps.Control.List.ControlDetail.ControlStatusHistory.history.show'
                    )
                  : _tg(
                      'tefps.Control.List.ControlDetail.ControlStatusHistory.history.hide'
                    )
              }
            />
            <BoButton
              primary
              className="control-rights-tickets-button"
              onClick={toggleControlTicketInformation}
              label={
                !showControlTicketInformation
                  ? _tg('tefps.Control.Detail.ControlTicketInformation.show')
                  : _tg('tefps.Control.Detail.ControlTicketInformation.hide')
              }
            />
          </div>

          {showStatusHistoryList && (
            <ControlStatusHistory
              controlStatusHistoryList={dto.controlStatusHistory}
            />
          )}
          {showControlTicketInformation && (
            <ControlTicketInformation controlDTO={dto} />
          )}
        </div>
      </Card>
    );
  };

  const renderMapAndProjectionInfo = (dto: ControlDTO) => {
    let card: JSX.Element | undefined;
    if (dto.lastVersion.projection?.parameters?.version === 'V1') {
      card = (
        <OverviewBlockProjectionV1
          parameters={
            dto.lastVersion.projection
              .parameters as ControlProjectionParametersV1
          }
          capturePosition={
            dto.metadata.capturePosition as CameraPositionControl
          }
        />
      );
    } else if (dto.lastVersion.projection?.parameters?.version === 'V2') {
      card = (
        <OverviewBlockProjectionV2
          parameters={
            dto.lastVersion.projection
              .parameters as ControlProjectionParametersV2
          }
          capturePosition={
            dto.metadata.capturePosition as CameraPositionControl
          }
        />
      );
    } else if (dto.lastVersion.projection?.parameters?.version === 'V3') {
      card = (
        <OverviewBlockProjectionV3
          parameters={
            dto.lastVersion.projection
              .parameters as ControlProjectionParametersV3
          }
          capturePosition={
            dto.metadata.capturePosition as CameraPositionControl
          }
        />
      );
    }
    // if there is a projection, and there are parameters, then display additional info
    return (
      <>
        <ControlDetailMap control={dto} />
        {card}
      </>
    );
  };

  const tabs = [
    {
      id: 'detail',
      label: _tg(
        'tefps.Control.List.ControlDetail.index.element.controlSynthesis'
      ),
      content: <></>,
    },
    {
      id: 'map',
      label: _tg(
        'tefps.Control.List.ControlDetail.index.element.controlGeoloc'
      ),
      content: <></>,
    },
  ];
  return controlDTO ? (
    <>
      <HorizontalTabs
        onActiveTabChange={newTab => setTab(newTab.id)}
        tabs={tabs}
      />
      {tab === 'detail'
        ? renderDetail(controlDTO)
        : renderMapAndProjectionInfo(controlDTO)}
    </>
  ) : (
    <></>
  );
};

export default connect(state => {
  const config = getConfigState(state);
  return {
    exemptionReasonsConfigurations: config.exemptionReasonsConfigurations,
  };
})(OverviewBlock);
