import React, { CSSProperties } from 'react';
import { Link } from 'react-router-dom';

const STYLE_LINK: CSSProperties = {
  fontSize: '1em',
  textDecoration: 'underline',
  fontStyle: 'oblique',
};

const STYLE_WRAPPER: CSSProperties = {
  fontFamily: 'Roboto',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  lineHeight: '1.5em',
};

const { _t } = window.loadTranslations(__filename);

const TransferredFpsLink = ({ fpsId }: { fpsId: string }): JSX.Element => (
  <div style={STYLE_WRAPPER}>
    <div>
      <p style={{ textTransform: 'uppercase' }}>{_t('info')}</p>
    </div>
    <div>
      <Link to={`/fps/detail/${fpsId}`} style={STYLE_LINK}>
        {_t('link')}
      </Link>
    </div>
  </div>
);

export default TransferredFpsLink;
