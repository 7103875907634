import React, { CSSProperties, useState } from 'react';

import BoButton from 'facade/BoButton';
import { DataBoxItem, DataBoxItemWrapper } from 'commons/DataBox';
import FakeInputBlock from 'commons/FakeInputBlock';
import Date from 'commons/Date';
import Separator from 'commons/Separator';
import { RefundProcedureDTO } from 'api/refund/types';

import EditModal from '../../../../Dashboard/fps/Refund/List/EditModal';

const STYLE_BUTTONS: CSSProperties = {
  margin: '10px',
};

type RefundProcedureProps = {
  refund: RefundProcedureDTO;
};

function RefundProcedure({ refund }: RefundProcedureProps) {
  const [refundId, setRefundId] = useState<string | null>(null);

  return (
    <div style={{ width: '100%' }}>
      <DataBoxItemWrapper>
        <DataBoxItem>
          <FakeInputBlock
            title="Date d'envoi"
            value={<Date datetime={refund.sentMailDatetime} withTime={false} />}
          />
          <FakeInputBlock
            title="Heure"
            value={<Date datetime={refund.sentMailDatetime} withDate={false} />}
          />
        </DataBoxItem>
        <Separator />
        <DataBoxItem>
          <FakeInputBlock
            title="Date de réception des informations"
            value={
              <Date
                datetime={refund.receivedDocumentDatetime}
                withTime={false}
              />
            }
          />
          <FakeInputBlock
            title="Heure"
            value={
              <Date
                datetime={refund.receivedDocumentDatetime}
                withDate={false}
              />
            }
          />
        </DataBoxItem>
        <Separator />
      </DataBoxItemWrapper>
      <div>
        <BoButton
          style={STYLE_BUTTONS}
          onClick={() => setRefundId(refund.refundId)}
          primary
          label="Modale de Remboursement"
        />
      </div>
      {refundId && (
        <EditModal refundId={refundId} onClose={() => setRefundId(null)} />
      )}
    </div>
  );
}

export default RefundProcedure;
