import React from 'react';
import moment, { Moment } from 'moment';
import _findLast from 'lodash.findlast';

import {
  CancelProposalDTO,
  Claim,
  ClaimStatus,
  FpsCaseDTO,
  FpsCommentDTO,
  FpsVersionDTO,
} from 'api/fps/types';
import { ControlAgent, Payment, PaymentChannel } from 'api/commonTypes';
import {
  AntaiFpsDTO,
  AntaiFpsReason,
  DebtRecoveryTransactionDTO,
  PaymentDTO as AntaiPayment,
} from 'api/antai/fps';
import { ZoningDTO } from '@cvfm-front/tefps-types';
import {
  BKG_BLUE,
  BKG_CYAN,
  BKG_DARK_BLUE,
  BKG_DARKER_BLUE,
  BKG_LIGHT_BLUE,
  BKG_PINK,
  TXT_RED,
} from 'theme';
import { translatePaymentMode } from 'commons/Utils/paymentUtil';
import FpsCancelProposal from 'tefps/Fps/Detail/Contents/FpsCancelProposal';

import FpsInitial from './Contents/FpsInitial';
import FpsPayment from './Contents/FpsPayment';
import FpsRefund from './Contents/FpsRefund';
import FpsRecourse from './Contents/FpsRecourse';
import FpsUpdate from './Contents/FpsUpdate';
import DebtRecovery from './Contents/DebtRecovery';
import Mail from './Contents/Mail';
import RefundProcedure from './Contents/RefundProcedure';
import AntaiAbandonment from './Contents/AntaiAbandonment';
import VehicleConsistency from './Contents/VehicleConsistency';

const { _tg } = window.loadTranslations(__filename);

const FPS_VERSION_TITLE_FROM_TYPE = {
  INITIAL: 'Constatation',
  TEST: 'Constatation de test',
  PRE_FPS: 'PRE-FPS',
  CORRECTION: 'Modification',
  CANCELLED: 'Annulation',
  RAPO: 'FPS Rectificatif',
  CCSP: 'FPS Rectificatif',
  CANCEL_ANTAI_CASE: 'Annulation procédure ANTAI',
};

const ITEM_TITLE_COLOR_FROM_TYPE = {
  INITIAL: BKG_CYAN,
  TEST: BKG_CYAN,
  PRE_FPS: BKG_CYAN,
  CORRECTION: BKG_LIGHT_BLUE,
  CANCELLED: 'black',
  RAPO: BKG_LIGHT_BLUE,
  CCSP: BKG_LIGHT_BLUE,
  CANCEL_ANTAI_CASE: BKG_BLUE,
};

const FPS_CONTENT_FROM_TYPE = {
  INITIAL: FpsInitial,
  TEST: FpsInitial,
  PRE_FPS: FpsInitial,
  CORRECTION: FpsUpdate,
  CANCELLED: FpsUpdate,
  RAPO: FpsUpdate,
  CCSP: FpsUpdate,
  CANCEL_ANTAI_CASE: FpsUpdate,
  CANCEL_PROPOSAL: FpsCancelProposal,
};

export type TimelineItem = {
  date: Moment;
  receptionDatetime?: Moment | undefined;
  title: {
    color?: string;
    left: string | React.FunctionComponent<any> | React.ClassicComponent<any>;
    right?: string | React.FunctionComponent<any> | React.ClassicComponent<any>;
  };
  contentElement: any;
  contentProps?: { [propKey: string]: any };
  fpsComments?: Array<FpsCommentDTO>;
  comment?: string; // TODO still used for payments, maybe remove
};

export enum FpsPaymentKeyType {
  FPS = 14, // Slice fpsId at pos 14 (cityId)
  FPSM = 9, // Slice fpsmId at pos 9
}

const sortChronologically = (dateA: Moment, dateB: Moment): number => {
  if (dateA.isBefore(dateB)) {
    return -1;
  }
  if (dateA.isAfter(dateB)) {
    return 1;
  }
  return 0;
};

export const findInitialVersion = (
  fps: FpsCaseDTO
): FpsVersionDTO | undefined => {
  return fps.versions.find(v => v.type === 'INITIAL' || v.type === 'PRE_FPS');
};

/**
 * Get last fps version
 */
export const getLastVersion = (
  fpsVersions: Array<FpsVersionDTO>,
  dateMax?: string
): FpsVersionDTO => {
  return fpsVersions.reduce((recent, version) => {
    if (moment(version.dateModified).isAfter(recent.dateModified)) {
      return dateMax && moment(version.dateModified).isAfter(dateMax)
        ? recent
        : version;
    }
    return recent;
  }, fpsVersions[0]);
};

/**
 * Get latest fps version which has an initialReducedDatetime
 */
export const getLastVersionWithInitialReduced = (
  fpsVersions: Array<FpsVersionDTO>
): FpsVersionDTO | null => {
  let lastVersionWithInitialReduced = null;
  fpsVersions.forEach(v => {
    if (v.initialReducedDatetime) {
      lastVersionWithInitialReduced = v;
    }
  });
  return lastVersionWithInitialReduced;
};

/**
 * Get version from id
 */
export const getVersionFromId = (
  fpsVersions: Array<FpsVersionDTO>,
  id?: string
): FpsVersionDTO | undefined => {
  return fpsVersions.find(version => version.id === id);
};

/**
 * Compute the initial paid price for an FpsVersionDTO
 */
export const getInitialPaidPrice = (fpsVersion: FpsVersionDTO): number => {
  let price = 0;

  if (fpsVersion.significantRights) {
    fpsVersion.significantRights.forEach(t => {
      price += t.rightPrice;
    });
  }

  return price;
};

/**
 * Compute surcharge amount when fps is in recovery
 * total surcharge = sum of last surcharge of each debtRecovery
 */
export const computeSurcharge = (antaiFps: AntaiFpsDTO): number | undefined => {
  if (!antaiFps || antaiFps.fps.debtRecoveryList.length === 0) {
    return undefined;
  }

  return antaiFps.fps.debtRecoveryList.reduce((total, current) => {
    const last = _findLast(current.titles);
    if (!last) {
      return total;
    }
    return total + last.surcharge;
  }, 0);
};

/**
 * Compute amount to pay at a given time
 */
export const computeAmountToPay = (
  fpsCase: FpsCaseDTO,
  dateMax?: string
): number => {
  const computeAmountPaid = (fps: FpsCaseDTO, date?: string) => {
    /**
     * We go through each payment and add it to the total
     * If a date is given and the payment date is later, we skip it by only returning the total instead of
     * adding the paiment.
     */
    const versionAmountPaid = fps.versions.reduce(
      (amountPaid, version) =>
        amountPaid +
        version.payments.reduce((paymentPaid, payment) => {
          return date && moment(payment.paymentDatetime).isAfter(date)
            ? paymentPaid
            : paymentPaid + payment.paymentAmount;
        }, 0),
      0
    );

    const antaiAmountPaid = fps.antaiFps
      ? fps.antaiFps.fps.cityMessages.reduce(
          (amountPaid, apa) =>
            amountPaid +
            apa.payments.reduce((apaPaid, payment) => {
              return date && moment(payment.datetime).isAfter(date)
                ? apaPaid
                : apaPaid + payment.amount;
            }, 0),
          0
        )
      : 0;

    const antaiRecoveryPaid = fps.antaiFps
      ? fps.antaiFps.fps.debtRecoveryList.reduce(
          (amountPaid, debtRecovery) =>
            amountPaid +
            debtRecovery.payments.reduce((recoveryPaid, payment) => {
              return date && moment(payment.datetime).isAfter(date)
                ? recoveryPaid
                : recoveryPaid + payment.amount;
            }, 0),
          0
        )
      : 0;

    return versionAmountPaid + antaiAmountPaid + antaiRecoveryPaid;
  };

  const lastVersion = getLastVersion(fpsCase.versions, dateMax);

  /**
   * If there is a reduced price, we consider it only if the reduced date time is not passed,
   * or if the fps has been paid before the date.
   */
  if (lastVersion.reducedDatetime) {
    const paidInReduced =
      fpsCase.paymentStatus === 'PAID_REDUCED' ||
      fpsCase.paymentStatus === 'OVERPAID_REDUCED';
    const isReducedPhase =
      (dateMax &&
        moment(dateMax).isSameOrBefore(lastVersion.reducedDatetime)) ||
      moment().isSameOrBefore(lastVersion.reducedDatetime);

    if (!paidInReduced && !isReducedPhase) {
      return lastVersion.finePrice - computeAmountPaid(fpsCase, dateMax);
    }

    if (lastVersion.reducedFinePrice != null) {
      return lastVersion.reducedFinePrice - computeAmountPaid(fpsCase, dateMax);
    }
  }

  return lastVersion.finePrice - computeAmountPaid(fpsCase, dateMax);
};

type PaymentOriginLabelKey = PaymentChannel | string;
export const PAYMENT_ORIGIN_LABEL: {
  [key in PaymentOriginLabelKey]: string;
} = {
  DGFIP: 'DGFIP',
  PARKMETER: 'Horodateur',
  MOBILE: 'Application mobile',
  DESK: 'Guichet',
  INTERNET: 'Internet',
  MAIL: 'Courrier',
  VP: 'Serveur vocal',
  COMMUNITY: 'Collectivité',
};

export const FPS_ANTAI_ABANDONED_REASON: { [key in AntaiFpsReason]: string } = {
  FOREIGN_PLATE: 'Plaque étrangère',
  DIPLOMATIC_PLATE: 'Plaque diplomatique',
  MILITARY_PLATE: 'Plaque militaire',
  ADMINISTRATION_PLATE: 'Plaque administrative',
  SUSPECT_VEHICLE: 'Véhicule suspect',
  IDENTIFICATION_FAILURE: "Échec d'identification",
  CANCELLED: 'Annulé',
  INCONSISTENT_BRAND: 'Marque du véhicule incorrecte',
  USURPATION: 'Usurpation',
  IMPOSSIBLE_RECOVERY: 'Recouvrement impossible',
};

const FPS_CLAIM_STATUS_LABEL: { [key in ClaimStatus]: string } = {
  NONE: 'Aucun',
  FILLED: 'Déposé',
  REJECTED: 'Rejeté',
  ACCEPTED: 'Accepté',
  TRANSFERRED: 'Transféré',
  DISMISSED: 'Non-lieu statué',
};

export const canAddPayment = (
  fps: FpsCaseDTO,
  amountToPay: number,
  securityPeriodForRecordingPayment: number
): boolean => {
  if (amountToPay <= 0) {
    return false;
  }

  if (fps.notificationAuthority === 'ANTAI') {
    const lastVersion = getLastVersion(fps.versions);
    if (!lastVersion.reducedDatetime) {
      return false;
    }
    const reducedDatetimePlusSecurityPeriodForPayment = moment(
      lastVersion.reducedDatetime
    ).add(securityPeriodForRecordingPayment, 'minutes');

    if (moment().isAfter(reducedDatetimePlusSecurityPeriodForPayment)) {
      return false;
    }
  } else if (fps.notificationAuthority === 'LOCAL') {
    const initialVersion = findInitialVersion(fps);

    if (
      !initialVersion ||
      (initialVersion.authTransferDatetime &&
        moment().isAfter(moment(initialVersion.authTransferDatetime)))
    ) {
      return false;
    }
  }
  return true;
};

/**
 * Check if there is a claim with an active status
 * Same code as FO
 */
export const ACTIVE_CLAIM_STATUS = [
  'FILLED',
  'ACCEPTED',
  'REJECTED',
  'TRANSFERRED',
];
export const hasActiveClaim = (fps: FpsCaseDTO): boolean => {
  const lastSignificantClaims = fps.claims
    .filter(c => c.claimType === 'PRELIMINARY' && c.claimDateModified !== null)
    .sort(
      (a, b) =>
        moment(b.claimDateModified).valueOf() -
        moment(a.claimDateModified).valueOf()
    );
  return (
    lastSignificantClaims[0] &&
    ACTIVE_CLAIM_STATUS.includes(lastSignificantClaims[0].claimStatus)
  );
};

export const canAddRecourse = (
  fps: FpsCaseDTO,
  currentPrice: number
): boolean => !(currentPrice <= 0 || hasActiveClaim(fps));

export const isAntaiAbandonned = (antaiFps?: AntaiFpsDTO): boolean => {
  if (!antaiFps) return false;

  return antaiFps.fps.fpsStatus.status === 'ABANDONED';
};

export const computeFpsRecourseBlockTitle = (
  claim: Claim,
  isCcsp: boolean,
  moduleRapoEnabled: boolean
): string => {
  const rapoTitle = moduleRapoEnabled
    ? `RAPO n°${claim.rapoNumber?.toString()}`
    : 'RAPO';
  const prefix = isCcsp ? 'TSP' : rapoTitle;
  if (claim.claimStatus === 'NONE' && claim.claimDateModified) {
    return `${prefix} non traitable`;
  }
  if (claim.claimStatus === 'FILLED') {
    return prefix;
  }
  // Si le RAPO est un RAPO pour cession de véhicule
  // Applicable seulement sur les RAPO
  if (claim.claimStatus === 'TRANSFERRED') {
    return 'RAPO traité : FPS transféré';
  }
  return `${prefix} ${FPS_CLAIM_STATUS_LABEL[
    claim.claimStatus
  ].toLocaleLowerCase()}`;
};

export const computeFpsCancelProposalBlockTitle = (
  cancelProposal: CancelProposalDTO
): string => {
  switch (cancelProposal.status) {
    case 'PENDING': {
      return "Demande d'annulation";
    }
    case 'REFUSED': {
      return "Demande d'annulation refusée";
    }
    case 'ACCEPTED': {
      return "Demande d'annulation acceptée";
    }
    default:
      return '';
  }
};

/**
 * Get last recourse status
 */
export const getLastClaimStatus = (claims: Array<Claim>): string => {
  const lastClaim = claims[claims.length - 1];
  if (lastClaim.claimStatus === 'NONE' && lastClaim.claimDateModified) {
    return 'Non traitable';
  }
  return FPS_CLAIM_STATUS_LABEL[lastClaim.claimStatus];
};

/**
 * Format a java Instant to a display format
 */

export const formatInstant = (date: string): string =>
  moment(date).format('DD/MM/YYYY - HH:ss');

export const dateTimestamp = (date: Date): number => moment(date).unix();

export type PaymentDTO = {
  datetime: string;
  receptionDatetime?: string;
  amount: number;
  amountToPay: number;
  origin: string;
  mode: string;
  id?: string;
  agent?: ControlAgent;
};

const buildPayment = (fps: FpsCaseDTO, payment: Payment): PaymentDTO => {
  const paymentDTO: PaymentDTO = {
    datetime: payment.paymentDatetime,
    receptionDatetime: payment.receptionDatetime,
    amount: payment.paymentAmount,
    amountToPay: computeAmountToPay(fps, payment.paymentDatetime),
    origin: PAYMENT_ORIGIN_LABEL[payment.paymentChannel],
    mode: translatePaymentMode()[payment.paymentMode],
    agent: payment.agent || undefined,
  };

  return paymentDTO;
};

const buildAntaiPayment = (
  fps: FpsCaseDTO,
  payment: AntaiPayment | DebtRecoveryTransactionDTO,
  shouldComputeAmountToPay = true
): PaymentDTO => {
  const paymentDTO: PaymentDTO = {
    datetime: payment.datetime,
    amount: payment.amount,
    mode: 'ANTAI',
    origin: 'ANTAI',
    id: payment.id,
    receptionDatetime: payment.receptionDatetime || undefined,
    amountToPay: shouldComputeAmountToPay
      ? computeAmountToPay(fps, payment.datetime)
      : 0,
  };

  return paymentDTO;
};

/**
 * Build & sort a list of items for the timeline
 */
export const buildTimelineItems = (
  fps: FpsCaseDTO,
  cvfmRapoPartner: boolean,
  noticeDownloadEnabled: boolean,
  apaRapoDownloadEnabled: boolean,
  fpsConsistencyEnabled: boolean,
  reloadFps: Function,
  zoning: ZoningDTO,
  rapoModuleActivated: boolean
): Array<TimelineItem> => {
  const items: Array<TimelineItem> = [];

  if (!fps) return items;

  let lastRapoIndex = 0;
  let lastCcspIndex = 0;

  // find latest reduced end
  let lastReducedDatetime: string | undefined;
  fps.versions.forEach(v => {
    if (v.reducedDatetime) {
      lastReducedDatetime = v.reducedDatetime;
    }
  });

  // retrieve the id of the last version with payments handled by the city
  const lastVersionWithCityPayment = _findLast(
    fps.versions,
    (version: FpsVersionDTO) => {
      return (
        !!version.payments.length &&
        version.payments.some(p => p.paymentChannel !== 'DGFIP')
      );
    }
  );

  const lastCityPayment = _findLast(
    lastVersionWithCityPayment
      ? lastVersionWithCityPayment.payments
          .filter(
            payment =>
              payment.clientAppId && payment.clientAppId.startsWith('cvfm')
          )
          .sort((a, b) =>
            sortChronologically(
              moment(a.paymentDatetime),
              moment(b.paymentDatetime)
            )
          )
      : [],
    (payment: Payment) => {
      return payment.paymentChannel !== 'DGFIP';
    }
  );

  for (let i = fps.claims.length - 1; i >= 0; i -= 1) {
    if (fps.claims[i].claimType === 'PRELIMINARY') {
      lastRapoIndex = i;
      break;
    }
  }

  for (let i = fps.claims.length - 1; i >= 0; i -= 1) {
    if (fps.claims[i].claimType === 'REGULATORY') {
      lastCcspIndex = i;
      break;
    }
  }

  const zone = zoning.zones.find(z => z.id === fps.zoneId)?.name;

  // Build items for every fps version
  fps.versions.forEach(version => {
    // Main event creation for the version

    // Convert type if new version is cancelled
    const type = version.finePrice === 0 ? 'CANCELLED' : version.type;

    // Build version items
    items.push({
      date: moment(version.dateModified),
      title: {
        color: ITEM_TITLE_COLOR_FROM_TYPE[type],
        left: FPS_VERSION_TITLE_FROM_TYPE[type],
      },
      contentElement: FPS_CONTENT_FROM_TYPE[type],
      contentProps: {
        fps,
        version,
        apaRapoDownloadEnabled,
        zone,
      },
      fpsComments: version.comments,
    });

    // Build city payment items
    version.payments.forEach(payment => {
      // isLastPayment est vrai si c'est le dernier paiement de la dernière version "ville"
      const paymentItem: TimelineItem = {
        date: moment(payment.paymentDatetime),
        receptionDatetime: moment(payment.receptionDatetime),
        title:
          payment.paymentAmount < 0
            ? {
                color: BKG_CYAN,
                left:
                  payment.paymentChannel === 'DGFIP'
                    ? 'Remboursement paiement ANTAI'
                    : 'Remboursement',
              }
            : {
                color: BKG_PINK,
                left: 'Paiement',
              },
        contentElement: payment.paymentAmount < 0 ? FpsRefund : FpsPayment,
        contentProps: {
          noticeDownloadEnabled,
          payment: buildPayment(fps, payment),
          fpsId: fps.versions[0].id,
          isLastPayment:
            lastCityPayment && payment.paymentId === lastCityPayment.paymentId,
          isReducedPayment:
            lastReducedDatetime &&
            payment.paymentDatetime < lastReducedDatetime,
        },
        comment: payment.comment || undefined,
      };
      items.push(paymentItem);
    });
  });

  if (fps.antaiFps) {
    fps.antaiFps.fps.cityMessages.forEach(apa => {
      // Build antai payment items
      apa.payments.forEach(payment => {
        items.push({
          date: moment(payment.datetime),
          title: {
            color: BKG_PINK,
            left: 'Paiement',
          },
          contentElement: FpsPayment,
          contentProps: {
            noticeDownloadEnabled,
            payment: buildAntaiPayment(fps, payment),
            fpsId: fps.versions[0].id,
          },
        });
      });

      // Build antai mail items
      apa.mails.forEach(mail => {
        items.push({
          date: moment(mail.sentDatetime),
          title: {
            color: BKG_CYAN,
            left: 'Courrier',
          },
          contentElement: Mail,
          contentProps: {
            mail,
          },
        });
      });
    });

    fps.antaiFps.fps.debtRecoveryList.forEach(debtRecovery => {
      // Build debt recovery payments
      debtRecovery.payments.forEach(payment => {
        items.push({
          date: moment(payment.datetime),
          title: {
            color: TXT_RED,
            left: 'Paiement en phase exécutoire',
          },
          contentElement: FpsPayment,
          contentProps: {
            noticeDownloadEnabled,
            payment: buildAntaiPayment(fps, payment, false),
            fpsId: fps.versions[0].id,
            fpsmId: debtRecovery.fpsmId,
          },
        });
      });

      // Build debt recovery items
      debtRecovery.titles.forEach(title => {
        items.push({
          date: moment(title.sentDatetime),
          title: {
            color: BKG_DARKER_BLUE,
            left:
              title.type === 'EXECUTOIRE' ? 'FPS Majoré' : "Titre d'annulation",
          },
          contentElement: DebtRecovery,
          contentProps: {
            fpsmId: debtRecovery.fpsmId,
            title,
          },
        });
      });
    });
  }

  const statuses = ['CONTACT_MISSING', 'RIB_MISSING'];
  if (
    fps.fpsRefund &&
    !fps.fpsRefund.dgfipRefund &&
    !statuses.includes(fps.fpsRefund.fpsRefundStatus)
  ) {
    items.push({
      date: moment(fps.fpsRefund.sentMailDatetime),
      title: {
        color: BKG_CYAN,
        left: 'Procédure de remboursement',
      },
      contentElement: RefundProcedure,
      contentProps: {
        refund: fps.fpsRefund,
      },
    });
  }

  // build item for fps consistency
  if (fpsConsistencyEnabled && fps.vehicleConsistency.decisionAgent !== null) {
    items.push({
      date: moment(fps.vehicleConsistency.decisionDatetime),
      title: {
        color: BKG_DARKER_BLUE,
        left: _tg('tefps.fps.detail.content.vehicleConsistency.title'),
      },
      contentElement: VehicleConsistency,
      contentProps: {
        initialVehicleBrand: fps.vehicle.brand,
        sivVehicleBrand: fps.sivVehicleBrand,
        vehicleConsistency: fps.vehicleConsistency,
        reloadFps,
      },
    });
  }

  // Build item for recourse
  fps.claims.forEach((claim, index) => {
    // Creation recourse
    if (claim.claimDateModified !== null) {
      const isCcsp = claim.claimType === 'REGULATORY';
      const lastRapo = lastRapoIndex === index;
      const lastCcsp = lastCcspIndex === index && isCcsp;
      items.push({
        date: moment(claim.claimDateModified),
        title: {
          color: BKG_DARK_BLUE,
          left: computeFpsRecourseBlockTitle(
            claim,
            isCcsp,
            rapoModuleActivated
          ),
        },
        contentElement: FpsRecourse,
        contentProps: {
          claim,
          fpsId: fps.id.split('/')[1],
          isLastCcsp: lastCcsp,
          isLastRapo: lastRapo,
          cvfmRapoPartner,
        },
      });
    }
  });

  if (fps.cancelProposals) {
    fps.cancelProposals.forEach(cancelProposal => {
      items.push({
        date: moment(cancelProposal.datetime),
        title: {
          color: BKG_DARKER_BLUE,
          left: computeFpsCancelProposalBlockTitle(cancelProposal),
        },
        contentElement: FpsCancelProposal,
        contentProps: {
          cancelProposal,
          fpsId: fps.versions[fps.versions.length - 1].id,
          reloadFps,
          isLast:
            cancelProposal.status === 'PENDING' &&
            fps.cancelProposals.indexOf(cancelProposal) ===
              fps.cancelProposals.length - 1,
        },
      });
    });
  }
  // Antai abandonnment
  if (isAntaiAbandonned(fps.antaiFps)) {
    items.push({
      date: moment(
        fps.antaiFps.abandonmentDate ??
          getLastVersion(fps.versions).lastSyncDate
      ),
      title: {
        color: TXT_RED,
        left: 'Abandon ANTAI',
      },
      contentElement: AntaiAbandonment,
      contentProps: {
        date: fps.antaiFps.abandonmentDate,
        reason: FPS_ANTAI_ABANDONED_REASON[fps.antaiFps.fps.fpsStatus.reason],
      },
    });
  }
  // Sort items by date
  items.sort((a, b) => sortChronologically(a.date, b.date));
  return items;
};

export const formatDateTime = (date: string): string =>
  moment(date).format('DD/MM/YYYY - HH:mm');

export const computePaymentKey = (
  fpsId: string,
  slicePos: FpsPaymentKeyType
): string => {
  const leftPart = Number(fpsId.slice(0, slicePos));
  const rightPart = Number(fpsId.slice(slicePos));
  const rightPartLen = fpsId.slice(slicePos).length;
  // Payment key = apaId % 97
  // Since an apaId is too big to be computed straight away, we use this formula
  // to compute the key in a decomposed way:
  // (((leftpart % x) * (10^(length of right part) % x)) + (rightpart % x)) % x
  // eslint-disable-next-line
  const key =
    ((leftPart % 97) * (10 ** rightPartLen % 97) + (rightPart % 97)) % 97;

  return key.toString().padStart(2, '0');
};

export const isLastVersionAndSynced = (
  fps: FpsCaseDTO,
  version: FpsVersionDTO
): boolean => {
  const lastVersion = fps.versions[fps.versions.length - 1];
  return (
    lastVersion.id === version.id &&
    !!version.authTransferDatetime &&
    version.type !== 'CANCEL_ANTAI_CASE'
  );
};
