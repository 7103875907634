import { LOGOUT } from 'api/duck';

import { SubscriptionPlansSearchCriteria } from './types';

const SET_FILTERS = 'SubscriptionPlans/List/SET_FILTERS';

type State = {
  filters: SubscriptionPlansSearchCriteria;
};

export const initialFilters = (): SubscriptionPlansSearchCriteria => {
  return {
    name: null,
    id: null,
    memberCount: null,
    planPrice: null,
  };
};

const initialState = (): State => ({
  filters: initialFilters(),
});

export default function subscriptionsListReducer(
  state: State = initialState(),
  action: any
): State {
  switch (action.type) {
    case SET_FILTERS:
      return {
        ...state,
        filters: action.filters,
      };
    case LOGOUT:
      return initialState();
    default:
      return state;
  }
}

export function getSubscriptionPlansSearchState(state: any): State {
  return state.subscriptionPlansSearchCriteria;
}

export function setFilters(filters: SubscriptionPlansSearchCriteria): any {
  return {
    type: SET_FILTERS,
    filters,
  };
}
