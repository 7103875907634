import React, { Component, CSSProperties } from 'react';
import Dialog from 'material-ui/Dialog';
import Snackbar from 'material-ui/Snackbar';
import TextField from 'material-ui/TextField';
import Toggle from 'material-ui/Toggle';

import BoButton from 'facade/BoButton';
import { TXT_BLACK } from 'theme';
import { CcspCreationDTO } from 'api/ccsp/types';
import { Throttle } from '@cvfm-front/commons-utils';

import { validateCcspNumber, validateDeadline, validateFpsId } from './helper';
import CcspDatetimePicker from './CcspDatetimePicker';

const { _t, _tg } = window.loadTranslations(__filename);

const STYLE_CONTENT_WRAPPER: CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  alignContent: 'center',
  flexDirection: 'column',
  flexWrap: 'wrap',
  margin: 30,
};

const STYLE_FIELD: CSSProperties = { marginBottom: 20 };

const initDeadline = () => {
  const now = new Date();
  now.setMonth(now.getMonth() + 1);
  return now;
};

type AddCcspState = {
  open: boolean;
  confirmationMode: boolean;
  errors: {
    [key: string]: string | null | undefined;
  };
  ccspNumber: string | undefined;
  fpsId: string | undefined;
  transmissionDate: Date;
  openingDate: Date;
  deadline: Date;
  recovery: boolean;
  disabledAddCcsp: boolean;
};

type AddCcspProps = {
  createNewCcsp: (
    ccspNumber: string,
    creationDto: CcspCreationDTO
  ) => Promise<void>;
  clearError: Function;
  error: string | null | undefined;
};

class AddCcsp extends Component<AddCcspProps, AddCcspState> {
  constructor(props: AddCcspProps) {
    super(props);
    this.state = {
      open: false,
      confirmationMode: false,
      errors: {},
      ccspNumber: undefined,
      fpsId: undefined,
      transmissionDate: new Date(),
      openingDate: new Date(),
      deadline: initDeadline(),
      recovery: false,
      disabledAddCcsp: false,
    };
  }

  onChangeOpeningDate = (
    event: Event | null | undefined,
    newDate: Date
  ): void => {
    const { openingDate } = this.state;
    newDate.setHours(openingDate.getHours());
    newDate.setMinutes(openingDate.getMinutes());
    this.setState({ openingDate: newDate });
  };

  onChangeOpeningTime = (
    event: Event | null | undefined,
    newTime: Date
  ): void => {
    const { openingDate } = this.state;
    newTime.setFullYear(openingDate.getFullYear());
    newTime.setMonth(openingDate.getMonth());
    newTime.setDate(openingDate.getDate());
    this.setState({ openingDate: newTime });
  };

  onChangeDeadline = (event: Event | null | undefined, newDate: Date): void => {
    const { deadline } = this.state;
    newDate.setHours(deadline.getHours());
    newDate.setMinutes(deadline.getMinutes());
    this.setState({ deadline: newDate });
  };

  onChangeDeadlineTime = (
    event: Event | null | undefined,
    newTime: Date
  ): void => {
    const { deadline } = this.state;
    newTime.setFullYear(deadline.getFullYear());
    newTime.setMonth(deadline.getMonth());
    newTime.setDate(deadline.getDate());
    this.setState({ deadline: newTime });
  };

  onChangetransmissionDate = (
    event: Event | null | undefined,
    newDate: Date
  ): void => {
    const { transmissionDate } = this.state;
    const now = new Date();
    newDate.setHours(transmissionDate?.getHours() ?? now.getHours());
    newDate.setMinutes(transmissionDate?.getMinutes() ?? now.getMinutes());
    this.setState({ transmissionDate: newDate });
  };

  onChangeReceiptTime = (
    event: Event | null | undefined,
    newTime: Date
  ): void => {
    const { transmissionDate } = this.state;
    const now = new Date();
    newTime.setFullYear(transmissionDate?.getFullYear() ?? now.getFullYear());
    newTime.setMonth(transmissionDate?.getMonth() ?? now.getMonth());
    newTime.setDate(transmissionDate?.getDate() ?? now.getDate());
    this.setState({ transmissionDate: newTime });
  };

  handleConfirmationMode = (): void => {
    const { confirmationMode } = this.state;
    this.setState({ confirmationMode: !confirmationMode });
  };

  addNewCcspRecourse = (): void => {
    const {
      ccspNumber,
      openingDate,
      deadline,
      fpsId,
      recovery,
      transmissionDate,
      disabledAddCcsp,
    } = this.state;

    const errors: {
      [key: string]: string | null | undefined;
    } = {};
    errors.ccspNumber = validateCcspNumber(ccspNumber);
    errors.fpsId = validateFpsId(fpsId, recovery);
    errors.deadline = validateDeadline(deadline, openingDate);

    if (
      !disabledAddCcsp &&
      Object.values(errors).every(key => key === undefined)
    ) {
      const { createNewCcsp } = this.props;
      const creationDto: CcspCreationDTO & { recovery: boolean } = {
        recovery,
        transmissionDate: transmissionDate.toISOString(),
        openingDate: openingDate.toISOString(),
        deadline: deadline.toISOString(),
      };
      creationDto[recovery ? 'fpsmId' : 'fpsId'] = fpsId;
      this.setState({ errors: {}, disabledAddCcsp: true });
      void createNewCcsp(ccspNumber as string, creationDto);
      setTimeout(() => {
        this.setState({ errors: {}, disabledAddCcsp: false });
      }, 2000);
    } else {
      this.setState({ confirmationMode: false });
      this.setState({ errors });
    }
  };

  openDialog = (): void =>
    this.setState({
      open: true,
      openingDate: new Date(),
      transmissionDate: new Date(),
      deadline: initDeadline(),
    });
  closeDialog = (): void => this.setState({ open: false });
  closeErrorSnackBar = (): void => {
    const { clearError } = this.props;
    clearError();
  };

  changeNumber = (event: React.MouseEvent<any>, value: string): void =>
    this.setState({ ccspNumber: value });

  changeFpsId = (event: React.MouseEvent<any>, value: string): void =>
    this.setState({ fpsId: value });

  changeRecovery = (_: any, recovery: boolean): void =>
    this.setState({ recovery });

  render(): React.ReactNode {
    const {
      errors,
      ccspNumber,
      deadline,
      fpsId,
      transmissionDate,
      openingDate,
      recovery,
      open,
      confirmationMode,
      disabledAddCcsp,
    } = this.state;
    const { error } = this.props;

    const actions = [
      <BoButton
        key="annuler"
        style={{ marginRight: 10 }}
        label={_tg('action.cancel')}
        primary
        onClick={
          confirmationMode ? this.handleConfirmationMode : this.closeDialog
        }
      />,
      <BoButton
        key="ajouter"
        style={{ marginRight: 10 }}
        label={confirmationMode ? _tg('action.confirm') : _tg('action.add')}
        primary={!confirmationMode}
        secondary={confirmationMode}
        disabled={disabledAddCcsp}
        onClick={Throttle(
          confirmationMode
            ? this.addNewCcspRecourse
            : this.handleConfirmationMode,
          1000
        )}
      />,
    ];

    return (
      <div>
        <BoButton
          label={_t('action.addCcsp')}
          onClick={this.openDialog}
          primary
        />
        <Dialog
          title={
            confirmationMode
              ? _t('element.confirmCcsp')
              : _t('element.saveCcsp')
          }
          actions={actions}
          modal
          autoScrollBodyContent
          open={open}
        >
          <div>
            <div style={STYLE_CONTENT_WRAPPER}>
              <div style={STYLE_FIELD}>
                <Toggle
                  label={_t('element.fpsIsSurcharged')}
                  onToggle={this.changeRecovery}
                  toggled={recovery}
                  labelStyle={{ color: TXT_BLACK }}
                  disabled={confirmationMode}
                />
              </div>
            </div>
            <div style={STYLE_CONTENT_WRAPPER}>
              <div style={STYLE_FIELD}>
                <div>{_t('field.ccspNumber')}</div>
                <TextField
                  title={_t('field.digits', { nb: '8' })}
                  name="n° de la saisine"
                  value={ccspNumber}
                  onChange={this.changeNumber}
                  errorText={errors.ccspNumber}
                  disabled={confirmationMode}
                />
              </div>
              <div style={STYLE_FIELD}>
                <div>
                  {recovery
                    ? _t('field.surchargedFpsNumber')
                    : _t('field.fpsNumber')}
                </div>
                <TextField
                  title={
                    recovery
                      ? _t('field.digits', { nb: '18' })
                      : _t('field.digits', { nb: '26' })
                  }
                  name={recovery ? 'n° du FPS majoré' : 'n° du FPS'}
                  value={fpsId}
                  onChange={this.changeFpsId}
                  errorText={errors.fpsId}
                  disabled={confirmationMode}
                />
              </div>
            </div>
            <div style={STYLE_CONTENT_WRAPPER}>
              <CcspDatetimePicker
                name="opening"
                fieldName={_t('field.openingDate')}
                date={openingDate}
                disabled={confirmationMode}
                onChangeDate={this.onChangeOpeningDate}
                onChangeTime={this.onChangeOpeningTime}
              />
              <CcspDatetimePicker
                name="receipt"
                fieldName={_t('field.transmissionDate')}
                date={transmissionDate}
                disabled={confirmationMode}
                onChangeDate={this.onChangetransmissionDate}
                onChangeTime={this.onChangeReceiptTime}
              />
              <CcspDatetimePicker
                name="deadLine"
                fieldName={_t('field.deadline')}
                date={deadline}
                errorText={errors.deadline}
                disabled={confirmationMode}
                onChangeDate={this.onChangeDeadline}
                onChangeTime={this.onChangeDeadlineTime}
              />
            </div>
          </div>
        </Dialog>
        <Snackbar
          open={!!error}
          message={error || ''}
          autoHideDuration={4000}
          onRequestClose={this.closeErrorSnackBar}
        />
      </div>
    );
  }
}

export default AddCcsp;
