import { CircularProgress, Dialog } from 'material-ui';
import React, { useEffect, useState } from 'react';

import BoButton from 'facade/BoButton';
import { ProductPrivateDTO } from '@cvfm-front/tefps-types';
import Date from 'commons/Date';
import ErrorBlock from 'commons/ErrorBlock';
import FlexCenter from 'commons/FlexCenter';
import { EligibilityRightPrivateDTO } from 'tefps/Eligibility/types';
import { fetchEligibility } from 'api/eligibility';
import FakeInputBlock from 'commons/FakeInputBlock';
import { renderPlates } from 'commons/Utils/plateUtils';

const { _tg } = window.loadTranslations(__filename);

type Props = {
  eligibilityId: string | undefined;
  products: Array<ProductPrivateDTO>;
  setEligibilityId: (egibilityId: string) => void;
};

const EligibilityDetail = ({
  eligibilityId,
  products,
  setEligibilityId,
}: Props): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null | undefined>(null);
  const [
    eligibility,
    setEligibility,
  ] = useState<EligibilityRightPrivateDTO | null>(null);
  const [open, setOpen] = useState<boolean>(false);

  const closeModal = () => {
    setOpen(false);
    setEligibilityId('');
  };
  const actions = [
    <BoButton label={_tg('action.close')} onClick={closeModal} />,
  ];

  const getEligibility = async (eligibilityId: string) => {
    setLoading(true);
    setError(null);
    try {
      const eligibilityResult = await fetchEligibility(eligibilityId);
      setEligibility(eligibilityResult);
      setOpen(true);
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (eligibilityId) {
      void getEligibility(eligibilityId);
      setOpen(true);
    } else {
      setEligibility(null);
      setOpen(false);
    }
  }, [eligibilityId]);

  const buildEligibilityContent = (eligibility: EligibilityRightPrivateDTO) => {
    const product = products.find(p => p.productId === eligibility.productId);
    return (
      <>
        <FakeInputBlock
          title={_tg('field.rights.id')}
          value={eligibility.eligibilityId}
        />
        <FakeInputBlock
          title={_tg('field.rights.name')}
          value={product !== undefined ? product.name : eligibility.productId}
        />
        <FakeInputBlock
          title={_tg('tefps.filters.misc.plates.title')}
          value={renderPlates(eligibility.hashedPlates)}
        />
        <FakeInputBlock
          title={_tg('field.address.validityZones')}
          value={eligibility.zoneIdsOfValidity?.join(', ')}
        />
        <FakeInputBlock
          title={_tg('tefps.filters.rapo.receptionDate')}
          value={<Date datetime={eligibility.dateOfReceipt} withSeconds />}
        />
        <FakeInputBlock
          title={_tg('field.date.creation')}
          value={<Date datetime={eligibility.creationDatetime} withSeconds />}
        />
        <FakeInputBlock
          title={_tg('field.date.dateOfValidityStart')}
          value={<Date datetime={eligibility.startOfValidity} withSeconds />}
        />
        <FakeInputBlock
          title={_tg('field.date.dateOfValidityEnd')}
          value={<Date datetime={eligibility.endOfValidity} withSeconds />}
        />
        {eligibility.cancellationDatetime && (
          <FakeInputBlock
            inputStyle={{ color: 'red' }}
            title={_tg('field.date.cancelDate')}
            value={
              <Date datetime={eligibility.cancellationDatetime} withSeconds />
            }
          />
        )}
      </>
    );
  };

  return (
    <Dialog
      title={`${_tg('field.eligibility.eligibility')}: ${eligibilityId}`}
      open={open}
      actions={actions}
      autoScrollBodyContent
      onRequestClose={closeModal}
    >
      {error && (
        <FlexCenter>
          <ErrorBlock
            message={_tg('feedback.error.fetchEligibility')}
            error={error}
          />
        </FlexCenter>
      )}
      {loading && (
        <FlexCenter>
          <CircularProgress />
        </FlexCenter>
      )}
      {eligibility && buildEligibilityContent(eligibility)}
    </Dialog>
  );
};

export default EligibilityDetail;
