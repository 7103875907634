import { Setter, WatchFunctionType } from '@cvfm-front/commons-types';
import { Watcher } from '@cvfm-front/commons-utils';
import {
  TaoArticleDTO,
  TaoArticleSearchRequestDTO,
} from '@cvfm-front/tefps-types';
import { apiPost } from 'api/helpers';

export interface TaoArticleSearchServiceInterfaceFactory {
  (): TaoArticleSearchServiceInterface;
}

export interface TaoArticleSearchServiceInterface {
  getArticles: () => void;
  watchIsLoading: WatchFunctionType<boolean>;
  watchHasLoadingError: WatchFunctionType<Error | undefined>;
  watchRequest: WatchFunctionType<Partial<TaoArticleSearchRequestDTO>>;
  watchArticles: WatchFunctionType<TaoArticleDTO[]>;
  setRequest: Setter<Partial<TaoArticleSearchRequestDTO>>;
  resetRequest: () => void;
  watchTotalHits: WatchFunctionType<number>;
}

const ROOT_API = '/api/proxy/tao/api/v1/{cityId}/articleConfig/search';

const TaoArticleSearchService: TaoArticleSearchServiceInterfaceFactory = () => {
  const { setValue: setIsLoading, watchValue: watchIsLoading } = Watcher(false);

  const {
    setValue: setHasLoadingError,
    watchValue: watchHasLoadingError,
  } = Watcher(undefined);

  const { watchValue: watchArticles, setValue: setArticles } = Watcher<
    TaoArticleDTO[]
  >([]);

  const {
    watchValue: watchRequest,
    setValue: setRequest,
    getValue: getRequest,
  } = Watcher<Partial<TaoArticleSearchRequestDTO>>({});

  const { watchValue: watchTotalHits, setValue: setTotalHits } = Watcher<
    number
  >(0);

  const resetRequest = () => {
    setRequest({});
  };

  const getArticles: TaoArticleSearchServiceInterface['getArticles'] = async () => {
    const request = getRequest();
    try {
      setIsLoading(true);
      setHasLoadingError(undefined);
      const response = await apiPost<TaoArticleDTO[]>(ROOT_API, request);
      setArticles(response);
      setTotalHits(response.length);
    } catch (error) {
      setHasLoadingError(error);
      setArticles([]);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    getArticles,
    watchIsLoading,
    watchHasLoadingError,
    watchRequest,
    watchArticles,
    setRequest,
    resetRequest,
    watchTotalHits,
  };
};
export default TaoArticleSearchService;
