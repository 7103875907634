import React from 'react';

import SimpleTable from 'commons/SimpleTable';
import { ControlResponse } from '@cvfm-front/tefps-types';

type ControlToCreateListProp = {
  controlResponses: ControlResponse[];
};
const { _tg } = window.loadTranslations(__filename);

const ControlToCreateList = ({ controlResponses }: ControlToCreateListProp) => {
  const TABLE_COLS = [
    {
      label: _tg(
        'tefps.Control.AddControlModal.ControlInformation.element.label.controlId'
      ),
      width: 300,
    },
    {
      label: _tg(
        'tefps.Control.AddControlModal.ControlInformation.element.label.controlStatus'
      ),
      width: 80,
    },
  ];

  return (
    <SimpleTable
      maxHeight={300}
      cols={TABLE_COLS}
      rowHeight={50}
      header
      itemsRenderer={(item: ControlResponse) => [
        item.controlId,
        item.controlStatus,
      ]}
      style={{ marginTop: 10 }}
      items={controlResponses}
    />
  );
};

export default ControlToCreateList;
