import React, { CSSProperties } from 'react';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import MenuItem from 'material-ui/MenuItem';
import SelectField from 'material-ui/SelectField';
import TextField from 'material-ui/TextField';
import { CircularProgress } from 'material-ui';
import { connect } from 'react-redux';

import { FilesV2 } from '@cvfm-front/commons-ui';
import { BKG_CYAN, BKG_LIGHT_BLUE, TXT_BLACK } from 'theme';
import {
  CcspFileDTO,
  CcspRecourseStatus,
  CcspVersionDTO,
} from 'api/ccsp/types';
import { DirectUploadDTO } from 'api/mediaupload/types';
import FpsNumber from 'commons/FpsNumber';
import { uploadFileV2 } from 'api/mediaupload';
import { InternalApiState } from 'api/duck';
import { getConfigState } from 'config/duck';
import { getDirectUploadLink } from 'api/ccsp';
import { FileErrorType } from '@cvfm-front/commons-ui/build/ui/FilesV2';

import { translateCcspVersionLimitedStatus } from '../helpers';

import './index.css';

const { _t, _tg } = window.loadTranslations(__filename);
const STYLE_TITLE: CSSProperties = {
  backgroundColor: BKG_LIGHT_BLUE,
  color: '#ffffff',
  fontWeight: 'bold',
};

const STYLE_INPUTS: CSSProperties = {
  borderColor: BKG_CYAN,
  color: BKG_CYAN,
};

const STYLE_SPINNER: CSSProperties = {
  display: 'flex',
  flexFlow: 'column nowrap',
  alignItems: 'center',
};

const STYLE_ERROR: CSSProperties = {
  marginTop: '5px',
  textAlign: 'center',
  color: 'red',
};

type VersionManagerProps = {
  ccspId: string;
  ccspNumber: string;
  fpsId: string;
  getDirectUploadLink: (mediaType: string) => Promise<DirectUploadDTO>;
  currentTotalSize: number;
  onSave: (ccspVersionDTO: CcspVersionDTO) => Promise<void>;
  closeModal: () => void;
  watermarkText: string | null;
};

type VersionManagerState = {
  version: CcspVersionDTO;
  uploading: boolean;
  saving: boolean;
  error: string;
  maxFiles: number;
  maxSize: number;
};

const SELECTABLE_STATUSES = Object.keys(
  translateCcspVersionLimitedStatus()
).map((key: CcspRecourseStatus) => (
  <MenuItem
    key={key}
    value={key}
    primaryText={translateCcspVersionLimitedStatus()[key]}
  />
));

const initializeCcspVersion = (): CcspVersionDTO => {
  return {
    /* not used for now, is set by core-ccsp */
    agent: undefined,
    modificationDate: undefined,
    newFpsPrice: undefined,
    newMajoration: undefined,

    /* only elements modified so far here */
    status: undefined,
    comment: undefined,
    files: [],
  };
};

const documentList: CcspFileDTO[] = [];

class VersionManager extends React.Component<
  VersionManagerProps,
  VersionManagerState
> {
  constructor(props: VersionManagerProps) {
    super(props);
    this.state = {
      version: initializeCcspVersion(),
      uploading: false,
      saving: false,
      error: '',
      maxFiles: 4,
      maxSize: 5,
    };
  }

  handleSave = async (): Promise<void> => {
    const { closeModal, onSave } = this.props;
    const { version } = this.state;
    this.setState({
      saving: true,
      version: { ...version, files: documentList },
      error: '',
    });
    await onSave(version);
    this.setState({ saving: true });
    closeModal();
  };

  changeStatus = (
    _e: React.ChangeEvent<HTMLInputElement>,
    _i: number,
    status: CcspRecourseStatus
  ): void => {
    const { version } = this.state;
    this.setState({
      version: {
        ...version,
        status,
      },
    });
  };

  changeComment = (
    _e: React.ChangeEvent<HTMLInputElement>,
    comment: string
  ): void => {
    const { version } = this.state;
    this.setState({
      version: {
        ...version,
        comment,
      },
    });
  };

  onChangeFile = async (files: Array<File>): Promise<void> => {
    this.setState({ error: '' });
    const { ccspId, watermarkText } = this.props;

    const { version } = this.state;
    const filesState = version.files ? version.files : [];

    await Promise.all(
      files.map(async file => {
        const directUploadDTO = await getDirectUploadLink(ccspId, file.type);

        await uploadFileV2(
          directUploadDTO.signedUploadUrl,
          file,
          watermarkText,
          undefined,
          () => {
            this.setState({ error: _tg('commons.error') });
          }
        )
          .catch(err =>
            this.setState({
              error: (err as Error)?.message || _tg('commons.error'),
            })
          )
          .then(() => {
            const document: CcspFileDTO = {
              urn: directUploadDTO.fileUrn,
              name: file.name,
              size: file.size,
              mime: file.type,
              created: new Date().toISOString(),
            };
            documentList.push(document);
            filesState.push(document);
          });
      })
    );

    this.setState({
      version: {
        ...version,
        files: filesState,
      },
    });
  };

  onDelete = (file: File): void => {
    const { version } = this.state;
    const filesState = version.files ? version.files : [];

    const newFiles = filesState.filter(f => f.name !== file.name);
    this.setState({
      version: {
        ...version,
        files: newFiles,
      },
    });
  };

  handleUploading = (uploading: boolean): void => this.setState({ uploading });

  onError = (error: FileErrorType, filenames?: Array<string>): JSX.Element => {
    const { maxFiles } = this.state;
    switch (error) {
      case 'maxFiles':
        return (
          <p className="filesV2__error_message">
            {_t('element.fileUpload.fileErrors.maxFiles', { maxFiles })}
          </p>
        );
      case 'maxSize':
        return (
          <>
            <p className="filesV2__error_message">
              {_t('element.fileUpload.fileErrors.maxSize', { maxSize: 5 })}
            </p>
            <ul className="filesV2__error__files">
              {filenames?.map(filename => (
                <li>{filename}</li>
              ))}
            </ul>
          </>
        );
      case 'mimeType':
        return (
          <>
            <p className="filesV2__error_message">
              {_t('element.fileUpload.fileErrors.mimeType')}
            </p>
            <ul className="filesV2__error__files">
              {filenames?.map(filename => (
                <li>{filename}</li>
              ))}
            </ul>
          </>
        );
      default:
        return (
          <p className="filesV2__error_message">
            {_t('element.fileUpload.fileErrors.unexpected')}
          </p>
        );
    }
  };

  render(): JSX.Element {
    const { ccspNumber, fpsId, closeModal } = this.props;
    const { version, uploading, saving, error, maxFiles, maxSize } = this.state;

    return (
      <Dialog
        actions={[
          <FlatButton
            label={_tg('action.cancel')}
            onClick={closeModal}
            disabled={uploading || saving}
          />,
          <FlatButton
            label={_tg('action.save_1')}
            primary
            onClick={this.handleSave}
            disabled={uploading || saving}
          />,
        ]}
        autoScrollBodyContent
        open
        modal
        title={
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>{_t('element.title.left', { ccspNumber })}</div>
            <div style={{ fontSize: 'smaller', fontWeight: 'normal' }}>
              {_t('element.title.right')}
              <FpsNumber fpsId={fpsId} />
            </div>
          </div>
        }
        titleStyle={STYLE_TITLE}
      >
        {saving && (
          <div style={STYLE_SPINNER}>
            <CircularProgress />
          </div>
        )}
        <SelectField
          autoWidth
          underlineFocusStyle={STYLE_INPUTS}
          floatingLabelStyle={STYLE_INPUTS}
          value={version.status}
          onChange={this.changeStatus}
          floatingLabelText={_t('element.selectStatus')}
        >
          {SELECTABLE_STATUSES}
        </SelectField>
        <TextField
          floatingLabelText={_tg('field.comment')}
          floatingLabelStyle={{ color: TXT_BLACK }}
          onChange={this.changeComment}
          value={version.comment || undefined}
          multiLine
          rows={5}
          rowsMax={5}
          style={{ width: '100%', margin: 10 }}
        />
        {!saving && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <FilesV2
              innerLabel="+"
              outerLabel={_t('element.fileUpload.outerLabel', {
                maxFiles,
                maxSize,
              })}
              onChange={(files: Array<File>) => this.onChangeFile(files)}
              acceptTypes={{
                'image/jpeg': ['.jpeg', '.jpg', '.png'],
                'application/pdf': ['.pdf'],
                'application/msword': ['.doc'],
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
                  '.docx',
                ],
                'application/vnd.oasis.opendocument.text': ['.odt'],
              }}
              onRemoveFile={(file): void => this.onDelete(file)}
              maxSize={5_000_000}
              onError={this.onError}
              maxFiles={maxFiles}
            />
          </div>
        )}
        {error && <div style={STYLE_ERROR}>{error}</div>}
      </Dialog>
    );
  }
}

function mapStateToProps(state: InternalApiState) {
  const { watermarkText } = getConfigState(state);
  return {
    watermarkText,
  };
}

export default connect(mapStateToProps)(VersionManager);
