import React, { CSSProperties } from 'react';

import BoButton from 'facade/BoButton';
import { FpsCaseDTO, FpsVersionDTO } from 'api/fps/types';
import { getNoticeUrl } from 'api/fps';
import { TXT_BLACK } from 'theme';
import FakeInputBlock from 'commons/FakeInputBlock';
import FpsNumber from 'commons/FpsNumber';
import Separator from 'commons/Separator';
import Price from 'commons/Price';
import Date from 'commons/Date';
import { DataBoxItem, DataBoxItemWrapper } from 'commons/DataBox';
import FpsSyncStatus from 'commons/FpsSyncStatus';
import {
  computePaymentKey,
  getVersionFromId,
  isLastVersionAndSynced,
  FpsPaymentKeyType,
} from 'tefps/Fps/Detail/helpers';
import { openNewAuthentifiedTab } from 'api/helpers';

const { _tg } = window.loadTranslations(__filename);

const STYLE_TEXT: CSSProperties = {
  fontSize: 12,
  fontWeight: 'bold',
  color: TXT_BLACK,
  flex: 1,
};

const STYLE_BUTTONS: CSSProperties = {
  margin: '10px',
};

type FpsUpdateProps = {
  fps: FpsCaseDTO;
  version: FpsVersionDTO;
  apaRapoDownloadEnabled: boolean;
};

class FpsUpdate extends React.Component<FpsUpdateProps> {
  openNoticePdf = () => {
    const { version } = this.props;
    openNewAuthentifiedTab(`${getNoticeUrl(version.id)}?corrected=true`);
  };

  render() {
    const { fps, version, apaRapoDownloadEnabled } = this.props;

    const parent = getVersionFromId(fps.versions, version.parent || undefined);
    const lastAndSynced = isLastVersionAndSynced(fps, version);

    return (
      <div style={{ width: '100%' }}>
        <FpsSyncStatus
          version={version}
          nextAntaiSyncDatetime={fps.nextAntaiSyncDatetime}
        />
        <div style={{ ...STYLE_TEXT, textAlign: 'center', marginBottom: 20 }}>
          <span style={{ marginRight: 10 }}>
            {_tg('tefps.fps.detail.content.fpsUpdated.fpsUpdate')}
          </span>
          <FpsNumber fpsId={version.id} />
        </div>
        <DataBoxItemWrapper>
          <DataBoxItem>
            <FakeInputBlock
              title="Date"
              value={<Date datetime={version.dateModified} withTime={false} />}
            />
            <FakeInputBlock
              title="Heure"
              value={<Date datetime={version.dateModified} withDate={false} />}
            />
          </DataBoxItem>
          <Separator />
          <DataBoxItem>
            <FakeInputBlock title="ID Agent" value={version.agent.agentId} />
            <FakeInputBlock
              title="Autorité d'émission"
              value={(version.agent.worksFor || { name: undefined }).name}
            />
            {version.reducedDatetime &&
              version.type !== 'CANCEL_ANTAI_CASE' && (
                <FakeInputBlock
                  title="Fin de minoration"
                  value={<Date datetime={version.reducedDatetime} />}
                />
              )}
            {lastAndSynced && (
              <FakeInputBlock
                title="Clé de paiement"
                value={computePaymentKey(version.id, FpsPaymentKeyType.FPS)}
              />
            )}
          </DataBoxItem>
          {version.type !== 'CANCEL_ANTAI_CASE' && <Separator />}
          {version.type !== 'CANCEL_ANTAI_CASE' && (
            <DataBoxItem>
              <FakeInputBlock
                title="Montant précédent"
                value={
                  <Price
                    price={(parent || { finePrice: undefined }).finePrice}
                  />
                }
              />
              <FakeInputBlock
                title="Nouveau montant"
                value={<Price price={version.finePrice} />}
              />
              {version.reducedDatetime &&
                fps.responsibleOfReducedPeriod === 'CITY' && (
                  <FakeInputBlock
                    title="Nouveau montant minoré"
                    value={<Price price={version.reducedFinePrice} />}
                  />
                )}
              {version.newSurcharge !== null && version.type === 'CCSP' && (
                <FakeInputBlock
                  title="Nouvelle majoration"
                  value={<Price price={version.newSurcharge} />}
                />
              )}
            </DataBoxItem>
          )}
        </DataBoxItemWrapper>
        {fps.administrativeCertificate && version.finePrice === 0 && (
          <FakeInputBlock
            title={_tg(
              'tefps.fps.detail.content.fpsUpdated.cancelProposalAdminCertificate'
            )}
            value={fps.administrativeCertificate}
          />
        )}
        {apaRapoDownloadEnabled &&
          (version.type === 'RAPO' ||
            version.type === 'CCSP' ||
            version.type === 'CORRECTION') && (
            <div>
              <BoButton
                style={STYLE_BUTTONS}
                onClick={this.openNoticePdf}
                primary
                label="Avis de Paiement rectificatif"
              />
            </div>
          )}
      </div>
    );
  }
}

export default FpsUpdate;
