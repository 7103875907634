import React, { useEffect, useState } from 'react';
import Dialog from 'material-ui/Dialog';
import CircularProgress from 'material-ui/CircularProgress';
import { v4 as uuidv4 } from 'uuid';
import { useHistory } from 'react-router-dom';

import BoButton from 'facade/BoButton';
import FormComponent from 'commons/FormComponent';
import {
  ControlResponse,
  ZoneDTO,
  ControlRequest,
} from '@cvfm-front/tefps-types';
import { agentList, ControlDTO, ControlVersionDTO } from 'api/control/types';
import { TicketPlate } from '@cvfm-front/commons-types';
import { STYLE_ERROR_WRAPPER, STYLE_LOADING_WRAPPER } from 'theme';
import { fetchAgents, getAgentDetail } from 'api/accounts';
import { getOrganization } from 'api/organizations';
import { createControl } from 'api/control';
import FlexCenter from 'commons/FlexCenter';
import ErrorBlock from 'commons/ErrorBlock';

import ControlToCreateList from '../List/ControlToCreateList';
import ControlDetail from '../List/ControlDetail';
import {
  getFileArray,
  incrementLicensePlate,
  uploadAllControlFiles,
} from '../helpers';

import ControlInformation from './ControlInformation';

const { _t, _tg } = window.loadTranslations(__filename);

type AddControlProps = {
  closeModal: () => void;
  zonesDTO: ZoneDTO[];
  zones: Map<string, string>;
  exemptionReasons: Map<string, string> | null;
  control?: ControlDTO | null | undefined;
};

type ControlInformationPropos = ControlDTO & {
  agentID: string;
  statementDatetime: Date;
  licensePlate: TicketPlate;
  plateCountry: string;
  plate: string;
  files: File;
};

const AddControlModal = ({
  closeModal,
  zones,
  zonesDTO,
  exemptionReasons,
  control,
}: AddControlProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [numberOfControls, setNumberOfControls] = useState<number>(1);
  const [openMultiCreateModal, setOpenMultiCreateModal] = useState<boolean>(
    false
  );
  const [controlCreateResponses, setControlCreateResponses] = useState<
    Array<ControlResponse>
  >([]);
  const [controlId, setControlId] = useState<string | null>();
  const [error, setError] = useState<Error>();
  const [dryRunControl, setDryRunControl] = useState<ControlDTO>();
  const [replay, setReplay] = useState<boolean>(false);
  const [openCreate, setOpenCreate] = useState<boolean>(true);
  const [form, setForm] = useState<FormComponent | null>(null);
  const [agents, setAgents] = useState<agentList[] | null | undefined>(null);
  const attachRef = (node: FormComponent) => {
    setForm(node);
  };
  const history = useHistory();

  const fetchAgentsList = async () => {
    try {
      const agents = await fetchAgents();
      setAgents(
        agents?.map(agent => {
          return {
            id: agent.agentId,
            name: agent.firstName,
          };
        }) ?? []
      );
    } catch (e) {
      setError(new Error(_tg('feedback.error.fetchAgents')));
    }
  };

  const addNewControl = async () => {
    if (form && form.isValid()) {
      const value = form.getFormEntries() as ControlInformationPropos;
      setLoading(true);
      const agentDetail = await getAgentDetail(value.agentID);
      const organization = await getOrganization(agentDetail.organizationId);
      const controlAgent = {
        agentId: agentDetail.agentId,
        name: agentDetail.firstName,
        worksFor: {
          organizationId: organization.organizationId,
          name: organization.name,
          url: organization.url,
          address: organization.address,
          shortId: (organization.shortId as unknown) as number,
        },
      };

      const controlRequest: ControlRequest = {
        statementDatetime: value.statementDatetime,
        deviceServiceChannel: {
          application: {
            name: 'Backoffice',
            author: {
              name: organization.name,
              address: organization.address,
              organizationId: organization.organizationId,
              url: organization.url,
            },
            softwareVersion: '2.0',
          },
          deviceType: value.deviceServiceChannel?.deviceType || 'PDA',
          operatingSystem: window.navigator.platform,
        },
        agent: controlAgent,
        zoneId: value.zoneId ? value.zoneId : null,
        licensePlate: {
          plate: value.plate.toUpperCase(),
          plateCountry: value.plateCountry.toUpperCase(),
        },
        dryRun: value.dryRun,
        statementLocation: {
          latitude: value.latitude,
          longitude: value.longitude,
        },
      };

      try {
        if (value.dryRun) {
          // Don't event know how this can work, don't have time to refacto
          const control = await createControl(controlRequest);
          const dryRunControl: ControlDTO = {
            controlId: control.controlId,
            statementDatetime: new Date().toISOString(),
            agent: value.agent,
            zoneId: value.zoneId,
            latitude: value.latitude,
            longitude: value.longitude,
            addressLatitude: value.addressLatitude,
            addressLongitude: value.addressLongitude,
            status: control.controlStatus,
            exemptionReason: control.exemptionReason,
            medias: [],
            lapiReviewFetchable: false,
            controlStatusHistory: [],
            initialPlate: {
              plate: value.plate,
              plateCountry: value.plateCountry,
            },
            metadata: {
              ocrScore: 'O.5',
            },
            dryRun: true,
            lastVersion: {} as ControlVersionDTO,
            lapiReviewFetchabilityFailReasons: [],
            versions: [],
          };
          setDryRunControl(dryRunControl);
          setControlId(control.controlId);
        } else {
          let i = 0;
          setOpenCreate(false);
          setOpenMultiCreateModal(true);
          const promises = [];

          while (i < numberOfControls) {
            controlRequest.controlId = uuidv4();
            controlRequest.licensePlate.plate = incrementLicensePlate(
              value.plate,
              i
            );

            const promise = createControl(controlRequest)
              .then(controlResponse => {
                if (value.files) {
                  return uploadAllControlFiles(
                    getFileArray(value.files)!,
                    controlResponse.controlId
                  ).then(() => controlResponse);
                }
                return controlResponse;
              })
              .catch(err => setError(err));

            promises.push(promise);
            i += 1;
          }
          void Promise.all(promises).then(
            (controlCreateResponses: ControlResponse[]) => {
              setControlCreateResponses(controlCreateResponses);
            }
          );
        }
        setOpenCreate(false);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    void fetchAgentsList();
  }, []);

  const actions = [
    <BoButton
      key="annuler"
      style={{ marginRight: 10 }}
      label={_tg('action.cancel')}
      secondary
      onClick={closeModal}
    />,
    <BoButton
      key="creer"
      style={{ marginRight: 10 }}
      label={_tg('action.create')}
      primary
      onClick={addNewControl}
    />,
  ];

  return (
    <>
      <Dialog
        title={
          control
            ? _t('element.dialog.titleReplay')
            : _t('element.dialog.title')
        }
        actions={actions}
        modal
        autoScrollBodyContent
        open={openCreate}
      >
        <FormComponent ref={attachRef}>
          {loading && (
            <div style={STYLE_LOADING_WRAPPER}>
              <CircularProgress />
            </div>
          )}
          {control ? (
            <ControlInformation
              zones={zonesDTO}
              controlToReplay={control}
              agents={agents}
            />
          ) : (
            <ControlInformation
              zones={zonesDTO}
              agents={agents}
              numberOfControls={numberOfControls}
              setNumberOfControls={setNumberOfControls}
            />
          )}
        </FormComponent>
      </Dialog>

      {controlId && (
        <ControlDetail
          exemptionReasons={exemptionReasons}
          controlId={controlId}
          zones={zones}
          onClose={() => setControlId(null)}
          onOpen={() => setReplay}
          dryRunControl={dryRunControl}
          history={history}
        />
      )}
      <Dialog
        title={_tg(
          'tefps.Control.AddControlModal.ControlInformation.element.label.multiCreateControl'
        )}
        actions={[
          <BoButton
            key="annuler"
            style={{ marginRight: 10 }}
            label={_tg('action.close')}
            secondary
            onClick={closeModal}
          />,
        ]}
        modal
        autoScrollBodyContent
        open={openMultiCreateModal}
      >
        {controlCreateResponses.length === 0 && loading && (
          <FlexCenter>
            <CircularProgress />
          </FlexCenter>
        )}
        {controlCreateResponses.length > 0 && (
          <ControlToCreateList controlResponses={controlCreateResponses} />
        )}
        {error && (
          <div style={STYLE_ERROR_WRAPPER}>
            <ErrorBlock error={{ message: error.message }} />
          </div>
        )}
      </Dialog>
    </>
  );
};

export default AddControlModal;
