import { RecourseFileDTO } from 'api/recourse/types';
import { UploadFile } from 'api/commonTypes';
import { uploadFileV2 } from 'api/mediaupload';
import { getDirectUploadLink } from 'api/recourse';

/**
 * Upload rapo files and add them as RecourseFileDTO to an array
 * Return errors indexed by file id
 */
export async function uploadRapoFiles(
  filesToUpload: { [key: string]: UploadFile },
  rapodId: string,
  watermarkText: string | null,
  filesArrayToAppend: Array<RecourseFileDTO>
): Promise<{ [key: string]: string }> {
  const uploadErrors = {};
  const requests: Array<() => Promise<void>> = [];
  Object.entries(filesToUpload).forEach((v: any) => {
    const [id, uploadFile] = v as [string, UploadFile];
    if (!uploadFile) {
      return;
    }
    requests.push(async () => {
      try {
        const { file } = uploadFile;
        if (!file) {
          throw new Error('shouldNotHappen');
        }
        // Get pre-signed upload url and urn of the file for a given resource type
        const directUploadDTO = await getDirectUploadLink(rapodId, file.type);

        await uploadFileV2(
          directUploadDTO.signedUploadUrl,
          file,
          watermarkText
        );

        filesArrayToAppend.push({
          fileId: id,
          contentUrl: uploadFile.contentUrl,
          urn: directUploadDTO.fileUrn,
          name: uploadFile.name,
          size: uploadFile.size,
          created: uploadFile.created,
          type: 'BY_AGENT', // type used to represent files uploaded from BO
          mime: uploadFile.mime,
          deleted: null,
        });
      } catch (e) {
        uploadErrors[id] = "Erreur lors de l'upload du fichier";
      }
    });
  });

  await Promise.all(requests.map(fn => fn()));
  return uploadErrors;
}
