import React, { CSSProperties, useEffect, useState } from 'react';
import { Dialog, MenuItem, SelectField, TextField } from 'material-ui';
import CircularProgress from 'material-ui/CircularProgress';
import { connect } from 'react-redux';
import CreatableSelect from 'react-select/creatable';

import BoButton from 'facade/BoButton';
import { BKG_CYAN, STYLE_ERROR_WRAPPER, STYLE_LOADING_WRAPPER } from 'theme';
import ErrorBlock from 'commons/ErrorBlock';
import { patchFps } from 'api/fps';
import { FpsCancelProposalReason, getConfigState } from 'config/duck';
import { getApiState } from 'api/duck';

const { _tg } = window.loadTranslations(__filename);

const STYLE_TITLE: CSSProperties = {
  backgroundColor: BKG_CYAN,
  color: '#ffffff',
  fontWeight: 'bold',
};

const STYLE_CONTENT_WRAPPER: CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignContent: 'center',
};

const STYLE_INPUTS: CSSProperties = {
  borderColor: BKG_CYAN,
  color: BKG_CYAN,
};

const STYLE_SELECT_CONTAINER: CSSProperties = {
  height: 90,
};

const REACT_SELECT_STYLE = {
  menu: (base: any) => ({
    ...base,
    zIndex: 100,
  }),
  placeholder: (styles: any) => ({
    ...styles,
    color: ' rgba(0, 0, 0, 0.3)',
  }),
  option: (styles: any, state: any) => ({
    ...styles,
    backgroundColor: state.isSelected ? 'rgb(65, 183, 195)' : 'white',
    '&:hover': {
      backgroundColor: 'rgb(65, 183, 195)',
    },
  }),
  valueContainer: (styles: any) => ({
    ...styles,
    padding: 0,
  }),
  input: (styles: any, state: any) => ({
    ...styles,
    width: '100%',
    gridTemplateColumns: '0 minmax(min-content, 1fr)',
  }),
  control: (styles: any, state: any) => ({
    ...styles,
    height: 60,
    border: 0,
    boxShadow: 'none',
    borderRadius: 0,
    borderBottom: state.isFocused
      ? '2px solid rgb(65, 183, 195)'
      : '1px solid #d0cfcf',
    '&:hover': {
      borderBottom: '2px solid rgb(65, 183, 195)',
    },
  }),
};
type Props = {
  isOpen: boolean;
  fpsId: string; // eslint-disable-line react/no-unused-prop-types
  close: () => any;
  canUpdateFps: boolean;
  agentId: string;
  reloadFps: () => any;
  fpsCancelProposalReasons: Array<FpsCancelProposalReason>;
  fpsComments: Array<string>;
};

const AddCancelProposal = ({
  isOpen,
  fpsId,
  close,
  reloadFps,
  canUpdateFps,
  agentId,
  fpsCancelProposalReasons,
  fpsComments,
}: Props) => {
  const [comment, setComment] = useState<string | undefined>(undefined);
  const [administrativeCertificate, setAdministrativeCertificate] = useState<
    string | undefined
  >(undefined);
  const [reason, setReason] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [email, setEmail] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setLoading(false);
  });

  function onClose() {
    close();
  }

  async function onClickValidate() {
    if (!canUpdateFps && !reason) {
      setError('Le champ raison est manquant');
      return;
    }

    const body = [
      {
        op: 'add',
        path: '/cancelProposal/-',
        value: {
          agentId,
          reason,
          comment,
          fpsId,
          email,
          administrativeCertificate,
          status: 'PENDING',
        },
      },
    ];
    setLoading(true);
    try {
      await patchFps(fpsId, body);
      await reloadFps();
    } catch (e) {
      setLoading(false);
      setError(e.json.message);
    }
  }

  function changeReason(
    e: React.ChangeEvent<HTMLInputElement>,
    i: number,
    value: string
  ) {
    setReason(value);
  }

  function onChangeEmail(
    e: React.ChangeEvent<HTMLInputElement>,
    newEmail: string
  ) {
    setEmail(newEmail);
  }

  function onChangeComment(
    e: React.ChangeEvent<HTMLInputElement>,
    newComment: string
  ) {
    setComment(newComment);
  }

  function onChangeAdministrativeCertificate(
    e: React.ChangeEvent<HTMLInputElement>,
    newAdminCert: string
  ) {
    setAdministrativeCertificate(newAdminCert);
  }

  const options = fpsComments?.map((value, index) => ({
    label: value,
    value: index,
  }));

  const actions = [
    <BoButton
      style={{ marginRight: 10 }}
      key={1}
      label="Annuler"
      onClick={onClose}
    />,
    <BoButton
      key={2}
      label="Ajouter"
      disabled={!comment}
      primary
      keyboardFocused
      onClick={onClickValidate}
    />,
  ];

  return (
    <Dialog
      open={isOpen}
      title={_tg(
        'tefps.fps.detail.addContents.addCancelProposal.cancellationRequest'
      )}
      onRequestClose={onClose}
      titleStyle={STYLE_TITLE}
      actions={actions}
    >
      <div style={{ minHeight: 200 }}>
        {loading ? (
          <div style={STYLE_LOADING_WRAPPER}>
            <CircularProgress />
          </div>
        ) : (
          <>
            {!canUpdateFps && (
              <div style={STYLE_CONTENT_WRAPPER}>
                <SelectField
                  underlineFocusStyle={STYLE_INPUTS}
                  value={reason}
                  onChange={changeReason}
                  floatingLabelText={_tg(
                    'tefps.fps.detail.addContents.addCancelProposal.cancellationReason'
                  )}
                >
                  {fpsCancelProposalReasons &&
                    fpsCancelProposalReasons.map(item => (
                      <MenuItem
                        key={item.key}
                        value={item.label}
                        primaryText={item.label}
                      />
                    ))}
                </SelectField>
              </div>
            )}
            <div style={STYLE_CONTENT_WRAPPER}>
              <TextField
                underlineFocusStyle={STYLE_INPUTS}
                floatingLabelFocusStyle={STYLE_INPUTS}
                value={email || ''}
                onChange={onChangeEmail}
                floatingLabelText={_tg(
                  'tefps.fps.detail.addContents.addCancelProposal.userAddress'
                )}
                fullWidth
              />
            </div>
            {fpsComments && fpsComments.length > 0 ? (
              <div style={STYLE_SELECT_CONTAINER}>
                <CreatableSelect
                  isClearable={comment !== undefined}
                  placeholder={_tg(
                    'tefps.fps.detail.addContents.addCancelProposal.selectComment'
                  )}
                  id="comment"
                  value={{ label: comment }}
                  options={options}
                  onChange={selectedOption => {
                    setComment(selectedOption?.label);
                  }}
                  onInputChange={(input, action) => {
                    if (action.action === 'input-change') {
                      setComment(input);
                    }
                  }}
                  styles={REACT_SELECT_STYLE}
                  maxMenuHeight={200}
                  minMenuHeight={50}
                  menuPosition="absolute"
                  noOptionsMessage={() => null}
                  isValidNewOption={() => false}
                />
              </div>
            ) : (
              <div style={STYLE_CONTENT_WRAPPER}>
                <TextField
                  underlineFocusStyle={STYLE_INPUTS}
                  floatingLabelFocusStyle={STYLE_INPUTS}
                  value={comment}
                  onChange={onChangeComment}
                  floatingLabelText={_tg(
                    'tefps.fps.detail.addContents.addCancelProposal.comment'
                  )}
                  fullWidth
                  multiLine
                  rows={3}
                  rowsMax={3}
                  required
                />
              </div>
            )}
            <div style={STYLE_CONTENT_WRAPPER}>
              <TextField
                underlineFocusStyle={STYLE_INPUTS}
                floatingLabelFocusStyle={STYLE_INPUTS}
                value={administrativeCertificate || ''}
                onChange={onChangeAdministrativeCertificate}
                floatingLabelText={_tg(
                  'tefps.fps.detail.addContents.addCancelProposal.adminCertificate'
                )}
                fullWidth
                multiLine
              />
            </div>
          </>
        )}
      </div>
      {error && (
        <div style={STYLE_ERROR_WRAPPER}>
          <ErrorBlock error={{ message: error }} />
        </div>
      )}
    </Dialog>
  );
};

export default connect(state => {
  const { userInfo } = getApiState(state);
  const { fpsCancelProposalReasons, fpsConfigurationDTO } = getConfigState(
    state
  );
  return {
    canUpdateFps: userInfo && userInfo.rights.includes('FPS_UPDATE'),
    agentId: userInfo?.agentId,
    fpsCancelProposalReasons,
    fpsComments: fpsConfigurationDTO?.fpsComments || [],
  };
})(AddCancelProposal);
