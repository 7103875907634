import { dissociateOpenId } from 'api/cvfm-core-subscription/subscriber';

const DissociateFranceConnectService = () => {
  const dissociate = (subscriberId: string | null, openId: string | null) => {
    if (subscriberId === null) {
      return;
    }

    if (openId === null) {
      return;
    }

    dissociateOpenId(subscriberId, openId);
  };

  return { dissociate };
};

export default DissociateFranceConnectService();
