import React, { useState } from 'react';
import { CircularProgress, Dialog, FlatButton } from 'material-ui';

import CsvImportDropZone from 'commons/CsvImportDropZone';
import { importEligibilitiesFromCSV } from 'api/eligibility';

const { _tg } = window.loadTranslations(__filename);

type Props = {
  onImport: (message: string) => Promise<void>;
};

const ImportEligibilities = ({ onImport }: Props): JSX.Element => {
  const [success, setSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [errors, setErrors] = useState<Array<string>>([]);

  const onDrop = async (files: Array<File>) => {
    if (files.length === 0) {
      return;
    }

    setLoading(true);
    setOpenModal(true);

    const { success, errors } = await importEligibilitiesFromCSV(files[0]);

    setSuccess(success);
    setErrors(errors);
    setLoading(false);
    setOpenModal(!success);

    if (success) {
      void onImport(_tg('tefps.eligibilities.success.creatingTickets'));
    }
  };

  const handleClose = () => {
    setOpenModal(false);
    setErrors([]);
  };

  let title = _tg('feedback.loading.importInProgress');
  if (!success && !loading) {
    title = _tg('feedback.error.requestNotProcessed');
  }

  return (
    <div style={{ marginLeft: 30 }}>
      <CsvImportDropZone
        buttonLabel={_tg('action.importCSV')}
        onDrop={onDrop}
      />

      <Dialog
        title={title}
        onRequestClose={handleClose}
        modal
        open={openModal}
        autoScrollBodyContent
        actions={[
          <FlatButton
            label={_tg('action.close')}
            onClick={handleClose}
            disabled={loading}
          />,
        ]}
      >
        {!loading && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: 10,
            }}
          >
            <ul style={{ marginTop: 20 }}>
              {errors.map(error => (
                <li style={{ marginBottom: 2 }}>
                  {_tg('characters.dot')} {error}
                </li>
              ))}
            </ul>
          </div>
        )}
        {loading && <CircularProgress />}
      </Dialog>
    </div>
  );
};

export default ImportEligibilities;
