import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import TextField from 'material-ui/TextField';
import Arrow from 'material-ui/svg-icons/action/trending-flat';
import Plus from 'material-ui/svg-icons/content/add';
import DatePicker from 'material-ui/DatePicker';
import IntlPolyfill from 'intl';

import { BKG_PINK } from 'theme';
import { Flex } from '@cvfm-front/commons-ui';
import { InternalAgent } from 'api/auth/types';
import { CcspVersionDTO } from 'api/ccsp/types';
import { DataBox, DataBoxContent, DataBoxItemWrapper } from 'commons/DataBox';
import ConfirmAction from 'commons/ConfirmAction';
import FakeInputBlock from 'commons/FakeInputBlock';
import Price from 'commons/Price';
import { getApiState } from 'api/duck';

import StatusButton from './StatusButton';

const { _t, _tg } = window.loadTranslations(__filename);

const STYLE_NEW_PRICE_VALUE: CSSProperties = {
  border: `1px solid ${BKG_PINK}`,
  borderRadius: '5px',
  padding: 8,
  marginLeft: 12,
};

const STYLE_FONT_14: CSSProperties = { fontSize: 14 };

const STYLE_NEW_PRICE_TEXT: CSSProperties = {
  color: BKG_PINK,
  display: 'flex',
  alignItems: 'center',
  ...STYLE_FONT_14,
};

const STYLE_SYMBOL: CSSProperties = {
  color: BKG_PINK,
  margin: '0px 20px 0px 20px',
  width: 32,
  height: 32,
};

const STYLE_PRICE_EVOLUTION_BLOCK: CSSProperties = {
  display: 'flex',
  flex: 1,
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  margin: '10px 10px 20px 10px',
};

const STYLE_HEADER: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: '5px',
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%) translateY(-50%)',
  height: 30,
};

const STYLE_FOOTER: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: '#FFF',
  color: BKG_PINK,
  border: `1px solid ${BKG_PINK}`,
  borderRadius: '5px',
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%) translateY(-51%)',
  height: 30,
  fontSize: 14,
  fontWeight: 'bold',
  padding: '0px 40px',
};

const NO_EDITION = 0;
const EDIT_ACCEPT = 1; // Autre acceptation
const EDIT_TOTAL_ACCEPT = 2; // Acceptation totale: Nouveau montant=0 et nouvelle majoration=0
const EDIT_PARTIAL_ACCEPT = 3; // Acceptation partielle: Montant inchangé et nouvelle majoration=0
const EDIT_REJECT = 4;

const getConfirmAcceptMessage = () => _t('feedback.confirm.accept');
const getConfirmRejectMessage = () => _t('feedback.confirm.reject');

type State = {
  editStatus: number; // 0 : pas d'édition, 1 : acceptation, 2 : acceptation totale, 3: acceptation partielle, 4: rejet
  editedPrice: number;
  editedMajoration?: number;
  canModifyStatus: boolean;
  decisionDate: Date;
  openAlert: boolean;
};

type Props = {
  ccspId: string;
  fpsId: string;
  initialPrice: number;
  initialMajoration?: number;
  isAccepted: null | boolean;
  newFpsPrice?: number;
  newMajoration?: number;
  userInfo: InternalAgent; // eslint-disable-line react/no-unused-prop-types
  changeDecision: (update: CcspVersionDTO) => Promise<void>;
  isAntaiCanceled: boolean;
};

const initState = (props: Props) => {
  const {
    userInfo,
    newMajoration,
    newFpsPrice,
    initialMajoration,
    initialPrice,
  } = props;
  return {
    editStatus: 0,
    editedPrice: typeof newFpsPrice === 'number' ? newFpsPrice : initialPrice,
    editedMajoration:
      typeof newMajoration === 'number' ? newMajoration : initialMajoration,
    canModifyStatus: userInfo.rights.includes('CCSP_ACCEPT'),
    decisionDate: new Date(),
    openAlert: false,
  };
};

class DecisionBlock extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = initState(props);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    this.setState(initState(nextProps));
  }

  setStatusTo = (editStatus: number) => {
    const { initialMajoration } = this.props;
    const editedPrice = this.computeDecisionPrice(editStatus);
    if (initialMajoration != null) {
      const editedMajoration = this.computeDecisionMajoration(
        editStatus,
        initialMajoration
      );
      this.setState({ editedMajoration });
    }
    this.setState({ editStatus, editedPrice });
  };

  setStatusToAccepted = () => this.setStatusTo(EDIT_ACCEPT);
  setStatusToTotalAccepted = () => this.setStatusTo(EDIT_TOTAL_ACCEPT);
  setStatusToPartialAccepted = () => this.setStatusTo(EDIT_PARTIAL_ACCEPT);
  setStatusToRefused = () => this.setStatusTo(EDIT_REJECT);

  changeDecision = () => {
    const {
      editStatus,
      editedPrice,
      editedMajoration,
      decisionDate,
    } = this.state;
    const { changeDecision, initialPrice } = this.props;
    this.setState({ editStatus: NO_EDITION });
    // ANTAI ne permet pas les augementations de prix
    if (initialPrice < editedPrice) {
      this.setState({ openAlert: true });
      return;
    }

    if (editStatus !== EDIT_REJECT) {
      void changeDecision({
        status: 'SAISINE_ACCEPTEE',
        newFpsPrice: editedPrice,
        newMajoration: editedMajoration,
        decisionDate: decisionDate.toISOString(),
      });
    } else {
      void changeDecision({
        status: 'SAISINE_REJETEE',
        decisionDate: decisionDate.toISOString(),
      });
    }
  };

  closeStatusEditionModal = () => this.setState({ editStatus: NO_EDITION });

  closeAlertModal = () => this.setState({ openAlert: false });

  changePrice = (event: React.ChangeEvent<any>, value: string) =>
    this.setState({ editedPrice: Number(value) * 100 });

  changeMajoration = (event: React.ChangeEvent<any>, value: string) => {
    this.setState({ editedMajoration: value ? Number(value) * 100 : 0 });
  };

  onDecisionDateChange = (e: React.ChangeEvent<any>, date: Date) => {
    this.setState({ decisionDate: date });
  };

  computeDecisionPrice = (editStatus: number) => {
    const { initialPrice } = this.props;

    switch (editStatus) {
      case EDIT_PARTIAL_ACCEPT:
      case EDIT_REJECT:
        return initialPrice;
      default:
        return 0;
    }
  };

  computeDecisionMajoration = (
    editStatus: number,
    initialMajoration: number
  ) => {
    switch (editStatus) {
      case EDIT_REJECT:
        return initialMajoration;
      default:
        return 0;
    }
  };

  // note : we use concatenated strings and not JSX.Element because the modal only accept a string
  computeModalLeadingText = (decision: string) => {
    const { ccspId, fpsId } = this.props;
    return [
      _t('element.modal.fps', { fpsId }),
      _t('element.modal.ccsp', { ccspId }),
      _t('element.modal.decision', { decision }),
    ].reduce((a, b) => a.concat(b));
  };

  getNewMajorationValue = () => {
    const { initialMajoration, newMajoration } = this.props;

    if (newMajoration === undefined || newMajoration === null) {
      return initialMajoration;
    }
    return newMajoration;
  };

  render() {
    const {
      isAccepted,
      initialPrice,
      initialMajoration,
      newFpsPrice,
      newMajoration,
      isAntaiCanceled,
    } = this.props;
    const {
      editStatus,
      canModifyStatus,
      editedPrice,
      editedMajoration,
      openAlert,
    } = this.state;

    const decisionHasBeenReported = isAccepted !== null;
    const acceptedLabel =
      initialPrice === newFpsPrice && newFpsPrice !== 0
        ? _t('element.button.partiallyAccepted')
        : _t('element.button.accepted');
    const surchargeFieldLabel =
      editStatus !== EDIT_REJECT
        ? _t('element.label.newSurcharge')
        : _t('element.label.unchangedSurcharge');
    const priceFieldLabel =
      editStatus === EDIT_ACCEPT || editStatus === EDIT_TOTAL_ACCEPT
        ? _t('element.label.newPrice')
        : _t('element.label.unchangedPrice');

    function totalPrice(
      initialP: number,
      initialM?: number,
      newFpsP?: number,
      newM?: number
    ) {
      let total = 0;
      // Si le nouveau fps n'est pas défini alors aucun changement n'a été demandé : on garde le fps initial
      if (newFpsP == null) {
        total += initialP;
      }
      // S'il existe un nouveau fps alors c'est ce dernier qui est pris en compte
      else {
        total += newFpsP;
      }
      // Même logique pour la majoration
      if (initialM) {
        if (newM === null || newM === undefined) {
          total += initialM;
        } else {
          total += newM;
        }
      }
      return total;
    }

    return (
      <div>
        <DataBox style={{ marginBottom: 15, marginTop: 15 }}>
          {(canModifyStatus || isAccepted !== null) && (
            <div style={STYLE_HEADER}>
              {(isAccepted === null || isAccepted) && (
                <ConfirmAction
                  action={this.setStatusToTotalAccepted}
                  message={this.computeModalLeadingText(
                    _t('element.button.totalAccept')
                  )
                    .concat(getConfirmAcceptMessage())
                    .concat(
                      initialMajoration != null
                        ? _t('feedback.confirm.totalAccept.surchage')
                        : _t('feedback.confirm.totalAccept.noSurcharge')
                    )}
                  enabled={canModifyStatus && !isAccepted}
                >
                  <StatusButton
                    active={isAccepted === true}
                    title={
                      canModifyStatus
                        ? _t('element.tooltip.changeToAccept')
                        : _t('element.tooltip.noPermissionToModify')
                    }
                    value={
                      !isAccepted
                        ? _t('element.button.totalAccept')
                        : acceptedLabel
                    }
                  />
                </ConfirmAction>
              )}
              {isAccepted === null &&
                initialMajoration != null &&
                !isAntaiCanceled && (
                  <ConfirmAction
                    action={this.setStatusToPartialAccepted}
                    message={this.computeModalLeadingText(
                      _t('element.button.partialAccept')
                    )
                      .concat(getConfirmAcceptMessage())
                      .concat(_t('feedback.confirm.partialAccept'))}
                    enabled={canModifyStatus}
                  >
                    <StatusButton
                      active={false}
                      title={
                        canModifyStatus
                          ? _t('element.tooltip.changeToAccept')
                          : _t('element.tooltip.noPermissionToModify')
                      }
                      value={_t('element.button.partialAccept')}
                    />
                  </ConfirmAction>
                )}
              {isAccepted === null && !isAntaiCanceled && (
                <ConfirmAction
                  action={this.setStatusToAccepted}
                  message={this.computeModalLeadingText(
                    _t('element.button.otherAccept')
                  )
                    .concat(getConfirmAcceptMessage())
                    .concat(
                      initialMajoration != null
                        ? _t('feedback.confirm.otherAccept.surchage')
                        : _t('feedback.confirm.otherAccept.noSurcharge')
                    )}
                  enabled={canModifyStatus}
                >
                  <StatusButton
                    active={false}
                    title={
                      canModifyStatus
                        ? _t('element.tooltip.changeToAccept')
                        : _t('element.tooltip.noPermissionToModify')
                    }
                    value={_t('element.button.otherAccept')}
                  />
                </ConfirmAction>
              )}
              {!isAccepted && (
                <ConfirmAction
                  action={this.setStatusToRefused}
                  message={this.computeModalLeadingText(
                    _t('element.button.reject')
                  ).concat(getConfirmRejectMessage())}
                  enabled={canModifyStatus && isAccepted === null}
                >
                  <StatusButton
                    active={isAccepted === false}
                    title={
                      canModifyStatus
                        ? _t('element.tooltip.changeToReject')
                        : _t('element.tooltip.noPermissionToModify')
                    }
                    value={
                      isAccepted === null
                        ? _t('element.button.reject')
                        : _t('element.button.rejected')
                    }
                  />
                </ConfirmAction>
              )}
            </div>
          )}
          <DataBoxContent>
            <DataBoxItemWrapper>
              <div
                style={{
                  ...STYLE_PRICE_EVOLUTION_BLOCK,
                  justifyContent: decisionHasBeenReported
                    ? 'space-between'
                    : 'left',
                }}
              >
                <FakeInputBlock
                  title={_t('element.fakeInput.originalPrice')}
                  value={<Price price={initialPrice} />}
                  labelStyle={STYLE_FONT_14}
                  inputStyle={STYLE_FONT_14}
                  containerStyle={{ width: '18%' }}
                />
                {initialMajoration != null && [
                  <Plus key="1" style={{ ...STYLE_SYMBOL, color: '#000' }} />,
                  <FakeInputBlock
                    key="2"
                    title={_t('element.fakeInput.originalSurcharge')}
                    value={<Price price={initialMajoration} />}
                    labelStyle={STYLE_FONT_14}
                    inputStyle={STYLE_FONT_14}
                    containerStyle={{ width: '18%' }}
                  />,
                ]}
                {decisionHasBeenReported && [
                  <Arrow key="1" style={STYLE_SYMBOL} />,
                  <span key="2" style={STYLE_NEW_PRICE_TEXT}>
                    {_t('element.newPrice')}
                    <span style={STYLE_NEW_PRICE_VALUE}>
                      <Price price={newFpsPrice || 0} />
                    </span>
                  </span>,
                ]}
                {decisionHasBeenReported &&
                  initialMajoration != null && [
                    <Plus key="3" style={STYLE_SYMBOL} />,
                    <span key="4" style={STYLE_NEW_PRICE_TEXT}>
                      {_t('element.newSurcharge')}
                      <span style={STYLE_NEW_PRICE_VALUE}>
                        <Price price={this.getNewMajorationValue()} />
                      </span>
                    </span>,
                  ]}
              </div>
            </DataBoxItemWrapper>
          </DataBoxContent>
          <div style={STYLE_FOOTER}>
            <span style={{ marginRight: 6 }}>{_t('element.totalPrice')}</span>
            <Price
              price={totalPrice(
                initialPrice,
                initialMajoration,
                newFpsPrice,
                newMajoration
              )}
            />
          </div>
        </DataBox>
        <Dialog
          title={
            editStatus !== EDIT_REJECT
              ? _t('element.dialog.title.accept')
              : _t('element.dialog.title.reject')
          }
          actions={[
            <FlatButton
              label={_tg('action.cancel')}
              onClick={this.closeStatusEditionModal}
            />,
            <FlatButton
              label={_tg('action.validate')}
              primary
              onClick={this.changeDecision}
            />,
          ]}
          open={editStatus !== NO_EDITION}
          modal
          onRequestClose={this.closeStatusEditionModal}
        >
          <span>{priceFieldLabel}</span>
          <TextField
            title={_t('element.form.inCurrency')}
            name="price"
            value={editedPrice / 100}
            type="number"
            onChange={this.changePrice}
            style={{ width: 100 }}
            disabled={editStatus !== EDIT_ACCEPT}
          />
          <span>{_t('element.currency')}</span>
          {initialMajoration != null && [
            // Si à la création de la saisine, le FPS était en phase amiable, alors on ne propose pas de statuer sur la surcharge
            <span style={{ marginLeft: 60 }}>{surchargeFieldLabel}</span>,
            <TextField
              key="2"
              title={_t('element.form.inCurrency')}
              name="majoration"
              value={(editedMajoration as number) / 100}
              onChange={this.changeMajoration}
              type="number"
              style={{ width: 100 }}
              disabled={editStatus !== EDIT_ACCEPT}
            />,
            <span key="3">{_t('element.currency')}</span>,
          ]}
          <div>
            <DatePicker
              DateTimeFormat={IntlPolyfill.DateTimeFormat}
              locale={window.i18next.language}
              autoOk
              textFieldStyle={{ width: 200, marginRight: 5 }}
              floatingLabelText={_t('element.form.decisionDate')}
              onChange={this.onDecisionDateChange}
              container="inline"
            />
          </div>
        </Dialog>
        <Dialog
          title={_t('element.dialog.title.alert')}
          actions={[
            <FlatButton
              label={_tg('action.validate')}
              primary
              onClick={this.closeAlertModal}
            />,
          ]}
          open={openAlert}
          modal
        >
          <div>{_t('element.dialog.content.message')}</div>
          <div>
            <Flex justifyContent="space-around">
              <FakeInputBlock
                title={_t('element.dialog.content.amount')}
                value={<Price price={initialPrice} />}
              />
              {initialMajoration && (
                <FakeInputBlock
                  title={_t('element.dialog.content.majoration')}
                  value={<Price price={initialMajoration} />}
                />
              )}
            </Flex>
          </div>
        </Dialog>
      </div>
    );
  }
}

export default connect(state => {
  const { userInfo } = getApiState(state);
  return {
    userInfo,
  };
})(DecisionBlock);
