import React, { Component } from 'react';
import Dialog from 'material-ui/Dialog';
import Snackbar from 'material-ui/Snackbar';
import CircularProgress from 'material-ui/CircularProgress';
import { History } from 'history';

import BoButton from 'facade/BoButton';
import FormComponent from 'commons/FormComponent';
import { STYLE_LOADING_WRAPPER } from 'theme';
import { createFps } from 'api/fps';
import { FpsCreation } from 'api/fps/types';
import { ZoningDTO } from '@cvfm-front/tefps-types';

import DatetimesSection from './Components/DatetimesSection';
import VehicleSection from './Components/VehicleSection';
import AddressSection from './Components/AddressSection';

type State = {
  open: boolean;
  loading: boolean;
  error: string;
};

type Props = {
  zoning: ZoningDTO | null | undefined;
  history: History;
};

class AddFPS extends Component<Props, State> {
  fpsForm: FormComponent | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      open: false,
      loading: false,
      error: '',
    };
  }

  attachRef = (node: FormComponent): void => {
    this.fpsForm = node;
  };

  createNewFps = async (formValue: FpsCreation): Promise<void> => {
    const { history } = this.props;
    const fps = await createFps(formValue);
    history.push(`/fps/detail/${fps.fineId}`);
  };

  addNewFPS = async (): Promise<void> => {
    const { fpsForm } = this;
    if (fpsForm && fpsForm.isValid()) {
      const value = fpsForm.getFormEntries();
      try {
        this.setState({ loading: true });
        await this.createNewFps(value as FpsCreation);
      } catch (error) {
        this.setState({ error: (error as Error).message });
      } finally {
        this.setState({ loading: false });
      }
    }
  };

  openDialog = (): void => {
    this.setState({ open: true });
  };

  closeDialog = (): void => {
    this.setState({ open: false });
  };

  closeErrorSnackBar = (): void => {
    this.setState({ error: '' });
  };

  render(): React.ReactNode {
    const { loading, error, open } = this.state;
    const { zoning } = this.props;

    const actions = [
      <BoButton
        key="annuler"
        style={{ marginRight: 10 }}
        label="Annuler"
        onClick={this.closeDialog}
      />,
      <BoButton
        key="ajouter"
        style={{ marginRight: 10 }}
        label="Ajouter"
        primary
        onClick={this.addNewFPS}
      />,
    ];

    return (
      <div>
        <BoButton label="Ajouter un FPS" onClick={this.openDialog} primary />
        <Dialog
          title="Enregistrement du nouveau FPS"
          actions={actions}
          modal
          autoScrollBodyContent
          open={open}
        >
          <FormComponent ref={this.attachRef}>
            {loading && (
              <div style={STYLE_LOADING_WRAPPER}>
                <CircularProgress />
              </div>
            )}
            <DatetimesSection />
            <VehicleSection />
            <AddressSection zoning={zoning} />
          </FormComponent>
        </Dialog>
        <Snackbar
          open={!!error}
          message={error}
          autoHideDuration={4000}
          onRequestClose={this.closeErrorSnackBar}
        />
      </div>
    );
  }
}

export default AddFPS;
