import moment from 'moment';

import { LOGOUT } from 'api/duck';

import { SubscriptionsSearchCriteria } from './types';

const SET_FILTERS = 'Subscriptions/List/SET_FILTERS';

type State = {
  filters: SubscriptionsSearchCriteria;
};

export const initialFilters = (plate?: string): SubscriptionsSearchCriteria => {
  return {
    firstName: null,
    lastName: null,
    subscriberId: null,
    subscriptionPlanIds: new Set(),
    plate,
    customFields: null,
    subscriptionStatus: ['ACCEPTED'],
    zoneIds: new Set(),
    validityStartDate: {
      from: undefined,
      to: undefined,
    },
    validityEndDate: {
      from: moment().toDate(),
      to: undefined,
    },
  };
};

const initialState = (): State => ({
  filters: initialFilters(),
});

export default function subscriptionsListReducer(
  state: State = initialState(),
  action: any
): State {
  switch (action.type) {
    case SET_FILTERS:
      return {
        ...state,
        filters: action.filters,
      };
    case LOGOUT:
      return initialState();
    default:
      return state;
  }
}

export function getSubscriptionSearchState(state: any): State {
  return state.subscriptionsSearchCriteria;
}

export function setFilters(filters: SubscriptionsSearchCriteria): any {
  return {
    type: SET_FILTERS,
    filters,
  };
}

export function setPlateFilter(plate: string): any {
  return {
    type: SET_FILTERS,
    filters: initialFilters(plate),
  };
}
