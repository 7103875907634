import React from 'react';
import './OverviewBlock.css';
import { Card } from 'material-ui';

import FakeInputBlock from 'commons/FakeInputBlock';
import {
  CameraPositionControl,
  ControlProjectionParametersV2,
} from '@cvfm-front/tefps-types';

const { _tg } = window.loadTranslations(__filename);

type Props = {
  parameters: ControlProjectionParametersV2;
  capturePosition: CameraPositionControl;
};

const OverviewBlockProjectionV2 = ({
  parameters,
  capturePosition,
}: Props): JSX.Element => {
  return (
    <Card>
      <div className="control-overview-block__header">
        <div className="text-bold">
          {_tg('field.control.projection.parameter')}
        </div>
      </div>

      <div className="control-overview-block">
        <FakeInputBlock
          title={_tg('field.versionAlone')}
          value={parameters.version}
        />

        {capturePosition !== undefined &&
          parameters.projectionDistanceMultipliers[capturePosition] && (
            <FakeInputBlock
              title={_tg('field.control.projection.distanceMultiplier')}
              value={parameters.projectionDistanceMultipliers[
                capturePosition
              ]?.toString()}
            />
          )}

        {capturePosition && parameters.projectionAngles[capturePosition] && (
          <FakeInputBlock
            title={_tg('field.control.projection.angle')}
            value={parameters.projectionAngles[capturePosition]?.toString()}
          />
        )}

        {parameters.cameraFOV && (
          <FakeInputBlock
            title={_tg('field.control.projection.cameraFOV')}
            value={parameters.cameraFOV.toString()}
          />
        )}

        {parameters.maxAssignmentDistanceFromParkingPlace && (
          <FakeInputBlock
            title={_tg('field.control.projection.maxDistance')}
            value={parameters.maxAssignmentDistanceFromParkingPlace.toString()}
          />
        )}
      </div>
    </Card>
  );
};

export default OverviewBlockProjectionV2;
