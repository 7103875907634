import * as React from 'react';

import Sidebar from 'commons/SidebarV2';
import BarTitle from 'commons/SidebarV2/Components/BarTitle';
import HitsCounter from 'commons/SidebarV2/Components/HitsCounter';
import Checkboxes from 'commons/SidebarV2/Components/Checkboxes';
import Dates from 'commons/SidebarV2/Components/Dates';
import Input from 'commons/SidebarV2/Components/Input';
import { KeyBucketDTO } from 'api/commonTypes';
import services from 'commons/services';
import MultiSearch from 'commons/SidebarV2/Components/MultiSearch';
import useWatcher from 'commons/hooks/useWatcher';

import { getStatusesFilterOptions, initialFilters } from './utils';

const { _t, _tg } = window.loadTranslations(__filename);

type CcspFiltersProps = {
  totalHits: number;
  facetings:
    | {
        [key: string]: Array<KeyBucketDTO>;
      }
    | null
    | undefined;
};

const CcspFilters = (props: CcspFiltersProps): JSX.Element => {
  const filters = useWatcher(
    services.ccspList.watchFilters,
    services.ccspList.getFilters()
  );

  const onChange = (id: string, value: any) => {
    void services.ccspList.updateFilters({
      ...filters,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      [id]: value,
    });
  };

  const resetFilters = () => services.ccspList.updateFilters(initialFilters());

  const { totalHits, facetings } = props;
  return (
    <Sidebar>
      <BarTitle resetFilters={resetFilters} />
      <HitsCounter hits={totalHits} />
      <Checkboxes
        id="statuses"
        title={_t('element.ccspStatus')}
        options={getStatusesFilterOptions()}
        filters={filters.statuses}
        onChange={onChange}
        faceting={facetings ? facetings.statuses : null}
      />
      <Dates
        id="openingDate"
        title={_t('element.openingDate')}
        dates={filters.openingDate}
        onChange={onChange}
      />
      <Dates
        id="responseLimitDate"
        title={_t('element.deadline')}
        dates={filters.responseLimitDate}
        onChange={onChange}
      />
      <MultiSearch
        id="ccspNumbers"
        title={_tg('field.ccsp.ccspNumber')}
        placeholder={_t('element.search.byCcspNumber')}
        modalTitle={_t('element.search.modalTitle')}
        modalFloatingLabelText={_t('element.search.modalFloatingLabelText')}
        onChange={onChange}
        value={filters.ccspNumbers}
      />
      <Input
        id="fpsOrFpsmId"
        title={_t('element.fpsOrFpsmNumber')}
        placeholder={_t('element.search.byFpsNumber')}
        onChange={onChange}
        value={filters.fpsOrFpsmId || ''}
      />
      <Input
        id="userLastName"
        title={_t('element.userLastName')}
        placeholder={_t('element.search.userLastName')}
        onChange={onChange}
        value={filters.userLastName || ''}
      />
      <Input
        id="userFirstName"
        title={_t('element.userFirstName')}
        placeholder={_t('element.search.userFirstName')}
        onChange={onChange}
        value={filters.userFirstName || ''}
      />
    </Sidebar>
  );
};

export default CcspFilters;
