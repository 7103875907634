export const STATEMENT_DATETIME = 'statementDatetime';
export const ZONE = 'zoneId';

export const LICENSE_PLATE = 'plate';
export const PLATE_COUNTRY = 'plateCountry';

export const LOCATION_LATITUDE = 'latitude';
export const LOCATION_LONGITUDE = 'longitude';
export const DEVICE = 'deviceServiceChannel.deviceType';
export const DRY = 'dryRun';
export const AGENT = 'agentID';
export const NUMBER_OF_CONTROL = 'numberOfControl';

export type FieldNames =
  | typeof STATEMENT_DATETIME
  | typeof LICENSE_PLATE
  | typeof PLATE_COUNTRY
  | typeof ZONE
  | typeof DEVICE
  | typeof DRY
  | typeof AGENT
  | typeof NUMBER_OF_CONTROL;
