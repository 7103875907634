export const LAST_NAME = 'lastName';
export const FIRST_NAME = 'firstName';
export const COMPANY_NAME = 'companyName';
export const ACTIVITY = 'activity';
export const GENDER = 'gender';
export const DEFAULT_FRONT_TYPE = 'defaultFrontType';

export const EMAIL = 'email';
export const PENDING_EMAIL = 'pending_email';

export const COMMENT = 'comment';
export const PHONE_NUMBER = 'phoneNumber';
export const HOME_PHONE_NUMBER = 'homePhoneNumber';
export const SHOULD_RECEIVE_SMS = 'shouldReceiveSms';
export const SHOULD_COMPUTE_ZONE = 'shouldComputeZone';
export const ZONE_ID = 'zoneId';

const ADDRESS = 'address.';
export const ADDRESS_STREET_NAME = `${ADDRESS}streetName`;
export const ADDRESS_STREET_NUMBER = `${ADDRESS}streetNumber`;
export const ADDRESS_STREET_NUMBER_BIS = `${ADDRESS}streetNumberBis`;
export const ADDRESS_STREET_TYPE = `${ADDRESS}streetType`;
export const ADDRESS_POSTAL_CODE = `${ADDRESS}postalCode`;
export const ADDRESS_COMPLEMENT = `${ADDRESS}complement`;
export const ADDRESS_LOCALITY = `${ADDRESS}locality`;

export type FieldNames =
  | typeof LAST_NAME
  | typeof FIRST_NAME
  | typeof COMPANY_NAME
  | typeof ACTIVITY
  | typeof PHONE_NUMBER
  | typeof HOME_PHONE_NUMBER
  | typeof EMAIL
  | typeof ADDRESS_STREET_NAME
  | typeof ADDRESS_STREET_NUMBER
  | typeof ADDRESS_STREET_NUMBER_BIS
  | typeof ADDRESS_STREET_TYPE
  | typeof ADDRESS_POSTAL_CODE
  | typeof ADDRESS_COMPLEMENT
  | typeof ADDRESS_LOCALITY
  | typeof SHOULD_RECEIVE_SMS
  | typeof SHOULD_COMPUTE_ZONE
  | typeof ZONE_ID
  | typeof GENDER
  | typeof COMMENT
  | typeof DEFAULT_FRONT_TYPE;
