import * as React from 'react';

import { DataBox, DataBoxContent, DataBoxHeader } from 'commons/DataBox';

import { TimelineItem } from '../helpers';

type Props = {
  style?: Record<string, any>;
  item: TimelineItem;
};

const Box = ({ style, item }: Props) => {
  const ContentElement = item.contentElement;

  return (
    <DataBox style={style}>
      <DataBoxHeader color={item.title.color}>
        {item.title && item.title.left}
      </DataBoxHeader>
      <DataBoxContent>
        <ContentElement {...item.contentProps} />
      </DataBoxContent>
    </DataBox>
  );
};

export default Box;
