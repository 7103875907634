import React, { CSSProperties } from 'react';

const { Trans } = window.loadTranslations(__filename);

const LINK_STYLE: CSSProperties = {
  cursor: 'pointer',
  textDecoration: 'underline',
};

const TransferSubscriptionActionV0 = ({ action, openDetailModal }: any) => {
  const showSubscriber = () => {
    openDetailModal(action.fromSubscriberId);
  };

  return (
    <span>
      <Trans
        i18nKey="element.content"
        components={[<span onClick={showSubscriber} style={LINK_STYLE} />]}
        tOptions={{
          planName: action.planName,
          plate: action.plate,
        }}
      />
    </span>
  );
};

export default TransferSubscriptionActionV0;
