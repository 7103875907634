import React, { CSSProperties } from 'react';

import FpsNumber from 'commons/FpsNumber';
import { FpsOverviewDTO } from 'api/fps/types';
import { BKG_PINK } from 'theme';

const STYLE_LINK: CSSProperties = {
  height: 50,
  width: '100%',
};

const STYLE_HOVERED: CSSProperties = {
  ...STYLE_LINK,
  color: 'white',
};

type Props = {
  fps: FpsOverviewDTO;
};

type State = {
  hovered: boolean;
};

function getStyle(id: string, hovered: boolean | null | undefined) {
  return hovered ? STYLE_HOVERED : STYLE_LINK;
}

const determineStyle = (tags: Array<string> | null | undefined) => {
  if (!tags) return null;
  if (tags.includes('TO_DELETE')) {
    return { color: BKG_PINK };
  }
  return null;
};

class FpsLink extends React.Component<Props, State> {
  state: State = {
    hovered: false,
  };

  handleHover = () => this.setState({ hovered: !this.state.hovered });

  render() {
    const { fps } = this.props;
    const { hovered } = this.state;

    return (
      <a
        data-fps-id={fps.id}
        style={{
          ...getStyle(fps.id, hovered),
          ...determineStyle(fps.tags),
        }}
        onMouseLeave={this.handleHover}
        onMouseEnter={this.handleHover}
        href={`#/fps/detail/${fps.lastVersionId}`}
      >
        <FpsNumber fpsId={fps.lastVersionId} />
      </a>
    );
  }
}

export default FpsLink;
