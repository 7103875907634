import * as React from 'react';

import UserNotificationDialog from 'tefps/Beneficiaries/commons/UserNotificationDialog';

const { _t } = window.loadTranslations(__filename);

const EditPlateActionV2 = ({ action }: any) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span>
        {_t('element.content', {
          planName: action.planName,
          fromPlates: action.fromPlates.join(', '),
          toPlates: action.toPlates.join(', '),
        })}
      </span>
      <UserNotificationDialog action={action} />
    </div>
  );
};

export default EditPlateActionV2;
