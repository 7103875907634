import React from 'react';
import './ErrorPage.css';

import { Flex } from '@cvfm-front/commons-ui';
import Content from 'commons/Content';
import ErrorBlock from 'commons/ErrorBlock';

const { _tg } = window.loadTranslations();

type ErrorPageProps = {
  message?: string;
  error: Error;
  navContent: JSX.Element;
};

const ErrorPage = ({
  message,
  error,
  navContent,
}: ErrorPageProps): JSX.Element => {
  return (
    <Content>
      {/* Take the whole page */}
      <Flex height100 flexDirection="column">
        <div className="navigation-bar">{navContent}</div>
        <div className="error-container">
          <ErrorBlock
            message={message || _tg('feedback.error.generic')}
            error={error}
          />
        </div>
      </Flex>
    </Content>
  );
};

export default ErrorPage;
