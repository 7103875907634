import React, { CSSProperties, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { History } from 'history';
import CircularProgress from 'material-ui/CircularProgress';

import { createCcspRecourse, getExport } from 'api/ccsp';
import { CcspCreationDTO } from 'api/ccsp/types';
import { getApiState } from 'api/duck';
import Exporter from 'commons/Exporter';
import AdvertisingModal from 'commons/AdvertisingModal';
import FlexCenter from 'commons/FlexCenter';
import Content from 'commons/Content';
import ErrorBlock from 'commons/ErrorBlock';
import { ListBody, ListBottom, ListWrapper } from 'commons/ListWrappers';
import services from 'commons/services';
import useWatcher from 'commons/hooks/useWatcher';
import { DEFAULT_PAGER } from 'commons/services/CcspListService';

import CcspRecourseTable from './CcspRecourseTable';
import { EXPORT_COL, filtersToRequest } from './utils';
import AddCcsp from './AddCcsp';
import CcspFilters from './CcspFilters';

const { _tg } = window.loadTranslations();

const STYLE_CONTENT: CSSProperties = {
  display: 'flex',
  height: '100%',
  overflowY: 'hidden',
};

const STYLE_ADD_CCSP: CSSProperties = {
  marginLeft: 20,
};

type Props = {
  canExport: boolean;
  canCreate: boolean;
  history: History; // we can access history thanks to the <Route /> component in ../index
};

const CcspRecourseLists = (props: Props): JSX.Element => {
  const { canCreate, canExport, history } = props;
  const recourseSearchResult = useWatcher(
    services.ccspList.watchCcspRecourses,
    services.ccspList.getCcspRecourses()
  );
  const error = useWatcher(
    services.ccspList.watchError,
    services.ccspList.getError()
  );
  const filters = useWatcher(
    services.ccspList.watchFilters,
    services.ccspList.getFilters()
  );
  const sortParameter = useWatcher(
    services.ccspList.watchSortParameter,
    services.ccspList.getSortParameter()
  );
  const isLoading = useWatcher(
    services.ccspList.watchLoading,
    services.ccspList.isLoading()
  );

  const [errorCreate, setErrorCreate] = useState<
    { message: string; json?: { message: string } } | null | undefined
  >(null);

  const createNewCcsp = async (
    ccspNumber: string,
    creationDto: CcspCreationDTO
  ) => {
    try {
      const recourse = await createCcspRecourse(ccspNumber, creationDto);
      history.push(`/tsp/detail/${recourse.ccspNumber}`);
    } catch (err) {
      setErrorCreate(err);
    }
  };
  const clearErrorCreate = () => setErrorCreate(null);

  useEffect(() => {
    void services.ccspList.init();
  }, []);

  const items = recourseSearchResult ? recourseSearchResult.ccspRecourses : [];
  const totalHits = recourseSearchResult ? recourseSearchResult.totalHits : 0;

  if (error) {
    return (
      <Content>
        <FlexCenter>
          <ErrorBlock
            message={_tg('feedback.error.fetchCcsp_plural')}
            error={error}
          />
        </FlexCenter>
      </Content>
    );
  }

  return (
    <div style={STYLE_CONTENT}>
      <AdvertisingModal module="ccsp" />
      <CcspFilters
        totalHits={totalHits}
        facetings={(recourseSearchResult || {}).checkboxFaceting}
      />
      <ListWrapper>
        <ListBody loading={!recourseSearchResult}>
          <CcspRecourseTable
            items={items}
            onUpdateSort={services.ccspList.updateSortParameter}
            loadMoreRows={services.ccspList.loadMoreRows}
            remoteRowCount={
              recourseSearchResult ? recourseSearchResult.totalHits : 0
            }
            colSorted={sortParameter.sortField}
            sortOrder={sortParameter.increasingOrder}
          />
        </ListBody>
        <ListBottom style={{ position: 'relative' }}>
          {canExport && (
            <Exporter
              disabled={totalHits === 0}
              columns={EXPORT_COL}
              type="CCSP"
              filters={filtersToRequest(filters, DEFAULT_PAGER, sortParameter)}
              fileExport={getExport}
            />
          )}
          {canCreate && (
            <div style={STYLE_ADD_CCSP}>
              <AddCcsp
                createNewCcsp={createNewCcsp}
                error={
                  errorCreate && errorCreate.json && errorCreate.json.message
                }
                clearError={clearErrorCreate}
              />
            </div>
          )}
          {isLoading && (
            <div style={{ position: 'absolute', left: '50%' }}>
              <CircularProgress />
            </div>
          )}
        </ListBottom>
      </ListWrapper>
    </div>
  );
};

export default connect(state => {
  const { userInfo } = getApiState(state);
  return {
    canExport: userInfo && userInfo.rights.includes('BACKOFFICE_CCSP_EXPORT'),
    canCreate: userInfo && userInfo.rights.includes('CCSP_CREATE'),
  };
})(CcspRecourseLists);
