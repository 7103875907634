import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';

import { InternalAgent } from 'api/auth/types';
import { reprojectControl } from 'api/control';
import { ControlDTO } from 'api/control/types';
import { getApiState } from 'api/duck';
import BoButton from 'facade/BoButton';
import useSnackbar from 'commons/CustomHooks/SnackBar/useSnackBar';
import services from 'commons/services';

const { _tg } = window.loadTranslations(__filename);

interface ReprojectControlButtonProps {
  control: ControlDTO;
  userInfo: InternalAgent | null | undefined;
}

const ReprojectControlButton = ({
  userInfo,
  control,
}: ReprojectControlButtonProps) => {
  const [pending, setPending] = useState<boolean>(false);
  const setMessage = useSnackbar();

  const reprojectSingleControl = useCallback(async () => {
    setPending(true);
    try {
      const result = await reprojectControl(control.controlId);
      setMessage(
        result ? _tg('field.operationSuccessful') : _tg('field.operationError')
      );
    } catch (e) {
      setMessage(_tg('field.operationError'));
    }
    await services.control.init(control.controlId);
    setPending(false);
  }, [control]);

  return (
    <>
      {userInfo?.admin && (
        <BoButton
          label={_tg('action.reproject')}
          onClick={reprojectSingleControl}
          backgroundColor="var(--bg-color--dark-blue)"
          labelColor="var(--white)"
          disabled={pending}
        />
      )}
    </>
  );
};

export default connect(state => {
  const { userInfo } = getApiState(state);
  return {
    userInfo,
  };
})(ReprojectControlButton);
