import React, { CSSProperties, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Toggle } from 'material-ui';

import {
  CoreAddress,
  Gender,
  SubscriberDTO,
  ZoneDTO,
} from '@cvfm-front/tefps-types';
import { BKG_PINK, STYLE_INPUTS } from 'theme';
import SeparatorWithTitle from 'commons/SeparatorWithTitle';
import TextFieldCustom from 'commons/FormComponent/Fields/TextFieldCustom';
import SelectFieldCustom from 'commons/FormComponent/Fields/SelectFieldCustom';
import CheckboxCustom from 'commons/FormComponent/Fields/CheckboxCustom';
import { getConfigState } from 'config/duck';
import AddressAutocomplete from 'commons/AddressAutocomplete';
import { LocalizedAddressDTO } from 'api/searchingCompletion/types';
import { SimpleAddress } from 'api/commonTypes';
import { coreAddressToAddress } from '@cvfm-front/tefps-types/build/address/CoreAddress';
import { fieldKeyAddress } from 'commons/types/fieldKeyAddress';
import services from 'commons/services';

import { frontTypes } from '../../utils';

import { isEmailValid } from './validators';
import {
  ACTIVITY,
  COMMENT,
  COMPANY_NAME,
  DEFAULT_FRONT_TYPE,
  EMAIL,
  PENDING_EMAIL,
  FIRST_NAME,
  GENDER,
  LAST_NAME,
  PHONE_NUMBER,
  HOME_PHONE_NUMBER,
  SHOULD_RECEIVE_SMS,
  ZONE_ID,
} from './fieldNames';
import DissociateFranceConnect from './DissociateFranceConnect';

const { _t, _tg } = window.loadTranslations(__filename);

const STYLE_INPUTS_CONTAINER: CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  marginLeft: -20,
  flexWrap: 'wrap',
};

const STYLE_FIELD: CSSProperties = {
  minWidth: 200,
  flexGrow: 1,
  flexBasis: '45%',
  marginLeft: 20,
};

const STYLE_ADDRESS_TITLE: CSSProperties = {
  height: '100%',
  width: '100%',
  marginTop: 60,
};

type ReduxProps = {
  smsEnabled: boolean;
  hasPmr: boolean;
  adV3Enabled: boolean;
};

type Props = {
  subscriber?: SubscriberDTO;
  zones: Array<ZoneDTO>;
};

const SubscriberInformation = (props: ReduxProps & Props) => {
  const { smsEnabled, zones, subscriber, hasPmr, adV3Enabled } = props;
  const [address, setAddress] = React.useState<CoreAddress | null | undefined>(
    adV3Enabled
      ? subscriber?.personalProfile?.location.address
      : subscriber?.address
  );
  const [isCompany, setIsCompany] = useState<boolean>(
    subscriber?.gender === 'ORGA'
  );
  const [professionalAddress, setProfessionalAddress] = React.useState<
    CoreAddress | null | undefined | null | undefined
  >(
    adV3Enabled
      ? subscriber?.professionalProfile?.location.address
      : subscriber?.professionalAddress
  );

  const [zoneId, setZoneId] = useState<string | null | undefined>(
    subscriber?.zoneId
  );
  const [professionalZoneId, setProfessionalZoneId] = useState<
    string | null | undefined
  >(subscriber?.professionalZoneId);

  const [isCalculZoneEnabled, setIsCalculZoneEnabled] = useState<boolean>(true);
  const [
    isCalculProfessionalZoneEnabled,
    setIsCalculProfessionalZoneEnabled,
  ] = useState<boolean>(true);

  const onSelectGender = (gender: Gender) => {
    setIsCompany(gender === 'ORGA');
  };

  const getOptions = () => [
    { id: 'MALE', name: 'Monsieur' },
    { id: 'FEMALE', name: 'Madame' },
    { id: 'ORGA', name: 'Société' },
  ];

  const onAutocompleteSubscriberAddress = (
    newAddress: LocalizedAddressDTO
  ): void => {
    const newSimpleAddress: SimpleAddress = {
      streetName: newAddress.address.streetName,
      streetNumber: newAddress.address.streetNumber,
      streetNumberBis: newAddress.address.streetNumberBis,
      streetType: newAddress.address.streetType,
      postalCode: newAddress.address.postalCode,
      complement: newAddress.address.specialPlace,
      locality: newAddress.address.addressLocality,
    };
    if (!isCalculZoneEnabled) {
      setZoneId(null);
    }
    setAddress(newSimpleAddress);
  };

  const onAutocompleteSubscriberProfessionalAddress = (
    newAddress: LocalizedAddressDTO
  ): void => {
    const newSimpleAddress: SimpleAddress = {
      streetName: newAddress.address.streetName,
      streetNumber: newAddress.address.streetNumber,
      streetNumberBis: newAddress.address.streetNumberBis,
      streetType: newAddress.address.streetType,
      postalCode: newAddress.address.postalCode,
      complement: newAddress.address.specialPlace,
      locality: newAddress.address.addressLocality,
    };
    if (!isCalculProfessionalZoneEnabled) {
      setProfessionalZoneId(null);
    }

    setProfessionalAddress(newSimpleAddress);
  };

  const toggleCalculation = (
    setter: React.Dispatch<React.SetStateAction<boolean>>
  ): void => {
    setter((prev: boolean) => !prev);
  };

  const fetchZoneId = async (
    addr: CoreAddress | null | undefined,
    setZone: React.Dispatch<React.SetStateAction<string | null | undefined>>
  ) => {
    const zoneId = await services.zoning.getZoneByAddress(addr);
    setZone(zoneId);
  };

  useEffect(() => {
    if (isCalculZoneEnabled) fetchZoneId(address, setZoneId);
  }, [address, isCalculZoneEnabled]);

  useEffect(() => {
    if (isCalculProfessionalZoneEnabled)
      fetchZoneId(professionalAddress, setProfessionalZoneId);
  }, [professionalAddress, isCalculProfessionalZoneEnabled]);

  return (
    <div>
      <SeparatorWithTitle
        style={STYLE_ADDRESS_TITLE}
        title={_t('element.title')}
        color={BKG_PINK}
        titleSize={20}
      />
      <div style={STYLE_INPUTS_CONTAINER}>
        <SelectFieldCustom
          name={GENDER}
          hint={_tg('field.human.civility')}
          style={STYLE_FIELD}
          value={(subscriber && subscriber.gender) || ''}
          onChange={onSelectGender}
          items={getOptions()}
          mandatory
        />
      </div>
      <div style={STYLE_INPUTS_CONTAINER}>
        <TextFieldCustom
          name={LAST_NAME}
          hint={_tg('field.human.lastname_short')}
          style={STYLE_FIELD}
          value={subscriber && subscriber.lastName}
          mandatory={!isCompany}
        />
        <TextFieldCustom
          name={FIRST_NAME}
          hint={_tg('field.human.firstname')}
          style={STYLE_FIELD}
          value={subscriber && subscriber.firstName}
        />
      </div>
      <div style={STYLE_INPUTS_CONTAINER}>
        <TextFieldCustom
          name={COMPANY_NAME}
          hint={_tg('field.company.name')}
          style={STYLE_FIELD}
          mandatory={isCompany}
          value={subscriber && subscriber.companyName}
        />
        <TextFieldCustom
          name={ACTIVITY}
          hint={_tg('field.company.activity')}
          style={STYLE_FIELD}
          value={subscriber && subscriber.activity}
        />
      </div>
      <div style={STYLE_INPUTS_CONTAINER}>
        <TextFieldCustom
          name={EMAIL}
          hint={_tg('field.human.email')}
          style={STYLE_FIELD}
          validators={[isEmailValid]}
          value={subscriber && subscriber.email}
        />
        {subscriber?.subscriberPendingEmail &&
          new Date(subscriber.subscriberPendingEmail.expiryDate) >
            new Date() && (
            <TextFieldCustom
              name={PENDING_EMAIL}
              hint={_tg('field.human.pending_email')}
              disabled
              style={STYLE_FIELD}
              value={subscriber.subscriberPendingEmail.pendingEmail}
            />
          )}
      </div>
      <div style={STYLE_INPUTS_CONTAINER}>
        <TextFieldCustom
          name={PHONE_NUMBER}
          hint={_tg('field.human.phone')}
          style={STYLE_FIELD}
          value={subscriber && subscriber.phoneNumber}
        />
        <TextFieldCustom
          name={HOME_PHONE_NUMBER}
          hint={_tg('field.human.homePhone')}
          style={STYLE_FIELD}
          value={subscriber && subscriber.homePhoneNumber}
        />
      </div>
      <div style={STYLE_INPUTS_CONTAINER}>
        <TextFieldCustom
          hint={_tg('field.comment')}
          multiLine
          name={COMMENT}
          rows={4}
          style={STYLE_FIELD}
          value={subscriber && subscriber.comment}
        />
      </div>
      {hasPmr && (
        <div style={STYLE_INPUTS_CONTAINER}>
          <SelectFieldCustom
            name={DEFAULT_FRONT_TYPE}
            hint={_tg('field.defaultFrontType')}
            style={STYLE_FIELD}
            value={subscriber?.defaultFrontType || 'SUBS'}
            items={frontTypes}
            mandatory
          />
        </div>
      )}
      {smsEnabled && (
        <div style={STYLE_INPUTS_CONTAINER}>
          <CheckboxCustom
            name={SHOULD_RECEIVE_SMS}
            style={STYLE_FIELD}
            value={subscriber && subscriber.shouldReceiveSms}
            label={_t('element.receiveSmsCheckbox.label')}
          />
        </div>
      )}
      <DissociateFranceConnect
        openId={subscriber ? subscriber.openId : null}
        subscriberId={subscriber ? subscriber.subscriberId : null}
      />

      <SeparatorWithTitle
        style={STYLE_ADDRESS_TITLE}
        title={_t('element.userAddress.title')}
        color={BKG_PINK}
        titleSize={20}
      />
      <AddressAutocomplete
        field="address"
        fieldKeys={fieldKeyAddress}
        mandatoryAddress={false}
        isForeignAddressAllow={false}
        styleInputs={STYLE_INPUTS}
        address={
          adV3Enabled
            ? coreAddressToAddress(
                subscriber?.personalProfile?.location.address
              )
            : coreAddressToAddress(subscriber?.address)
        }
        onAutoCompleteCallback={onAutocompleteSubscriberAddress}
      />
      {zones && (
        <div key="address" style={STYLE_INPUTS_CONTAINER}>
          <SelectFieldCustom
            name={ZONE_ID}
            hint={_t('element.residenceArea.hint')}
            style={STYLE_FIELD}
            value={zoneId}
            items={[...zones]}
            onChange={() => setIsCalculZoneEnabled(false)}
          />
        </div>
      )}
      <div key="zone">
        <Toggle
          id="CalculZoneResidence-enable"
          labelPosition="left"
          label={_t('element.computeResidenceArea.label')}
          toggled={isCalculZoneEnabled}
          color="primary"
          onToggle={() => toggleCalculation(setIsCalculZoneEnabled)}
        />
      </div>
      <>
        <SeparatorWithTitle
          style={STYLE_ADDRESS_TITLE}
          title={_tg('field.address.professionalAddress')}
          color={BKG_PINK}
          titleSize={20}
        />
        <AddressAutocomplete
          field="professionalAddress"
          fieldKeys={fieldKeyAddress}
          mandatoryAddress={false}
          isForeignAddressAllow={false}
          styleInputs={STYLE_INPUTS}
          address={
            adV3Enabled
              ? coreAddressToAddress(
                  subscriber?.professionalProfile?.location.address
                )
              : coreAddressToAddress(subscriber?.professionalAddress)
          }
          onAutoCompleteCallback={onAutocompleteSubscriberProfessionalAddress}
        />
        {zones && (
          <div key="professionalAddress" style={STYLE_INPUTS_CONTAINER}>
            <SelectFieldCustom
              name="professionalZoneId"
              hint={_t('element.residenceArea.hint')}
              style={STYLE_FIELD}
              value={professionalZoneId}
              items={[...zones]}
              onChange={() => setIsCalculProfessionalZoneEnabled(false)}
            />
          </div>
        )}
        <div key="zone">
          <Toggle
            id="CalculProfessionalZoneResidence-enable"
            labelPosition="left"
            label={_t('element.computeResidenceArea.labelProfessional')}
            toggled={isCalculProfessionalZoneEnabled}
            color="primary"
            onToggle={() =>
              toggleCalculation(setIsCalculProfessionalZoneEnabled)
            }
          />
        </div>
      </>
    </div>
  );
};

export default connect<ReduxProps, unknown, Props>(
  (state: any): ReduxProps => {
    const config = getConfigState(state);

    return {
      smsEnabled: config.smsEnabled,
      hasPmr: !!config.frontPmrUrl,
      adV3Enabled: config.subscriptionConfigurationDTO.adV3Enabled,
    };
  }
)(SubscriberInformation);
