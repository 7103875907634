import * as React from 'react';
import { connect } from 'react-redux';
import CircularProgress from 'material-ui/CircularProgress';
import FlatButton from 'material-ui/FlatButton';

import { InternalAgent } from 'api/auth/types';
import { FpsCaseDTO } from 'api/fps/types';
import Content from 'commons/Content';
import ErrorBlock from 'commons/ErrorBlock';
import FlexCenter from 'commons/FlexCenter';
import { FETCH_LIGHT_ZONING_CONFIG } from 'commons/FetchZoningConfigs';
import { fetchFps, getFpsCasePdfUrl } from 'api/fps';
import { getApiState } from 'api/duck';
import { getConfigState } from 'config/duck';
import { fetchZoning } from 'api/pricing';
import { openNewAuthentifiedTab } from 'api/helpers';

import FpsPage from './FpsPage';
import { buildTimelineItems, TimelineItem } from './helpers';

type Props = {
  userInfo: InternalAgent;
  securityPeriodForRecordingPayment: number;
  cvfmRapoPartner: boolean;
  noticeDownloadEnabled: boolean;
  apaRapoDownloadEnabled: boolean;
  fpsConsistencyEnabled: boolean;
  moduleRapoEnabled: boolean;
  match: {
    params: {
      fpsId: string;
    };
  };
};

type State = {
  error: Error | null | undefined;
  fps: FpsCaseDTO | null | undefined;
  items: Array<TimelineItem>;
};

class FpsDetail extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      fps: null,
      error: null,
      items: [],
    };
  }

  componentDidMount() {
    this.fetchFps(this.props.match.params.fpsId);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    this.fetchFps(nextProps.match.params.fpsId);
  }

  reloadFps = () => this.fetchFps(this.props.match.params.fpsId);

  fetchFps = async (fpsId: string) => {
    const {
      cvfmRapoPartner,
      noticeDownloadEnabled,
      apaRapoDownloadEnabled,
      fpsConsistencyEnabled,
    } = this.props;

    this.setState({ fps: null, error: null });
    try {
      const fps = await fetchFps(fpsId);
      const zoning = await fetchZoning(FETCH_LIGHT_ZONING_CONFIG);
      const { moduleRapoEnabled } = this.props;
      this.setState({
        fps,
        items: buildTimelineItems(
          fps,
          cvfmRapoPartner,
          noticeDownloadEnabled,
          apaRapoDownloadEnabled,
          fpsConsistencyEnabled,
          this.reloadFps,
          zoning,
          moduleRapoEnabled
        ),
      });
    } catch (error) {
      this.setState({ error });
    }
  };

  openFpsCasePdf = (withPictures: boolean) => {
    const { fps } = this.state;
    // Guard
    if (!fps) return;
    openNewAuthentifiedTab(getFpsCasePdfUrl(fps.versions[0].id, withPictures));
  };

  render() {
    const { userInfo, securityPeriodForRecordingPayment } = this.props;
    const { fps, error, items } = this.state;

    if (error) {
      return (
        <Content>
          <div style={{ padding: '30px 0px 5px 70px' }}>
            <FlatButton href="#/fps/list" label="Retour à la liste de FPS" />
          </div>
          <FlexCenter>
            <ErrorBlock
              message="Erreur lors de la récupération du FPS"
              error={error}
            />
          </FlexCenter>
        </Content>
      );
    }

    if (!fps) {
      return (
        <Content>
          <FlatButton href="#/fps/list" label="Retour à la liste de FPS" />
          <FlexCenter>
            <CircularProgress />
          </FlexCenter>
        </Content>
      );
    }

    return (
      <FpsPage
        fps={fps}
        userInfo={userInfo}
        items={items}
        securityPeriodForRecordingPayment={securityPeriodForRecordingPayment}
        reloadFps={this.reloadFps}
        onExport={this.openFpsCasePdf}
      />
    );
  }
}

export default connect(state => {
  const { userInfo } = getApiState(state);
  const {
    cvfmRapoPartner,
    securityPeriodForRecordingPayment,
    noticeDownloadEnabled,
    apaRapoDownloadEnabled,
    sivConfiguration,
    modulesConfiguration,
  } = getConfigState(state);
  return {
    userInfo,
    securityPeriodForRecordingPayment,
    cvfmRapoPartner,
    noticeDownloadEnabled,
    apaRapoDownloadEnabled,
    fpsConsistencyEnabled: !!sivConfiguration?.inconsistentFpsDelay,
    moduleRapoEnabled: modulesConfiguration.rapo.enabled,
  };
})(FpsDetail);
