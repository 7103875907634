import React, { useState } from 'react';
import { connect } from 'react-redux';

import {
  DataBox,
  DataBoxContent,
  DataBoxHeader,
  DataBoxItem,
  DataBoxItemWrapper,
} from 'commons/DataBox';
import { Flex } from '@cvfm-front/commons-ui';
import { BKG_CYAN } from 'theme';
import { FpsCaseDTO } from 'api/fps/types';
import { FpsVehicleConsistencyStatus } from '@cvfm-front/tefps-types';
import FakeInputBlock from 'commons/FakeInputBlock';
import Separator from 'commons/Separator';
import BoButton from 'facade/BoButton';
import { updateFpsConsistencyStatus } from 'api/fps';
import { getConfigState } from 'config/duck';

import FpsConsistencyConfirmationModal from './FpsConsistencyConfirmationModal';

const { _tg } = window.loadTranslations(__filename);

type Props = {
  fps: FpsCaseDTO;
  canUpdateFpsConsistency: boolean;
  reloadFps: () => any;
  fpsConsistencyEnabled: boolean;
};

const FpsInconsistentBlock = ({
  fps,
  canUpdateFpsConsistency,
  reloadFps,
  fpsConsistencyEnabled,
}: Props): JSX.Element => {
  if (
    !fpsConsistencyEnabled ||
    fps.vehicleConsistency.status !== 'INCONSISTENT_AWAIT_REVIEW'
  ) {
    return <></>;
  }

  const [open, setOpen] = useState<boolean>(false);
  const [confirmation, setConfirmation] = useState<boolean>(false);

  const update = (status: FpsVehicleConsistencyStatus) => {
    if (!open) {
      setOpen(true);
    } else {
      void updateFpsConsistencyStatus(fps.versions[0].id, status).then(() =>
        reloadFps()
      );
      setOpen(false);
    }
  };
  const onConfirm = () => {
    setConfirmation(true);
    update('INCONSISTENT');
  };
  const onCancel = () => {
    setConfirmation(false);
    update('CONSISTENT');
  };

  const onClose = () => {
    setOpen(false);
  };
  const onSubmit = () => {
    if (confirmation) {
      onConfirm();
    } else {
      onCancel();
    }
  };

  const computeTitle = () => {
    return _tg('tefps.fps.detail.fpsConsistencyConfirmationModal.validBrand', {
      brand: confirmation ? fps.sivVehicleBrand : fps.vehicle.brand,
    });
  };

  return (
    <DataBox panel style={{ width: '95%', marginTop: 10 }}>
      <DataBoxHeader color={BKG_CYAN}>
        {_tg('tefps.fps.detail.fpsInconsistentBlock.title')}
      </DataBoxHeader>
      <DataBoxContent>
        <DataBoxItemWrapper style={{ marginBottom: 20, width: '90%' }}>
          <DataBoxItem style={{ maxWidth: '100%' }}>
            <FakeInputBlock
              title={_tg(
                'tefps.fps.detail.fpsInconsistentBlock.fpsVehicleBrand'
              )}
              value={fps.vehicle.brand}
            />
          </DataBoxItem>
          <Separator />
          <DataBoxItem style={{ maxWidth: '100%' }}>
            <FakeInputBlock
              title={_tg(
                'tefps.fps.detail.fpsInconsistentBlock.sivVehicleBrand'
              )}
              value={fps.sivVehicleBrand || ''}
            />
          </DataBoxItem>
        </DataBoxItemWrapper>
        <Flex gap={35}>
          <BoButton
            label={_tg(
              'tefps.fps.detail.fpsInconsistentBlock.ignoreCorrection'
            )}
            key="order-decision-cancel"
            style={{ marginRight: 10 }}
            disabled={!canUpdateFpsConsistency}
            onClick={onCancel}
          />
          <BoButton
            label={_tg(
              'tefps.fps.detail.fpsInconsistentBlock.acceptCorrection'
            )}
            key="orer-decision-confirm"
            primary
            style={{ marginRight: 10 }}
            disabled={!canUpdateFpsConsistency}
            onClick={onConfirm}
          />
        </Flex>
        <FpsConsistencyConfirmationModal
          isOpen={open}
          onSubmit={onSubmit}
          onClose={onClose}
          title={computeTitle()}
        />
      </DataBoxContent>
    </DataBox>
  );
};

export default connect(state => {
  const { sivConfiguration } = getConfigState(state);
  return {
    fpsConsistencyEnabled: !!sivConfiguration?.inconsistentFpsDelay,
  };
})(FpsInconsistentBlock);
