import React, { CSSProperties } from 'react';

import { FpsCommentDTO } from 'api/fps/types';
import DataBoxComment from 'commons/DataBox/DataBoxComment';
import Date from 'commons/Date';
import { BRD_GREY } from 'theme';

const STYLE_COMMENT_WRAPPER: CSSProperties = {
  padding: 20,
  borderBottom: `1px solid ${BRD_GREY}`,
};

const STYLE_COMMENT_AUTHOR: CSSProperties = {
  fontWeight: 'bold',
  fontSize: 14,
  marginBottom: 5,
};

const STYLE_COMMENT: CSSProperties = {
  fontSize: 12,
  fontWeight: 'normal',
};

const STYLE_DATE: CSSProperties = {
  ...STYLE_COMMENT,
  marginLeft: 10,
};

type Props = {
  comment: string | null | undefined;
  fpsComments?: Array<FpsCommentDTO>;
};

const Comments = ({ comment, fpsComments }: Props) => {
  // TODO pour l'instant, seuls les commentaires avec du texte sont affichés, plus tard les changements de tags seront présents
  const fpsCommentsWithText = fpsComments
    ? fpsComments.filter(c => c.text && c.text !== '')
    : null;
  if (fpsCommentsWithText && fpsCommentsWithText.length > 0) {
    return (
      <DataBoxComment
        style={{ flex: 10, marginBottom: 60 }}
        title="Commentaires"
      >
        {fpsCommentsWithText.map(c => (
          <div style={STYLE_COMMENT_WRAPPER}>
            <div style={STYLE_COMMENT_AUTHOR}>
              {c.agent.name}
              <span style={STYLE_DATE}>
                <Date datetime={c.dateCreated} />
              </span>
            </div>
            <div style={STYLE_COMMENT}>{c.text}</div>
          </div>
        ))}
      </DataBoxComment>
    );
  }

  if (comment) {
    return (
      <DataBoxComment style={{ flex: 10, marginBottom: 60 }}>
        {comment}
      </DataBoxComment>
    );
  }

  // Placeholder to keep a proper layout
  return <div style={{ flex: 10, marginBottom: 60 }} />;
};

export default Comments;
