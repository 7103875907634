import React from 'react';

import { DataBoxItem, DataBoxItemWrapper } from 'commons/DataBox';
import FakeInputBlock from 'commons/FakeInputBlock';
import Date from 'commons/Date';
import Separator from 'commons/Separator';

type AntaiAbandonmentProps = {
  date: string | null;
  reason: string;
};

const AntaiAbandonment = ({
  date,
  reason,
}: AntaiAbandonmentProps): JSX.Element => {
  return (
    <div style={{ width: '100%' }}>
      <DataBoxItemWrapper>
        <DataBoxItem>
          <FakeInputBlock
            title="Date"
            value={
              date ? (
                <Date datetime={date} withTime={false} />
              ) : (
                'Pas de date fournie'
              )
            }
          />
          <FakeInputBlock
            title="Heure"
            value={
              date ? (
                <Date datetime={date} withDate={false} />
              ) : (
                'Pas de date fournie'
              )
            }
          />
        </DataBoxItem>
        <Separator />
        <DataBoxItem>
          <FakeInputBlock title="Raison de l'annulation" value={reason} />
        </DataBoxItem>
      </DataBoxItemWrapper>
    </div>
  );
};

export default AntaiAbandonment;
