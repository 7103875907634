import React, { CSSProperties } from 'react';
import { Link } from 'react-router-dom';

const { _tg } = window.loadTranslations(__filename);

const STYLE_LINK: CSSProperties = {
  fontSize: '1em',
  textDecoration: 'underline',
  fontStyle: 'oblique',
};

const STYLE_WRAPPER: CSSProperties = {
  fontFamily: 'Roboto',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  lineHeight: '1.5em',
};

const CessionFpsLink = ({ fpsId }: { fpsId: string }) => (
  <div style={STYLE_WRAPPER}>
    <div>
      <p style={{ textTransform: 'uppercase' }}>
        {_tg('tefps.fps.detail.cessionFpsLink.text1')}
      </p>
    </div>
    <div>
      <Link to={`/fps/detail/${fpsId}`} style={STYLE_LINK}>
        {_tg('tefps.fps.detail.cessionFpsLink.text2')}
      </Link>
    </div>
  </div>
);

export default CessionFpsLink;
