import React, { CSSProperties, useEffect, useState } from 'react';
import { Snackbar } from 'material-ui';
import { connect } from 'react-redux';
import CircularProgress from 'material-ui/CircularProgress';

import AdvertisingModal from 'commons/AdvertisingModal';
import { ListBody, ListBottom, ListWrapper } from 'commons/ListWrappers';
import { getExport } from 'api/eligibility';
import Content from 'commons/Content';
import FlexCenter from 'commons/FlexCenter';
import ErrorBlock from 'commons/ErrorBlock';
import Exporter from 'commons/Exporter';
import Services from 'commons/services';
import { getApiState } from 'api/duck';
import useEligibilityRights from 'commons/hooks/useEligibilityRights';
import useProducts from 'commons/hooks/useProducts';
import useWatcher from 'commons/hooks/useWatcher';

import EligibilityFilters from '../EligibilityFilters';
import EligibilityTable from '../EligibilityTable';
import ImportEligibilities from '../ImportEligibilities';

import { getExportCol } from './utils';
import EligibilityDetail from './Detail';

const { _tg } = window.loadTranslations(__filename);

const STYLE_CONTENT: CSSProperties = {
  display: 'flex',
  height: '100%',
  overflowY: 'hidden',
};

type EligibilitiesListProps = {
  canImport: boolean;
  canExport: boolean;
};

const EligibilityList = ({
  canExport,
  canImport,
}: EligibilitiesListProps): JSX.Element => {
  const products = useProducts();
  const [eligibilityId, setEligibilityId] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [error, setError] = useState<Error | null | undefined>(undefined);
  const isLoading = useWatcher(
    Services.eligibilityRights.watchLoading,
    Services.eligibilityRights.isLoading()
  );
  const { sortField, increasingOrder } = useWatcher(
    Services.eligibilityRights.watchSortField,
    Services.eligibilityRights.getSortField()
  );

  const getProducts = async (): Promise<void> => {
    try {
      await Services.products.init({ activeOnly: false });
    } catch (e) {
      setError(e as Error);
    }
  };

  const getEligibilities = async (): Promise<void> => {
    try {
      await Services.eligibilityRights.init();
    } catch (e) {
      setError(e as Error);
    }
  };

  useEffect(() => {
    void getProducts();
    void getEligibilities();
  }, []);

  const handleClickOnEligibility = (eligibility: string) =>
    setEligibilityId(eligibility);

  const closeMessageBar = () => setMessage('');

  const onImport = async (msg: string) => {
    setMessage(msg);
    await Services.eligibilityRights.init();
  };

  const eligibilities = useEligibilityRights();

  if (error || !eligibilities) {
    return (
      <Content>
        <FlexCenter>
          <ErrorBlock
            message={_tg('feedback.error.fetchEligibilities')}
            error={error}
          />
        </FlexCenter>
      </Content>
    );
  }

  const { totalHits } = eligibilities;

  return (
    <div style={STYLE_CONTENT}>
      <AdvertisingModal module="tv" />
      <EligibilityFilters
        totalHits={totalHits}
        facetings={eligibilities && eligibilities.checkboxFaceting}
      />
      <ListWrapper>
        <ListBody loading={false}>
          <EligibilityTable
            loadMoreRows={Services.eligibilityRights.searchNextPage}
            onUpdateSort={Services.eligibilityRights.updateSortParameter}
            remoteRowCount={totalHits}
            colSorted={sortField}
            sortOrder={increasingOrder}
            onClick={handleClickOnEligibility}
          />
        </ListBody>
        <ListBottom style={{ position: 'relative' }}>
          {canExport && (
            <Exporter
              // disabled={totalHits === 0}
              disabled={false}
              columns={getExportCol()}
              type={_tg('field.eligibility.eligibilities')}
              filters={Services.eligibilityRights.getFormattedFilters()}
              fileExport={getExport}
            />
          )}
          {canImport && <ImportEligibilities onImport={onImport} />}
          {isLoading && (
            <div style={{ position: 'absolute', left: '50%' }}>
              <CircularProgress />
            </div>
          )}
        </ListBottom>
      </ListWrapper>
      <EligibilityDetail
        eligibilityId={eligibilityId}
        products={products ?? []}
        setEligibilityId={setEligibilityId}
      />
      <Snackbar
        open={!!message}
        message={message}
        autoHideDuration={4000}
        onRequestClose={closeMessageBar}
      />
    </div>
  );
};

export default connect(state => {
  const { userInfo } = getApiState(state);
  return {
    canImport: userInfo && userInfo.rights.includes('ELIGIBILITY_IMPORT'),
    canExport:
      userInfo && userInfo.rights.includes('BACKOFFICE_ELIGIBILITY_EXPORT'),
  };
})(EligibilityList);
