import React, { useState } from 'react';
import { Dialog } from 'material-ui';

import BoButton from 'facade/BoButton';
import { NotificationService } from '@cvfm-front/commons-services';
import { GenericForm } from '@cvfm-front/commons-ui';

import AddressFields from './AddressFields';
import IdentityFields from './IdentityFields';
import { transferFPS } from './api';

interface Props {
  close: () => void;
  isOpen: boolean;
  rootFpsId: string;
}

const AddFpsTransfer = ({ isOpen, close, rootFpsId }: Props): JSX.Element => {
  const [submit, setSubmit] = useState<() => void>(() => 42);

  const actions = [
    <BoButton
      key={1}
      label="Annuler"
      onClick={close}
      style={{ marginRight: 16 }}
    />,
    <BoButton
      key={1}
      label="Transferer"
      onClick={() => submit()}
      style={{ marginRight: 16 }}
      primary
    />,
  ];

  return (
    <Dialog
      actions={actions}
      onRequestClose={close}
      open={isOpen}
      title="Transférer un FPS"
    >
      <GenericForm
        fields={[
          {
            id: '_identity-title',
            label: 'Nouveau propriétaire',
            type: 'title',
          },
          ...IdentityFields,
          {
            id: '_address-title',
            label: 'Adresse du nouveau propriétaire',
            type: 'title',
          },
          ...AddressFields,
          {
            id: '_comment-title',
            label: 'Commentaire de transfert',
            type: 'title',
          },
          {
            classSuffix: 'comment',
            fullWidth: true,
            id: 'comment',
            label: 'Commentaire',
            multiline: true,
            required: true,
            rows: 3,
            type: 'text',
          },
        ]}
        setSubmit={setSubmit}
        onSubmitCallback={async data => {
          try {
            const { comment, ...fnmsPerson } = data;
            const payload = {
              comment,
              fnmsPerson,
              fpsId: rootFpsId,
            };
            await transferFPS(payload);
            close();
            window.location.reload();
          } catch (e) {
            NotificationService.pushNotification({
              id: 'transferFPS',
              type: 'error',
              message: e.json.message,
            });
          }
        }}
      />
    </Dialog>
  );
};

export default AddFpsTransfer;
