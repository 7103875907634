import React, { useEffect, useState } from 'react';

import Sidebar from 'commons/SidebarV2';
import BarTitle from 'commons/SidebarV2/Components/BarTitle';
import HitsCounter from 'commons/SidebarV2/Components/HitsCounter';
import Input from 'commons/SidebarV2/Components/Input';
import Dates, { FilterDate } from 'commons/SidebarV2/Components/Dates';
import Times from 'commons/SidebarV2/Components/Times';
import Checkboxes from 'commons/SidebarV2/Components/Checkboxes';
import { fetchZoning } from 'api/pricing';
import { FETCH_LIGHT_ZONING_CONFIG } from 'commons/FetchZoningConfigs';
import { searchProducts } from 'api/cvfm-core-subscription/product';
import {
  ProductPrivateDTO,
  ProductType,
  ZoneDTO,
} from '@cvfm-front/tefps-types';
import Services from 'commons/services';
import useEligibilityRightsFilters from 'commons/hooks/useEligibilityRightsFilters';

import { EligibilitySearchCriteria } from './List/types';
import { initialFilters } from './List/duck';

const { _tg } = window.loadTranslations(__filename);

type Props = {
  totalHits: number;
  facetings:
    | {
        [key: string]: Array<KeyBucketDTO>;
      }
    | null
    | undefined;
};

const EligibilityFilters = (props: Props): JSX.Element => {
  const filters = useEligibilityRightsFilters();
  const { totalHits, facetings } = props;

  const [zoneOptions, setZoneOptions] = useState<
    Array<{ value: string; label: string }>
  >([]);
  const [productOptions, setProductOptions] = useState<
    Array<{ value: string; label: string }>
  >([]);

  const onChange = (id: string, value: any) => {
    void Services.eligibilityRights.updateFilter({
      ...filters,
      [id]: value,
    } as EligibilitySearchCriteria);
  };

  function resetFilters() {
    void Services.eligibilityRights.updateFilter(initialFilters());
  }

  const buildZoneOptions = (
    options: Array<ZoneDTO>
  ): Array<{ value: string; label: string }> => {
    return options.map(option => ({
      value: option.id,
      label: option.name,
    }));
  };

  const fetchZonesAndBuildOptions = async () => {
    try {
      const zoning = await fetchZoning(FETCH_LIGHT_ZONING_CONFIG);
      setZoneOptions(buildZoneOptions(zoning.zones));
    } catch (e) {
      setZoneOptions([]);
    }
  };

  const buildProductOptions = (
    products: Array<ProductPrivateDTO>
  ): Array<{ value: string; label: string }> => {
    return products.map(product => ({
      value: product.productId,
      label: product.name,
    }));
  };

  const fetchProductsAndBuildOptions = async () => {
    try {
      const products = await searchProducts({
        productType: ProductType.ELIGIBILITY,
        activeOnly: false,
      });
      setProductOptions(buildProductOptions(products));
    } catch (e) {
      setProductOptions([]);
    }
  };

  useEffect(() => {
    void fetchZonesAndBuildOptions();
    void fetchProductsAndBuildOptions();
  }, []);

  return (
    { filters } && (
      <Sidebar>
        <BarTitle resetFilters={resetFilters} />
        <HitsCounter hits={totalHits} />
        <Input
          id="plates"
          title={_tg('field.vehicle.licensePlate')}
          placeholder={_tg('commons.plateFilter')}
          onChange={onChange}
          value={filters.plates || ''}
        />
        <Input
          id="eligibilityId"
          title={_tg('field.eligibility.eligibilityNumberLong')}
          placeholder={_tg('field.eligibility.filterId')}
          onChange={onChange}
          value={filters.eligibilityId || ''}
        />
        <Checkboxes
          id="productIds"
          title={_tg('field.eligibility.eligibilityName')}
          options={productOptions}
          filters={new Set<string>(filters.productIds || '')}
          onChange={onChange}
          faceting={facetings ? facetings.productId : null}
        />
        <Dates
          id="startDate"
          title={_tg('field.date.dateOfValidityStart')}
          dates={
            filters.startDate ||
            ({ from: undefined, to: undefined } as FilterDate)
          }
          onChange={onChange}
        />
        <Times
          id="startTime"
          title={_tg('field.date.timeOfValidityStart')}
          times={filters.startTime}
          onChange={onChange}
        />
        <Dates
          id="endDate"
          title={_tg('field.date.dateOfValidityEnd')}
          dates={
            filters.endDate ||
            ({ from: undefined, to: undefined } as FilterDate)
          }
          onChange={onChange}
        />
        <Times
          id="endTime"
          title={_tg('field.date.timeOfValidityEnd')}
          times={filters.endTime}
          onChange={onChange}
        />
        <Checkboxes
          id="validityZonesIds"
          title={_tg('field.address.validityZones')}
          options={zoneOptions}
          filters={filters.validityZonesIds || new Set<string>()}
          onChange={onChange}
          faceting={facetings ? facetings.zoneIdsOfValidity : null}
        />
      </Sidebar>
    )
  );
};

export default EligibilityFilters;
