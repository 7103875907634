import React from 'react';
import CircularProgress from 'material-ui/CircularProgress';

import Content from 'commons/Content';
import FlexCenter from 'commons/FlexCenter';

// TODO: Move in commons
const Loading = (): JSX.Element => {
  return (
    <Content>
      <FlexCenter>
        <CircularProgress />
      </FlexCenter>
    </Content>
  );
};

export default Loading;
