import React, { CSSProperties } from 'react';
import SuccessIcon from 'material-ui/svg-icons/action/check-circle';
import ErrorIcon from 'material-ui/svg-icons/action/highlight-off';
import WaitIcon from 'material-ui/svg-icons/action/schedule';

import SimpleTable from 'commons/SimpleTable';
import { BKG_GREEN, BKG_PINK, TXT_BLACK } from 'theme';
import { CancellableFpsMap } from 'api/fps/types';

const STYLE_ICON: CSSProperties = {
  width: 25,
  height: 25,
  margin: '2px 10px 2px 0',
};

type Props = {
  cancellableFpsMap: CancellableFpsMap;
  fpsIds: Array<string> | null | undefined;
  open: boolean;
};

type State = {
  open: boolean;
  status: string | null | undefined;
  icon: string | null | undefined;
  errorMessage: string | null | undefined;
};

const initialState = (props: Props) => ({
  open: props.open,
  status: null,
  errorMessage: null,
  icon: null,
  items: null,
});

const TABLE_COLS = [
  { label: '', width: 30 },
  { label: 'Numéro de FPS', width: 250 },
  {
    label: 'Statut',
    width: 100,
  },
  { label: "Message d'erreur", width: 300 },
];

class FpsToDeleteList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = initialState(props);
  }

  renderIcon = (status: string | null | undefined) => {
    let icon = <WaitIcon style={STYLE_ICON} color={TXT_BLACK} />;
    if (status === 'annulé') {
      icon = <SuccessIcon style={STYLE_ICON} color={BKG_GREEN} />;
    } else if (status === 'erreur') {
      icon = <ErrorIcon style={STYLE_ICON} color={BKG_PINK} />;
    }
    return icon;
  };

  renderFpsRow = (fpsId: string) => {
    const { cancellableFpsMap } = this.props;
    const fpsResult = cancellableFpsMap && cancellableFpsMap[fpsId];

    if (fpsResult) {
      return [
        this.renderIcon(fpsResult.status),
        fpsId,
        fpsResult.status,
        fpsResult.errorMessage,
      ];
    }
    return [this.renderIcon(null), fpsId, 'en attente', ''];
  };

  render() {
    const { fpsIds } = this.props;

    if (!fpsIds || fpsIds.length === 0) {
      return <p />;
    }
    return (
      <SimpleTable
        maxHeight={300}
        cols={TABLE_COLS}
        rowHeight={50}
        header
        itemsRenderer={this.renderFpsRow}
        style={{ marginTop: 10 }}
        items={fpsIds}
      />
    );
  }
}

export default FpsToDeleteList;
