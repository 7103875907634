import { Setter, WatchFunctionType } from '@cvfm-front/commons-types';
import {
  SimpleSubscriberDTO,
  SubscribersSearchResultDTO,
  TaoSubscriberSearchRequestDTO,
} from '@cvfm-front/tefps-types';
import { Watcher } from '@cvfm-front/commons-utils';

import { apiPost } from '../../../api/helpers';

export interface TaoSubscriberSearchServiceInterfaceFactory {
  (): TaoSubscriberSearchServiceInterface;
}

export interface TaoSubscriberSearchServiceInterface {
  searchTaoSubscriber: () => void;
  watchIsLoading: WatchFunctionType<boolean>;
  watchHasLoadingError: WatchFunctionType<Error | undefined>;
  watchRequest: WatchFunctionType<Partial<TaoSubscriberSearchRequestDTO>>;
  watchSubscribers: WatchFunctionType<SimpleSubscriberDTO[]>;
  setRequest: Setter<Partial<TaoSubscriberSearchRequestDTO>>;
  resetRequest: () => void;
  watchTotalHits: WatchFunctionType<number>;
}

const ROOT_API = '/api/proxy/tao/api/v1/{cityId}/subscriber/search';

const TaoSubscriberSearchService: TaoSubscriberSearchServiceInterfaceFactory = () => {
  const { setValue: setIsLoading, watchValue: watchIsLoading } = Watcher(false);
  const {
    watchValue: watchRequest,
    setValue: setRequest,
    getValue: getRequest,
  } = Watcher<Partial<TaoSubscriberSearchRequestDTO>>({});
  const { watchValue: watchSubscribers, setValue: setSubscribers } = Watcher<
    SimpleSubscriberDTO[]
  >([]);
  const {
    setValue: setHasLoadingError,
    watchValue: watchHasLoadingError,
  } = Watcher(undefined);
  const { watchValue: watchTotalHits, setValue: setTotalHits } = Watcher<
    number
  >(0);

  const searchTaoSubscriber: TaoSubscriberSearchServiceInterface['searchTaoSubscriber'] = async () => {
    const request = getRequest();
    try {
      setIsLoading(true);
      setHasLoadingError(undefined);
      const response = await apiPost<SubscribersSearchResultDTO>(
        ROOT_API,
        request
      );
      setSubscribers(response.subscribers);
      setTotalHits(response.totalHits);
    } catch (error) {
      setHasLoadingError(error);
      setSubscribers([]);
    } finally {
      setIsLoading(false);
    }
  };

  const resetRequest = () => {
    setRequest({});
  };

  return {
    searchTaoSubscriber,
    watchIsLoading,
    watchHasLoadingError,
    watchRequest,
    watchSubscribers,
    setRequest,
    resetRequest,
    watchTotalHits,
  };
};

export default TaoSubscriberSearchService;
