import moment from 'moment';

import { LOGOUT, ApiAction } from 'api/duck';
import { SortParameters } from 'api/commonTypes';

import { ControlSearchCriterias } from './types';

export const SET_FILTERS = 'Control/List/SET_FILTERS';
export const SET_SORT_PARAMETERS = 'Control/List/SET_SORT_PARAMETERS';

export type State = {
  filters: ControlSearchCriterias | null | undefined;
  sort: SortParameters | null | undefined;
};

type SetFiltersAction = {
  type: typeof SET_FILTERS;
  filters?: ControlSearchCriterias;
};

type SortParametersAction = {
  type: typeof SET_SORT_PARAMETERS;
  sort?: SortParameters;
};

type Action = SetFiltersAction | SortParametersAction | ApiAction;

export const initialFilters = (): ControlSearchCriterias => {
  const initialFilters: ControlSearchCriterias = {
    esLAPIControlStates: new Set(),
    controlStatuses: new Set(),
    esControlDeviceTypes: new Set(),
    exemptionReasons: new Set(),
    tags: new Set(),
    lapiReviewFetchabilityFailReasons: new Set(),
    fpsCreationStatuses: new Set(),
    statementDatetime: {
      from: moment()
        .subtract(1, 'months')
        .startOf('day')
        .toDate(),
      to: undefined,
    },
    times: {
      from: '00:00',
      to: '23:59',
    },
    zoneId: null,
    patrolZoneId: null,
    fineId: null,
    plate: null,
    agentId: null,
    agentReviewId: null,
    terminalId: null,
    parkingSpaceId: null,
    organizationShortId: null,
    organizationIds: new Set<string>(),
    controlId: null,
    mediaStatuses: new Set(),
    qualityControlComparativeStates: new Set(),
    reviewReasons: new Set(),
    vehicleTypes: new Set(),
    presumedVehicleTypes: new Set(),
    hasParkingSpaceId: new Set(),
  };

  /* handling the case where filters are received in the url */
  const hashParamsIndex = window.location.href.indexOf('?');
  if (hashParamsIndex !== -1) {
    const urlParams = new URLSearchParams(
      window.location.href.substring(hashParamsIndex)
    );
    initialFilters.plate = urlParams.get('plate');
    initialFilters.zoneId = urlParams.get('parkingSpaceId'); // update zoneId with parkingSpaceId
    const statementStartDatetime = urlParams.get('statementStartDatetime');
    if (statementStartDatetime) {
      initialFilters.statementDatetime.from = new Date(statementStartDatetime);
    }
    const statementEndDatetime = urlParams.get('statementEndDatetime');
    if (statementEndDatetime) {
      initialFilters.statementDatetime.to = new Date(statementEndDatetime);
    }
    const controlStatus = urlParams.get('controlStatus');
    if (controlStatus) {
      initialFilters.controlStatuses.add(controlStatus);
    }
  }

  return initialFilters;
};

export const initialState = (): State => ({
  filters: initialFilters(),
  sort: {
    sortField: 1,
    increasingOrder: false,
  },
});

export default function controlListReducer(
  state: State = initialState(),
  action: Action
): State {
  switch (action.type) {
    case SET_SORT_PARAMETERS:
      return {
        ...state,
        sort: action.sort,
      };
    case SET_FILTERS:
      return {
        ...state,
        filters: action.filters,
      };
    case LOGOUT:
      return initialState();
    default:
      return state;
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getControlListState(state: any): State {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  return state.controlList as State;
}

export function setFilters(filters: ControlSearchCriterias): any {
  return {
    type: SET_FILTERS,
    filters,
  };
}

export function setSortParameters(sort: SortParameters): any {
  return {
    type: SET_SORT_PARAMETERS,
    sort,
  };
}
