import React, { useState, useEffect, useMemo } from 'react';
import { RouteComponentProps } from 'react-router';
import { CircularProgress, FlatButton } from 'material-ui';

import { PatchObject } from 'api/commonTypes';
import { TaoSubscriberDTO } from '@cvfm-front/tefps-types';

import useWatcher from '../../../commons/hooks/useWatcher';
import useSnackbar from '../../../commons/CustomHooks/SnackBar/useSnackBar';
import FlexCenter from '../../../commons/FlexCenter';
import ErrorBlock from '../../../commons/ErrorBlock';
import Content from '../../../commons/Content';
import services from '../../../commons/services';
import UpdateTaoSubscriber from '../edition/UpdateTaoSubscriber';

import TaoSubscriberDetail from './TaoSubscriberDetail';

const { _t } = window.loadTranslations(__filename);

type TaoSubscriberDetailPageProps = RouteComponentProps<{
  subscriberId: string;
}>;

const TaoSubscriberDetailPage = ({
  match,
}: TaoSubscriberDetailPageProps): JSX.Element => {
  const { subscriberId } = useMemo(() => match.params, [match]);
  const [subscriber, setSubscriber] = useState<TaoSubscriberDTO>();
  const [editing, setEditing] = useState<boolean>(false);
  const displaySnackbar = useSnackbar();
  const taoSubscriberFetchService = useMemo(
    () => services.taoSubscriberFetch,
    []
  );
  const taoSubscriberUpdateService = useMemo(
    () => services.taoSubscriberUpdate,
    []
  );
  const isLoading = useWatcher(taoSubscriberFetchService.watchIsLoading, false);
  const error = useWatcher<Error | undefined>(
    taoSubscriberFetchService.watchHasLoadingError,
    undefined
  );
  const isUpdating = useWatcher(
    taoSubscriberUpdateService.watchIsUpdating,
    false
  );

  async function loadSubscriber() {
    if (subscriberId) {
      const fetchedTaoSubscriber = await taoSubscriberFetchService.fetchTaoSubscriber(
        subscriberId
      );
      setSubscriber(fetchedTaoSubscriber);
    }
  }

  async function updateSubscriber(
    updateSubscriberId: string,
    value: Array<PatchObject<unknown>>
  ): Promise<void> {
    await taoSubscriberUpdateService.updateTaoSubscriber(
      updateSubscriberId,
      value
    );
    loadSubscriber();
  }

  useEffect(() => {
    loadSubscriber();
  }, [subscriberId]);

  useEffect(() => {
    if (!isUpdating) {
      setEditing(false);
    }
  }, [isUpdating]);

  useEffect(() => {
    if (error) {
      displaySnackbar(error.message);
    }
  }, [error]);

  return (
    <Content>
      <div className="order-detail-page_header">
        <FlatButton href="#/subscribers" label={_t('back')} />
      </div>
      <div className="order-detail-page_container">
        {isLoading && (
          <FlexCenter>
            <CircularProgress />
          </FlexCenter>
        )}
        {error && (
          <FlexCenter>
            <ErrorBlock error={error} />
          </FlexCenter>
        )}
        {!isLoading && subscriber && !editing && !error && (
          <TaoSubscriberDetail
            subscriber={subscriber}
            openUpdateSubscriber={() => {
              setEditing(true);
            }}
          />
        )}

        {!isLoading && subscriber && editing && (
          <UpdateTaoSubscriber
            updateSubscriber={updateSubscriber}
            subscriber={subscriber}
            closeModal={() => {
              setEditing(false);
            }}
          />
        )}
      </div>
    </Content>
  );
};

export default TaoSubscriberDetailPage;
