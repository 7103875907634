import React from 'react';
import { useHistory } from 'react-router';

import FpsMapButton from 'commons/FpsMapButton';
import ControlMapButton from 'commons/ControlMapButton';

const buttonBarWrapperStyle = {
  display: 'flex',
  width: '85%',
  marginTop: 50,
  justifyContent: 'space-evenly',
};

type Props = {
  fpsId: string;
};

const FpsMapButtonBar = ({ fpsId }: Props) => {
  return (
    <div style={buttonBarWrapperStyle}>
      <FpsMapButton fpsId={fpsId} />
      <ControlMapButton fineId={fpsId} history={useHistory()} />
    </div>
  );
};

export default FpsMapButtonBar;
