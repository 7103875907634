import React, { CSSProperties } from 'react';
import { Checkbox } from 'material-ui';

import SimpleTable from 'commons/SimpleTable';
import Date from 'commons/Date';
import { ControlOverviewDTO } from 'api/control/types';
import { ICON_STYLE, LABEL_STYLE, WIDTH_STYLE } from 'theme';

import BoButton from '../../../../facade/BoButton';
import ControlMediaModal from '../ControlDetail/ControlMediaModal';

import FpsLink from './FpsLink';

const { _t, _tg } = window.loadTranslations(__filename);

const STYLE_LINK: CSSProperties = {
  height: 50,
  width: '100%',
};

const STYLE_HOVERED: CSSProperties = {
  ...STYLE_LINK,
  color: 'white',
  cursor: 'pointer',
};

type ControlTableProps = {
  items: Array<ControlOverviewDTO>;
  zones: Map<string, string> | null | undefined;
  exemptionReasons: Map<string, string> | null | undefined;
  loadMoreRows: (arg0: { startIndex: number }) => Promise<void>;
  onUpdateSort: (colNumber: number, increasingOrder: boolean) => void;
  remoteRowCount?: number;
  colSorted?: number;
  sortOrder?: boolean;
  onClick: (id: string) => any;
  multipleMode: boolean;
  selectedControls: Map<string, string>;
  toggleSelectedControl: (controlId: string) => void;
};

type ControlTableState = {
  hovered: string | null | undefined;
  controlID: string | null;
};

function getStyle(id: string, hovered: string | null | undefined) {
  return id === hovered ? STYLE_HOVERED : STYLE_LINK;
}

class ControlTable extends React.Component<
  ControlTableProps,
  ControlTableState
> {
  constructor(props: ControlTableProps) {
    super(props);
    this.state = { hovered: null, controlID: null };
  }

  handleMouseLeave = (): void => this.setState({ hovered: null });

  handleMouseEnter = ({ currentTarget }: React.MouseEvent<any>): void =>
    // eslint-disable-next-line
    this.setState({ hovered: currentTarget.dataset.controlId });

  handleClick = ({ currentTarget }: React.MouseEvent<any>): void => {
    const { onClick } = this.props;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    onClick(currentTarget.dataset.controlId);
  };

  showControlPictures = (controlId: string) =>
    this.setState({ controlID: controlId });
  hideControlPictures = () => this.setState({ controlID: null });

  render(): React.ReactNode {
    const {
      exemptionReasons,
      onUpdateSort,
      loadMoreRows,
      remoteRowCount,
      colSorted,
      sortOrder,
      zones,
      items,
      multipleMode,
      selectedControls,
      toggleSelectedControl,
    } = this.props;
    const { hovered, controlID } = this.state;

    const tableCols = [
      { label: _t('table.controlId'), width: 300, grow: 3, onSort: true },
      {
        label: _tg('field.control.controlDate'),
        width: 150,
        onSort: true,
      },
      { label: _tg('field.agent.agent'), width: 140, onSort: true },
      { label: _t('table.terminalNumber'), width: 150, onSort: true },
      {
        label: _tg('field.status'),
        width: 130,
      },
      { label: _tg('field.address.zone'), width: 130, onSort: true },
      { label: _tg('field.comment'), width: 300 },
      {
        label: _tg('tefps.Control.Detail.ControlMediaList.displayPictures'),
        width: 130,
      },
    ];
    if (multipleMode) {
      tableCols.unshift({ label: _tg('field.selection'), width: 80 });
    }

    return (
      <>
        <SimpleTable
          style={{ marginTop: 25 }}
          cols={tableCols}
          rowHeight={50}
          onSort={onUpdateSort}
          loadMoreRows={loadMoreRows}
          remoteRowCount={remoteRowCount}
          colSorted={colSorted}
          sortOrder={sortOrder}
          itemsRenderer={(control: ControlOverviewDTO) => {
            let controlExemptionReason = null;

            if (control.exemptionReason && exemptionReasons) {
              controlExemptionReason = exemptionReasons.get(
                control.exemptionReason
              );
            }
            const row = [
              <span
                // onClick={this.handleClick}
                style={getStyle(control.controlId, hovered)}
                data-control-id={control.controlId}
                onMouseLeave={this.handleMouseLeave}
                onMouseEnter={this.handleMouseEnter}
              >
                <a
                  href={location.hash.replace(
                    /[^/]*$/,
                    `detail/${control.controlId}`
                  )}
                >
                  {control.controlId}
                </a>
              </span>,
              <Date datetime={control.statementDatetime} />,
              <span>{control.agentName}</span>,
              <span>{control.terminalId}</span>,
              <span>{control.controlStatus}</span>,
              <span>
                {zones && zones.has(control.zoneId)
                  ? zones.get(control.zoneId)
                  : control.zoneId}
              </span>,
              <span>
                {control.fineLegalId ? (
                  <FpsLink fpsId={control.fineLegalId} />
                ) : (
                  ''
                )}
                {controlExemptionReason ? ` ${controlExemptionReason}` : ''}
                {control.comment ? ` ${control.comment}` : ''}
              </span>,
              <BoButton
                onClick={() => this.showControlPictures(control.controlId)}
                label={_tg(
                  'commons.ZoningComponents.ParkingMeters.ShowParkingMeter.element.parkingMeterManagement.show'
                )}
              />,
            ];

            if (multipleMode) {
              row.unshift(
                <Checkbox
                  iconStyle={{ ...ICON_STYLE, fill: '#000' }}
                  labelStyle={LABEL_STYLE}
                  style={WIDTH_STYLE}
                  checked={selectedControls.has(control.controlId)}
                  onCheck={() => toggleSelectedControl(control.controlId)}
                />
              );
            }

            return row;
          }}
          items={items}
        />
        {controlID && (
          <ControlMediaModal
            open={controlID !== null}
            close={this.hideControlPictures}
            controlId={controlID}
          />
        )}
      </>
    );
  }
}

export default ControlTable;
