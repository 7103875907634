import React from 'react';
import { NavLink } from 'react-router-dom';

import './MarcheNavigation.css';

const { _t } = window.loadTranslations(__filename);

type LinkData = {
  to: string;
  label: string;
};

const getTranslatableLink = (): { [key: string]: Array<LinkData> } => {
  return {
    articles: [
      {
        to: '/marches/articles',
        label: _t('element.articles.configuration'),
      },
    ],
    products: [
      {
        to: '/marches/products',
        label: _t('element.products.configuration'),
      },
      {
        to: '/marches/orders',
        label: _t('element.products.orders'),
      },
      {
        to: '/marches/granted',
        label: _t('element.products.granted'),
      },
    ],
  };
};

function getClassName(index: number, length: number): string {
  if (length === 1) {
    return 'marche-nav-content marche-nav-content_middle';
  }
  if (index === 0) {
    return 'marche-nav-content marche-nav-content_left';
  }
  if (index === length - 1) {
    return 'marche-nav-content marche-nav-content_right';
  }
  return 'marche-nav-content';
}

export const MarcheNavigation = () => {
  const nav: {
    [k: string]: LinkData[];
  } = getTranslatableLink();

  return (
    <div className="marche-nav_component">
      {nav.articles.length > 0 && (
        <span>
          <div className="marche-nav_title">{_t('element.articles.label')}</div>
          <div className="marche-nav_container">
            {nav.articles.map((link, index) => (
              <NavLink
                key={index}
                to={link.to}
                className={getClassName(index, nav.articles.length)}
                activeClassName="marche-nav-content_active"
              >
                <span>{link.label}</span>
              </NavLink>
            ))}
          </div>
        </span>
      )}

      {nav.products.length > 0 && (
        <span>
          <div className="marche-nav_title">{_t('element.products.label')}</div>
          <div className="marche-nav_container">
            {nav.products.map((link, index) => (
              <NavLink
                key={index}
                to={link.to}
                className={getClassName(index, nav.products.length)}
                activeClassName="marche-nav-content_active"
              >
                <span>{link.label}</span>
              </NavLink>
            ))}
          </div>
        </span>
      )}
    </div>
  );
};
