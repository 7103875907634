import { CSSProperties } from 'react';

import { BKG_BLUE, TXT_GREY } from 'theme';

export const SidebarStyle: CSSProperties = {
  width: 414,
  height: '100%',
  backgroundColor: BKG_BLUE,
  display: 'flex',
  fontFamily: 'Roboto, sans-serif',
  color: TXT_GREY,
  paddingTop: 32,
  overflowX: 'hidden',
  overflowY: 'auto',
  boxSizing: 'border-box',
};
