import { CSSProperties } from 'react';

export const STYLE_INPUTS_CONTAINER: CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  marginLeft: -20,
  flexWrap: 'wrap',
};

export const STYLE_FIELD: CSSProperties = {
  minWidth: 200,
  flexGrow: 1,
  flexBasis: '45%',
  marginLeft: 20,
};

export const STYLE_ADDRESS_TITLE: CSSProperties = {
  height: '100%',
  width: '100%',
  marginTop: 60,
};

export const STYLE_UPPERCASE: CSSProperties = {
  textTransform: 'uppercase',
};
