import { Pager, EligibilityFilterParam } from '@cvfm-front/tefps-types';
import { SortParameters } from 'api/commonTypes';
import { Column } from 'commons/Exporter';

import { EligibilitySearchCriteria } from './types';

const { _tg } = window.loadTranslations(__filename);

function getSortFieldText(sortField?: number) {
  switch (sortField) {
    case 0:
      return 'eligibilityId';
    case 1:
      return 'productId';
    case 2:
      return 'startOfValidity';
    case 4:
      return 'hashedPlates';
    case 5:
      return 'zoneIdsOfValidity';
    default:
      return 'endOfValidity';
  }
}

export const filtersToRequest = (
  filters: EligibilitySearchCriteria,
  sort?: SortParameters,
  pager?: Pager
): EligibilityFilterParam => {
  const {
    startDate,
    endDate,
    startTime,
    endTime,
    eligibilityId,
    plates,
    productIds,
    validityZonesIds,
    organizationIds,
  } = filters;
  const { page, maxRecords } = pager || { page: 0, maxRecords: 0 };
  return {
    page,
    maxRecords,
    sort: {
      sortOrder: sort?.increasingOrder ? 'ASC' : 'DESC',
      sortField: getSortFieldText(sort?.sortField),
    },

    query: {
      eligibilityIds: eligibilityId ? [eligibilityId] : null,
      plates: plates ? plates.split(', ') : null,
      validityZonesIds: validityZonesIds ? Array.from(validityZonesIds) : null,
      productIds: productIds ? Array.from(productIds) : null,
      validityStart: {
        from: startDate?.from?.toISOString() || '',
        to: startDate?.to?.toISOString() || '',
      },
      validityEnd: {
        from: endDate?.from?.toISOString() || '',
        to: endDate?.to?.toISOString() || '',
      },
      fromValidityStartTime: startTime?.from,
      toValidityStartTime: startTime?.to,
      fromValidityEndTime: endTime?.from,
      toValidityEndTime: endTime?.to,
      subscriberIds: null,
      organizationIds: organizationIds ?? null,
    },
  };
};

export function getExportCol(): Column[] {
  return [
    {
      key: 'eligibilityId',
      label: _tg('field.eligibility.eligibilityNumber'),
      checked: true,
    },
    {
      key: 'productId',
      label: _tg('field.eligibility.eligibilityName'),
      checked: true,
    },
    {
      key: 'hashedPlates',
      label: _tg('field.vehicle.plates'),
      checked: true,
    },
    {
      key: 'creationDatetime',
      label: _tg('field.date.creation'),
      checked: true,
    },
    { key: 'zoneIds', label: _tg('field.address.zone_plural'), checked: true },
    { key: 'startDatetime', label: _tg('field.date.datetimeOfValidityStart') },
    {
      key: 'endDatetime',
      label: _tg('field.date.datetimeOfValidityEndSimple'),
    },
    {
      key: 'cancellationDatetime',
      label: _tg('field.date.cancelDate'),
    },
    {
      key: 'lastModificationDatetime',
      label: _tg('field.date.lastModificationDate'),
    },
    { key: 'versionDatetime', label: _tg('tefps.filters.rapo.receptionDate') },
  ];
}
