import moment from 'moment';

import { FpsFiltersParam, VehicleCategory } from '@cvfm-front/tefps-types';
import { KeyLabel, ValueLabel } from '@cvfm-front/commons-types';
import { convertToCents } from 'commons/Utils/paymentUtil';
import { Pager, SortParameters } from 'api/commonTypes';
import { PeriodType } from 'api/fps/types';
import { AuthorizedVehicleCategory } from 'config/duck';

import { FpsSearchCriterias } from './types';

const { _tg } = window.loadTranslations(__filename);

const BASE_RECOURSE_FILTER_OPTIONS = () => [
  { value: 'REJECTED', label: _tg('field.rejected') },
  { value: 'NONE', label: _tg('field.none') },
];

export const RAPO_FILTER_OPTIONS: () => ValueLabel[] = () => [
  { value: 'ONGOING_RAPO', label: _tg('field.ongoing') },
  { value: 'ACCEPTED', label: _tg('field.accepted') },
  ...BASE_RECOURSE_FILTER_OPTIONS(),
  { value: 'INCORRECT', label: _tg('field.inadmissible') },
];

export const CCSP_FILTER_OPTIONS: () => ValueLabel[] = () => [
  { value: 'ONGOING_CCSP', label: _tg('field.ongoing') },
  { value: 'ACCEPTED', label: _tg('field.ccsp.ccspAccepted') },
  { value: 'ACCEPTED_PARTIAL', label: _tg('field.ccsp.ccspPartialAccepted') },
  { value: 'DISMISSED', label: _tg('field.ccsp.ccspDismissed') },
  { value: 'ACCEPTED_OTHER', label: _tg('field.ccsp.ccspOtherAccepted') },
  ...BASE_RECOURSE_FILTER_OPTIONS(),
];

export const PAYMENT_FILTER_OPTIONS: () => ValueLabel[] = () => [
  { value: 'PENDING', label: _tg('field.awaitingPayment') },
  { value: 'INCOMPLETE', label: _tg('field.incompletePayment') },
  { value: 'OVERPAID', label: _tg('field.overpaid') },
  { value: 'OVERPAID_REDUCED', label: _tg('field.overpaidReduced') },
  { value: 'PAID', label: _tg('field.paid') },
  { value: 'PAID_REDUCED', label: _tg('field.paidReduced') },
  { value: 'RECOVERED', label: _tg('field.recovered') },
  { value: 'ABANDONED', label: _tg('field.fpsAbandoned') },
];

export const SUBSCRIPTION_FILTER_OPTIONS: () => ValueLabel[] = () => [
  { value: 'NONE', label: _tg('field.subscription.none') },
  { value: 'INACTIVE', label: _tg('field.subscription.inactive') },
  { value: 'ACTIVE', label: _tg('field.subscription.active') },
];

export const STATUSES_FILTER_OPTIONS: () => ValueLabel[] = () => [
  { value: 'INITIAL', label: _tg('tefps.filters.fps.fpsStatus.initial') },
  {
    value: 'IDENTIFICATION',
    label: _tg('tefps.filters.fps.fpsStatus.identification'),
  },
  {
    value: 'ONGOING_NOTIFICATION',
    label: _tg('tefps.filters.fps.fpsStatus.ongoingNotification'),
  },
  {
    value: 'SENT_NOTIFICATION',
    label: _tg('tefps.filters.fps.fpsStatus.sentNotification'),
  },
  { value: 'CITY_PAID', label: _tg('tefps.filters.fps.fpsStatus.paidToCity') },
  {
    value: 'ANTAI_PAID',
    label: _tg('tefps.filters.fps.fpsStatus.paidToAntai'),
  },
  {
    value: 'PENDING_RECOVERY',
    label: _tg('tefps.filters.fps.fpsStatus.pendingRecovery'),
  },
  {
    value: 'ONGOING_RECOVERY',
    label: _tg('tefps.filters.fps.fpsStatus.ongoingRecovery'),
  },
  { value: 'RECOVERED', label: _tg('tefps.filters.fps.fpsStatus.recovered') },
  { value: 'ABANDONED', label: _tg('tefps.filters.fps.fpsStatus.abandoned') },
  { value: 'PRE_FPS', label: _tg('tefps.filters.fps.fpsStatus.preFps') },
  {
    value: 'ABANDONNED_CONSISTENCY',
    label: _tg('tefps.filters.fps.fpsStatus.abandonedLAPIConsistency'),
  },
  {
    value: 'ABANDONNED_CONTROL_REPLAY_CONSISTENCY',
    label: _tg('tefps.filters.fps.fpsStatus.abandonedReplayConsistency'),
  },
];

export const REASONS_FILTER_OPTIONS: () => ValueLabel[] = () => [
  {
    value: 'TRANSMISSION_FAILURE',
    label: _tg('tefps.filters.fps.abandonedReasons.transmissionFailure'),
  },
  {
    value: 'FOREIGN_PLATE',
    label: _tg('tefps.filters.fps.abandonedReasons.foreignPlate'),
  },
  {
    value: 'UNPROCESSABLE_PLATE',
    label: _tg('tefps.filters.fps.abandonedReasons.unprocessablePlate'),
  },
  {
    value: 'SUSPECT_VEHICLE',
    label: _tg('tefps.filters.fps.abandonedReasons.suspectVehicle'),
  },
  {
    value: 'IDENTIFICATION_FAILURE',
    label: _tg('tefps.filters.fps.abandonedReasons.identificationFailure'),
  },
  { value: 'CANCELLED', label: _tg('field.cancelled') },
  {
    value: 'INCONSISTENT_BRAND',
    label: _tg('tefps.filters.fps.abandonedReasons.inconsistentBrand'),
  },
  {
    value: 'USURPATION',
    label: _tg('tefps.filters.fps.abandonedReasons.usurpation'),
  },
  {
    value: 'IMPOSSIBLE_RECOVERY',
    label: _tg('tefps.filters.fps.abandonedReasons.impossibleRecovery'),
  },
];

export const PAYMENT_DEFICIENCY_OPTIONS: () => ValueLabel[] = () => [
  {
    value: 'NO_PAYMENT',
    label: _tg('tefps.filters.fps.paymentDeficiencyStatuses.noPayment'),
  },
  {
    value: 'INSUFFISIENTLY_PAID',
    label: _tg(
      'tefps.filters.fps.paymentDeficiencyStatuses.insuffisientlyPaid'
    ),
  },
];

export const REDUCED_DATETIME_UPDATE_OPTIONS: () => ValueLabel[] = () => [
  {
    value: 'UPDATED',
    label: _tg('tefps.filters.fps.reducedDatetimeUpdateStatuses.updated'),
  },
  {
    value: 'NOT_UPDATED',
    label: _tg('tefps.filters.fps.reducedDatetimeUpdateStatuses.notUpdated'),
  },
];

export const CANCEL_PROPOSAL_OPTIONS: () => ValueLabel[] = () => [
  { value: 'PENDING', label: _tg('field.ongoing') },
  { value: 'ACCEPTED', label: _tg('field.acceptedFeminin') },
  { value: 'REFUSED', label: _tg('field.refusedFeminin') },
];

export const MEDIAS_OPTIONS: () => ValueLabel[] = () => [
  { value: 'WITH', label: _tg('tefps.filters.fps.mediaStatuses.withMedias') },
  {
    value: 'WITHOUT',
    label: _tg('tefps.filters.fps.mediaStatuses.withoutMedias'),
  },
];

export const TERMINAL_OPTIONS = (
  origins: KeyBucketDTO[] | undefined,
  allTypes: boolean
): KeyLabel[] => {
  const types = [
    { key: 'LAPI', label: _tg('tefps.filters.fps.terminalId.lapi') },
    {
      key: 'PDA|WEB',
      label: _tg('tefps.filters.fps.terminalId.foot'),
    },
  ];
  if (allTypes) {
    types.push({
      key: 'PDA_REVIEW',
      label: _tg('tefps.filters.fps.terminalId.pdaReview'),
    });
  } else {
    origins?.forEach(origin => {
      if (origin.key === 'PDA_REVIEW' && origin.value > 0) {
        types.push({
          key: 'PDA_REVIEW',
          label: _tg('tefps.filters.fps.terminalId.pdaReview'),
        });
      }
    });
  }
  return types;
};

export const PARENTS_OPTIONS: () => ValueLabel[] = () => [
  { value: 'HAS', label: _tg('field.yes') },
  { value: 'HAS_NOT', label: _tg('field.no') },
];

export const VEHICLE_CONSISTENCY_OPTIONS: () => ValueLabel[] = () => [
  {
    value: 'CONSISTENT',
    label: _tg('tefps.filters.fps.fpsVehicleConsistencyStatuses.consistent'),
  },
  {
    value: 'INCONSISTENT',
    label: _tg('tefps.filters.fps.fpsVehicleConsistencyStatuses.inconsistent'),
  },
  {
    value: 'INCONSISTENT_AWAIT_REVIEW',
    label: _tg(
      'tefps.filters.fps.fpsVehicleConsistencyStatuses.inconsistentAwaitReview'
    ),
  },
  {
    value: 'INCONSISTENT_REVIEW_IGNORED',
    label: _tg(
      'tefps.filters.fps.fpsVehicleConsistencyStatuses.inconsistentReviewIgnored'
    ),
  },
  {
    value: 'PENDING',
    label: _tg('tefps.filters.fps.fpsVehicleConsistencyStatuses.pending'),
  },
  {
    value: 'UNKNOWN',
    label: _tg('tefps.filters.fps.fpsVehicleConsistencyStatuses.unknown'),
  },
];

export const buildVehicleCategorySelect = (
  vehicleCategories: Array<AuthorizedVehicleCategory>
): Array<{ key: string | undefined; label: string }> =>
  vehicleCategories.map(category => ({
    key: category.vehicleCategory,
    label: VehicleCategory[category.vehicleCategory],
  }));

// The key should be the same as the id in FpsExportColumnsConfig in the back.
export const EXPORT_COL = [
  { key: 'fineLegalId', label: 'N° de FPS', checked: true },
  { key: 'lastVersionId', label: 'Dernière version de FPS', checked: true },
  { key: 'finePrice', label: 'Tarif', checked: true },
  { key: 'fpsStatus', label: 'Statut du FPS', checked: true },
  { key: 'rapoStatus', label: 'Statut RAPO', checked: true },
  { key: 'ccspStatus', label: 'Statut TSP', checked: true },
  { key: 'paymentStatus', label: 'Statut du paiement', checked: true },
  { key: 'statementDatetime', label: 'Date de constat' },
  { key: 'statementAddress', label: 'Adresse du constat' },
  { key: 'zoneId', label: 'Zone' },
  { key: 'plate', label: "N° d'immatriculation" },
  { key: 'brand', label: 'Marque du véhicule' },
  { key: 'initialAgentId', label: 'ID Agent FPS initial' },
  { key: 'lastAgentId', label: 'ID Agent dernière version' },
  { key: 'statementAuthority', label: "Autorité d'émission" },
  { key: 'abandonedReason', label: "Raison d'abandon auprès de l'ANTAI" },
  { key: 'abandonedMessage', label: 'Erreur lors de la synchronisation' },
  { key: 'coordonnees', label: 'Coordonnées GPS' },
  { key: 'nbMedias', label: 'Photographies' },
  { key: 'comments', label: 'Commentaires' },
  { key: 'endAmicableDate', label: 'Date de recouvrement' },
  { key: 'limitRecoursePeriod', label: 'Date limite pour déposer un RAPO' },
  { key: 'responsibleOfReducedPeriod', label: 'Responsable de minoration' },
  { key: 'origin', label: "Mode d'émission" },
  { key: 'plateCountry', label: "Pays d'immatriculation" },
  { key: 'vehicleType', label: 'Type de véhicule' },
  { key: 'dateModified', label: 'Dernière modification' },
  { key: 'cancelProposalDatetime', label: "Date d'annulation" },
  { key: 'initialFinePrice', label: 'Montant initial' },
  { key: 'controlLocationCode', label: 'Zone résident' },
  { key: 'paidAmount', label: 'Montant payé' },
  { key: 'remainingAmount', label: 'Montant restant' },
  { key: 'vehicleConsistencyStatus', label: 'Cohérence marque' },
  {
    key: 'vehicleBrandAntai',
    label: _tg('tefps.fps.detail.mainBlock.vehicleBrandSentToANTAI'),
  },
  {
    key: 'antaiNotificationDatetime',
    label: "Date et heure de la notification à l'ANTAI",
  },
  {
    key: 'rapoNumbers',
    label: _tg(
      'tefps.RecoursesV2.pages.List.RecourseTable.tableCols.rapoNumber'
    ),
  },
  {
    key: 'administrativeCertificate',
    label: _tg('tefps.filters.fps.cancelProposalAdminCertificate.label'),
  },
];

function getSortFieldText(sortField?: number) {
  switch (sortField) {
    case 0:
      return 'id';
    case 1:
      return 'finePrice';
    default:
      return 'statementDatetime';
  }
}

export const initialFilters = (plate?: string): FpsSearchCriterias => ({
  rapoClaimStatuses: new Set(),
  ccspClaimStatuses: new Set(),
  paymentStatuses: new Set(),
  subscriptionStatuses: new Set(),
  fpsStatuses: new Set(),
  abandonedReasons: new Set(),
  tags: new Set(),
  fpsOrFpsmIds: new Set(),
  reducedDatetimeUpdateStatuses: new Set(),
  statementDatetime: {
    from: moment()
      .subtract(6, 'months')
      .startOf('day')
      .toDate(),
    to: undefined,
  },
  times: {
    from: '00:00',
    to: '23:59',
  },
  price: { from: NaN, to: NaN },
  zoneId: null,
  controlId: null,
  fineId: null,
  plates: new Set(plate ? [plate] : []), // new Set("abc") => ["a", "b", "c"]
  agentId: null,
  fineLegalId: null,
  address: null,
  vehicleCategories: new Set(),
  mediaStatuses: new Set(),
  paymentDeficiencyStatuses: new Set(),
  initialOrganizationShortId: null,
  organizationIds: new Set(),
  terminalId: null,
  origins: new Array<string>(),
  cancelProposalStatuses: new Set(),
  parentStatuses: new Set(),
  fpsVehicleConsistencyStatuses: new Set(),
  fpsComment: null,
  administrativeCertificate: null,
  profiles: new Array<string>(),
  notProfiles: new Array<string>(),
  rapoNumbers: new Set(),
});

export const filtersToRequest = (
  filters: FpsSearchCriterias,
  sort?: SortParameters,
  pager?: Pager
): FpsFiltersParam => {
  const {
    rapoClaimStatuses,
    ccspClaimStatuses,
    paymentStatuses,
    subscriptionStatuses,
    fpsStatuses,
    statementDatetime,
    tags,
    fpsOrFpsmIds,
    agentId,
    plates,
    mediaStatuses,
    zoneId,
    address,
    times,
    price,
    vehicleCategories,
    paymentDeficiencyStatuses,
    initialOrganizationShortId,
    organizationIds,
    reducedDatetimeUpdateStatuses,
    terminalId,
    origins,
    cancelProposalStatuses,
    parentStatuses,
    fpsComment,
    fpsVehicleConsistencyStatuses,
    administrativeCertificate,
    profiles,
    notProfiles,
    rapoNumbers,
  } = filters;
  const { page, maxRecords } = pager || { page: 0, maxRecords: 0 };
  const { from, to } = statementDatetime || {};

  const fpsIds = Array.from(fpsOrFpsmIds);
  const idsLength = fpsIds.length;

  let periods;
  if (to || from) {
    periods = [
      {
        type: 'STATEMENT' as PeriodType,
        startDatetime: from ? moment(from).toISOString() : undefined,
        endDatetime: to ? moment(to).toISOString() : undefined,
      },
    ];
  }

  const organizationIdsArray = organizationIds
    ? Array.from(organizationIds)
    : null;
  return {
    rapoClaimStatuses: Array.from(rapoClaimStatuses),
    ccspClaimStatuses: Array.from(ccspClaimStatuses),
    paymentStatuses: Array.from(paymentStatuses),
    subscriptionStatuses: Array.from(subscriptionStatuses),
    abandonedReasons: Array.from(filters.abandonedReasons),
    fpsStatuses: Array.from(fpsStatuses),
    periods,
    startTime: times.from,
    endTime: times.to,
    fromAmount: price.from ? convertToCents(price.from) : null,
    toAmount: price.to ? convertToCents(price.to) : null,
    tags: Array.from(tags),
    fineLegalId: idsLength === 1 ? fpsIds[0] : null,
    fpsOrFpsmIds: idsLength === 1 ? [] : fpsIds,
    agentIds: agentId ? [agentId] : null,
    plates: Array.from(plates),
    mediaStatuses: Array.from(mediaStatuses),
    zoneId,
    page,
    maxRecords,
    address,
    vehicleCategories: Array.from(vehicleCategories),
    initialOrganizationShortId,
    organizationIds: organizationIdsArray?.length ? organizationIdsArray : null,
    sortOrder: sort?.increasingOrder ? 'ASC' : 'DESC',
    sortField: getSortFieldText(sort?.sortField),
    paymentDeficiencyStatuses: Array.from(paymentDeficiencyStatuses),
    reducedDatetimeUpdateStatuses: Array.from(reducedDatetimeUpdateStatuses),
    terminalId,
    origins: origins.reduce<string[]>((acc, item) => {
      const splitItems = item.split('|');
      return acc.concat(splitItems);
    }, []),
    cancelProposalStatuses: Array.from(cancelProposalStatuses),
    parentStatuses: Array.from(parentStatuses),
    fpsVehicleConsistencyStatuses: Array.from(fpsVehicleConsistencyStatuses),
    fpsComment,
    administrativeCertificate,
    rapoNumbers: Array.from(rapoNumbers) // Array<string>
      .map(rapoNumberLine => rapoNumberLine.split(',')) // Array<Array<string>>
      .reduce((acc, newValue) => [...acc, ...newValue], [] as Array<string>) // Array<string> flatmap
      .map(rapoNumber => rapoNumber.trim()) // Array<string>
      .map(Number), // Array<Number>
    profiles: profiles.map(p => p.trim()).filter(p => p !== ''),
    notProfiles: notProfiles.map(p => p.trim()).filter(p => p !== ''),
  };
};
