import React, { Component, CSSProperties } from 'react';
import moment from 'moment';

import BoButton from 'facade/BoButton';
import { BKG_PINK } from 'theme';
import SeparatorWithTitle from 'commons/SeparatorWithTitle';
import {
  DatetimePickerCustom,
  TextFieldCustom,
} from 'commons/FormComponent/Fields';
import { convertToCents } from 'commons/Utils/paymentUtil';

import {
  STYLE_ADDRESS_TITLE,
  STYLE_FIELD,
  STYLE_INPUTS_CONTAINER,
} from '../common.styles';
import {
  FINE_PRICE,
  REDUCED_DATETIME,
  REDUCED_FINE_PRICE,
  STATEMENT_DATETIME,
  VALIDITY_DATETIME,
} from '../fieldNames';
import {
  beforeNow,
  greaterThanZero,
  lowerThanFinePrice,
  reducedAfterStatementDatetime,
  validityAfterStatementDatetime,
} from '../validators';

const STYLE_DISCARD_CONTAINER: CSSProperties = {
  ...STYLE_FIELD,
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'flex-end',
};

const STYLE_ADD_CONTAINER: CSSProperties = {
  marginTop: 50,
  display: 'flex',
  justifyContent: 'center',
};

type State = {
  open: boolean;
};

class DatetimesSection extends Component<{}, State> {
  now = moment().startOf('minute');
  currentDate = this.now.toDate();
  reducedDate = this.now.add(96, 'hours').toDate(); // +5 days from now
  validityDate = moment()
    .startOf('minute')
    .add(6, 'hours')
    .toDate(); // +6 hours from now

  state: State = {
    open: false,
  };

  toggleReducedPrice = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    return (
      <div>
        <DatetimePickerCustom
          hintDate="Date de constatation"
          hintHour="Heure de constatation"
          name={STATEMENT_DATETIME}
          styleContainer={STYLE_INPUTS_CONTAINER}
          styleField={STYLE_FIELD}
          defaultValue={this.currentDate}
          validators={[beforeNow]}
          mandatory
        />
        <div key="price" style={STYLE_INPUTS_CONTAINER}>
          <TextFieldCustom
            defaultValue="20.00"
            name={FINE_PRICE}
            style={STYLE_FIELD}
            hint="Montant (€)"
            type="number"
            min="0.00"
            onBeforeSubmit={convertToCents}
            validators={[greaterThanZero]}
            mandatory
          />
          <div style={STYLE_FIELD} />
        </div>
        <SeparatorWithTitle
          style={STYLE_ADDRESS_TITLE}
          title="Fin de validité du FPS"
          color={BKG_PINK}
          titleSize={20}
        />
        <DatetimePickerCustom
          hintDate="Date de fin de validité du FPS"
          hintHour="Heure de fin de validité du FPS"
          name={VALIDITY_DATETIME}
          styleContainer={STYLE_INPUTS_CONTAINER}
          styleField={STYLE_FIELD}
          defaultValue={this.validityDate}
          validators={[validityAfterStatementDatetime]}
          mandatory
        />
        <SeparatorWithTitle
          style={STYLE_ADDRESS_TITLE}
          title="Minoration du FPS"
          color={BKG_PINK}
          titleSize={20}
        />
        {this.state.open ? (
          <div>
            <DatetimePickerCustom
              hintDate="Date de fin de validité du prix réduit"
              hintHour="Heure de fin de validité du prix réduit"
              name={REDUCED_DATETIME}
              styleContainer={STYLE_INPUTS_CONTAINER}
              styleField={STYLE_FIELD}
              defaultValue={this.reducedDate}
              validators={[reducedAfterStatementDatetime]}
              mandatory
            />
            <div key="reducedPrice" style={STYLE_INPUTS_CONTAINER}>
              <TextFieldCustom
                defaultValue="18.00"
                name={REDUCED_FINE_PRICE}
                style={STYLE_FIELD}
                hint="Prix réduit (€)"
                type="number"
                onBeforeSubmit={convertToCents}
                mandatory
                validators={[lowerThanFinePrice]}
                min="0.00"
              />
              <div style={STYLE_DISCARD_CONTAINER}>
                <BoButton
                  key="discard_reduced_price"
                  label="Annuler"
                  secondary
                  onClick={this.toggleReducedPrice}
                />
              </div>
            </div>
          </div>
        ) : (
          <div style={STYLE_ADD_CONTAINER}>
            <BoButton
              key="add_reduced_price"
              label="Ajouter une minoration du prix"
              primary
              onClick={this.toggleReducedPrice}
            />
          </div>
        )}
      </div>
    );
  }
}

export default DatetimesSection;
