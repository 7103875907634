import * as React from 'react';
import { useRouteMatch } from 'react-router-dom';

import { TaoProductDTO } from '@cvfm-front/tefps-types';
import SimpleTable from 'commons/SimpleTable';
import './ProductTablePage.css';

const { _t, _tg } = window.loadTranslations(__filename);

type ProductTableProps = {
  pagedProducts: Array<TaoProductDTO>;
};

const ProductTable = ({
  pagedProducts: products,
}: ProductTableProps): JSX.Element => {
  const { url } = useRouteMatch();

  const getHeaderCols = () => [
    {
      label: _t('productName'),
      width: 240,
      grow: 1,
    },
    {
      label: _t('nbArticles'),
      width: 240,
      grow: 1,
    },
  ];

  const renderProductNameCell = (product: TaoProductDTO): JSX.Element => {
    return (
      <a
        id={`${product.productId}-id`}
        className="product-table-row_id"
        href={`#${url}/${product.productId}`}
      >
        {product.name}
      </a>
    );
  };

  const renderNumberArticlesCell = (product: TaoProductDTO): JSX.Element => {
    const listSize = product.listArticleConfigDTO
      ? product.listArticleConfigDTO.length
      : 0;
    return <span id={`${product.productId}-nbArticles`}>{listSize}</span>;
  };

  const renderRow = (product: TaoProductDTO) => {
    const row = [
      renderProductNameCell(product),
      renderNumberArticlesCell(product),
    ];
    return row;
  };

  return (
    <SimpleTable
      style={{ marginTop: 25 }}
      cols={getHeaderCols()}
      rowHeight={50}
      itemsRenderer={renderRow}
      items={products}
    />
  );
};

export default ProductTable;
