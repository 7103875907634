import React from 'react';

import { BKG_PINK } from 'theme';
import SeparatorWithTitle from 'commons/SeparatorWithTitle';
import {
  SelectFieldCustom,
  TextFieldCustom,
} from 'commons/FormComponent/Fields';
import { validateFpsPostalCode } from 'commons/Validators';
import { ZoningDTO } from '@cvfm-front/tefps-types';

import {
  STYLE_ADDRESS_TITLE,
  STYLE_FIELD,
  STYLE_INPUTS_CONTAINER,
} from '../common.styles';
import { isAddressSubRegionValid } from '../validators';
import {
  ADDRESS_LOCALITY,
  ADDRESS_REGION,
  ADDRESS_SUB_REGION,
  LOCATION_LATITUDE,
  LOCATION_LONGITUDE,
  POST_OFFICE_BOX_NUMBER,
  POSTAL_CODE,
  SPECIAL_PLACE,
  STREET_NAME,
  STREET_NUMBER,
  STREET_NUMBER_BIS,
  STREET_TYPE,
  ZONE,
} from '../fieldNames';

type Props = {
  zoning: ZoningDTO | null | undefined;
};

const AddressSection = ({ zoning }: Props): JSX.Element => {
  return (
    <div>
      <SeparatorWithTitle
        style={STYLE_ADDRESS_TITLE}
        title="Localisation du contrôle"
        color={BKG_PINK}
        titleSize={20}
      />
      <div key="address" style={STYLE_INPUTS_CONTAINER}>
        <TextFieldCustom
          name={STREET_NUMBER}
          hint="Numéro de rue"
          style={STYLE_FIELD}
          maxLength={10}
        />
        <TextFieldCustom
          name={STREET_NUMBER_BIS}
          hint="Numéro de rue bis"
          style={STYLE_FIELD}
          maxLength={10}
        />
        <TextFieldCustom
          name={STREET_TYPE}
          hint="type de voie (rue, avenue, impasse...)"
          style={STYLE_FIELD}
          maxLength={50}
        />
        <TextFieldCustom
          name={STREET_NAME}
          hint="Nom de rue"
          style={STYLE_FIELD}
          maxLength={80}
          mandatory
        />
        <TextFieldCustom
          name={POST_OFFICE_BOX_NUMBER}
          hint="Numéro de boite postale"
          style={STYLE_FIELD}
        />
        <TextFieldCustom
          name={SPECIAL_PLACE}
          hint="Lieu-dit"
          style={STYLE_FIELD}
          maxLength={100}
        />
        <TextFieldCustom
          name={POSTAL_CODE}
          hint="Code postal"
          style={STYLE_FIELD}
          type="number"
          step="1"
          validators={[validateFpsPostalCode]}
          mandatory
        />
        <TextFieldCustom
          name={ADDRESS_SUB_REGION}
          hint="Numéro de département"
          style={STYLE_FIELD}
          validators={[isAddressSubRegionValid]}
          maxLength={3}
          mandatory
        />
        <TextFieldCustom
          name={ADDRESS_REGION}
          hint="Région"
          style={STYLE_FIELD}
          mandatory
        />
        <TextFieldCustom
          name={ADDRESS_LOCALITY}
          hint="Ville"
          style={STYLE_FIELD}
          maxLength={80}
          mandatory
        />
        <SelectFieldCustom
          name={ZONE}
          style={STYLE_FIELD}
          hint="Zone tarifaire"
          items={zoning ? zoning.zones : []}
          mandatory
        />
      </div>
      <SeparatorWithTitle
        style={STYLE_ADDRESS_TITLE}
        title="Coordonnées GPS du contrôle"
        color={BKG_PINK}
        titleSize={20}
      />
      <div key="location" style={STYLE_INPUTS_CONTAINER}>
        <TextFieldCustom
          name={LOCATION_LATITUDE}
          hint="Latitude"
          type="number"
          style={STYLE_FIELD}
        />
        <TextFieldCustom
          name={LOCATION_LONGITUDE}
          hint="Longitude"
          type="number"
          style={STYLE_FIELD}
        />
      </div>
    </div>
  );
};

export default AddressSection;
