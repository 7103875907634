import * as React from 'react';
import { Redirect } from 'react-router-dom';
import CircularProgress from 'material-ui/CircularProgress';

import Content from 'commons/Content';
import ErrorBlock from 'commons/ErrorBlock';
import FlexCenter from 'commons/FlexCenter';
import { fetchCcspRecourseByFpsId } from 'api/ccsp';

const { _tg } = window.loadTranslations();

type RecourseLoaderProps = {
  match: {
    params: {
      fpsId: string;
    };
  };
};

type RecourseLoaderState = {
  error: Error | null | undefined;
  ccspNumber: string | null | undefined;
};

class RecourseLoader extends React.Component<
  RecourseLoaderProps,
  RecourseLoaderState
> {
  state: RecourseLoaderState = {
    error: null,
    ccspNumber: null,
  };

  componentDidMount() {
    this.fetchCcspRecourseByFpsId(this.props.match.params.fpsId);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: RecourseLoaderProps) {
    this.fetchCcspRecourseByFpsId(nextProps.match.params.fpsId);
  }

  fetchCcspRecourseByFpsId = async (fpsId: string) => {
    try {
      const recourse = await fetchCcspRecourseByFpsId(fpsId);
      this.setState({ ccspNumber: recourse.ccspNumber });
    } catch (error) {
      this.setState({ error, ccspNumber: null });
    }
  };

  render() {
    const { ccspNumber, error } = this.state;

    if (error) {
      return (
        <Content>
          <FlexCenter>
            <ErrorBlock
              message={_tg('feedback.error.fetchCcsp')}
              error={error}
            />
          </FlexCenter>
        </Content>
      );
    }

    if (ccspNumber) {
      return <Redirect to={`/tsp/detail/${ccspNumber}`} />;
    }

    return (
      <Content>
        <FlexCenter>
          <CircularProgress />
        </FlexCenter>
      </Content>
    );
  }
}

export default RecourseLoader;
