import React from 'react';
import moment from 'moment';
import IconButton from 'material-ui/IconButton';
import AddIcon from 'material-ui/svg-icons/content/add-circle-outline';
import RemoveIcon from 'material-ui/svg-icons/content/remove';

import {
  DatetimePickerCustom,
  SelectFieldCustom,
  TextFieldCustom,
} from 'commons/FormComponent/Fields';
import { ZoneDTO } from '@cvfm-front/tefps-types';
import { Flex } from '@cvfm-front/commons-ui';
import NumberField from 'commons/NumberField';

import { BKG_PINK } from '../../../theme';
import {
  STYLE_ADDRESS_TITLE,
  STYLE_FIELD,
  STYLE_INPUTS_CONTAINER,
  STYLE_UPPERCASE,
} from '../../Fps/List/AddFPS/common.styles';
import {
  beforeNow,
  plateCountryValidator,
  plateValidator,
} from '../../Fps/List/AddFPS/validators';
import SeparatorWithTitle from '../../../commons/SeparatorWithTitle';
import UploadFilesRecourse from '../../Fps/Detail/AddContents/AddRecourse/UploadFilesRecourse';
import ToggleField from '../../Beneficiaries/SubscriptionPlans/AddSubscriptionPlan/Components/ToggleField';
import { agentList, ControlDTO } from '../../../api/control/types';

import {
  AGENT,
  DEVICE,
  DRY,
  LICENSE_PLATE,
  LOCATION_LATITUDE,
  LOCATION_LONGITUDE,
  NUMBER_OF_CONTROL,
  PLATE_COUNTRY,
  STATEMENT_DATETIME,
  ZONE,
} from './fieldNames';

const { _t, _tg } = window.loadTranslations(__filename);

type ControlInformationProps = {
  zones: ZoneDTO[] | null | undefined;
  agents: agentList[] | null | undefined;
  controlToReplay?: ControlDTO;
  numberOfControls?: number;
  setNumberOfControls?: (value: number) => void;
};
const ControlInformation = ({
  zones,
  agents,
  controlToReplay,
  numberOfControls,
  setNumberOfControls,
}: ControlInformationProps) => {
  const date = controlToReplay
    ? new Date(controlToReplay.statementDatetime)
    : moment()
        .startOf('minute')
        .toDate();
  const [hasZone, setHasZone] = React.useState<boolean>(false);
  const [hasLatitude, setHasLatitude] = React.useState<boolean>(false);
  const [hasLongitude, setHasLongitude] = React.useState<boolean>(false);
  const [showAddContextImage, setShowAddContextImage] = React.useState<boolean>(
    false
  );
  const [showAddPlatetImage, setShowAddPlatetImage] = React.useState<boolean>(
    false
  );
  const [showAddHikContextImage, setShowAddHikContextImage] = React.useState<
    boolean
  >(false);
  return (
    <>
      <div>
        <SeparatorWithTitle
          style={STYLE_ADDRESS_TITLE}
          title={_t('element.label.controlDateTime')}
          color={BKG_PINK}
          titleSize={20}
        />
        <DatetimePickerCustom
          hintDate={_t('element.label.hintDate')}
          hintHour={_t('element.label.hintHour')}
          name={STATEMENT_DATETIME}
          styleContainer={STYLE_INPUTS_CONTAINER}
          styleField={STYLE_FIELD}
          defaultValue={date}
          validators={[beforeNow]}
          mandatory
        />
      </div>
      <div>
        <SeparatorWithTitle
          style={STYLE_ADDRESS_TITLE}
          title={_t('element.label.numberOfControl')}
          color={BKG_PINK}
          titleSize={20}
        />
        {numberOfControls !== undefined && setNumberOfControls && (
          <NumberField
            style={STYLE_FIELD}
            name={NUMBER_OF_CONTROL}
            value={numberOfControls}
            onChange={setNumberOfControls}
            min={1}
            max={100}
          />
        )}
      </div>
      <div>
        <SeparatorWithTitle
          style={STYLE_ADDRESS_TITLE}
          title={_t('element.label.controlAgent')}
          color={BKG_PINK}
          titleSize={20}
        />
        <SelectFieldCustom
          name={AGENT}
          style={STYLE_FIELD}
          hint={_t('element.label.hintAgent')}
          defaultValue={controlToReplay ? controlToReplay.agent.agentId : ''}
          items={agents ?? []}
          mandatory
        />
      </div>
      <SeparatorWithTitle
        style={STYLE_ADDRESS_TITLE}
        title={_t('element.label.controlCar')}
        color={BKG_PINK}
        titleSize={20}
      />
      <div key="vehicule" style={STYLE_INPUTS_CONTAINER}>
        <TextFieldCustom
          name={PLATE_COUNTRY}
          hint={_t('element.dialog.plateCountry')}
          style={STYLE_FIELD}
          defaultValue={
            controlToReplay ? controlToReplay.initialPlate.plateCountry : ''
          }
          type="text"
          inputStyle={STYLE_UPPERCASE}
          validators={[plateCountryValidator]}
          mandatory
        />
        <TextFieldCustom
          name={LICENSE_PLATE}
          hint={_tg('field.vehicle.licensePlate')}
          style={STYLE_FIELD}
          defaultValue={
            controlToReplay ? controlToReplay.initialPlate.plate : ''
          }
          inputStyle={STYLE_UPPERCASE}
          validators={[plateValidator]}
          mandatory
        />
      </div>
      <div>
        <SeparatorWithTitle
          style={STYLE_ADDRESS_TITLE}
          title={_t('element.label.controlLocation')}
          color={BKG_PINK}
          titleSize={20}
        />
        <SelectFieldCustom
          name={ZONE}
          style={STYLE_FIELD}
          hint={_t('element.label.hintZone')}
          defaultValue={controlToReplay ? controlToReplay.zoneId : ''}
          items={zones ?? []}
          type="text"
          onChange={() => setHasZone(true)}
          mandatory={!hasLongitude || !hasLatitude}
        />
      </div>
      <SeparatorWithTitle
        style={STYLE_ADDRESS_TITLE}
        title={_t('element.label.gps')}
        color={BKG_PINK}
        titleSize={20}
      />
      <div key="location" style={STYLE_INPUTS_CONTAINER}>
        <TextFieldCustom
          name={LOCATION_LATITUDE}
          hint={_t('element.label.hintLat')}
          type="number"
          defaultValue={controlToReplay ? controlToReplay.latitude : null}
          style={STYLE_FIELD}
          onChange={() => setHasLatitude(true)}
          mandatory={!hasZone}
        />
        <TextFieldCustom
          name={LOCATION_LONGITUDE}
          hint={_t('element.label.hintLog')}
          type="number"
          defaultValue={controlToReplay ? controlToReplay.longitude : null}
          style={STYLE_FIELD}
          onChange={() => setHasLongitude(true)}
          mandatory={!hasZone}
        />
      </div>
      <div>
        <SeparatorWithTitle
          style={STYLE_ADDRESS_TITLE}
          title={_t('element.label.device')}
          color={BKG_PINK}
          titleSize={20}
        />
        <SelectFieldCustom
          name={DEVICE}
          style={STYLE_FIELD}
          hint={_t('element.label.hintDevice')}
          defaultValue={controlToReplay?.controlDeviceType}
          items={[
            { id: 'PDA', name: 'PDA' },
            { id: 'AUTOMATED_CONTROL_VEHICLE', name: 'LAPI' },
          ]}
          mandatory
        />
      </div>
      <SeparatorWithTitle
        style={STYLE_ADDRESS_TITLE}
        title={_t('element.label.simulate')}
        color={BKG_PINK}
        titleSize={20}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: 50,
          marginLeft: -20,
          flexWrap: 'wrap',
        }}
      >
        <h2
          style={{
            minWidth: 200,
            flexGrow: 1,
            flexBasis: '45%',
            marginLeft: 20,
            fontSize: 18,
          }}
        >
          {_t('element.label.dry')}
        </h2>
        <ToggleField
          name={DRY}
          defaultValue
          style={{
            minWidth: 200,
            flexGrow: 0,
            flexBasis: '45%',
            marginLeft: 20,
          }}
        />
      </div>
      <SeparatorWithTitle
        style={STYLE_ADDRESS_TITLE}
        title={_t('element.attachements.item')}
        color={BKG_PINK}
        titleSize={20}
      />
      <div
        style={{
          display: 'flex',
          width: '100%',
          flexDirection: 'column',
          marginTop: 50,
          gap: 50,
        }}
      >
        <div>
          <Flex flexDirection="row">
            <h2
              style={{
                alignItems: 'center',
                paddingTop: 14,
                paddingBottom: 0,
              }}
            >
              {' '}
              {_t('element.attachements.context')}
            </h2>
            <IconButton
              tooltip={
                showAddContextImage
                  ? _t('element.image.up')
                  : _t('element.image.down')
              }
              style={{
                alignItems: 'center',
                paddingTop: 8,
              }}
              onClick={() => setShowAddContextImage(!showAddContextImage)}
            >
              {showAddContextImage ? <RemoveIcon /> : <AddIcon />}
            </IconButton>
          </Flex>
          {showAddContextImage && (
            <UploadFilesRecourse
              name="files.context"
              optional
              mediaType="CONTEXT"
            />
          )}
        </div>
        <div>
          <Flex flexDirection="row">
            <h2
              style={{
                alignItems: 'center',
                paddingTop: 14,
                paddingBottom: 0,
              }}
            >
              {' '}
              {_t('element.attachements.plate')}
            </h2>
            <IconButton
              tooltip={
                showAddPlatetImage
                  ? _t('element.image.up')
                  : _t('element.image.down')
              }
              style={{
                alignItems: 'center',
                paddingTop: 8,
              }}
              onClick={() => setShowAddPlatetImage(!showAddPlatetImage)}
            >
              {showAddPlatetImage ? <RemoveIcon /> : <AddIcon />}
            </IconButton>
          </Flex>
          {showAddPlatetImage && (
            <UploadFilesRecourse
              name="files.plate"
              optional
              mediaType="PLATE"
            />
          )}
        </div>
        <div>
          <Flex flexDirection="row">
            <h2
              style={{
                alignItems: 'center',
                paddingTop: 14,
                paddingBottom: 0,
              }}
            >
              {' '}
              {_t('element.attachements.context_hik')}
            </h2>
            <IconButton
              tooltip={
                showAddHikContextImage
                  ? _t('element.image.up')
                  : _t('element.image.down')
              }
              style={{
                alignItems: 'center',
                paddingTop: 8,
              }}
              onClick={() => setShowAddHikContextImage(!showAddHikContextImage)}
            >
              {showAddHikContextImage ? <RemoveIcon /> : <AddIcon />}
            </IconButton>
          </Flex>
          {showAddHikContextImage && (
            <UploadFilesRecourse
              name="files.contextHik"
              optional
              mediaType="CONTEXT_HIK"
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ControlInformation;
