import moment from 'moment';

import { UploadFile } from 'api/commonTypes';

const { _t, _tg } = window.loadTranslations(__filename);

const MAX_FILE_SIZE = 20000000; // 20MB

export const validateFileSize = (
  file: UploadFile
): string | null | undefined => {
  if (file && file.size > MAX_FILE_SIZE) {
    return _tg('feedback.error.evidenceFileSize', { mo: 20 });
  }
  return undefined;
};

export function isBeforeNow(value: Date): boolean {
  return moment()
    .startOf('day')
    .isAfter(value);
}

export function beforeNow(value: Date) {
  if (isBeforeNow(value)) {
    return _t('feedback.error.dateAfterToday');
  }
  return undefined;
}

export function beforeNowWarning(
  value: Date | string | undefined | null
): string | undefined {
  if (value === undefined || value === null) {
    return undefined;
  }
  if (isBeforeNow(new Date(value))) {
    return _t('feedback.warning.dateBeforeNow');
  }
  return undefined;
}

export function idValidator(value: string) {
  if (value && value.length === 0) {
    return _t('feedback.error.mandatoryId');
  }
  return /^[a-zA-Z0-9_-]*$/.test(value) ? undefined : 'Identifiant incorrect';
}

function keyValidator(value: string, key: string) {
  if (value && value.length < 3) {
    return _t('feedback.error.tooShort', { key });
  }
  return undefined;
}

export function nameValidator(value: string) {
  return keyValidator(value, _tg('field.human.lastname_short'));
}

export function restrictionCountValidator(value: string) {
  if (!(Number(value) > 0)) {
    return _t('feedback.error.instanceCount');
  }
  return undefined;
}

export function priceValidator(value: string) {
  if (Number(value) < 0) {
    return _t('feedback.error.createSubscriptionWithNegativeAmount');
  }
  return undefined;
}

export function nbMaxPlatesValidator(value: string) {
  if (Number(value) < 1) {
    return _t('feedback.error.createSubscriptionWithNoPlate');
  }
  return undefined;
}

export function customFieldValidator(value: string) {
  return keyValidator(value, _t('field.fieldName'));
}

export function evidenceNameValidator(value: string) {
  return keyValidator(value, _t('field.attachmentName'));
}

export function evidenceNameValidatorNotNull(value: string) {
  if (!value || value.length < 3) {
    return _t('feedback.error.tooShort', { key: _t('field.attachmentName') });
  }
  return undefined;
}
