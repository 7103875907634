import React, { CSSProperties } from 'react';

import BoButton from 'facade/BoButton';
import { BKG_PINK } from 'theme';
import { getNoticeUrl, getProofUrl } from 'api/fps';
import FakeInputBlock from 'commons/FakeInputBlock';
import Separator from 'commons/Separator';
import Price from 'commons/Price';
import Date from 'commons/Date';
import { openNewAuthentifiedTab } from 'api/helpers';
import { DataBoxItem, DataBoxItemWrapper } from 'commons/DataBox';

import { PaymentDTO } from '../helpers';

const STYLE_BUTTONS: CSSProperties = {
  margin: '10px',
};

type FpsRefundProps = {
  payment: PaymentDTO;
  fpsId: string;
  isLastPayment: boolean;
  noticeDownloadEnabled: boolean;
};

class FpsRefund extends React.Component<FpsRefundProps> {
  openPaymentProofPdf = () => {
    const { fpsId } = this.props;
    openNewAuthentifiedTab(getProofUrl(fpsId));
  };

  openNoticePdf = () => {
    const { fpsId } = this.props;
    openNewAuthentifiedTab(getNoticeUrl(fpsId));
  };

  render() {
    const { payment, isLastPayment, noticeDownloadEnabled } = this.props;

    return (
      <div style={{ width: '100%' }}>
        <DataBoxItemWrapper>
          <DataBoxItem>
            <FakeInputBlock
              title="Date"
              value={<Date datetime={payment.datetime} withTime={false} />}
            />
            <FakeInputBlock
              title="Heure"
              value={<Date datetime={payment.datetime} withDate={false} />}
            />
          </DataBoxItem>
          <Separator />
          <DataBoxItem>
            <FakeInputBlock
              title="Montant remboursé"
              value={<Price price={payment.amount * -1} />}
            />
            <FakeInputBlock
              title="Montant restant à payer"
              value={<Price price={payment.amountToPay} />}
              inputStyle={{ border: `1px solid ${BKG_PINK}` }}
            />
          </DataBoxItem>
          <Separator />
          <DataBoxItem>
            {payment.origin !== 'DGFIP' && (
              <FakeInputBlock title="Origine" value={payment.origin} />
            )}
            {payment.origin === 'DGFIP' && (
              <FakeInputBlock
                title="Moyen de remboursement"
                value={payment.mode}
              />
            )}
            {payment.agent && (
              <FakeInputBlock title="Agent" value={payment.agent.name} />
            )}
          </DataBoxItem>
          {payment.origin !== 'DGFIP' && <Separator />}
          {payment.origin !== 'DGFIP' && (
            <DataBoxItem>
              <FakeInputBlock
                title="Moyen de remboursement"
                value={payment.mode}
              />
            </DataBoxItem>
          )}
        </DataBoxItemWrapper>
        {isLastPayment && (
          <div>
            <BoButton
              style={STYLE_BUTTONS}
              onClick={this.openPaymentProofPdf}
              primary
              label="Justificatif global de Paiement"
            />
            {noticeDownloadEnabled && (
              <BoButton
                style={STYLE_BUTTONS}
                onClick={this.openNoticePdf}
                primary
                label="Avis de Paiement"
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

export default FpsRefund;
