import * as React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';

import MissNotFound from 'commons/MissNotFound';

import ArticleTablePage from './Article/table/ArticleTablePage';
import ProductTablePage from './Product/table/ProductTablePage';

type MarcheRouterProps = RouteComponentProps & {
  enabled: boolean;
};

export const MarcheRouter = ({ match, enabled }: MarcheRouterProps) => {
  const baseRoute = match.url;

  return (
    <div style={{ height: '100%' }}>
      <Switch>
        <Redirect exact path={baseRoute} to={`${baseRoute}/articles`} />
        <Route
          path={`${baseRoute}/articles`}
          exact
          component={() => <ArticleTablePage />}
        />
        <Route
          path={`${baseRoute}/products`}
          exact
          component={() => <ProductTablePage />}
        />
        <Route
          path={`${baseRoute}/orders`}
          exact
          component={() => <ProductTablePage />}
        />
        <Route
          path={`${baseRoute}/granted`}
          exact
          component={() => <ProductTablePage />}
        />
        <MissNotFound />
      </Switch>
    </div>
  );
};
