import * as React from 'react';

const { _t } = window.loadTranslations(__filename);

const EditPlateActionV1 = ({ action }: any) => {
  return (
    <span>
      {_t('element.content', {
        planName: action.planName,
        fromPlates: action.fromPlates.join(', '),
        toPlates: action.toPlates.join(', '),
      })}
    </span>
  );
};

export default EditPlateActionV1;
