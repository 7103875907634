import './ControlMediaList.css';
import React, { useCallback, useState } from 'react';

import { Container } from '@cvfm-front/commons-ui';
import { ControlDTO } from 'api/control/types';
import { FnmsMediaDTO } from 'api/fps/types';
import Picture from 'commons/FpsPictures/Picture';
import { STYLE_LINK } from 'theme';

const { _t } = window.loadTranslations(__filename);

interface ControlMediaListProps {
  control: ControlDTO;
}

function ControlMediaList({ control }: ControlMediaListProps) {
  const [displayPictures, setDisplayPicture] = useState(false);
  const handleOnClick = useCallback(() => {
    setDisplayPicture(!displayPictures);
  }, [displayPictures]);

  if (control.medias && control.medias.length <= 0) {
    return <></>;
  }

  return (
    <Container className="control-media-list">
      {control.medias.length > 0 ? (
        <span style={STYLE_LINK} onClick={handleOnClick}>
          {displayPictures ? _t('dontDisplayPictures') : _t('displayPictures')}
        </span>
      ) : (
        <span>{_t('noPicturesToDisplay')}</span>
      )}
      {displayPictures &&
        control.medias.map((media: FnmsMediaDTO, i: number) => (
          <Picture key={media.contentUrl} index={i} media={media} />
        ))}
    </Container>
  );
}

export default ControlMediaList;
