import React, { CSSProperties } from 'react';

import { BKG_PINK, TXT_BLACK } from 'theme';
import FakeInputBlock from 'commons/FakeInputBlock';
import Separator from 'commons/Separator';
import Price from 'commons/Price';
import Date from 'commons/Date';
import { TitleDataDTO } from 'api/antai/fps';
import { DataBoxItem, DataBoxItemWrapper } from 'commons/DataBox';
import { computePaymentKey, FpsPaymentKeyType } from 'tefps/Fps/Detail/helpers';

const { _tg } = window.loadTranslations(__filename);

const STYLE_TEXT: CSSProperties = {
  fontSize: 12,
  fontWeight: 'bold',
  color: TXT_BLACK,
  flex: 1,
};

type DebtRecoveryProps = {
  fpsmId: string;
  title: TitleDataDTO;
};

const DebtRecovery = ({ fpsmId, title }: DebtRecoveryProps) => {
  return (
    <div style={{ width: '100%' }}>
      <div style={{ ...STYLE_TEXT, textAlign: 'center', marginBottom: 20 }}>
        <span style={{ marginRight: 10 }}>
          {_tg('tefps.fps.detail.content.debtRecovery.fpsId')}
        </span>
        {fpsmId}
      </div>
      <DataBoxItemWrapper>
        <DataBoxItem>
          <FakeInputBlock
            title="Date"
            value={<Date datetime={title.sentDatetime} withTime={false} />}
          />
          <FakeInputBlock
            title="Heure"
            value={<Date datetime={title.sentDatetime} withDate={false} />}
          />
        </DataBoxItem>
        <Separator />
        <DataBoxItem>
          <FakeInputBlock
            title="Clé de paiement"
            value={computePaymentKey(fpsmId, FpsPaymentKeyType.FPSM)}
          />
        </DataBoxItem>
        <Separator />
        <DataBoxItem>
          <FakeInputBlock
            title="Prix"
            value={<Price price={title.price} />}
            inputStyle={{ border: `1px solid ${BKG_PINK}` }}
          />
          <FakeInputBlock
            title="Majoration"
            value={<Price price={title.surcharge} />}
          />
        </DataBoxItem>
      </DataBoxItemWrapper>
    </div>
  );
};

export default DebtRecovery;
