import React, { CSSProperties } from 'react';
import moment from 'moment';

import { BKG_PINK } from 'theme';
import { FpsCaseDTO } from 'api/fps/types';
import FakeInputBlock from 'commons/FakeInputBlock';
import Separator from 'commons/Separator';
import Price from 'commons/Price';
import FpsNumber from 'commons/FpsNumber';
import Date from 'commons/Date';
import {
  DataBox,
  DataBoxContent,
  DataBoxHeader,
  DataBoxItem,
  DataBoxItemWrapper,
} from 'commons/DataBox';
import DataBoxComment from 'commons/DataBox/DataBoxComment';
import {
  FPS_PAYMENT_STATUS,
  FPS_STATUS,
} from 'tefps/RecoursesV2/utils/translationUtils';
import { SivVehicleDetails } from '@cvfm-front/tefps-types';

import LinkBottomContent from '../../RecoursesV2/pages/Detail/FpsBlock/LinkBottomContent';

import {
  computeAmountToPay,
  computeSurcharge,
  FPS_ANTAI_ABANDONED_REASON,
  getLastClaimStatus,
  getLastVersion,
  getLastVersionWithInitialReduced,
  isAntaiAbandonned,
} from './helpers';

const { _tg } = window.loadTranslations(__filename);

const STYLE_RED: CSSProperties = {
  color: BKG_PINK,
};

const STYLE_CROSSED: CSSProperties = {
  textDecoration: 'line-through',
};

const STYLE_HEADER_LEFT: CSSProperties = {
  fontWeight: 'bold',
  marginLeft: 30,
};

const STYLE_HEADER_RIGHT: CSSProperties = {
  fontSize: 14,
  marginRight: 30,
};

type MainBlockProperties = {
  fps: FpsCaseDTO;
  securityPeriodForRecordingPayment: number;
};

const MainBlock = ({
  fps,
  securityPeriodForRecordingPayment,
}: MainBlockProperties): JSX.Element => {
  const plate = `${fps.licensePlate.plate} [${fps.licensePlate.plateCountry}]`;
  const lastVersion = getLastVersion(fps.versions);
  const latestInitialReduced = getLastVersionWithInitialReduced(fps.versions);
  const reducedDatetimePlusSecurityPeriodForPayment = moment(
    lastVersion.reducedDatetime || undefined
  ).add(securityPeriodForRecordingPayment, 'minutes');
  let reducedDatetimeStyle = moment().isBetween(
    moment(lastVersion.reducedDatetime || undefined),
    reducedDatetimePlusSecurityPeriodForPayment
  )
    ? STYLE_RED
    : {};
  if (moment().isAfter(reducedDatetimePlusSecurityPeriodForPayment)) {
    reducedDatetimeStyle = STYLE_CROSSED;
  }
  const amicablePhaseEndStyle = moment().isAfter(
    moment(fps.endAmicableDate || undefined)
  )
    ? STYLE_CROSSED
    : {};

  return (
    <DataBox panel style={{ width: '95%' }}>
      <DataBoxHeader>
        <div style={STYLE_HEADER_LEFT}>FPS</div>
        <div style={STYLE_HEADER_RIGHT}>
          {_tg('tefps.fps.detail.mainBlock.fpsNumber')}
          <span style={{ fontWeight: 'bold' }}>
            <FpsNumber fpsId={fps.versions[0].id} />
          </span>
        </div>
      </DataBoxHeader>
      <DataBoxContent>
        <DataBoxItemWrapper style={{ marginBottom: 20, width: '90%' }}>
          <DataBoxItem style={{ maxWidth: '100%' }}>
            <FakeInputBlock
              title="N° du dernier avis de paiement"
              value={lastVersion.id}
            />
          </DataBoxItem>
          <Separator />
          <DataBoxItem style={{ maxWidth: '100%' }}>
            <FakeInputBlock
              title="Autorité d'émission"
              value={
                lastVersion.agent.worksFor
                  ? lastVersion.agent.worksFor.name
                  : ''
              }
            />
          </DataBoxItem>
        </DataBoxItemWrapper>
        <DataBoxItemWrapper>
          <DataBoxItem>
            <FakeInputBlock
              title="Fin de validité"
              value={<Date datetime={fps.validityDatetime} withTime />}
            />
            <FakeInputBlock
              title="Statut du FPS"
              value={FPS_STATUS()[fps.state]}
            />
            {isAntaiAbandonned(fps.antaiFps) && (
              <FakeInputBlock
                title="Raison de l'annulation"
                value={
                  FPS_ANTAI_ABANDONED_REASON[fps.antaiFps.fps.fpsStatus.reason]
                }
              />
            )}
            <FakeInputBlock
              title="Statut du RAPO"
              value={getLastClaimStatus(fps.claims)}
            />
            <FakeInputBlock
              title="Statut du paiement"
              value={FPS_PAYMENT_STATUS()[fps.paymentStatus]}
            />
          </DataBoxItem>
          <Separator />
          <DataBoxItem>
            <FakeInputBlock
              title={_tg('field.vehicle.license')}
              value={plate}
            />
            {fps.vehicle.vehicleCategory && (
              <FakeInputBlock
                title="Catégorie du véhicule"
                value={_tg(`field.vehicleType.${fps.vehicle.vehicleCategory}`)}
              />
            )}
            <FakeInputBlock
              title="Marque du véhicule"
              value={
                (fps.vehicleConsistency.status === 'INCONSISTENT'
                  ? fps.sivVehicleBrand
                  : fps.vehicle.brand) || ''
              }
            />
            <FakeInputBlock
              title="Modèle du véhicule"
              value={fps.vehicle.model || ''}
            />
            {fps.antaiFps && (
              <FakeInputBlock
                title="Véhicule de flotte"
                value={fps.antaiFps.fps.fleetVehicle ? 'Oui' : 'Non'}
              />
            )}
          </DataBoxItem>
          <Separator />
          <DataBoxItem>
            <FakeInputBlock
              title="Montant"
              value={<Price price={lastVersion.finePrice} />}
            />
            {(lastVersion.reducedDatetime ||
              (fps.responsibleOfReducedPeriod === 'ANTAI' &&
                lastVersion.reducedFinePrice)) && (
              <FakeInputBlock
                title="Montant minoré"
                value={<Price price={lastVersion.reducedFinePrice} />}
              />
            )}
            {latestInitialReduced && (
              <FakeInputBlock
                title="Fin de minoration initiale"
                inputStyle={reducedDatetimeStyle}
                value={
                  <Date
                    datetime={latestInitialReduced.initialReducedDatetime}
                    withTime
                  />
                }
              />
            )}
            {(lastVersion.reducedDatetime ||
              fps.responsibleOfReducedPeriod === 'ANTAI') && (
              <FakeInputBlock
                title="Fin de minoration"
                inputStyle={reducedDatetimeStyle}
                value={
                  lastVersion.reducedDatetime ? (
                    <Date datetime={lastVersion.reducedDatetime} withTime />
                  ) : (
                    'Date non communiquée'
                  )
                }
              />
            )}
            <FakeInputBlock
              title="Montant restant à payer"
              value={<Price price={computeAmountToPay(fps)} />}
              inputStyle={{ border: `1px solid ${BKG_PINK}` }}
            />
            {fps.antaiFps && fps.antaiFps.fps.debtRecoveryList.length > 0 && (
              <FakeInputBlock
                title="Majoration"
                value={<Price price={computeSurcharge(fps.antaiFps)} />}
              />
            )}
            {fps.endAmicableDate && (
              <FakeInputBlock
                title="Fin de période amiable"
                inputStyle={amicablePhaseEndStyle}
                value={<Date datetime={fps.endAmicableDate} withTime />}
              />
            )}
          </DataBoxItem>
        </DataBoxItemWrapper>
        <LinkBottomContent
          plate={fps.licensePlate.plate}
          date={fps.statementDatetime}
          fpsEligibilities={fps.fpsEligibilities}
          sivVehicle={
            {
              massF4: fps.massF4,
              category: fps.category,
              fuelType: fps.fuelType,
              co2Emissions: fps.co2Emissions,
              registrationDate: fps.registrationDate,
            } as SivVehicleDetails
          }
        />
      </DataBoxContent>
      {fps.comment && <DataBoxComment>{fps.comment}</DataBoxComment>}
    </DataBox>
  );
};

export default MainBlock;
