import { LOGOUT, ApiAction } from 'api/duck';
import { SortParameters } from 'api/commonTypes';

import { SubscribersSearchCriteria } from './types';

const SET_FILTERS = 'Subscribers/List/SET_FILTERS';
const SET_SORT_PARAMETERS = 'Subscribers/List/SET_SORT_PARAMETERS';

type State = {
  filters: SubscribersSearchCriteria;
  sort: SortParameters;
};

type SetFiltersAction = {
  type: typeof SET_FILTERS;
  filters: SubscribersSearchCriteria;
};

type SortParametersAction = {
  type: typeof SET_SORT_PARAMETERS;
  sort: SortParameters;
};

type Action = SetFiltersAction | SortParametersAction | ApiAction;

export const initialFilters = (): SubscribersSearchCriteria => {
  return {
    subscriberId: null,
    firstName: null,
    lastName: null,
    companyName: null,
    email: null,
    streetName: null,
    zoneIds: new Set(),
    postalCode: null,
    zoneResolverStatus: null,
    geocodingStatus: null,
    phoneNumber: null,
    professionalZoneIds: new Set(),
    professionalPostalCode: null,
    professionalGeocodingStatus: null,
    professionalZoneResolverStatus: null,
    professionalStreetName: null,
    accountTypes: new Set(),
  };
};

const initialState = (): State => ({
  filters: initialFilters(),
  sort: {
    sortField: 0,
    increasingOrder: false,
  },
});

export default function subscribersListReducer(
  state: State = initialState(),
  action: Action
): State {
  switch (action.type) {
    case SET_SORT_PARAMETERS:
      return {
        ...state,
        sort: action.sort,
      };
    case SET_FILTERS:
      return {
        ...state,
        filters: action.filters,
      };
    case LOGOUT:
      return initialState();
    default:
      return state;
  }
}

export function getSubscriberListState(state: any): State {
  return state.subscriberList;
}

export function setFilters(filters: SubscribersSearchCriteria): any {
  return {
    type: SET_FILTERS,
    filters,
  };
}

export function setSortParameters(sort: SortParameters): any {
  return {
    type: SET_SORT_PARAMETERS,
    sort,
  };
}
