import querystring from 'querystring';
import React, { CSSProperties } from 'react';

import BoButton from 'facade/BoButton';
import { BKG_PINK } from 'theme';
import FakeInputBlock from 'commons/FakeInputBlock';
import Separator from 'commons/Separator';
import Price from 'commons/Price';
import Date from 'commons/Date';
import { getNoticeUrl, getProofUrl } from 'api/fps';
import { openNewAuthentifiedTab } from 'api/helpers';
import { DataBoxItem, DataBoxItemWrapper } from 'commons/DataBox';

import { PaymentDTO } from '../helpers';

type Props = {
  payment: PaymentDTO;
  fpsId: string;
  fpsmId?: string;
  isLastPayment: boolean;
  noticeDownloadEnabled: boolean;
  isReducedPayment: boolean;
};

const STYLE_BUTTONS: CSSProperties = {
  margin: '10px',
};

class FpsPayment extends React.Component<Props> {
  openPaymentProofPdf = () => {
    const { fpsId } = this.props;
    this.openPdf(getProofUrl(fpsId), null);
  };

  openNoticePdf = () => {
    const { fpsId, isReducedPayment } = this.props;
    this.openPdf(getNoticeUrl(fpsId), { reduced: isReducedPayment || 'false' });
  };

  openPdf = (url: string, params: any | null | undefined = {}) => {
    const queryParams = querystring.stringify({
      ...params,
    });
    openNewAuthentifiedTab(`${url}?${queryParams}`);
  };

  render() {
    const {
      payment,
      fpsmId,
      isLastPayment,
      noticeDownloadEnabled,
      isReducedPayment,
    } = this.props;

    return (
      <div style={{ width: '100%' }}>
        <DataBoxItemWrapper>
          <DataBoxItem>
            <FakeInputBlock
              title="Date"
              value={<Date datetime={payment.datetime} withTime={false} />}
            />
            <FakeInputBlock
              title="Heure"
              value={<Date datetime={payment.datetime} withDate={false} />}
            />
          </DataBoxItem>
          <Separator />
          <DataBoxItem>
            <FakeInputBlock
              title="Montant payé"
              value={<Price price={payment.amount} />}
            />
            {!fpsmId && (
              <FakeInputBlock
                title="Montant restant à payer"
                value={<Price price={payment.amountToPay} />}
                inputStyle={{ border: `1px solid ${BKG_PINK}` }}
              />
            )}
          </DataBoxItem>
          <Separator />
          <DataBoxItem>
            <FakeInputBlock title="Origine" value={payment.origin} />
            {payment.agent && (
              <FakeInputBlock title="Agent" value={payment.agent.name} />
            )}
            {payment.id && (
              <FakeInputBlock title="ID de paiement DGFiP" value={payment.id} />
            )}
          </DataBoxItem>
          <Separator />
          <DataBoxItem>
            <FakeInputBlock title="Moyen de Paiement" value={payment.mode} />
            {fpsmId && <FakeInputBlock title="FPS majoré" value={fpsmId} />}
            {payment.receptionDatetime && (
              <FakeInputBlock
                title="Date de réception"
                value={
                  <Date datetime={payment.receptionDatetime} withTime={false} />
                }
              />
            )}
          </DataBoxItem>
        </DataBoxItemWrapper>
        {isLastPayment && (
          <div>
            <BoButton
              style={STYLE_BUTTONS}
              onClick={this.openPaymentProofPdf}
              primary
              label="Justificatif global de Paiement"
            />
            {noticeDownloadEnabled && (
              <BoButton
                style={STYLE_BUTTONS}
                onClick={this.openNoticePdf}
                primary
                label={`Avis de Paiement${isReducedPayment ? ' minoré' : ''}`}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

export default FpsPayment;
