import * as React from 'react';
import { useRouteMatch } from 'react-router-dom';

import { SimpleSubscriberDTO } from '@cvfm-front/tefps-types';

import SimpleTable from '../../../commons/SimpleTable';
import '../../../tefps/Subscription/SubscriberTablePage.css';
import BoButton from '../../../facade/BoButton';

const { _t, _tg } = window.loadTranslations(__filename);

type TaoSubscriberTableProps = {
  pagedSubscribers: Array<SimpleSubscriberDTO>;
};

const TaoSubscriberTable = ({
  pagedSubscribers: subscribers,
}: TaoSubscriberTableProps): JSX.Element => {
  const { url } = useRouteMatch();

  const getHeaderCols = () => [
    {
      label: _t('table.subscriberName'),
      width: 240,
      grow: 1,
      sortId: 'lastName.raw',
      onSort: true,
    },
    {
      label: _t('table.email'),
      width: 240,
      grow: 1,
      sortId: 'email',
      onSort: false,
    },
    { label: '', width: 80, grow: 1, onSort: false },
  ];

  const renderSubscriberNameCell = (
    subscriber: SimpleSubscriberDTO
  ): JSX.Element => {
    const name = `${subscriber.lastName || ''} ${subscriber.firstName || ''}`;
    return (
      <a
        id={`${subscriber.subscriberId}-id`}
        className="order-table-row_id"
        href={`#${url}/${subscriber.subscriberId}`}
      >
        {name}
      </a>
    );
  };

  const renderTextFieldCell = (
    subscriber: SimpleSubscriberDTO,
    field: string
  ): JSX.Element => {
    return (
      <span
        id={`${subscriber.subscriberId}-${field}`}
        className="subscriber-table-row"
      >
        {subscriber[field] as string}
      </span>
    );
  };

  const renderDetailCell = (subscriber: SimpleSubscriberDTO): JSX.Element => {
    return (
      <span
        id={`${subscriber.subscriberId}-detail`}
        className="order-table-row_delete"
      >
        <BoButton
          primary
          label={_tg('field.detail')}
          href={`#${url}/${subscriber.subscriberId}`}
        />
      </span>
    );
  };

  const renderRow = (subscriber: SimpleSubscriberDTO) => {
    const row = [
      renderSubscriberNameCell(subscriber),
      renderTextFieldCell(subscriber, 'email'),
      renderDetailCell(subscriber),
    ];
    return row;
  };

  return (
    <SimpleTable
      style={{ marginTop: 25 }}
      cols={getHeaderCols()}
      rowHeight={50}
      itemsRenderer={renderRow}
      items={subscribers}
    />
  );
};

export default TaoSubscriberTable;
