import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import _cloneDeep from 'lodash.clonedeep';
import { History, Location } from 'history';
import CircularProgress from 'material-ui/CircularProgress';

import { fetchAllFpsId, getExport } from 'api/fps';
import FlexCenter from 'commons/FlexCenter';
import Content from 'commons/Content';
import Exporter, { Column } from 'commons/Exporter';
import ErrorBlock from 'commons/ErrorBlock';
import { ListBody, ListBottom, ListWrapper } from 'commons/ListWrappers';
import AdvertisingModal from 'commons/AdvertisingModal';
import { FpsOverviewDTO } from 'api/fps/types';
import { getApiState } from 'api/duck';
import { FpsCancelProposalReason, getConfigState } from 'config/duck';
import PdfExportConfirmationModal from 'tefps/PdfExport/PdfExportConfirmationModal';
import PdfExportButton from 'tefps/PdfExport/PdfExportButton';
import useZoning from 'commons/hooks/useZoning';
import services from 'commons/services';
import useWatcher from 'commons/hooks/useWatcher';
import { DEFAULT_PAGER } from 'commons/services/FpsListService';

import { FpsSearchCriterias } from './types';
import FpsTable from './FpsTable';
import { EXPORT_COL, filtersToRequest, STATUSES_FILTER_OPTIONS } from './utils';
import AddFPS from './AddFPS';
import FpsFilters from './FpsFilters';
import VehicleConsistency from './VehicleConsistency';
import CancelProposal from './CancelProposal';

const STYLE_CONTENT: CSSProperties = {
  display: 'flex',
  height: '100%',
  overflowY: 'hidden',
};

const STYLE_CANCEL_BUTTON: CSSProperties = {
  marginLeft: 'auto',
  display: 'flex',
  flexDirection: 'row',
  gap: 10,
};

const STYLE_MARGIN_LEFT: CSSProperties = {
  marginLeft: 20,
};

const MAX_CANCELLABLE_FPS = 9999;

export type FpsActions = 'FPS_CONSISTENCY' | 'PROPOSAL_CANCELLATION';

type Props = {
  agentId: string;
  canAddFps: boolean;
  canExportPdfs: boolean;
  canReadFps: boolean;
  canUpdateFps: boolean;
  canUpdateFpsConsistency: boolean;
  fpsConsistencyEnabled: boolean;
  cancelFpsMultipleEnabledForThisAgent: boolean;
  canExport: boolean;
  history: History; // we can access history thanks to the <Route /> component in ../index
  location: Location;
  fpsCancelProposalReasons: Array<FpsCancelProposalReason>;
  moduleRapoEnabled: boolean;
};

const FpsList = (props: Props): JSX.Element => {
  const {
    canReadFps,
    canExport,
    canAddFps,
    canExportPdfs,
    canUpdateFps,
    canUpdateFpsConsistency,
    fpsConsistencyEnabled,
    cancelFpsMultipleEnabledForThisAgent,
    history,
    location,
    fpsCancelProposalReasons,
    moduleRapoEnabled,
    agentId,
  } = props;

  const zoning = useZoning();
  const isLoading = useWatcher(
    services.fpsList.watchLoading,
    services.fpsList.isLoading()
  );
  const error = useWatcher(
    services.fpsList.watchError,
    services.fpsList.getError()
  );
  const sortParameter = useWatcher(
    services.fpsList.watchSortParameter,
    services.fpsList.getSortParameter()
  );
  const filters = useWatcher(
    services.fpsList.watchFilters,
    services.fpsList.getFilters()
  );
  const fpsSearchResult = useWatcher(
    services.fpsList.watchFpses,
    services.fpsList.getFpses()
  );

  const [openProposal, setOpenProposal] = useState(false);
  const [selectAllFps, setSelectAllFps] = useState(false);
  const [selectAllFpsAwaitingReview, setSelectAllFpsAwaitingReview] = useState(
    false
  );
  const [cancelList, setCancelList] = useState<string[]>([]);
  const [awaitReviewList, setAwaitReviewList] = useState<string[]>([]);
  const actionRef = useRef<FpsActions | undefined>(undefined);

  // Effet de maj de la sélection des FPS
  useEffect(() => {
    const isProposalCancellation =
      actionRef.current === 'PROPOSAL_CANCELLATION';
    const isFpsConsistencyReviewing = actionRef.current === 'FPS_CONSISTENCY';

    const areAllSelected = (
      listFpses: string[] | null | undefined
    ): boolean => {
      const totalHits = fpsSearchResult ? fpsSearchResult.totalHits : 0;
      return (
        !!listFpses &&
        listFpses.length > 0 &&
        (listFpses.length === totalHits ||
          listFpses.length === MAX_CANCELLABLE_FPS)
      );
    };
    if (isProposalCancellation) {
      setSelectAllFps(areAllSelected(cancelList));
    }
    if (isFpsConsistencyReviewing) {
      setSelectAllFpsAwaitingReview(areAllSelected(awaitReviewList));
    }
  }, [awaitReviewList, cancelList, fpsSearchResult]);

  const handleCheckProposal = (
    event: React.MouseEvent<HTMLTableElement>,
    isInputChecked: boolean
  ): void => {
    const target = event.currentTarget;
    if (!(target instanceof HTMLInputElement)) return; // Guard

    if (!cancelList) return;
    const { fpsId } = target.dataset;

    setCancelList(
      isInputChecked
        ? ([] as string[]).concat(cancelList, fpsId as string)
        : cancelList.filter(x => x !== fpsId)
    );
  };

  const handleCheckFpsConsistency = (
    event: React.MouseEvent<HTMLTableElement>,
    isInputChecked: boolean
  ): void => {
    const target = event.currentTarget;
    if (!(target instanceof HTMLInputElement)) return; // Guard

    if (!awaitReviewList) return;
    const { fpsId } = target.dataset;

    setAwaitReviewList(
      isInputChecked
        ? ([] as string[]).concat(awaitReviewList, fpsId as string)
        : awaitReviewList.filter(x => x !== fpsId)
    );
  };

  const handleCheck = (
    event: React.MouseEvent<HTMLTableElement>,
    isInputChecked: boolean
  ): void => {
    if (actionRef.current === 'PROPOSAL_CANCELLATION') {
      handleCheckProposal(event, isInputChecked);
    }
    if (actionRef.current === 'FPS_CONSISTENCY') {
      handleCheckFpsConsistency(event, isInputChecked);
    }
  };

  const toggle = (newActionRef: FpsActions, b: boolean | undefined): void => {
    if (b !== undefined) {
      setOpenProposal(b);
    } else {
      setOpenProposal(!openProposal);
      actionRef.current = !openProposal ? newActionRef : undefined;
    }
  };

  const toggleCancelProposal = (b?: boolean): void => {
    toggle('PROPOSAL_CANCELLATION', b);
    if (b !== undefined || !b) {
      setSelectAllFps(false);
    }
  };

  const toggleVehicleConsistency = (b?: boolean): void => {
    toggle('FPS_CONSISTENCY', b);
    if (b !== undefined || !b) {
      setSelectAllFpsAwaitingReview(false);
    }
  };

  const processableList = (): string[] | null | undefined => {
    if (actionRef.current === 'PROPOSAL_CANCELLATION') {
      return cancelList;
    }
    if (actionRef.current === 'FPS_CONSISTENCY') {
      return awaitReviewList;
    }
    return null;
  };

  const getValidFiltersQuery = (): FpsSearchCriterias => {
    const allFilters = _cloneDeep(filters);

    // We do not fetch the ABANDONED FPSes
    allFilters.fpsStatuses.delete('ABANDONED');

    if (allFilters.fpsStatuses.size === 0) {
      allFilters.fpsStatuses = new Set(
        STATUSES_FILTER_OPTIONS()
          .filter(s => s.value !== 'ABANDONED')
          .map(s => s.value)
      );
    }
    return allFilters;
  };

  const fetchAllValidFpsId = async (): Promise<void> => {
    try {
      const fpsesId = await fetchAllFpsId(
        filtersToRequest(getValidFiltersQuery(), sortParameter, {
          page: 0,
          maxRecords: MAX_CANCELLABLE_FPS,
        })
      );
      setSelectAllFps(true);
      setCancelList(fpsesId);
    } catch (err) {
      services.fpsList.reportError(err);
    }
  };

  const fetchAllFpsAwaitingConsistencyReview = async (): Promise<void> => {
    try {
      const allFilters = getValidFiltersQuery();
      allFilters.fpsVehicleConsistencyStatuses = new Set([
        'INCONSISTENT_AWAIT_REVIEW',
      ]);

      const awaitReviwFpsesId = await fetchAllFpsId(
        filtersToRequest(allFilters, sortParameter, {
          page: 0,
          maxRecords: MAX_CANCELLABLE_FPS,
        })
      );
      setSelectAllFpsAwaitingReview(true);
      setAwaitReviewList(awaitReviwFpsesId);
    } catch (err) {
      services.fpsList.reportError(err);
    }
  };

  // Effet sur le premier mount de la page
  useEffect(() => {
    // init du service de zoning
    void services.zoning.init();

    // init du service fps list avec la location
    void services.fpsList.init(
      location && location.search ? location.search : null
    );
  }, []);

  // Si erreur ou manque de droit
  if (error || !canReadFps) {
    return (
      <Content>
        <FlexCenter>
          <ErrorBlock
            message="Erreur lors de la récupération des FPS"
            error={error}
          />
        </FlexCenter>
      </Content>
    );
  }

  const items =
    fpsSearchResult && fpsSearchResult.fpses ? fpsSearchResult.fpses : [];
  const totalHits = fpsSearchResult ? fpsSearchResult.totalHits : 0;

  const getExportColumns = (): Array<Column> => {
    const exportColumns = moduleRapoEnabled
      ? EXPORT_COL
      : EXPORT_COL.filter(item => item.key !== 'rapoNumber');
    return fpsConsistencyEnabled
      ? exportColumns
      : exportColumns.filter(item => item.key !== 'vehicleConsistencyStatus');
  };

  const disableRow = (fps: FpsOverviewDTO): boolean => {
    if (actionRef.current === 'PROPOSAL_CANCELLATION') {
      return fps.fpsState === 'ABANDONED';
    }
    if (actionRef.current === 'FPS_CONSISTENCY') {
      return fps.vehicleConsistencyStatus !== 'INCONSISTENT_AWAIT_REVIEW';
    }
    return false;
  };

  return (
    <div style={STYLE_CONTENT}>
      <AdvertisingModal module="fps" />
      <FpsFilters
        totalHits={totalHits}
        facetings={(fpsSearchResult || {}).checkboxFaceting}
        zoning={zoning}
      />
      <ListWrapper>
        <ListBody loading={!fpsSearchResult}>
          <FpsTable
            items={items}
            onUpdateSort={services.fpsList.updateSortParameter}
            onCheck={handleCheck}
            processList={processableList()}
            openProposal={openProposal}
            loadMoreRows={services.fpsList.loadMoreRows}
            remoteRowCount={fpsSearchResult ? fpsSearchResult.totalHits : 0}
            colSorted={sortParameter.sortField}
            sortOrder={sortParameter.increasingOrder}
            disabled={disableRow}
          />
        </ListBody>
        <ListBottom style={{ position: 'relative' }}>
          {canExport && (
            <Exporter
              disabled={totalHits === 0}
              columns={getExportColumns()}
              type="FPS"
              filters={filtersToRequest(filters, sortParameter, DEFAULT_PAGER)}
              fileExport={getExport}
            />
          )}
          {canAddFps && (
            <div style={STYLE_MARGIN_LEFT}>
              <AddFPS history={history} zoning={zoning} />
            </div>
          )}
          <div style={STYLE_CANCEL_BUTTON}>
            {(actionRef.current === undefined ||
              actionRef.current === 'PROPOSAL_CANCELLATION') &&
              canReadFps &&
              cancelFpsMultipleEnabledForThisAgent && (
                <CancelProposal
                  agentId={agentId}
                  toggleCancelProposal={toggleCancelProposal}
                  fetchAllFpsId={fetchAllValidFpsId}
                  canUpdateFps={canUpdateFps}
                  selectAllFps={selectAllFps}
                  reportError={services.fpsList.reportError}
                  cancelList={cancelList}
                  reloadFpsList={services.fpsList.reload}
                  updateCancelList={setCancelList}
                  fpsCancelProposalReasons={fpsCancelProposalReasons}
                />
              )}
            {fpsConsistencyEnabled &&
              actionRef.current === 'FPS_CONSISTENCY' &&
              canReadFps &&
              canUpdateFps && (
                <VehicleConsistency
                  canUpdateFpsConsistency={canUpdateFpsConsistency}
                  selectAllFps={selectAllFpsAwaitingReview}
                  processList={awaitReviewList}
                  toggleModal={toggleVehicleConsistency}
                  fetchAllFpsId={fetchAllFpsAwaitingConsistencyReview}
                  reloadFpsList={services.fpsList.reload}
                  reportError={services.fpsList.reportError}
                  updateProcessList={setAwaitReviewList}
                />
              )}
          </div>

          {canExportPdfs && (
            <PdfExportButton
              search={filtersToRequest(filters, sortParameter, DEFAULT_PAGER)}
            />
          )}
          {canExportPdfs && (
            <PdfExportConfirmationModal numberOfPdf={totalHits} />
          )}
          {isLoading && (
            <div style={{ position: 'absolute', left: '50%' }}>
              <CircularProgress />
            </div>
          )}
        </ListBottom>
      </ListWrapper>
    </div>
  );
};

export default connect(state => {
  const {
    cancelFpsMultipleEnabled,
    fpsCancelProposalReasons,
    sivConfiguration,
    modulesConfiguration,
  } = getConfigState(state);
  const { userInfo } = getApiState(state);
  const moduleRapoEnabled = modulesConfiguration.rapo.enabled;
  return {
    agentId: userInfo?.name,
    canAddFps: userInfo && userInfo.rights.includes('FPS_CREATE'),
    canExportPdfs: userInfo && userInfo.rights.includes('FPS_PDF_EXPORT'),
    canUpdateFps: userInfo && userInfo.rights.includes('FPS_UPDATE'),
    canReadFps: userInfo && userInfo.rights.includes('FPS_READ'),
    canUpdateFpsConsistency:
      userInfo && userInfo.rights.includes('FPS_CONSISTENCY_UPDATE'),
    canExport: userInfo && userInfo.rights.includes('BACKOFFICE_FPS_EXPORT'),
    cancelFpsMultipleEnabledForThisAgent:
      cancelFpsMultipleEnabled &&
      userInfo &&
      userInfo.rights.includes('FPS_MULTIPLE_CANCEL'),
    fpsCancelProposalReasons,
    fpsConsistencyEnabled: !!sivConfiguration?.inconsistentFpsDelay,
    moduleRapoEnabled,
  };
})(FpsList);
