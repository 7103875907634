import React, { useMemo } from 'react';

import Sidebar from 'commons/SidebarV2';
import BarTitle from 'commons/SidebarV2/Components/BarTitle';
import HitsCounter from 'commons/SidebarV2/Components/HitsCounter';
import Input from 'commons/SidebarV2/Components/Input';
import useWatcher from 'commons/hooks/useWatcher';
import services from 'commons/services';
import { TaoProductSearchRequestDTO } from '@cvfm-front/tefps-types';

const { _tg } = window.loadTranslations(__filename);

const ProductTableSideBar = (): JSX.Element => {
  const taoProductSearchService = useMemo(
    () => services.taoProductSearchService,
    []
  );
  const request = useWatcher(services.taoProductSearchService.watchRequest, {});
  const totalHits = useWatcher(
    services.taoProductSearchService.watchTotalHits,
    0
  );

  const onChange = (id: keyof TaoProductSearchRequestDTO, value: unknown) => {
    taoProductSearchService.setRequest({
      ...request,
      [id]: value,
    });
  };

  return (
    <Sidebar>
      <BarTitle resetFilters={taoProductSearchService.resetRequest} />
      <HitsCounter hits={totalHits} />
      <Input
        id="productId"
        title={_tg('field.id')}
        placeholder=""
        onChange={onChange}
        value={request.productId ?? ''}
      />
      <Input
        id="name"
        title={_tg('field.human.lastname_short')}
        placeholder=""
        onChange={onChange}
        value={request.name ?? ''}
      />
    </Sidebar>
  );
};

export default ProductTableSideBar;
