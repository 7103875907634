import React, { useCallback, useMemo } from 'react';
import Dialog from 'material-ui/Dialog';
import CircularProgress from 'material-ui/CircularProgress';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { TaoSubscriberDTO } from '@cvfm-front/tefps-types';
import { GenericFormV2 } from '@cvfm-front/commons-ui';
import { FormFieldTypeWithTitleV2 } from '@cvfm-front/commons-types';

import services from '../../../commons/services';
import useWatcher from '../../../commons/hooks/useWatcher';
import {
  validateEmail,
  validateFirstName,
  validateLastName,
} from '../../../commons/validatorsV2';

const { _t, _tg } = window.loadTranslations(__filename);

type AddTaoSubscriberProps = {
  closeModal: () => void;
};

const AddTaoSubscriber = ({
  closeModal,
}: AddTaoSubscriberProps): JSX.Element => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const taoSubscriberCreate = useMemo(() => services.taoSubscriberCreate, []);
  const request = useWatcher(taoSubscriberCreate.watchRequest, {
    lastName: undefined,
    firstName: undefined,
    email: undefined,
  });

  const isUploading = useWatcher(taoSubscriberCreate.watchIsUploading, false);
  const creationError = useWatcher(
    taoSubscriberCreate.watchCreationError,
    null
  );

  const goToNewSubscriberPage = useCallback(
    (res: TaoSubscriberDTO | undefined) => {
      if (res) {
        history.push(`${url}/${res.subscriberId}`);
      }
    },
    []
  );

  const formFields: FormFieldTypeWithTitleV2[] = [
    {
      type: 'title',
      label: _t('dialog.sectionsTitles.mainInfo'),
    },
    {
      type: 'text',
      label: _tg('field.human.lastname_short'),
      value: request?.lastName,
      onValueChange: v => {
        taoSubscriberCreate.setRequest({
          ...request,
          lastName: v,
        });
      },
      required: true,
      validator: validateFirstName,
    },
    {
      type: 'text',
      label: _tg('field.human.firstname'),
      value: request?.firstName,
      onValueChange: v => {
        taoSubscriberCreate.setRequest({
          ...request,
          firstName: v,
        });
      },
      required: true,
      validator: validateLastName,
    },
    {
      type: 'text',
      label: _tg('field.human.email'),
      value: request?.email,
      onValueChange: v => {
        taoSubscriberCreate.setRequest({
          ...request,
          email: v,
        });
      },
      required: true,
      validator: validateEmail,
    },
  ];

  const addNewSubscriber = useCallback(async () => {
    const res = await taoSubscriberCreate.addTaoSubscriber();
    taoSubscriberCreate.resetRequest();
    goToNewSubscriberPage(res);
  }, []);

  return (
    <Dialog title={_t('dialog.title')} modal autoScrollBodyContent open>
      {isUploading && <CircularProgress />}
      <GenericFormV2
        fields={formFields}
        onSubmitCallback={addNewSubscriber}
        onCancelCallback={closeModal}
        cancelLabel={_tg('action.cancel')}
        submitLabel={_tg('action.create')}
        errorMessage={creationError ? creationError.message : undefined}
      />
    </Dialog>
  );
};

export default AddTaoSubscriber;
