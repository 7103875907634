import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';
import { Checkbox, CircularProgress, Dialog } from 'material-ui';
import moment from 'moment';

import BoButton from 'facade/BoButton';
import ErrorBlock from 'commons/ErrorBlock';
import FormComponent from 'commons/FormComponent';
import {
  DatetimePickerCustom,
  SelectFieldCustom,
  TextFieldCustom,
} from 'commons/FormComponent/Fields';
import SeparatorWithTitle from 'commons/SeparatorWithTitle';
import { validateBeforeToday } from 'commons/Validators';
import {
  BKG_DARK_BLUE,
  BKG_PINK,
  STYLE_ERROR_WRAPPER,
  STYLE_LOADING_WRAPPER,
} from 'theme';
import {
  RecourseFileDTO,
  RecourseReason,
  RecourseUser,
} from 'api/recourse/types';
import { createRecourse, initRecourseCreation } from 'api/recourse';
import { UploadFile } from 'api/commonTypes';
import { getConfigState } from 'config/duck';
import { ApiError } from 'api/ApiError';
import { AttachedField } from 'commons/FormComponent/types';

import { RAPO_REASONS } from '../../../../RecoursesV2/pages/Detail/helpers';

import Contact from './Contact';
import UploadFilesRecourse from './UploadFilesRecourse';
import { uploadRapoFiles } from './helpers';

const { _tg } = window.loadTranslations(__filename);

const STYLE_TITLE: CSSProperties = {
  backgroundColor: BKG_DARK_BLUE,
  color: '#ffffff',
  fontWeight: 'bold',
};

const STYLE_CONTENT_WRAPPER: CSSProperties = {
  marginTop: '-6px',
  width: '100%',
};

const STYLE_SEPARATOR: CSSProperties = {
  width: '100%',
  marginTop: '20px',
};

const STYLE_INPUTS: CSSProperties = {
  borderColor: BKG_DARK_BLUE,
  color: BKG_DARK_BLUE,
};

const RAPO_REASON_OPTIONS = [
  'PAYE',
  'CESSION',
  'DISPENSE',
  'DESTRUCTION',
  'VOL',
  'USURPATION',
  'AVANCE',
  'AUTRE',
].map(id => ({
  id,
  name: RAPO_REASONS[id],
}));

type FormValues = {
  receptionDate: Date | null | undefined;
  user: RecourseUser;
  representative: RecourseUser | null | undefined;
  transferUser: RecourseUser | null | undefined;
  reason: RecourseReason;
  comment: string;
  files: {
    [key: string]: UploadFile;
  };
  shouldSendLetters: boolean;
};

type AddRecourseProps = {
  isOpen: boolean;
  close: () => any;
  rootFpsId: string;
  fpsStatementDatetime: string;
  reloadFps: () => any;
  sendingRecourseLetterEnabled: boolean;
  watermarkText: string | null;
};

type AddRecourseState = {
  loading: boolean;
  error: string | null | undefined;
  hasRepresentative: boolean;
  shouldSendLetters: boolean;
  recourseReason: RecourseReason;
  uploadErrors: {
    [key: string]: string;
  };
};

const initialState = (): AddRecourseState => ({
  loading: false,
  error: null,
  hasRepresentative: false,
  shouldSendLetters: true, // changed to false in back if sendingRecourseLetterEnabled is false
  recourseReason: 'PAYE',
  uploadErrors: {},
});

class AddRecourse extends React.Component<AddRecourseProps, AddRecourseState> {
  recourseForm: any = null;

  constructor(props: AddRecourseProps) {
    super(props);
    this.state = initialState();
  }

  getErrorMessage = (
    error: ApiError,
    rapoFiles: Array<RecourseFileDTO>
  ): string => {
    let errorMessage: string = _tg('feedback.error.simple', {
      error: error.message,
    });

    if (error.name === 'InvalidFile') {
      const corruptedFile = rapoFiles.find(obj => {
        return error.message.includes(obj.urn);
      });
      if (corruptedFile) {
        errorMessage = _tg(`feedback.error.InvalidFile`, {
          fileName: corruptedFile.name,
        });
      }
    }
    return errorMessage;
  };

  onClose = (): void => {
    const { close } = this.props;
    close();
    this.setState(initialState());
  };

  onClickValidate = async (): Promise<void> => {
    const { rootFpsId, close, reloadFps, watermarkText } = this.props;
    const form = this.recourseForm;
    if (!form) {
      return;
    }
    const fields = form.getFields();
    let fileCount = 0;

    fields.forEach((field: AttachedField) => {
      if (field.getName().includes('files.') && field.getValue()?.file) {
        fileCount += 1;
      }
    });

    if (!fileCount) {
      this.setState(prev => ({
        uploadErrors: { ...prev.uploadErrors, filesEmpty: 'mandatory' },
      }));
    }

    if (!form.isValid()) {
      return;
    }
    const formValues: FormValues = form.getFormEntries();

    this.setState({ loading: true, error: null });
    const rapoFiles: Array<RecourseFileDTO> = [];
    try {
      // Init upload
      const { rapoId } = await initRecourseCreation();

      // Upload files
      const uploadErrors = await uploadRapoFiles(
        formValues.files,
        rapoId,
        watermarkText,
        rapoFiles
      );
      this.setState({ uploadErrors });
      if (Object.keys(uploadErrors).length > 0) {
        this.setState({ loading: false });
        return;
      }

      // Send rapo creation request
      await this.sendRapo(formValues, rootFpsId, rapoId, rapoFiles);

      this.setState(initialState());
      close();
      reloadFps();
    } catch (err) {
      this.setState({
        loading: false,
        error: this.getErrorMessage(err as ApiError, rapoFiles),
      });
    }
  };

  onCheckRepresentative = (
    e: React.MouseEvent<HTMLInputElement>,
    hasRepresentative: boolean
  ): void => {
    this.setState({ hasRepresentative });
  };

  onCheckShouldSendLetters = (
    e: React.MouseEvent<HTMLInputElement>,
    shouldSendLetters: boolean
  ): void => {
    this.setState({ shouldSendLetters });
  };

  onChangeReason = (recourseReason: RecourseReason): void => {
    this.setState({ recourseReason });
  };

  validateDate = (value: Date): string | undefined => {
    const { fpsStatementDatetime } = this.props;
    if (moment(value).isBefore(fpsStatementDatetime, 'day')) {
      return 'La date ne peut être antérieure à celle du fps';
    }
    return undefined;
  };

  sendRapo = async (
    formValues: FormValues,
    rootFpsId: string,
    rapoId: string,
    rapoFiles: Array<RecourseFileDTO>
  ): Promise<void> => {
    const { shouldSendLetters } = this.state;
    const creationParam = {
      fpsId: rootFpsId,
      receptionDate: formValues.receptionDate,
      user: formValues.user,
      representative: formValues.representative,
      transferUser: formValues.transferUser,
      reason: formValues.reason,
      comment: formValues.comment,
      files: rapoFiles,
      shouldSendLetters,
    };
    await createRecourse(rapoId, creationParam);
  };

  attachRef = (node: unknown): void => {
    this.recourseForm = node;
  };

  render(): JSX.Element {
    const {
      loading,
      error,
      hasRepresentative,
      recourseReason,
      uploadErrors,
      shouldSendLetters,
    } = this.state;
    const { isOpen, sendingRecourseLetterEnabled } = this.props;

    const actions = [
      <BoButton
        style={{ marginRight: 10 }}
        key={1}
        label="Annuler"
        onClick={this.onClose}
      />,
      <BoButton
        key={2}
        label="Ajouter"
        primary
        keyboardFocused
        onClick={this.onClickValidate}
      />,
    ];

    return (
      <Dialog
        actions={actions}
        title="Déposer un RAPO"
        open={isOpen}
        onRequestClose={this.onClose}
        titleStyle={STYLE_TITLE}
        autoScrollBodyContent
      >
        {loading && (
          <div style={STYLE_LOADING_WRAPPER}>
            <CircularProgress />
          </div>
        )}
        {error && (
          <div style={STYLE_ERROR_WRAPPER}>
            <ErrorBlock error={{ message: error }} />
          </div>
        )}
        {!error && (
          <FormComponent ref={this.attachRef}>
            <Checkbox
              label="Demande effectuée par un représentant légal ou un tiers"
              onCheck={this.onCheckRepresentative}
              style={{ marginTop: '1.7em' }}
            />
            {sendingRecourseLetterEnabled && (
              <Checkbox
                label="Cet usager doit être contacté par courrier"
                checked={shouldSendLetters}
                onCheck={this.onCheckShouldSendLetters}
                style={{ marginTop: '1.7em' }}
              />
            )}
            <SeparatorWithTitle
              style={STYLE_SEPARATOR}
              title="Informations du redevable"
              color={BKG_PINK}
              titleSize={20}
            />
            <div style={STYLE_CONTENT_WRAPPER}>
              <DatetimePickerCustom
                hintDate="Date de réception de la demande"
                hideTime
                name="receptionDate"
                validators={[validateBeforeToday, this.validateDate]}
                mandatory
                styleField={{ width: '40%' }}
              />
            </div>
            <Contact
              field="user"
              mandatoryAddress={shouldSendLetters}
              /* confirmEmail TODO: flow-to-ts disable useless ? */
              styleInputs={STYLE_INPUTS}
            />
            {hasRepresentative && (
              <div>
                <SeparatorWithTitle
                  style={STYLE_SEPARATOR}
                  title="Informations du représentant légal ou d'un tiers"
                  color={BKG_PINK}
                  titleSize={20}
                />
                <Contact
                  field="representative" /* confirmEmail TODO: flow-to-ts disable useless ? */
                  mandatoryAddress={shouldSendLetters}
                  styleInputs={STYLE_INPUTS}
                />
              </div>
            )}
            <SeparatorWithTitle
              style={STYLE_SEPARATOR}
              title="Nature de la demande"
              color={BKG_PINK}
              titleSize={20}
            />
            <div style={STYLE_CONTENT_WRAPPER}>
              <SelectFieldCustom
                name="reason"
                hint="Nature de la demande"
                items={RAPO_REASON_OPTIONS}
                value={recourseReason}
                mandatory
                onChange={this.onChangeReason}
                underlineFocusStyle={STYLE_INPUTS}
                floatingLabelFocusStyle={STYLE_INPUTS}
                fullWidth
              />
            </div>
            <div style={STYLE_CONTENT_WRAPPER}>
              <TextFieldCustom
                name="comment"
                hint="Message du demandeur"
                type="text"
                multiLine
                rows={3}
                underlineFocusStyle={STYLE_INPUTS}
                floatingLabelFocusStyle={STYLE_INPUTS}
                fullWidth
              />
            </div>
            {recourseReason === 'CESSION' && (
              <div>
                <SeparatorWithTitle
                  style={STYLE_SEPARATOR}
                  title="Informations du nouveau propriétaire"
                  color={BKG_PINK}
                  titleSize={20}
                />
                <Contact
                  field="transferUser"
                  mandatoryAddress={shouldSendLetters}
                  styleInputs={STYLE_INPUTS}
                  contactCountryIn2Char
                />
              </div>
            )}
            <SeparatorWithTitle
              style={STYLE_SEPARATOR}
              title="Pièces justificatives"
              color={BKG_PINK}
              titleSize={20}
            />
            <UploadFilesRecourse name="files" errors={uploadErrors} />
          </FormComponent>
        )}
      </Dialog>
    );
  }
}

export default connect(state => {
  const { sendingRecourseLetterEnabled, watermarkText } = getConfigState(state);
  return {
    sendingRecourseLetterEnabled,
    watermarkText,
  };
})(AddRecourse);
