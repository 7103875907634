import { FormFieldType } from '@cvfm-front/commons-types';

const IdentityFields: FormFieldType[] = [
  {
    id: 'gender',
    label: 'Genre',
    type: 'select',
    defaultValue: '',
    selectOptions: [
      { value: '', label: 'Genre', disabled: true },
      { value: 'MALE', label: 'Homme' },
      { value: 'FEMALE', label: 'Femme' },
    ],
    required: true,
  },
  { id: 'familyName', label: 'Nom', type: 'text', required: true },
  { id: 'givenName', label: 'Prénom', type: 'text', required: true },
  { id: 'additionalName', label: 'Nom intermédiaire', type: 'text' },
  { id: 'maidenName', label: 'Nom originel', type: 'text' },
  { id: 'honorificPrefix', label: 'Préfixe honorifique', type: 'text' },
  { id: 'email', label: 'Email', type: 'text' },
  { id: 'compagnyName', label: "Nom de l'entreprise", type: 'text' },
];

export default IdentityFields;
