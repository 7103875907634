import React from 'react';
import Smiley from 'material-ui/svg-icons/image/tag-faces';

import { EsSubscriptionStatus } from 'api/fps/types';
import { BKG_GREEN, BKG_ORANGE } from 'theme';

const STATUS_COLOR: { [status in EsSubscriptionStatus]?: string } = {
  NONE: undefined,
  INACTIVE: BKG_ORANGE,
  ACTIVE: BKG_GREEN,
};

const SubscriptionStatus = ({ status }: { status: EsSubscriptionStatus }) => {
  if (status === 'NONE') return null;

  return (
    <div style={{ width: '100%', textAlign: 'center' }}>
      <Smiley color={STATUS_COLOR[status]} />
    </div>
  );
};

export default SubscriptionStatus;
