import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';

import Sidebar from 'commons/SidebarV2';
import BarTitle from 'commons/SidebarV2/Components/BarTitle';
import HitsCounter from 'commons/SidebarV2/Components/HitsCounter';
import Checkboxes, {
  CheckboxContext,
} from 'commons/SidebarV2/Components/Checkboxes';
import Dates from 'commons/SidebarV2/Components/Dates';
import Times from 'commons/SidebarV2/Components/Times';
import PriceRange from 'commons/SidebarV2/Components/PriceRange';
import Autocomplete from 'commons/SidebarV2/Components/Autocomplete';
import Select from 'commons/SidebarV2/Components/Select';
import Input from 'commons/SidebarV2/Components/Input';
import MultiSearch from 'commons/SidebarV2/Components/MultiSearch';
import { buildZoneSelect } from 'commons/Utils/zoneUtils';
import useWatcher from 'commons/hooks/useWatcher';
import services from 'commons/services';
import { UpDownArrow } from 'commons/UpDownArrow';
import { KeyBucketDTO } from 'api/commonTypes';
import { fetchMatchingAgents } from 'api/accounts';
import {
  getConfigState,
  InitialOrganization,
  AuthorizedVehicleCategory,
} from 'config/duck';
import { AgentIdAndNameDTO, ZoningDTO } from '@cvfm-front/tefps-types';
import { Flex } from '@cvfm-front/commons-ui';

import {
  initialFilters,
  buildVehicleCategorySelect,
  CANCEL_PROPOSAL_OPTIONS,
  CCSP_FILTER_OPTIONS,
  MEDIAS_OPTIONS,
  PAYMENT_DEFICIENCY_OPTIONS,
  PAYMENT_FILTER_OPTIONS,
  RAPO_FILTER_OPTIONS,
  REASONS_FILTER_OPTIONS,
  REDUCED_DATETIME_UPDATE_OPTIONS,
  STATUSES_FILTER_OPTIONS,
  SUBSCRIPTION_FILTER_OPTIONS,
  TERMINAL_OPTIONS,
  PARENTS_OPTIONS,
  VEHICLE_CONSISTENCY_OPTIONS,
} from './utils';

const { _tg } = window.loadTranslations(__filename);

type Props = {
  totalHits: number;
  facetings:
    | {
        [key: string]: Array<KeyBucketDTO>;
      }
    | null
    | undefined;
  zoning: ZoningDTO | null | undefined;
  authorizedVehicleCategories: Array<AuthorizedVehicleCategory>;
  initialOrganizationsConfigurations: Array<InitialOrganization>;
  withSubscriptions: boolean;
  cancelFpsMultipleEnabled: boolean;
  fpsConsistencyEnabled: boolean;
  moduleRapoEnabled: boolean;
};

const FpsFilterss = (props: Props): JSX.Element => {
  const {
    zoning,
    authorizedVehicleCategories,
    totalHits,
    withSubscriptions,
    cancelFpsMultipleEnabled,
    fpsConsistencyEnabled,
    moduleRapoEnabled,
    initialOrganizationsConfigurations,

    facetings,
  } = props;

  const filters = useWatcher(
    services.fpsList.watchFilters,
    services.fpsList.getFilters()
  );

  const [agentSearchOptions, setAgentSearchOptions] = useState<
    { id: string; name: string }[]
  >([]);
  const [isParentCollapsing, setIsParentCollapsing] = useState(true);

  // Buid memo zone options
  const zoneOptions = useMemo(() => {
    if (!zoning) return [];
    return buildZoneSelect(zoning.zones);
  }, [zoning]);

  // Build memo vehicle category options
  const vehicleCategoryOptions = useMemo(() => {
    if (!authorizedVehicleCategories) return [];
    return buildVehicleCategorySelect(authorizedVehicleCategories);
  }, [authorizedVehicleCategories]);

  const onChange = (id: string, value: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    void services.fpsList.updateFilters({ ...filters, [id]: value });
  };

  const resetFilters = () =>
    services.fpsList.updateFilters({ ...initialFilters(), agentId: undefined });

  const fetchAgentsAndGenerateOptions = async (
    agentFilter: string | null | undefined
  ) => {
    let agents: Array<AgentIdAndNameDTO> = [];
    if (agentFilter && agentFilter.length >= 3) {
      agents = (await fetchMatchingAgents(agentFilter)).matchingAgents;
    }

    setAgentSearchOptions(
      agents.map(({ id, fullName }) => ({
        id,
        name: fullName,
      }))
    );
  };

  // on "mount", load agents with empty filter and build options
  useEffect(() => {
    void fetchAgentsAndGenerateOptions(null);
  }, []);

  return (
    <CheckboxContext.Provider value={{ isParentCollapsing }}>
      <Sidebar>
        <Flex>
          <BarTitle resetFilters={resetFilters} />
          <UpDownArrow
            isCollapsed={isParentCollapsing}
            className="collapsible-arrow"
            color="#67C5CFFF"
            size="20px"
            isUpAndDownIcon
            setIsCollapsed={value => setIsParentCollapsing(value)}
          />
        </Flex>
        <HitsCounter hits={totalHits} />
        <Dates
          id="statementDatetime"
          title="Dates"
          dates={filters.statementDatetime}
          onChange={onChange}
        />
        <br />
        <Times
          id="times"
          title="Horaires"
          times={filters.times}
          onChange={onChange}
        />
        <br />
        <MultiSearch
          id="fpsOrFpsmIds"
          title="N° de FPS ou de FPS majoré"
          placeholder="Rechercher par numéro de fps"
          modalTitle="Liste de FPS"
          modalFloatingLabelText="Ajouter des numéro de FPS"
          onChange={onChange}
          value={filters.fpsOrFpsmIds}
        />
        <MultiSearch
          id="plates"
          title="Plaques d'immatriculation"
          placeholder="Rechercher par plaque d'immatriculation"
          modalTitle="Liste de plaques d'immatriculation"
          modalFloatingLabelText="Ajouter des plaques d'immatriculation"
          onChange={onChange}
          value={filters.plates}
        />
        <br />
        <Checkboxes
          id="rapoClaimStatuses"
          title="Statut du RAPO administratif"
          options={RAPO_FILTER_OPTIONS()}
          filters={filters.rapoClaimStatuses}
          onChange={onChange}
          faceting={facetings ? facetings.rapoClaimStatuses : null}
        />
        <Checkboxes
          id="ccspClaimStatuses"
          title="Statut du TSP"
          options={CCSP_FILTER_OPTIONS()}
          filters={filters.ccspClaimStatuses}
          onChange={onChange}
          faceting={facetings ? facetings.ccspClaimStatuses : null}
        />
        <Checkboxes
          id="paymentStatuses"
          title="Statut du paiement"
          options={PAYMENT_FILTER_OPTIONS()}
          filters={filters.paymentStatuses}
          onChange={onChange}
          faceting={facetings ? facetings.paymentStatuses : null}
        />
        {withSubscriptions && (
          <Checkboxes
            id="subscriptionStatuses"
            title="Statut de l'ayant-droit"
            options={SUBSCRIPTION_FILTER_OPTIONS()}
            filters={filters.subscriptionStatuses}
            onChange={onChange}
            faceting={facetings ? facetings.subscriptionStatuses : null}
          />
        )}
        <Checkboxes
          id="fpsStatuses"
          title="Statut du FPS"
          options={STATUSES_FILTER_OPTIONS()}
          filters={filters.fpsStatuses}
          onChange={onChange}
          faceting={facetings ? facetings.fpsStatuses : null}
        />
        <Checkboxes
          id="abandonedReasons"
          title="Raison d'abandon auprès de l'ANTAI"
          options={REASONS_FILTER_OPTIONS()}
          filters={filters.abandonedReasons}
          onChange={onChange}
          faceting={facetings ? facetings.abandonedReasons : null}
        />
        <PriceRange
          id="price"
          title="Montant"
          price={filters.price}
          onChange={onChange}
        />
        <Checkboxes
          id="paymentDeficiencyStatuses"
          title="Prise en compte de ticket"
          options={PAYMENT_DEFICIENCY_OPTIONS()}
          filters={filters.paymentDeficiencyStatuses}
          onChange={onChange}
          faceting={facetings ? facetings.paymentDeficiencyStatuses : null}
        />
        <Checkboxes
          id="mediaStatuses"
          title="Medias"
          options={MEDIAS_OPTIONS()}
          filters={filters.mediaStatuses}
          onChange={onChange}
          faceting={facetings ? facetings.mediaStatuses : null}
        />
        <br />
        <Autocomplete
          id="agentId"
          title="ID ou nom d'agent"
          options={agentSearchOptions}
          onChange={onChange}
          onAutocomplete={fetchAgentsAndGenerateOptions}
          search={filters.agentId}
        />
        {initialOrganizationsConfigurations &&
          initialOrganizationsConfigurations.length > 0 && (
            <Select
              id="organizationIds"
              title="Autorité d'émission"
              onChange={onChange}
              selected={[...filters.organizationIds] || undefined}
              multiple
              options={[...initialOrganizationsConfigurations]}
            />
          )}
        <Select
          id="zoneId"
          title="Zone"
          onChange={onChange}
          selected={filters.zoneId}
          options={zoneOptions}
        />
        {vehicleCategoryOptions.length > 1 && (
          <Select
            id="vehicleCategory"
            title="Catégorie de véhicule"
            onChange={onChange}
            selected={[...filters.vehicleCategories]}
            options={vehicleCategoryOptions}
          />
        )}
        <Input
          id="profiles"
          title="Droits de stationnement"
          placeholder="VIS, RES"
          onChange={(id, v) => onChange(id, v.split(','))}
          value={filters.profiles.join(',') || ''}
        />
        <Input
          id="notProfiles"
          title="Droits de stationnement absents"
          placeholder="VIS, RES"
          onChange={(id, v) => onChange(id, v.split(','))}
          value={filters.notProfiles.join(',') || ''}
        />
        <Input
          id="address"
          title="Adresse"
          placeholder="Rechercher par adresse de constat"
          onChange={onChange}
          value={filters.address || ''}
        />
        {moduleRapoEnabled && (
          <MultiSearch
            id="rapoNumbers"
            title="Numéro de RAPO"
            placeholder="Rechercher par numéro de RAPO"
            onChange={onChange}
            value={filters.rapoNumbers}
            modalTitle="N° de RAPO"
            modalFloatingLabelText="Ajouter des numéros de RAPO"
          />
        )}
        <Checkboxes
          id="reducedDatetimeUpdateStatuses"
          title="Minoration"
          options={REDUCED_DATETIME_UPDATE_OPTIONS()}
          filters={filters.reducedDatetimeUpdateStatuses}
          onChange={onChange}
          faceting={facetings ? facetings.reducedDatetimeUpdateStatuses : null}
        />
        {cancelFpsMultipleEnabled && (
          <Checkboxes
            id="cancelProposalStatuses"
            title="Demande d'annulation"
            options={CANCEL_PROPOSAL_OPTIONS()}
            filters={filters.cancelProposalStatuses}
            faceting={facetings ? facetings.cancelProposalStatuses : null}
            onChange={onChange}
          />
        )}
        <Select
          id="origins"
          title="Provenance FPS"
          multiple
          options={TERMINAL_OPTIONS(facetings?.origins, false)}
          selected={filters.origins}
          onChange={onChange}
        />
        <Checkboxes
          id="parentStatuses"
          title="Suite à cession"
          options={PARENTS_OPTIONS()}
          filters={filters.parentStatuses}
          onChange={onChange}
          faceting={facetings ? facetings.parentStatuses : null}
        />
        <Input
          id="fpsComment"
          title="Commentaire"
          placeholder="Rechercher par le commentaire"
          onChange={onChange}
          value={filters.fpsComment || ''}
        />
        <Input
          id="administrativeCertificate"
          title="Numéro de certificat administratif"
          placeholder="Rechercher par le numéro"
          onChange={onChange}
          value={filters.administrativeCertificate || ''}
        />
        {fpsConsistencyEnabled && (
          <Checkboxes
            id="fpsVehicleConsistencyStatuses"
            title={_tg('tefps.filters.fps.vehicleConsistency')}
            options={VEHICLE_CONSISTENCY_OPTIONS()}
            filters={filters.fpsVehicleConsistencyStatuses}
            onChange={onChange}
            faceting={
              facetings ? facetings.fpsVehicleConsistencyStatuses : null
            }
          />
        )}
      </Sidebar>
    </CheckboxContext.Provider>
  );
};

export default connect(state => {
  const {
    authorizedVehicleCategories,
    initialOrganizationsConfigurations,
    cancelFpsMultipleEnabled,
    modulesConfiguration,
    sivConfiguration,
  } = getConfigState(state);
  return {
    authorizedVehicleCategories,
    initialOrganizationsConfigurations,
    cancelFpsMultipleEnabled,
    withSubscriptions: modulesConfiguration.subscribers.enabled,
    fpsConsistencyEnabled: !!sivConfiguration?.inconsistentFpsDelay,
    moduleRapoEnabled: modulesConfiguration.rapo.enabled,
  };
})(FpsFilterss);
