import { Setter, WatchFunctionType } from '@cvfm-front/commons-types';
import {
  TaoSubscriberCreationDTO,
  TaoSubscriberDTO,
} from '@cvfm-front/tefps-types';
import { Watcher } from '@cvfm-front/commons-utils';

import { apiPost } from '../../../api/helpers';

export interface TaoSubscriberCreateServiceInterfaceFactory {
  (): TaoSubscriberCreateServiceInterface;
}

export interface TaoSubscriberCreateServiceInterface {
  addTaoSubscriber: () => Promise<TaoSubscriberDTO | undefined>;
  resetRequest: () => void;
  watchCreationError: WatchFunctionType<Error | undefined>;
  watchIsUploading: WatchFunctionType<boolean>;
  watchRequest: WatchFunctionType<Partial<TaoSubscriberCreationDTO>>;
  setRequest: Setter<Partial<TaoSubscriberCreationDTO>>;
}

const ROOT_API = '/api/proxy/tao/api/v1/{cityId}/subscriber';

const TaoSubscriberCreateService: TaoSubscriberCreateServiceInterfaceFactory = () => {
  const {
    watchValue: watchRequest,
    setValue: setRequest,
    getValue: getRequest,
  } = Watcher<Partial<TaoSubscriberCreationDTO>>({});
  const {
    setValue: setCreationError,
    watchValue: watchCreationError,
  } = Watcher<Error | undefined>(undefined);

  const { setValue: setIsUploading, watchValue: watchIsUploading } = Watcher(
    false
  );

  const addTaoSubscriber: TaoSubscriberCreateServiceInterface['addTaoSubscriber'] = async () => {
    const request = getRequest();
    try {
      setCreationError(undefined);
      setIsUploading(true);
      return await apiPost<TaoSubscriberDTO>(ROOT_API, request);
    } catch (e) {
      if (e.name === 'TaoSubscriberConflict') {
        setCreationError({
          name: 'TaoSubscriberConflict',
          message: 'Cet email est déjà associé à un utilisateur existant.',
        });
      }
      return undefined;
    } finally {
      setIsUploading(false);
    }
  };

  const resetRequest: TaoSubscriberCreateServiceInterface['resetRequest'] = () => {
    setRequest({});
  };

  return {
    addTaoSubscriber,
    resetRequest,
    watchCreationError,
    watchIsUploading,
    watchRequest,
    setRequest,
  };
};

export default TaoSubscriberCreateService;
