import * as React from 'react';
import moment from 'moment';

import Price from 'commons/Price';
import { FpsOverviewDTO } from 'api/fps/types';

const getCurrentPrice = (fps: FpsOverviewDTO): number => {
  if (
    fps.paymentStatus === 'PAID_REDUCED' ||
    fps.paymentStatus === 'OVERPAID_REDUCED'
  ) {
    return fps.reducedFinePrice;
  }
  if (fps.reducedFinePrice && moment().isSameOrBefore(fps.reducedFinePrice)) {
    return fps.reducedFinePrice;
  }
  return fps.finePrice;
};

function compareDateToNow(date: string | undefined) {
  if (date !== null) {
    return moment(date).isAfter(moment());
  }
  return false;
}

const PriceToDisplay = ({ fps }: { fps: FpsOverviewDTO }) => (
  <div>
    <Price price={fps.finePrice} />
    {(getCurrentPrice(fps) !== fps.finePrice ||
      compareDateToNow(fps.reducedDatetime)) && <span>*</span>}
  </div>
);

export default PriceToDisplay;
