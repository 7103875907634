import React from 'react';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';

type Props = {
  isOpen: boolean;
  title: string;
  onSubmit: () => void;
  onClose: () => void;
};
const { _tg } = window.loadTranslations(__filename);

const FpsConsistencyConfirmationModal = ({
  isOpen,
  title,
  onSubmit,
  onClose,
}: Props): JSX.Element => {
  return (
    <Dialog
      title={title}
      open={isOpen}
      modal
      autoScrollBodyContent
      actions={[
        <FlatButton label={_tg('action.cancel')} onClick={onClose} />,
        <FlatButton label={_tg('action.confirm')} primary onClick={onSubmit} />,
      ]}
    >
      <div>
        {_tg(
          'tefps.fps.detail.fpsConsistencyConfirmationModal.confirmationMessage'
        )}
      </div>
    </Dialog>
  );
};
export default FpsConsistencyConfirmationModal;
