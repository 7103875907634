import '@babel/polyfill';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader'; // eslint-disable-line import/no-extraneous-dependencies
// AppContainer is a necessary wrapper component for HMR
import { Provider, Store } from 'react-redux';
import URLSearchParams from 'url-search-params';
import { i18n as I18n } from 'i18next';

import { LoadTranslations, initI18next } from 'i18next-utils';

import { init } from './sentry-utils';
import './moment-conf-fr';
import { getSentryDsn } from './api/root-config';

declare global {
  interface Window {
    i18next: I18n;
    URLSearchParams: any;
    onPhone: boolean;
    cordova: any;
    clipboardData: any;
    loadTranslations: LoadTranslations;
  }
}
window.URLSearchParams = URLSearchParams;

// eslint-disable-next-line no-console
console.log(
    `Running FPS-BO ${__BUILD_VERSION__} commit ${__BUILD_SHA__} built ${__BUILD_TIME__}`, // eslint-disable-line
);
initI18next()
  .then(() => {
    // eslint-disable-next-line
  const createStore = require('./createStore').default;
    // eslint-disable-next-line
  const { registerStore } = require('./api/helpers');

    // eslint-disable-next-line
  const store = createStore();
    // eslint-disable-next-line
  registerStore(store);

    return (
      getSentryDsn()
        .then(res => {
          init(
            res.dsn,
            res.environment,
            res.enabled,
            process.env.SENTRY_RELEASE || '',
            process.env.BUILD_SHA || ''
          );
          const render = (Component: any): void => {
            ReactDOM.render(
              <AppContainer>
                <Provider store={store as Store<unknown>}>
                  <Component />
                </Provider>
              </AppContainer>,
              document.getElementById('root')
            );
          };

          // eslint-disable-next-line
        const App = require('./App').default;
          // eslint-disable-next-line
        render(App);

          // Hot Module Replacement API
          if (module.hot) {
            module.hot.accept('./App', () => {
            const NewApp = require('./App').default; // eslint-disable-line
              render(NewApp);
            });
          }
        })
        // eslint-disable-next-line no-console
        .catch(e => console.error(e))
    );
  })
  .catch(error => {
    // eslint-disable-next-line no-console
    console.error(
      `Loading translation i18n failed. Could not initialize app`,
      error
    );
  });
