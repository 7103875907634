import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';

import attachToForm from 'commons/FormComponent/attachToForm';
import { ControlMediaType, UploadFile } from 'api/commonTypes';
import { UploadFileCustom } from 'commons/FormComponent/Fields';

const MAX_FILE_SIZE = 20000000; // 20MB
const MAX_TOTAL_FILE_SIZE = 20000000; // 20MB

type UploadFilesRecourseProps = {
  name: string;
  errors?: {
    [key: string]: string;
  };
  optional?: boolean;
  mediaType?: ControlMediaType;
};

type UploadFilesRecourseState = {
  values: Array<{ id: string; file: UploadFile | null | undefined }>;
  newFileId: string;
};

const initialState = () => ({
  values: [],
  newFileId: '',
});

const { _tg } = window.loadTranslations(__filename);

class UploadFilesRecourse extends React.Component<
  UploadFilesRecourseProps,
  UploadFilesRecourseState
> {
  state: UploadFilesRecourseState = initialState();

  onChangeFile = (newValues: UploadFile[]) => {
    const newFiles: Array<{ id: string; file: UploadFile }> = [];
    newValues.forEach(newValue =>
      newFiles.push({ id: uuidv4(), file: newValue })
    );
    this.setState({ values: newFiles, newFileId: uuidv4() });
  };

  validateFileSize = (
    file: UploadFile | null | undefined
  ): string | null | undefined => {
    if (file && file.size > MAX_FILE_SIZE) {
      return _tg('feedback.error.fileMaxSize');
    }
    return undefined;
  };

  validateTotalFileSize = (
    file: UploadFile | null | undefined
  ): string | null | undefined => {
    const { values } = this.state;
    const totalSize = values
      .map(value => (value.file ? value.file.size : 0))
      .reduce((total, size) => total + size, 0);
    if (file && totalSize > MAX_TOTAL_FILE_SIZE) {
      return _tg('feedback.error.filesMaxSize');
    }
    return undefined;
  };

  render() {
    const { name, errors, optional, mediaType } = this.props;
    const { values, newFileId } = this.state;
    return (
      <div>
        <div
          style={{
            flexDirection: 'row',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
          }}
        >
          {values.map((value, pos) => {
            return (
              <UploadFileCustom
                key={uuidv4()}
                name={`${name}.${value.id}`}
                value={value.file}
                allValues={values.map(v => v.file)}
                onChange={(newFiles: UploadFile[]) => {
                  this.onChangeFile(newFiles);
                }}
                mandatory={optional ? false : pos === 0}
                validators={[this.validateFileSize, this.validateTotalFileSize]}
                error={errors && errors[value.id]}
                accept="image/jpeg, image/png, application/pdf"
                hint={`Document ${pos + 1}`}
                multipleFiles
                mediaType
              />
            );
          })}
          <UploadFileCustom
            key={uuidv4()}
            name={`${name}.${newFileId}`}
            onChange={(newFile: UploadFile[]) => {
              this.onChangeFile(newFile);
            }}
            allValues={values.map(v => v.file)}
            mandatory={optional === true ? false : values.length === 0}
            validators={[this.validateFileSize, this.validateTotalFileSize]}
            hint={_tg('field.ccsp.addFiles')}
            accept="image/jpeg, image/png, application/pdf"
            multipleFiles
            mediaType={mediaType}
          />
        </div>
        <div
          style={{
            textAlign: 'center',
            color: '#F44336',
            fontSize: '12px',
          }}
        >
          {values.length === 0 && !optional && errors?.filesEmpty && (
            <span>{_tg('feedback.error.mandatoryField')}</span>
          )}
        </div>
      </div>
    );
  }
}

const AttachedForm = attachToForm((p: any) => <UploadFilesRecourse {...p} />);
export default AttachedForm;
