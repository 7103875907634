import React, { useMemo } from 'react';

import { TaoArticleSearchRequestDTO } from '@cvfm-front/tefps-types';
import Sidebar from 'commons/SidebarV2';
import BarTitle from 'commons/SidebarV2/Components/BarTitle';
import HitsCounter from 'commons/SidebarV2/Components/HitsCounter';
import Input from 'commons/SidebarV2/Components/Input';
import useWatcher from 'commons/hooks/useWatcher';
import services from 'commons/services';

const { _tg } = window.loadTranslations(__filename);

const ArticleTableSidebar = (): JSX.Element => {
  const taoArticleSearchService = useMemo(
    () => services.taoArticleSearchService,
    []
  );
  const request = useWatcher(services.taoArticleSearchService.watchRequest, {});
  const totalHits = useWatcher(
    services.taoArticleSearchService.watchTotalHits,
    0
  );

  const onChange = (id: keyof TaoArticleSearchRequestDTO, value: unknown) => {
    taoArticleSearchService.setRequest({
      ...request,
      [id]: value,
    });
  };

  return (
    <Sidebar>
      <BarTitle resetFilters={taoArticleSearchService.resetRequest} />
      <HitsCounter hits={totalHits} />
      <Input
        id="articleConfigId"
        title={_tg('field.id')}
        placeholder=""
        onChange={onChange}
        value={request.articleConfigId ?? ''}
      />
      <Input
        id="name"
        title={_tg('field.human.lastname_short')}
        placeholder=""
        onChange={onChange}
        value={request.name ?? ''}
      />
    </Sidebar>
  );
};

export default ArticleTableSidebar;
