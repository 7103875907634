import React, { useState } from 'react';
import Dialog from 'material-ui/Dialog';
import CircularProgress from 'material-ui/CircularProgress';

import BoButton from 'facade/BoButton';
import {
  CoreAddress,
  HistoryAction,
  SubscriberDTO,
  UserNotificationDTO,
} from '@cvfm-front/tefps-types';
import { fetchUserNotification } from 'api/notifications';
import { ApiError } from 'api/ApiError';
import ErrorBlock from 'commons/ErrorBlock';
import FlexCenter from 'commons/FlexCenter';

import { BUTTON_STYLE } from '../commons.styles';
import './UserNotificationDialog.css';

const { _tg } = window.loadTranslations(__filename);

type Props = {
  action: HistoryAction;
  subscriber?: SubscriberDTO;
};

const UserNotificationDialog = ({ action, subscriber }: Props): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [error, setError] = useState<Error>();
  const [userNotification, setUserNotification] = useState<
    UserNotificationDTO | undefined
  >(undefined);

  function sanitizeText(message: string) {
    return message
      .split('\n')
      .filter(s => s.length > 0)
      .reduce((acc, curr) => `${acc}<p>${curr}</p>`, '');
  }

  async function fetch(notificationId: string) {
    try {
      const notification = await fetchUserNotification(notificationId);
      setUserNotification(notification);
    } catch (e) {
      setError(e as ApiError);
    }
  }

  function openDialog() {
    setIsOpen(true);
    if (!action.notificationId) {
      setError(Error(_tg('feedback.error.incorrectField')));
    } else if (!userNotification) {
      void fetch(action.notificationId);
    }
  }

  function closeDialog() {
    setIsOpen(false);
    setError(undefined);
  }

  const actions = [
    <BoButton
      style={BUTTON_STYLE}
      label={_tg('action.close')}
      primary
      onClick={closeDialog}
    />,
  ];

  const subscriberAddress = subscriber?.address ?? subscriber?.addressFc;

  const renderSubscriberAddress = (address: CoreAddress): JSX.Element => {
    return (
      <div>
        {subscriber?.firstName} {subscriber?.lastName}
        <br />
        {address.complement}
        <br />
        {address.streetNumber} {address.streetNumberBis} {address.streetName}
        <br />
        {address.postalCode} {address.locality}
        <br />
        <br />
      </div>
    );
  };

  return (
    <div style={{ marginLeft: 'auto', padding: '5px' }}>
      <BoButton
        key="details"
        label={_tg('field.details')}
        onClick={openDialog}
      />
      <Dialog modal open={isOpen} actions={actions}>
        <div style={{ paddingTop: 20 }}>
          {!userNotification && !error && (
            <FlexCenter>
              <CircularProgress />
            </FlexCenter>
          )}
          {userNotification?.subject && (
            <div style={{ fontWeight: 'bolder' }}>
              {userNotification.subject}
              <hr />
            </div>
          )}
          {subscriber && subscriberAddress && (
            <>{renderSubscriberAddress(subscriberAddress)}</>
          )}
          {userNotification && (
            <div
              className="sanitized-text"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: sanitizeText(userNotification.message),
              }}
            />
          )}
          {error && <ErrorBlock error={error} />}
        </div>
      </Dialog>
    </div>
  );
};
export default UserNotificationDialog;
