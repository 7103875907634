import moment from 'moment';

import { SortParameters } from 'api/commonTypes';
import { LOGOUT } from 'api/duck';

import { EligibilitySearchCriteria } from './types';

const SET_FILTERS = 'Eligibility/List/SET_FILTERS';
export const SET_SORT_PARAMETERS = 'Eligibilities/List/SET_SORT_PARAMETERS';

export type FilterSortState = {
  filters: EligibilitySearchCriteria;
  sort: SortParameters | null | undefined;
};

type SetFilters = {
  type: string;
  filters: EligibilitySearchCriteria;
};

export const initialFilters = (plate?: string): EligibilitySearchCriteria => ({
  eligibilityId: undefined,
  productIds: undefined,
  plates: plate || null,
  validityZonesIds: null,
  startDate: {
    from: undefined,
    to: moment()
      .endOf('day')
      .toDate(),
  },
  endDate: {
    from: moment()
      .endOf('day')
      .subtract(3, 'months')
      .toDate(),
    to: undefined,
  },
  startTime: {
    from: '00:00',
    to: '23:59',
  },
  endTime: {
    from: '00:00',
    to: '23:59',
  },
});

export function setFilters(filters: EligibilitySearchCriteria): SetFilters {
  return {
    type: SET_FILTERS,
    filters,
  };
}

export function setSortParameters(sort: SortParameters): any {
  return {
    type: SET_SORT_PARAMETERS,
    sort,
  };
}

export function getEligibilityListState(state: any): FilterSortState {
  return state.eligibilitiesList;
}

export const initialState = (): FilterSortState => ({
  filters: initialFilters(),
  sort: {
    sortField: 3,
    increasingOrder: false,
  },
});

export default function eligibilitiesListReducer(
  state: FilterSortState = initialState(),
  action: any
): FilterSortState {
  switch (action.type) {
    case SET_SORT_PARAMETERS:
      return {
        ...state,
        sort: action.sort,
      };
    case SET_FILTERS:
      return {
        ...state,
        filters: action.filters,
      };
    case LOGOUT:
      return initialState();
    default:
      return state;
  }
}

export function setPlateFilter(plate: string): any {
  return {
    type: SET_FILTERS,
    filters: initialFilters(plate),
  };
}
