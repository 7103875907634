import { TaoSubscriberDTO } from '@cvfm-front/tefps-types';
import { Setter, WatchFunctionType } from '@cvfm-front/commons-types';
import { Watcher } from '@cvfm-front/commons-utils';
import { PatchObject } from 'api/commonTypes';

import { apiPatch } from '../../../api/helpers';

export interface TaoSubscriberUpdateServiceInterface {
  updateTaoSubscriber: (
    subscriberId: string,
    patches: Array<PatchObject<unknown>>
  ) => Promise<any>;
  watchIsUpdating: WatchFunctionType<boolean>;
  watchHasError: WatchFunctionType<Error | undefined>;
  watchUpdatedSubscriber: WatchFunctionType<Partial<TaoSubscriberDTO>>;
  setUpdatedSubscriber: Setter<Partial<TaoSubscriberDTO>>;
}

const ROOT_API = '/api/proxy/tao/api/v1/{cityId}/subscriber';

const TaoSubscriberUpdateService = () => {
  const { setValue: setIsUpdating, watchValue: watchIsUpdating } = Watcher(
    false
  );

  const { setValue: setHasError, watchValue: watchHasError } = Watcher(
    undefined
  );

  const {
    setValue: setUpdatedSubscriber,
    watchValue: watchUpdatedSubscriber,
  } = Watcher<Partial<TaoSubscriberDTO>>({});

  const updateTaoSubscriber: TaoSubscriberUpdateServiceInterface['updateTaoSubscriber'] = async (
    subscriberId,
    patches
  ) => {
    try {
      setIsUpdating(true);
      setHasError(undefined);
      return apiPatch<TaoSubscriberDTO>(`${ROOT_API}/${subscriberId}`, patches);
    } catch (error) {
      setHasError(error);
      return undefined;
    } finally {
      setIsUpdating(false);
    }
  };

  return {
    updateTaoSubscriber,
    watchIsUpdating,
    watchHasError,
    watchUpdatedSubscriber,
    setUpdatedSubscriber,
  };
};

export default TaoSubscriberUpdateService;
