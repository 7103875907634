import { LOGOUT } from 'api/duck';

import { ParkingRightDefinitionSearchCriteria } from './types';

const SET_FILTERS = 'ParkingRightDefinitions/List/SET_FILTERS';

type State = {
  filters: ParkingRightDefinitionSearchCriteria;
};

export const initialFilters = (): ParkingRightDefinitionSearchCriteria => {
  return {
    name: null,
    parkingRightDefinitionId: null,
    memberCount: null,
    price: { from: NaN, to: NaN },
  };
};

const initialState = (): State => ({
  filters: initialFilters(),
});

export default function defininitionsListReducer(
  state: State = initialState(),
  action: any
): State {
  switch (action.type) {
    case SET_FILTERS:
      return {
        ...state,
        filters: action.filters,
      };
    case LOGOUT:
      return initialState();
    default:
      return state;
  }
}

export function getParkingRightDefinitionsSearchCriteriaState(
  state: any
): State {
  return state.parkingRightDefinitionsSearchCriteria;
}

export function setFilters(filters: ParkingRightDefinitionSearchCriteria): any {
  return {
    type: SET_FILTERS,
    filters,
  };
}
