import * as React from 'react';
import moment from 'moment';

const { _t } = window.loadTranslations(__filename);

const StartParkingRightRequestActionV0 = ({ action }: any) => {
  return (
    <span>
      {_t('element.content', {
        planName: action.parkingRightDefinitionName,
        plate: action.plate,
        startDate: moment(action.startDate).format('DD/MM/YYYY HH:mm'),
        endDate: moment(action.endDate).format('DD/MM/YYYY HH:mm'),
      })}
    </span>
  );
};

export default StartParkingRightRequestActionV0;
