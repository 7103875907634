import * as React from 'react';
import { connect } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';

import Sidebar from 'commons/SidebarV2';
import BarTitle from 'commons/SidebarV2/Components/BarTitle';
import HitsCounter from 'commons/SidebarV2/Components/HitsCounter';
import Checkboxes from 'commons/SidebarV2/Components/Checkboxes';
import Dates from 'commons/SidebarV2/Components/Dates';
import Times from 'commons/SidebarV2/Components/Times';
import Autocomplete from 'commons/SidebarV2/Components/Autocomplete';
import Select from 'commons/SidebarV2/Components/Select';
import Input from 'commons/SidebarV2/Components/Input';
import { buildZoneSelect } from 'commons/Utils/zoneUtils';
import { KeyBucketDTO } from 'api/commonTypes';
import { fetchMatchingAgents } from 'api/accounts';
import {
  AuthorizedVehicleCategory,
  ExemptionReason,
  getConfigState,
  InitialOrganization,
} from 'config/duck';
import { ControlTag } from 'api/lapiReviewConfiguration/types';
import { translateVehicleTypeFilterOptions } from 'commons/Utils/vehicleTypeUtils';
import {
  AgentIdAndNameDTO,
  controlLapiReviewFetchabilityFailReasons,
  ZoningDTO,
} from '@cvfm-front/tefps-types';
import services from 'commons/services';
import useControlSearchCriterias from 'commons/hooks/useControlSearchCriterias';

import { initialFilters } from './duck';
import { ControlSearchCriterias } from './types';
import {
  translateDeviceTypeFilterOptions,
  translateFPSFromControlFilterOptions,
  translateMediasOptions,
  translateStatusesFilterOptions,
  translateLAPIStatesFilterOptions,
  translateQualityControlStatesFilterOptions,
  translateReviewReasonFilterOptions,
  translateHasParkingSpaceFilterOptions,
} from './utils';

const { _t, _tg } = window.loadTranslations(__filename);

type ControlFiltersProps = {
  totalHits: number;
  facetings:
    | {
        [key: string]: Array<KeyBucketDTO>;
      }
    | null
    | undefined;
  authorizedVehicleCategories: Array<AuthorizedVehicleCategory>;
  hasReviewReason: boolean;
  initialOrganizationsConfigurations: Array<InitialOrganization>;
  exemptionReasonsConfigurations: Array<ExemptionReason>;
  zoning: ZoningDTO | null | undefined;
  patrolZones: Map<string, string> | null | undefined;
  qualityControlEnabled: boolean;
  tags: Array<ControlTag>;
  tagsEnabled: boolean;
  fetchControlActivated: boolean;
};

const ControlFilters = (props: ControlFiltersProps): JSX.Element => {
  const filters = useControlSearchCriterias();
  const {
    totalHits,
    facetings,
    zoning,
    exemptionReasonsConfigurations,
    patrolZones,
    hasReviewReason,
    authorizedVehicleCategories,
    qualityControlEnabled,
    tagsEnabled,
    tags,
    fetchControlActivated,
    initialOrganizationsConfigurations,
  } = props;

  const [agentSearchOptions, setAgentSearchOptions] = useState<
    Array<{ id: string; name: string }>
  >([]);

  const reasonsOptions = useMemo(() => {
    if (!exemptionReasonsConfigurations) {
      return [];
    }
    return exemptionReasonsConfigurations.map(reason => ({
      value: reason.key,
      label: reason.label,
    }));
  }, [exemptionReasonsConfigurations]);

  const zoneOptions = useMemo(() => {
    if (!zoning) return [];
    return buildZoneSelect(zoning.zones);
  }, [zoning]);

  const patrolZoneOptions = useMemo(() => {
    if (!patrolZones) return [];
    return [{ key: '', label: _t('element.allPatrolZones') }].concat(
      Array.from(patrolZones.entries())
        .map(([key, label]) => ({ key, label }))
        .sort((a, b) => a.label.localeCompare(b.label))
    );
  }, [patrolZones]);

  const onChange = (id: string, value: any): void => {
    void services.controls.updateFilter({
      ...filters,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      [id]: value,
    } as ControlSearchCriterias);
  };

  function resetFilters() {
    void services.controls.updateFilter(initialFilters());
  }

  const fetchAgentsAndGenerateOptions = async (
    agentFilter: string | null | undefined
  ): Promise<void> => {
    let agents: Array<AgentIdAndNameDTO> = [];
    if (agentFilter && agentFilter.length >= 3) {
      agents = (await fetchMatchingAgents(agentFilter)).matchingAgents;
    }

    const options = agents.map(({ id, fullName }) => ({
      id,
      name: fullName,
    }));
    setAgentSearchOptions(options);
  };

  useEffect(() => {
    void fetchAgentsAndGenerateOptions(null);
  }, []);

  return (
    <Sidebar>
      <BarTitle resetFilters={resetFilters} />
      <HitsCounter hits={totalHits} />
      <Dates
        id="statementDatetime"
        title={_tg('field.date.date_plural')}
        dates={filters.statementDatetime}
        onChange={onChange}
      />
      <br />
      <Times
        id="times"
        title={_t('element.times')}
        times={filters.times}
        onChange={onChange}
      />
      <br />
      <Input
        id="controlId"
        title={_tg('field.control.controlId')}
        placeholder={_t('element.search.byControlId')}
        onChange={onChange}
        value={filters.controlId || ''}
      />
      <Input
        id="fineId"
        title={_tg('field.control.fpsId')}
        placeholder={_t('element.search.byFpsId')}
        onChange={onChange}
        value={filters.fineId || ''}
      />
      <Input
        id="plate"
        title={_tg('field.vehicle.licensePlate')}
        placeholder={_t('element.search.byPlate')}
        onChange={onChange}
        value={filters.plate || ''}
      />
      <Input
        id="terminalId"
        title={_t('element.terminalId')}
        placeholder={_t('element.search.byTer')}
        onChange={onChange}
        value={filters.terminalId || ''}
      />
      <Input
        id="parkingSpaceId"
        title={_tg('field.control.parkingSpaceId')}
        placeholder={_t('element.search.byParkingSpaceId')}
        onChange={onChange}
        value={filters.parkingSpaceId || ''}
      />
      <br />
      <Checkboxes
        id="controlStatuses"
        title={_t('element.controlStatus')}
        options={translateStatusesFilterOptions()}
        filters={filters.controlStatuses}
        onChange={onChange}
        faceting={facetings ? facetings.controlStatuses : null}
      />
      <Checkboxes
        id="mediaStatuses"
        title={_t('element.medias')}
        options={translateMediasOptions()}
        filters={filters.mediaStatuses}
        onChange={onChange}
        faceting={facetings ? facetings.mediaStatuses : null}
      />
      <Checkboxes
        id="esControlDeviceTypes"
        title={_t('element.controlEmittedBy')}
        options={translateDeviceTypeFilterOptions(
          facetings?.esControlDeviceTypes,
          false
        )}
        filters={filters.esControlDeviceTypes}
        onChange={onChange}
        faceting={facetings ? facetings.esControlDeviceTypes : null}
      />
      <Checkboxes
        id="esLAPIControlStates"
        title={_t('element.esLAPIControlState')}
        options={translateLAPIStatesFilterOptions()}
        filters={filters.esLAPIControlStates}
        onChange={onChange}
        faceting={facetings ? facetings.esLAPIControlStates : null}
      />
      {hasReviewReason && (
        <Checkboxes
          id="reviewReasons"
          title={_t('element.reviewReason')}
          options={translateReviewReasonFilterOptions()}
          filters={filters.reviewReasons}
          onChange={onChange}
          faceting={facetings ? facetings.reviewReasons : null}
        />
      )}
      {authorizedVehicleCategories.length > 0 && (
        <Checkboxes
          id="vehicleTypes"
          title={_t('element.vehicleType')}
          options={translateVehicleTypeFilterOptions(
            authorizedVehicleCategories
          )}
          filters={filters.vehicleTypes}
          onChange={onChange}
          faceting={facetings ? facetings.vehicleTypes : null}
        />
      )}
      {authorizedVehicleCategories.length > 0 && (
        <Checkboxes
          id="presumedVehicleTypes"
          title={_t('element.presumedVehicleType')}
          options={translateVehicleTypeFilterOptions(
            authorizedVehicleCategories
          )}
          filters={filters.presumedVehicleTypes}
          onChange={onChange}
          faceting={facetings ? facetings.presumedVehicleTypes : null}
        />
      )}
      {qualityControlEnabled && (
        <Checkboxes
          id="qualityControlComparativeStates"
          title={_t('element.qualityControlComparativeStates')}
          options={translateQualityControlStatesFilterOptions()}
          filters={filters.qualityControlComparativeStates}
          onChange={onChange}
          faceting={
            facetings ? facetings.qualityControlComparativeStates : null
          }
        />
      )}
      <Checkboxes
        id="fpsCreationStatuses"
        title={_t('element.ledToFps')}
        options={translateFPSFromControlFilterOptions()}
        filters={filters.fpsCreationStatuses}
        onChange={onChange}
        faceting={facetings ? facetings.fpsCreationStatuses : null}
      />
      {tagsEnabled && tags.length > 0 && (
        <Checkboxes
          id="tags"
          title={_t('element.tags')}
          options={tags
            .map(({ text }) => ({ value: text, label: text }))
            .concat([{ value: '<Tags deleted>', label: '<Tags supprimés>' }])}
          filters={filters.tags}
          onChange={onChange}
          faceting={facetings ? facetings.tags : null}
        />
      )}
      {reasonsOptions && reasonsOptions.length > 0 && (
        <Checkboxes
          id="exemptionReasons"
          title={_tg('field.control.exemptionReason')}
          options={reasonsOptions}
          filters={filters.exemptionReasons}
          onChange={onChange}
          faceting={facetings ? facetings.exemptionReasons : null}
        />
      )}
      {fetchControlActivated && (
        <Checkboxes
          id="lapiReviewFetchabilityFailReasons"
          title={_tg(
            'field.control.ControlLapiReviewFetchabilityFailReason.title'
          )}
          options={controlLapiReviewFetchabilityFailReasons.map(
            (reason: string) => ({
              value: reason,
              label: _tg(
                `field.control.ControlLapiReviewFetchabilityFailReason.${reason}`
              ),
            })
          )}
          filters={filters.lapiReviewFetchabilityFailReasons}
          onChange={onChange}
          faceting={
            facetings ? facetings.lapiReviewFetchabilityFailReasons : null
          }
        />
      )}
      <Checkboxes
        id="hasParkingSpaceId"
        title={_tg('field.control.hasParkingSpaceId')}
        options={translateHasParkingSpaceFilterOptions()}
        filters={filters.hasParkingSpaceId}
        onChange={onChange}
        faceting={facetings ? facetings.hasParkingSpaceId : null}
      />
      <Autocomplete
        id="agentId"
        title={_tg('field.agent.idOrName')}
        options={agentSearchOptions}
        onChange={onChange}
        onAutocomplete={fetchAgentsAndGenerateOptions}
        search={filters.agentId}
      />
      <Autocomplete
        id="agentReviewId"
        title={_tg('field.agent.agentReviewIdOrName')}
        options={agentSearchOptions}
        onChange={onChange}
        onAutocomplete={fetchAgentsAndGenerateOptions}
        search={filters.agentReviewId}
      />
      {initialOrganizationsConfigurations &&
        initialOrganizationsConfigurations.length > 0 && (
          <Select
            id="organizationIds"
            title="Autorité d'émission"
            onChange={onChange}
            selected={[...filters.organizationIds] || undefined}
            multiple
            options={[...initialOrganizationsConfigurations]}
          />
        )}
      <Select
        id="zoneId"
        title={_tg('field.address.zone')}
        onChange={onChange}
        selected={filters.zoneId}
        options={zoneOptions}
      />
      {patrolZones && (
        <Select
          id="patrolZoneId"
          title={_tg('tefps.Planner.index.element.patrolZone')}
          onChange={onChange}
          selected={filters.patrolZoneId}
          options={patrolZoneOptions}
        />
      )}
    </Sidebar>
  );
};

export default connect(state => {
  const {
    initialOrganizationsConfigurations,
    exemptionReasonsConfigurations,
    lapiReviewConfigurationDTO,
    controlConfiguration,
    authorizedVehicleCategories,
  } = getConfigState(state);
  const {
    qualityControlEnabled,
    tags,
    tagsEnabled,
    fetchControlActivated,
  } = lapiReviewConfigurationDTO;
  const {
    pricingCategoryVerification,
    vehicleTypeVerification,
  } = controlConfiguration;
  return {
    authorizedVehicleCategories,
    initialOrganizationsConfigurations,
    exemptionReasonsConfigurations,
    qualityControlEnabled,
    hasReviewReason:
      pricingCategoryVerification.length > 0 ||
      vehicleTypeVerification.length > 0,
    tags,
    tagsEnabled,
    fetchControlActivated,
  };
})(ControlFilters);
