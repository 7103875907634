import React from 'react';
import moment from 'moment';

import { DataBoxItem, DataBoxItemWrapper } from 'commons/DataBox';
import FakeInputBlock from 'commons/FakeInputBlock';
import Date from 'commons/Date';
import Separator from 'commons/Separator';
import { FpsVehicleConsistency } from '@cvfm-front/tefps-types';
import 'commons/css/commons.css';

const { _tg } = window.loadTranslations(__filename);

type FpsVehicleConsistencyProps = {
  initialVehicleBrand: string;
  sivVehicleBrand: string;
  vehicleConsistency: FpsVehicleConsistency;
};

const VehicleConsistency = ({
  initialVehicleBrand,
  sivVehicleBrand,
  vehicleConsistency,
}: FpsVehicleConsistencyProps): JSX.Element => {
  const getVehicleBrandSentToANTAI = (): string => {
    return vehicleConsistency.status !== 'CONSISTENT'
      ? sivVehicleBrand
      : initialVehicleBrand;
  };
  return (
    <div className="full-width">
      <DataBoxItemWrapper>
        <DataBoxItem>
          <FakeInputBlock
            title={_tg('field.date.date')}
            value={
              <Date
                datetime={moment(vehicleConsistency.decisionDatetime).toJSON()}
                withTime={false}
              />
            }
          />
          <FakeInputBlock
            title={_tg('field.date.time')}
            value={
              <Date
                datetime={moment(vehicleConsistency.decisionDatetime).toJSON()}
                withDate={false}
              />
            }
          />
          <FakeInputBlock
            title={_tg('tefps.fps.detail.content.vehicleConsistency.agentId')}
            value={vehicleConsistency.decisionAgent.agentId}
          />
        </DataBoxItem>
        <Separator />
        <DataBoxItem>
          <FakeInputBlock
            title={_tg('tefps.fps.detail.content.vehicleConsistency.authority')}
            value={
              (vehicleConsistency.decisionAgent.worksFor || { name: undefined })
                .name
            }
          />
          <FakeInputBlock
            title={_tg(
              'tefps.fps.detail.content.vehicleConsistency.initialVehicleBrand'
            )}
            value={initialVehicleBrand}
          />
          <FakeInputBlock
            title={_tg(
              'tefps.fps.detail.content.vehicleConsistency.sivVehicleBrand'
            )}
            value={getVehicleBrandSentToANTAI()}
          />
        </DataBoxItem>
      </DataBoxItemWrapper>
    </div>
  );
};

export default VehicleConsistency;
