import React, { CSSProperties } from 'react';

import FakeInputBlock from 'commons/FakeInputBlock';
import Date from 'commons/Date';
import Separator from 'commons/Separator';
import { DataBox, DataBoxContent, DataBoxHeader } from 'commons/DataBox';
import { CcspVersionDTO } from 'api/ccsp/types';
import { BRD_BLUE, BRD_GREY, TEXT_COLOR_LIGHT } from 'theme';

import { translateCcspVersionStatus } from './helpers';
import CcspFileBlock from './VersionManager/CcspFileBlock';

const { _t, _tg } = window.loadTranslations(__filename);

const STYLE_CONTAINER: CSSProperties = {
  display: 'flex',
  flex: 1,
  justifyContent: 'space-around',
  width: '100%',
  margin: '10px 10px 20px 10px',
};

const STYLE_CONTAINER_COMMENT: CSSProperties = {
  justifyContent: 'left',
  width: '100%',
  margin: '10px 10px 20px 10px',
};

const STYLE_CONTAINER_FILES: CSSProperties = {
  display: 'flex',
  flexWrap: 'wrap',
  borderTop: `1px solid ${BRD_GREY}`,
};

const STYLE_BAR: CSSProperties = {
  width: 1,
  height: 30,
  backgroundColor: TEXT_COLOR_LIGHT,
  margin: '0 12px',
};

const STYLE_COMMENT: CSSProperties = {
  padding: 20,
  fontSize: 14,
  fontWeight: 'normal',
};

const CcspVersionInfoBlock = ({
  ccspVersion,
  ccspId,
  ccspNumber,
  fetchRecourse,
}: {
  ccspVersion: CcspVersionDTO;
  ccspId: string;
  ccspNumber: string;
  fetchRecourse: (ccspNumber: string, reset: boolean) => void;
}): JSX.Element => {
  const ccspIsFinished =
    ccspVersion.status === 'SAISINE_ACCEPTEE' ||
    ccspVersion.status === 'SAISINE_REJETEE';

  return (
    <DataBox style={{ marginBottom: 15, marginTop: 15 }}>
      {ccspVersion.status && (
        <DataBoxHeader color={BRD_BLUE}>
          {translateCcspVersionStatus()[ccspVersion.status]}
        </DataBoxHeader>
      )}
      <DataBoxContent>
        <div style={STYLE_CONTAINER}>
          <FakeInputBlock
            containerStyle={{ width: 300 }}
            title={
              ccspIsFinished ? _t('field.decisionDate') : _tg('field.date.date')
            }
            value={
              ccspIsFinished ? (
                <Date datetime={ccspVersion.decisionDate} />
              ) : (
                <Date datetime={ccspVersion.modificationDate} />
              )
            }
          />
          <Separator />
          {ccspVersion.agent && (
            <FakeInputBlock
              containerStyle={{ width: 300 }}
              title={_tg('field.agent.agent')}
              value={ccspVersion.agent.name}
            />
          )}
        </div>
        {ccspIsFinished && (
          <div style={STYLE_CONTAINER}>
            <FakeInputBlock
              title={_t('field.decisionModificationDate')}
              value={<Date datetime={ccspVersion.modificationDate} />}
            />
          </div>
        )}
        {ccspVersion.comment && (
          <div style={STYLE_CONTAINER_COMMENT}>
            <span>{_t('element.comment')}</span>
            <div style={STYLE_COMMENT}>{ccspVersion.comment}</div>
          </div>
        )}
        <div style={STYLE_CONTAINER_FILES}>
          {ccspVersion.files &&
            ccspVersion.files.map((file, i) => (
              <div
                key={i}
                style={{
                  display: 'flex',
                  alignContent: 'center',
                  alignItems: 'center',
                  width: 186,
                }}
              >
                <CcspFileBlock
                  file={file}
                  ccspId={ccspId}
                  ccspNumber={ccspNumber}
                  fetchRecourse={fetchRecourse}
                />
                {ccspVersion.files && i < ccspVersion.files.length - 1 && (
                  <div style={STYLE_BAR} />
                )}
              </div>
            ))}
        </div>
      </DataBoxContent>
    </DataBox>
  );
};

export default CcspVersionInfoBlock;
