import React, { CSSProperties } from 'react';
import Dialog from 'material-ui/Dialog';
import CircularProgress from 'material-ui/CircularProgress';
import { connect } from 'react-redux';
import { History } from 'history';

import BoButton from 'facade/BoButton';
import FakeInputBlock from 'commons/FakeInputBlock';
import FlexCenter from 'commons/FlexCenter';
import ErrorBlock from 'commons/ErrorBlock';
import FpsNumber from 'commons/FpsNumber';
import Date from 'commons/Date';
import AddressRenderer from 'commons/Address/AddressRenderer';
import { ControlDTO } from 'api/control/types';
import { fetchControl } from 'api/control';
import { BKG_LIGHT_BLUE } from 'theme';
import Picture from 'commons/FpsPictures/Picture';
import ControlMapButton from 'commons/ControlMapButton';
import { getApiState } from 'api/duck';
import { getConfigState } from 'config/duck';

import { FnmsMediaDTO } from '../../../../api/fps/types';

import ControlStatusHistory from './ControlStatusHistory';

const { _t, _tg } = window.loadTranslations(__filename);

const STYLE_LINK: CSSProperties = {
  fontSize: 12,
  color: BKG_LIGHT_BLUE,
  cursor: 'pointer',
};

const STYLE_NOT_LINK: CSSProperties = {
  fontSize: 12,
};

type ControlDetailState = {
  loading: boolean;
  error: Error | null | undefined;
  control: ControlDTO | null | undefined;
  displayPictures: boolean;
  replay: boolean;
  showStatusHistoryList: boolean;
};

type ControlDetailProps = {
  exemptionReasons: Map<string, string> | null | undefined;
  zones: Map<string, string> | null | undefined;
  controlId: string;
  dryRunControl?: ControlDTO | null | undefined;
  onClose: (arg0: void) => any;
  onOpen?: (arg0: void) => any;
  controlMapDisabled?: boolean; // Used to disable control map button when acceding to control details from control map
  history: History; // We need to pass history through props because of material-ui  Dialog rendering. Dialog does not render as a child of the controlList so we can't use hooks inside as intended ...
  lapiReviewEnabled: boolean;
  canReadLapiReview: boolean;
  canReplay: boolean;
};

class ControlDetail extends React.Component<
  ControlDetailProps,
  ControlDetailState
> {
  constructor(props: ControlDetailProps) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      control: this.props.dryRunControl,
      displayPictures: false,
      replay: false,
      showStatusHistoryList: false,
    };
  }

  componentDidMount(): void {
    const { controlId } = this.props;
    if (!this.props.dryRunControl) {
      void this.getControl(controlId);
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(newProps: ControlDetailProps): void {
    if (newProps.controlId) {
      if (!this.props.dryRunControl) {
        void this.getControl(newProps.controlId);
      }
    } else {
      this.setState({ control: null });
    }
  }

  getControl = async (controlId: string): Promise<void> => {
    this.setState({ loading: true, error: null });
    try {
      const control = await fetchControl(controlId);
      this.setState({ error: null, control });
    } catch (error) {
      this.setState({ error: error as Error, control: null });
    }
    this.setState({ loading: false });
  };

  closeModal = (): void => {
    const { onClose } = this.props;
    onClose();
  };
  openReplay = (): void => {
    const { onOpen } = this.props;
    if (onOpen) {
      onOpen();
    }
  };
  closeReplay = (): void => this.setState({ replay: false });
  openPictures = (): void => this.setState({ displayPictures: true });

  closePictures = (): void => this.setState({ displayPictures: false });

  openInReview = (): void => {
    const { control } = this.state;
    const { history } = this.props;
    history.push(`/review/${control?.controlId || ''}`);
  };

  toggleShowStatusHistoryList = () => {
    this.setState(prevState => ({
      showStatusHistoryList: !prevState.showStatusHistoryList,
    }));
  };

  render(): React.ReactNode {
    const {
      control,
      loading,
      error,
      displayPictures,
      showStatusHistoryList,
    } = this.state;
    const {
      controlId,
      zones,
      exemptionReasons,
      controlMapDisabled,
      history,
      lapiReviewEnabled,
      canReadLapiReview,
      canReplay,
      dryRunControl,
    } = this.props;
    const actions = canReplay
      ? [
          <BoButton
            label={_tg('action.close')}
            key={2}
            secondary
            style={{ marginRight: 10 }}
            onClick={this.closeModal}
          />,
          <BoButton
            label={_tg('action.replay')}
            key={2}
            primary
            disabled={!!dryRunControl}
            style={{ marginRight: 10 }}
            onClick={this.openReplay}
          />,
        ]
      : [
          <BoButton
            label={_tg('action.close')}
            key={2}
            secondary
            style={{ marginRight: 10 }}
            onClick={this.closeModal}
          />,
        ];

    if (
      lapiReviewEnabled &&
      canReadLapiReview &&
      control?.lapiReviewFetchable &&
      control?.medias[0] &&
      !control?.medias[0].expires
    ) {
      actions.unshift(
        <BoButton
          label={_t('element.openInReview')}
          key={1}
          primary
          style={{ marginRight: 10 }}
          onClick={this.openInReview}
        />
      );
    }

    return (
      <Dialog
        title={_t('element.controlTitle', {
          id: control ? controlId : _t('element.whiteControl'),
        })}
        open={!!control}
        actions={actions}
        autoScrollBodyContent
        onRequestClose={this.closeModal}
        repositionOnUpdate
      >
        {error && (
          <FlexCenter>
            <ErrorBlock
              message={_tg('feedback.error.fetchTicket')}
              error={error}
            />
          </FlexCenter>
        )}
        {loading && (
          <FlexCenter>
            <CircularProgress />
          </FlexCenter>
        )}
        {control && (
          <div>
            <FakeInputBlock
              title={_t('element.dateTimeControl')}
              value={<Date datetime={control.statementDatetime} />}
            />
            {control.ticketPlate && (
              <FakeInputBlock
                title={_tg('field.vehicle.license')}
                value={control.ticketPlate.plate}
              />
            )}
            {control.pricingCategory && (
              <FakeInputBlock
                title={_t('element.princingCategory')}
                value={control.pricingCategory}
              />
            )}
            {control?.agent?.name && (
              <FakeInputBlock
                title={_t('element.controlAgent')}
                value={control.agent.name}
              />
            )}
            {control.treatmentDatetime && (
              <FakeInputBlock
                title={_t('element.dateTimeTreatment')}
                value={<Date datetime={control.treatmentDatetime} />}
              />
            )}
            {control.treatmentAgent && (
              <FakeInputBlock
                title={_t('element.treatmentAgent')}
                value={control.treatmentAgent.name}
              />
            )}
            <FakeInputBlock
              title={_tg('field.status')}
              value={control.status}
            />
            {control.exemptionReason && exemptionReasons && (
              <FakeInputBlock
                title={_tg('field.control.exemptionReason')}
                value={exemptionReasons.get(control.exemptionReason)}
              />
            )}
            {control.fineLegalId && (
              <FakeInputBlock
                title={_tg('commons.fps')}
                value={<FpsNumber fpsId={control.fineLegalId} />}
              />
            )}
            {control.comment && (
              <FakeInputBlock
                title={_tg('field.comment')}
                value={control.comment}
              />
            )}
            <FakeInputBlock
              title={_tg('field.address.zone')}
              value={
                zones && zones.has(control.zoneId)
                  ? zones.get(control.zoneId)
                  : control.zoneId
              }
            />
            <FakeInputBlock
              title={_t('element.latitudeLongitude')}
              value={`${control.latitude ? control.latitude : '0'}, ${
                control.longitude ? control.longitude : '0'
              }`}
            />
            {control.statementAddress && (
              <FakeInputBlock
                title={_tg('field.address.address')}
                value={<AddressRenderer address={control.statementAddress} />}
              />
            )}
            {control.statementAddress && control.statementAddressDistance && (
              <FakeInputBlock
                title={_t('element.addressDistance')}
                value={control.statementAddressDistance.toFixed(2)}
              />
            )}

            {control.controlStatusHistory.length > 0 &&
              showStatusHistoryList && (
                <ControlStatusHistory
                  controlStatusHistoryList={control.controlStatusHistory}
                />
              )}

            <BoButton
              primary
              className="control-status-history-button"
              onClick={this.toggleShowStatusHistoryList}
              label={
                !showStatusHistoryList
                  ? _tg(
                      'tefps.Control.List.ControlDetail.ControlStatusHistory.history.show'
                    )
                  : _tg(
                      'tefps.Control.List.ControlDetail.ControlStatusHistory.history.hide'
                    )
              }
            />

            {!controlMapDisabled && (
              <span
                style={{
                  display: 'inline-block',
                  textAlign: 'right',
                  width: '50%',
                }}
              >
                <ControlMapButton controlId={controlId} history={history} />
              </span>
            )}

            {control?.medias && (
              <div style={{ textAlign: 'center', marginTop: 20 }}>
                {control.medias.length > 0 ? (
                  <span
                    style={STYLE_LINK}
                    onClick={
                      displayPictures ? this.closePictures : this.openPictures
                    }
                  >
                    {displayPictures
                      ? _t('element.dontDisplayPictures')
                      : _t('element.displayPictures')}
                  </span>
                ) : (
                  <span style={STYLE_NOT_LINK}>
                    {_t('element.noPicturesToDisplay')}
                  </span>
                )}
                {displayPictures
                  ? control.medias.map((media: FnmsMediaDTO, i: number) => (
                      <Picture index={i} media={media} />
                    ))
                  : null}
              </div>
            )}
          </div>
        )}
      </Dialog>
    );
  }
}

export default connect(state => {
  const { userInfo } = getApiState(state);
  const {
    modulesConfiguration: {
      lapiReview: { enabled },
    },
  } = getConfigState(state);
  return {
    lapiReviewEnabled: enabled,
    canReadLapiReview: userInfo && userInfo.rights.includes('LAPI_REVIEW_READ'),
    canReplay: userInfo?.admin,
  };
})(ControlDetail);
