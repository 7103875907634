import moment from 'moment';

import {
  ReminderConfiguration,
  SubscriptionPlanDTO,
  LightSubscriptionDTO,
  PaymentType,
  SimpleSubscriptionDTO,
  SubscriptionDTO,
  ParkingRightRequestDTO,
  SimpleParkingRightRequestDTO,
} from '@cvfm-front/tefps-types';
import { Payment } from 'api/commonTypes';

import { Reminder } from './SubscriptionPlans/AddSubscriptionPlan/Components/ducks/types';

const { _t, _tg } = window.loadTranslations(__filename);

export function generateRestrictionMessage(
  restrictionCount?: number,
  subscriptionPlan?: SubscriptionPlanDTO
): string | null {
  if (!subscriptionPlan || typeof restrictionCount !== 'number') {
    return null;
  }

  const {
    restrictionKey,
    restrictionCount: maxCount,
    applicationProcedure: { customFields },
  } = subscriptionPlan;

  if (!restrictionKey || restrictionCount < maxCount) {
    return null;
  }

  let customFieldText = '';
  if (restrictionKey === '_id') {
    customFieldText = _t('element.forSubscriber');
  } else if (customFields) {
    const customField = customFields.find(c => c.key === restrictionKey);
    if (customField) {
      customFieldText = ` ${_t('element.restrictionKey')} : "${
        customField.label
      }"`;
    }
  }

  const message = `Il existe déjà ${
    restrictionCount > 1
      ? `${restrictionCount} autres souscriptions actives associées`
      : 'une autre souscription active associée'
  } ${customFieldText} (max: ${maxCount})`;
  return message;
}

export const getExpirableStatusLabel = (): { [key: string]: string } => {
  return {
    EXPIRED: _tg('status.expired'),
    AWAITING_BEGINING: _tg('status.awaitingBegining'),
    AWAITING_VALIDATION: _tg('status.awaitingValidation'),
    AWAITING_PAYMENT: _tg('status.awaitingPayment'),
    ACCEPTED: _tg('status.accepted'),
    REJECTED: _tg('status.rejected'),
    DISCARDED: _tg('status.discarded'),
    DELETED: _tg('status.deleted'),
  };
};

type Expirable =
  | SimpleSubscriptionDTO
  | SimpleParkingRightRequestDTO
  | ParkingRightRequestDTO
  | SubscriptionDTO
  | LightSubscriptionDTO;

export const computeStatus = (expirable: Expirable): string => {
  if (
    expirable.status === 'ACCEPTED' &&
    moment(expirable.startOfValidity).isAfter(moment())
  ) {
    return getExpirableStatusLabel().AWAITING_BEGINING;
  }

  if (
    expirable.status === 'ACCEPTED' &&
    moment(expirable.endOfValidity).isBefore(moment())
  ) {
    return getExpirableStatusLabel().EXPIRED;
  }

  return getExpirableStatusLabel()[expirable.status];
};

/**
 * Compute amount to pay at a given time for subscription and requests
 */
export const computeAmountToPay = (
  payments: Array<Payment>,
  price: number,
  refund: boolean
): number => {
  /**
   * We go through each payment and add it to the total
   */
  const totalAmountPaid = payments.reduce(
    (amountPaid, payment) => amountPaid + payment.paymentAmount,
    0
  );

  return refund ? totalAmountPaid : price - totalAmountPaid;
};

export const isRefund = (paymentType: PaymentType): boolean => {
  return paymentType === 'REFUND';
};

/**
 * Display plates in listing
 * */
export const displayPlates = (plates: Array<string>): string => {
  return plates[0] + (plates.length > 1 ? ` (${plates.length})` : '');
};

export const reminderConfigurationToReminder = (
  arr: ReminderConfiguration[]
): Reminder[] => {
  return arr.map(x => {
    const res: Reminder = {
      ...x,
      error: false,
      errorText: '',
    };

    return res;
  });
};

type FrontOption = {
  id: string;
  name: string;
};

export const frontTypes: Array<FrontOption> = [
  { id: 'SUBS', name: _t('element.subsFront.label') },
  { id: 'PMR', name: _t('element.pmrFront.label') },
];
