import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';

import { DeviceServiceChannel } from '@cvfm-front/tefps-types/build/device/DeviceType';
import { getAgentDetail } from 'api/accounts';
import { InternalAgent } from 'api/auth/types';
import { createControl } from 'api/control';
import { ControlDTO, ControlVersionDTO } from 'api/control/types';
import { getApiState } from 'api/duck';
import { getOrganization } from 'api/organizations';
import useZoning from 'commons/hooks/useZoning';
import BoButton from 'facade/BoButton';

import AddControlModal from '../AddControlModal';
import { uploadAllControlFiles } from '../helpers';
import ControlDetail from '../List/ControlDetail';
import { UploadFile } from '../../../api/commonTypes';

interface AddControlButtonProps {
  control: ControlDTO;
  userInfo: InternalAgent | null | undefined;
}

// This code has been refactored quickly in order to complete a different ticket
// TODO: Refacto this file properly
function AddControlButton({ userInfo, control }: AddControlButtonProps) {
  const zoning = useZoning();
  const [openControlCreationModal, setOpenCreationModal] = useState(false);
  const [dryRunModal, setDryRunModal] = useState(false);
  const handleClickOnAddControlButton = useCallback(() => {
    setOpenCreationModal(!openControlCreationModal);
  }, [openControlCreationModal]);
  const [dryRunControl, setDryRunControl] = useState<ControlDTO | null>(null);
  const history = useHistory();

  // TODO: useCallback
  const handleClickOnCreate = async (
    formValue: any,
    numberOfControls: number,
    files?: UploadFile[]
  ): Promise<void> => {
    const agentDetail = await getAgentDetail(formValue.agentID);
    const organization = await getOrganization(agentDetail.organizationId);
    const controlAgent = {
      agentId: agentDetail.agentId,
      name: agentDetail.firstName,
      worksFor: organization,
    };
    const deviceServiceChannel: DeviceServiceChannel = {
      application: {
        name: 'Backoffice',
        author: {
          name: organization.name,
          address: organization.address,
          organizationId: organization.organizationId,
          url: organization.url,
        },
        softwareVersion: '2.0',
      },
      deviceType: formValue.deviceServiceChannel?.deviceType || 'PDA',
      operatingSystem: window.navigator.platform,
    };
    formValue.agent = controlAgent;
    formValue.deviceServiceChannel = deviceServiceChannel;
    try {
      const control = await createControl(formValue);
      if (files) {
        await uploadAllControlFiles(files, control.controlId);
      }
      setOpenCreationModal(false);
      if (formValue.dryRun) {
        setDryRunControl({
          controlId: control.controlId,
          statementDatetime: new Date().toISOString(),
          agent: formValue.controlAgent,
          zoneId: formValue.zoneId,
          latitude: formValue.statementLocation.latitude,
          longitude: formValue.statementLocation.longitude,
          addressLatitude: formValue.statementLocation.latitude,
          addressLongitude: formValue.statementLocation.longitude,
          status: control.controlStatus,
          exemptionReason: control.exemptionReason,
          medias: [],
          lapiReviewFetchable: false,
          controlStatusHistory: [],
          initialPlate: {
            plate: formValue.plate,
            plateCountry: formValue.plateCountry,
          },
          metadata: {
            ocrScore: '0.5',
          },
          dryRun: true,
          lastVersion: {} as ControlVersionDTO,
          lapiReviewFetchabilityFailReasons: [],
          versions: [],
        });
        setDryRunModal(true);
      }
    } catch (error) {
      console.error(error);
      setOpenCreationModal(false);
      setDryRunControl(null);
      setDryRunModal(false);
    }
  };

  return (
    <>
      {userInfo?.admin && (
        <BoButton
          label="Rejouer"
          onClick={handleClickOnAddControlButton}
          backgroundColor="var(--bg-color--dark-blue)"
          labelColor="var(--white)"
        />
      )}
      {openControlCreationModal && (
        <AddControlModal
          closeModal={handleClickOnAddControlButton}
          control={control}
          zonesDTO={zoning?.zones || []}
          zones={new Map(zoning?.zones.map(z => [z.id, z.name]))}
          exemptionReasons={null}
        />
      )}
      {dryRunModal && dryRunControl && (
        <ControlDetail
          exemptionReasons={null}
          controlId={dryRunControl.controlId}
          zones={null}
          onClose={() => {
            setDryRunModal(false);
            setDryRunControl(null);
          }}
          dryRunControl={dryRunControl}
          history={history}
        />
      )}
    </>
  );
}

export default connect(state => {
  const { userInfo } = getApiState(state);
  return {
    userInfo,
  };
})(AddControlButton);
