import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';

import FpsNumber from 'commons/FpsNumber';
import { getConfigState } from 'config/duck';

const STYLE_LINK: CSSProperties = {
  height: 50,
  width: '100%',
};

const STYLE_HOVERED: CSSProperties = {
  ...STYLE_LINK,
  color: 'white',
};

type FpsLinkProps = {
  fpsId: string;
  fpsEnabled: boolean;
};

type FpsLinkState = {
  hovered: boolean;
};

class FpsLink extends React.Component<FpsLinkProps, FpsLinkState> {
  constructor(props: FpsLinkProps) {
    super(props);
    this.state = {
      hovered: false,
    };
  }

  handleHover = () => {
    const { hovered } = this.state;
    this.setState({ hovered: !hovered });
  };

  render() {
    const { fpsId, fpsEnabled } = this.props;
    const { hovered } = this.state;

    if (!fpsEnabled) {
      return <FpsNumber fpsId={fpsId} />;
    }

    return (
      <a
        data-fps-id={fpsId}
        style={hovered ? STYLE_HOVERED : STYLE_LINK}
        onMouseLeave={this.handleHover}
        onMouseEnter={this.handleHover}
        href={`#/fps/detail/${fpsId}`}
      >
        <FpsNumber fpsId={fpsId} />
      </a>
    );
  }
}

export default connect(state => {
  const {
    modulesConfiguration: {
      fps: { enabled },
    },
  } = getConfigState(state);
  return { fpsEnabled: enabled };
})(FpsLink);
