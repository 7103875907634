import React, { useCallback, useEffect, useMemo, useState } from 'react';

import TicketInformation from 'tefps/LapiReview/TicketInformation/TicketInformation';
import { FnmsCityFpsPriceDTO, LightLapiZone } from 'api/pricing/types';
import { getFpsPrice } from 'api/pricing';
import { findSubscriptionPlansDTO } from 'api/cvfm-core-subscription/subscriptionPlanPublic';
import { ControlDTO, ControlSubscriber } from 'api/control/types';
import { ControlParkingRightDTO } from '@cvfm-front/commons-types';
import useZoning from 'commons/hooks/useZoning';

const { _tg } = window.loadTranslations(__filename);

interface Props {
  controlDTO: ControlDTO;
}

const ControlTicketInformation = ({ controlDTO }: Props) => {
  const zoning = useZoning();
  const [subscriptionPlanMap, setSubscriptionPlanMap] = useState<
    Map<string, string>
  >();
  const [fpsPrice, setFpsPrice] = useState<FnmsCityFpsPriceDTO>();

  const zonesMap: Map<string, LightLapiZone> = useMemo(() => {
    if (zoning) {
      const newZonesMap = new Map();
      zoning.zones.forEach(zone => {
        newZonesMap.set(zone.id, {
          name: zone.name,
          alias: zone.alias,
          virtual: zone.virtual,
        });
      });
      return newZonesMap;
    }
    return new Map<string, LightLapiZone>();
  }, [zoning]);

  async function fetchSubscriptionPlans(): Promise<void> {
    try {
      const list = await findSubscriptionPlansDTO();
      const map = new Map();
      list.forEach(value => {
        map.set(value.subscriptionPlanId, value.name);
      });
      setSubscriptionPlanMap(map);
    } catch (e) {
      console.error(_tg('feedback.error.fetchSubscription'));
    }
  }

  async function fetchFpsPrice(): Promise<void> {
    try {
      const fpsPrice = await getFpsPrice(
        controlDTO.zoneId,
        controlDTO.statementDatetime,
        {
          plate: controlDTO.initialPlate.plate,
          plateCountry: controlDTO.initialPlate.plateCountry,
        },
        {
          brand: controlDTO.lastVersion.vehicleBrand,
          model: controlDTO.lastVersion.vehicleModel,
          vehicleCategory: controlDTO.lastVersion.vehicleCategory,
        },
        []
      );
      setFpsPrice(fpsPrice);
    } catch (e) {
      console.error(_tg('feedback.error.fetchFpsPrice'));
    }
  }

  useEffect(() => {
    fetchSubscriptionPlans();
    fetchFpsPrice();
  }, [controlDTO]);

  const convertToArray = useCallback((obj: unknown) => {
    if (obj === undefined || obj === null) {
      return undefined;
    }
    return Array.isArray(obj) ? obj : [obj];
  }, []);

  const parkingRights: ControlParkingRightDTO[] | undefined = useMemo(
    () => convertToArray(controlDTO.lastVersion.parkingRightHistory),
    [controlDTO]
  );

  const subscriptions: ControlSubscriber[] | undefined = useMemo(
    () => convertToArray(controlDTO.lastVersion.subscriberHistory),
    [controlDTO]
  );

  const relevantParkingRight: ControlParkingRightDTO | undefined = useMemo(
    () => controlDTO.lastVersion.relevantParkingRight,
    [controlDTO]
  );

  const relevantSubscription: ControlSubscriber | undefined = useMemo(
    () => controlDTO.lastVersion.relevantSubscriber,
    [controlDTO]
  );

  return (
    <>
      <div className="control-rights-ticketslist">
        <TicketInformation
          parkingRights={parkingRights}
          subscriptions={subscriptions}
          subscriptionPlanMap={subscriptionPlanMap}
          fpsPrice={fpsPrice}
          zonesMap={zonesMap}
          relevantParkingRight={relevantParkingRight}
          relevantSubscription={relevantSubscription}
          isForControl
          controlDate={controlDTO.statementDatetime}
          controlZone={controlDTO.zoneId}
          controlPricingCategory={controlDTO.pricingCategory}
          darkMode={false}
        />
      </div>
    </>
  );
};

export default ControlTicketInformation;
