import * as React from 'react';
import { connect } from 'react-redux';
import Checkbox from 'material-ui/Checkbox';

import SimpleTable from 'commons/SimpleTable';
import Date from 'commons/Date';
import { FpsOverviewDTO } from 'api/fps/types';
import { ICON_STYLE, LABEL_STYLE, WIDTH_STYLE } from 'theme';
import { getConfigState } from 'config/duck';
import {
  CLAIM_STATUS,
  FPS_PAYMENT_STATUS,
  FPS_STATUS,
} from 'tefps/RecoursesV2/utils/translationUtils';

import FpsLink from './FpsLink';
import PriceToDisplay from './PriceToDisplay';
import SubscriptionStatus from './SubscriptionStatus';

type Props = {
  items: Array<FpsOverviewDTO>;
  processList: Array<string> | null | undefined;
  loadMoreRows: (arg0: { startIndex: number }) => Promise<void>;
  onUpdateSort: (colNumber: number, increasingOrder: boolean) => Promise<void>;
  openProposal: boolean;
  onCheck: (event: React.MouseEvent<any>, checked: boolean) => void;
  remoteRowCount?: number;
  colSorted?: number;
  sortOrder?: boolean;
  withSubscriptions: boolean;
  disabled: (fps: FpsOverviewDTO) => boolean;
};

class FpsTable extends React.Component<Props> {
  isInProcessList = (id: string) => {
    const { processList } = this.props;
    return processList ? processList.includes(id) : false;
  };

  render() {
    const {
      onUpdateSort,
      onCheck,
      disabled,
      openProposal,
      loadMoreRows,
      remoteRowCount,
      colSorted,
      sortOrder,
      withSubscriptions,
      items,
    } = this.props;

    const tableCols = [
      { label: 'Numéro de FPS', width: 330, grow: 3, onSort: true },
      {
        label: 'Tarif',
        width: 75,
        onSort: true,
      },
      { label: 'Date de constat', width: 150, onSort: true },
      { label: 'Agent', width: 130 },
      {
        label: 'Statut du FPS',
        width: 130,
      },
      { label: 'Statut du RAPO', width: 130 },
      { label: 'Statut du paiement', width: 150 },
    ];

    if (openProposal) {
      tableCols.unshift({
        label: 'Annulation',
        width: 80,
      });
    }

    if (withSubscriptions) {
      tableCols.push({ label: 'Ayant-droit', width: 100 });
    }

    return (
      <SimpleTable
        style={{ marginTop: 25 }}
        cols={tableCols}
        rowHeight={50}
        onSort={onUpdateSort}
        loadMoreRows={loadMoreRows}
        remoteRowCount={remoteRowCount}
        colSorted={colSorted}
        sortOrder={sortOrder}
        itemsRenderer={(fps: FpsOverviewDTO) => {
          const row = [
            <FpsLink fps={fps} />,
            <PriceToDisplay fps={fps} />,
            <Date datetime={fps.statementDatetime} />,
            <span>{fps.agentName}</span>,
            <span>{FPS_STATUS()[fps.fpsState]}</span>,
            <span>{CLAIM_STATUS()[fps.claimStatus]}</span>,
            <span>{FPS_PAYMENT_STATUS()[fps.paymentStatus]}</span>,
          ];

          if (openProposal) {
            row.unshift(
              <Checkbox
                iconStyle={{ ...ICON_STYLE, fill: '#000' }}
                labelStyle={LABEL_STYLE}
                style={WIDTH_STYLE}
                checked={this.isInProcessList(fps.lastVersionId)}
                onCheck={onCheck}
                disabled={disabled(fps)}
                data-fps-id={fps.lastVersionId}
              />
            );
          }

          if (withSubscriptions) {
            row.push(<SubscriptionStatus status={fps.subscriptionStatus} />);
          }

          return row;
        }}
        items={items}
      />
    );
  }
}

export default connect(state => {
  const { modulesConfiguration } = getConfigState(state);
  return {
    withSubscriptions: modulesConfiguration.subscribers.enabled,
  };
})(FpsTable);
