import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import BoButton from 'facade/BoButton';
import { DataBoxItem, DataBoxItemWrapper } from 'commons/DataBox';
import FakeInputBlock from 'commons/FakeInputBlock';
import Date from 'commons/Date';
import Separator from 'commons/Separator';
import { getApiState } from 'api/duck';
import { patchFps, updateFps } from 'api/fps';
import useSnackbar from 'commons/CustomHooks/SnackBar/useSnackBar';
import { CancelProposalDTO } from 'api/fps/types';
import { AgentAccountFullDTO } from '@cvfm-front/tefps-types';
import { getAgentDetail } from 'api/accounts/index';

type Props = {
  cancelProposal: CancelProposalDTO;
  canUpdateFps: boolean;
  fpsId: string;
  reloadFps: () => void;
  isLast: boolean;
  agentId: string;
};

const FpsCancelProposal = ({
  cancelProposal,
  canUpdateFps,
  agentId,
  fpsId,
  reloadFps,
  isLast,
}: Props) => {
  const setMessage = useSnackbar();
  const [editable, setEditable] = useState<boolean>(isLast);
  const [agentDetail, setAgentDetail] = useState<AgentAccountFullDTO | null>(
    null
  );

  useEffect(() => {
    getAgentDetail(cancelProposal.agentId)
      .then(result => {
        setAgentDetail(result);
      })
      .catch(error => console.error(error));
  }, []);

  async function validateProposal() {
    try {
      await updateFps(fpsId, {
        amount: 0,
        comment: cancelProposal.comment,
      });
      reloadFps();
      // we don't want the buttons to still be there after taking a decision
      setEditable(false);
    } catch (e) {
      setMessage(e.json.message);
    }
  }

  async function refuseProposal() {
    try {
      const body = [
        {
          op: 'add',
          path: '/cancelProposal/-',
          value: {
            agentId,
            reason: null,
            comment: null,
            email: null,
            status: 'REFUSED',
          },
        },
      ];
      await patchFps(fpsId, body);
      reloadFps();
      // same as above, the button don't have to be here after decision
      setEditable(false);
    } catch (e) {
      setMessage(e.json.message);
    }
  }

  function getMatriculeFieldValue(): string {
    if (agentDetail) {
      return agentDetail.firstPinLogin
        ? agentDetail.firstPinLogin
        : agentDetail.agentId;
    }
    return cancelProposal.agentId;
  }

  return (
    <div style={{ width: '100%' }}>
      <DataBoxItemWrapper>
        <DataBoxItem>
          <FakeInputBlock
            title="Date"
            value={<Date datetime={cancelProposal.datetime} withTime={false} />}
          />
          <FakeInputBlock
            title="Heure"
            value={<Date datetime={cancelProposal.datetime} withDate={false} />}
          />
        </DataBoxItem>
        <Separator />
        <DataBoxItem>
          {/* for older data, the agentId in cancelProposal may actually be the agentName, hence agentDetail would stay null (the conditional regarding the nullity of agentDetail in both FakeInputBlocks may be replaced when older data is changed) */}
          <FakeInputBlock
            title="Matricule Agent"
            value={getMatriculeFieldValue()}
          />
          <FakeInputBlock
            title="Nom Agent"
            value={
              agentDetail
                ? `${agentDetail.firstName} ${agentDetail.lastName}`
                : cancelProposal.agentId
            }
          />
        </DataBoxItem>
        {cancelProposal.status === 'PENDING' && [
          <Separator />,
          <DataBoxItem>
            <FakeInputBlock title="Raison" value={cancelProposal.reason} />
            <FakeInputBlock
              title="Commentaire"
              value={cancelProposal.comment}
            />
          </DataBoxItem>,
        ]}
      </DataBoxItemWrapper>
      {canUpdateFps && cancelProposal.status === 'PENDING' && editable && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <BoButton primary label="Valider" onClick={validateProposal} />
          <BoButton
            secondary
            label="Refuser"
            style={{ marginLeft: 40 }}
            onClick={refuseProposal}
          />
        </div>
      )}
    </div>
  );
};

export default connect(state => {
  const { userInfo } = getApiState(state);
  return {
    canUpdateFps: userInfo && userInfo.rights.includes('FPS_UPDATE'),
    agentId: userInfo?.agentId,
  };
})(FpsCancelProposal);
