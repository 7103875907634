import * as React from 'react';

import { BKG_PINK } from 'theme';
import SeparatorWithTitle from 'commons/SeparatorWithTitle';
import { TextFieldCustom } from 'commons/FormComponent/Fields';

import {
  STYLE_ADDRESS_TITLE,
  STYLE_FIELD,
  STYLE_INPUTS_CONTAINER,
  STYLE_UPPERCASE,
} from '../common.styles';
import { plateCountryValidator, plateValidator } from '../validators';
import { BRAND, LICENSE_PLATE, MODEL, PLATE_COUNTRY } from '../fieldNames';

const { _tg } = window.loadTranslations(__filename);

function convertToUppercase(value: string) {
  return value.toUpperCase();
}

function vehicleSection() {
  return (
    <div>
      <SeparatorWithTitle
        style={STYLE_ADDRESS_TITLE}
        title="Véhicule contrôlé"
        color={BKG_PINK}
        titleSize={20}
      />
      <div key="vehicule" style={STYLE_INPUTS_CONTAINER}>
        <TextFieldCustom
          name={LICENSE_PLATE}
          hint={_tg('field.vehicle.licensePlate')}
          onBeforeSubmit={convertToUppercase}
          style={STYLE_FIELD}
          inputStyle={STYLE_UPPERCASE}
          validators={[plateValidator]}
          mandatory
        />
        <TextFieldCustom
          name={PLATE_COUNTRY}
          hint="Pays (ex: FR)"
          maxLength={2}
          onBeforeSubmit={convertToUppercase}
          style={STYLE_FIELD}
          inputStyle={STYLE_UPPERCASE}
          mandatory
          validators={[plateCountryValidator]}
        />
        <TextFieldCustom
          name={BRAND}
          hint="Marque de la voiture"
          style={STYLE_FIELD}
          mandatory
        />
        <TextFieldCustom
          name={MODEL}
          hint="Modèle de la voiture"
          style={STYLE_FIELD}
        />
      </div>
    </div>
  );
}

export default vehicleSection;
