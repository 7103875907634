import { connect } from 'react-redux';
import React, { CSSProperties, useEffect, useRef } from 'react';

import services from 'commons/services';
import { Config, getConfigState } from 'config/duck';
import { ControlDTO } from 'api/control/types';
import { ArrowWrapper, DrawingType, MapId } from 'commons/services/MapService';
import { Point } from '@cvfm-front/tefps-types/build/planner/types';
import { InteractiveMode } from 'commons/services/ParkingSpace/ParkingSpaceMapService';

type Props = {
  config: Config;
  control: ControlDTO;
  styleOverwrite?: CSSProperties;
  reload?: number;
};

const { _tg } = window.loadTranslations(__filename);

const ControlDetailMap = ({
  config,
  control,
  styleOverwrite,
  reload,
}: Props): JSX.Element => {
  const refMap = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (refMap?.current) {
      void services.mapService.init(MapId.CONTROL_DETAIL, config, refMap);
    }
  }, [refMap, reload]);

  useEffect(() => {
    const map = services.mapService.get(MapId.CONTROL_DETAIL);
    map?.clear?.map();
    const controlPosition: Point = {
      latitude: control.latitude,
      longitude: control.longitude,
    };
    const controlAddress: Point = {
      latitude: control.addressLatitude,
      longitude: control.addressLongitude,
    };

    const newArrows: Array<ArrowWrapper> = [];
    const markersToAdd = [
      // position in blue
      {
        id: 'LAPI_position',
        type: DrawingType.CONTROL_DETAIL,
        point: controlPosition,
        title: _tg(
          'tefps.lapiReview.geolocalisation.components.legend.controlPosition'
        ),
        symbol: '/static/img/map/circles/circle_position.png',
      },
      // address in brown
      {
        id: 'LAPI_address',
        type: DrawingType.CONTROL_DETAIL,
        point: controlAddress,
        title: _tg(
          'tefps.lapiReview.geolocalisation.components.legend.controlAddress'
        ),
        symbol: '/static/img/map/circles/circle_address.png',
      },
    ];

    // projection in red
    if (control.lastVersion.projection?.projectedPoint) {
      markersToAdd.push({
        id: 'LAPI_projection',
        type: DrawingType.CONTROL_DETAIL,
        point: {
          latitude:
            control.lastVersion.projection?.projectedPoint?.latitude || 0,
          longitude:
            control.lastVersion.projection?.projectedPoint?.longitude || 0,
        },
        title: _tg('field.control.projection.positionTarget'),
        symbol: '/static/img/map/circles/circle_ko.png',
      });
      newArrows.push({
        id: 'LAPI_projection_arrow',
        type: DrawingType.CONTROL_DETAIL,
        start: controlPosition,
        end: {
          latitude:
            control.lastVersion.projection?.projectedPoint?.latitude || 0,
          longitude:
            control.lastVersion.projection?.projectedPoint?.longitude || 0,
        },
        color: '#ff0000',
      });
    }

    // velocity in yellow
    if (
      control.lastVersion.projection?.projectedPoint &&
      control.metadata?.directionLatitude &&
      control.metadata?.directionLongitude
    ) {
      // in case of bad data, we add a try/catch
      try {
        const lat = Number.parseFloat(control.metadata.directionLatitude);
        const lon = Number.parseFloat(control.metadata.directionLongitude);
        const norm = Math.sqrt(lat * lat + lon * lon);
        const end =
          norm > 0.001 // approximately 100 meters
            ? // then scale it down to approximately 10 meters
              {
                latitude: control.latitude + (0.0001 * lat) / norm,
                longitude: control.longitude + (0.0001 * lon) / norm,
              }
            : {
                latitude: control.latitude + lat,
                longitude: control.longitude + lon,
              };
        markersToAdd.push({
          id: 'LAPI_velocity',
          type: DrawingType.CONTROL_DETAIL,
          point: end,
          title: _tg('field.control.projection.directionLAPI'),
          symbol: '/static/img/map/circles/circle_exemption.png',
        });
        newArrows.push({
          id: 'LAPI_velocity_arrow',
          type: DrawingType.CONTROL_DETAIL,
          start: controlPosition,
          end,
          color: '#b8e01a',
        });
      } catch (e) {
        // NO-OP
      }
    }
    map?.markers?.add(markersToAdd);
    map?.arrows?.add(newArrows);
    map?.center?.set(controlPosition);
    map?.zoom?.set(20);
    if (control.lastVersion.projection?.parkingSpace) {
      void services.parkingSpaceMap
        .setSelectedId(
          MapId.CONTROL_DETAIL,
          control.lastVersion.projection.parkingSpace.parkingSpaceId
        )
        .then(() => services.parkingSpaceMap.refresh(MapId.CONTROL_DETAIL));
    }

    services.parkingSpaceMap.setInteractiveMode(InteractiveMode.NONE);
    services.parkingSpaceMap.setupAutoRefresh(MapId.CONTROL_DETAIL);
    services.addressMap.setupAutoRefresh(MapId.CONTROL_DETAIL);

    services.zoning.drawZonesOnMap(MapId.CONTROL_DETAIL);

    return () => {
      services.parkingSpaceMap.cancelAutoRefresh(MapId.CONTROL_DETAIL);
      services.addressMap.cancelAutoRefresh(MapId.CONTROL_DETAIL);
    };
  }, [control, reload]);

  return (
    <div
      ref={refMap}
      style={{
        height: '500px',
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '15px',
        ...styleOverwrite,
      }}
    />
  );
};

export default connect(state => {
  const config = getConfigState(state);
  return { config };
})(ControlDetailMap);
