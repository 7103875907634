import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';
import IntlPolyfill from 'intl';
import Dialog from 'material-ui/Dialog';
import Checkbox from 'material-ui/Checkbox';
import TextField from 'material-ui/TextField';
import DatePicker from 'material-ui/DatePicker';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import CircularProgress from 'material-ui/CircularProgress';
import moment, { Moment } from 'moment';

import BoButton from 'facade/BoButton';
import { patchFps } from 'api/fps/';
import { InternalAgent } from 'api/auth/types';
import { PaymentChannel, PaymentMode, PaymentOrigin } from 'api/commonTypes';
import { getApiState } from 'api/duck';
import { BKG_CYAN, STYLE_ERROR_WRAPPER, STYLE_LOADING_WRAPPER } from 'theme';
import ErrorBlock from 'commons/ErrorBlock';
import {
  convertToCents,
  formatCentsToEuro,
  translatePaymentMode,
  translatePaymentOriginAntai,
  positivePrice,
  translateRefundOrigin,
} from 'commons/Utils/paymentUtil';
import { buildControlAgent } from 'commons/Utils/agentUtil';
import { formatDate, toHours, toMinutes } from 'commons/Utils/dateUtil';

const STYLE_TITLE: CSSProperties = {
  backgroundColor: BKG_CYAN,
  color: '#ffffff',
  fontWeight: 'bold',
};

const STYLE_CONTENT_WRAPPER: CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignContent: 'center',
};

const STYLE_PRICE_INPUT: CSSProperties = {
  color: BKG_CYAN,
  fontWeight: 'bold',
  fontSize: 40,
};

const STYLE_INPUTS: CSSProperties = {
  borderColor: BKG_CYAN,
  color: BKG_CYAN,
};

type AddRefundProps = {
  isOpen: boolean;
  fpsId: string; // eslint-disable-line react/no-unused-prop-types
  isAntaiSync: boolean;
  amountToPay: number;
  close: () => any;
  reloadFps: () => any;
  userInfo: InternalAgent;
};

type AddRefundState = {
  loading: boolean;
  error: string | null | undefined;
  amount: number;
  origin: PaymentOrigin;
  mode: string;
  date: Moment;
  comment: string | null | undefined;
  isAntaiRefund: boolean;
};

const origins = translateRefundOrigin().map(value => (
  <MenuItem key={value.key} value={value} primaryText={value.label} />
));

const originsAntai = translatePaymentOriginAntai().map(value => (
  <MenuItem key={value.key} value={value} primaryText={value.label} />
));

const getInitialState = (amountToPay: number) => ({
  loading: false,
  error: null,
  amount: Math.max(0, -formatCentsToEuro(amountToPay)),
  origin: translateRefundOrigin()[0],
  mode: 'TRANSFERT',
  date: moment(),
  comment: '',
  isAntaiRefund: false,
});

class AddRefund extends React.Component<AddRefundProps, AddRefundState> {
  state: AddRefundState = getInitialState(this.props.amountToPay);

  onClose = () => {
    this.props.close();
    this.setState({ ...getInitialState(this.props.amountToPay) });
  };

  onClickValidate = async () => {
    const { fpsId, reloadFps, userInfo } = this.props;
    const { date, origin, amount, mode, comment } = this.state;
    const body = [
      {
        op: 'add',
        path: '/payments/-',
        value: {
          paymentDatetime: moment(date),
          paymentAmount: -convertToCents(amount),
          paymentChannel: origin.key,
          paymentMode: mode,
          comment,
          agent: buildControlAgent(userInfo),
        },
      },
    ];

    this.setState({ loading: true, error: null });
    try {
      await patchFps(fpsId, body);
      await reloadFps();
    } catch (error) {
      this.setState({ loading: false, error: 'Une erreur est survenue.' });
    }
  };

  onChangeComment = (e: React.ChangeEvent<HTMLInputElement>, comment: string) =>
    this.setState({ comment });

  onChangeDate = (useless: any, newDate: Date) => {
    const { date } = this.state;
    const newDateTime = moment(newDate);
    newDateTime.hours(date.hours());
    newDateTime.minutes(date.minutes());
    this.setState({ date: newDateTime });
  };
  onChangeMinutes = (
    e: React.ChangeEvent<HTMLInputElement>,
    minutes: string
  ) => {
    this.setState({
      date: this.state.date.clone().minutes(toMinutes(minutes)),
    });
  };
  onChangeHours = (e: React.ChangeEvent<HTMLInputElement>, hours: string) => {
    this.setState({ date: this.state.date.clone().hours(toHours(hours)) });
  };

  changeAmount = (e: React.ChangeEvent<HTMLInputElement>, amount: string) =>
    this.setState({ amount: positivePrice(amount) });

  changeOrigin = (
    e: React.ChangeEvent<HTMLInputElement>,
    i: number,
    origin: {
      key: PaymentChannel;
      label: string;
      paymentModes: Array<PaymentMode>;
    }
  ) => {
    this.setState({ origin, mode: origin.paymentModes[0] });
  };

  changeMode = (
    e: React.ChangeEvent<HTMLInputElement>,
    i: number,
    mode: string
  ) => this.setState({ mode });

  handleAntaiCheck = (e: any, isInputChecked: boolean) => {
    const origin = (isInputChecked
      ? translatePaymentOriginAntai()
      : translateRefundOrigin())[0];
    this.setState({
      isAntaiRefund: isInputChecked,
      origin,
      mode: origin.paymentModes[0],
    });
  };

  render() {
    const {
      date,
      comment,
      loading,
      error,
      amount,
      origin,
      mode,
      isAntaiRefund,
    } = this.state;
    const { isOpen, isAntaiSync } = this.props;

    const actions = [
      <BoButton
        style={{ marginRight: 10 }}
        key={1}
        label="Annuler"
        onClick={this.onClose}
      />,
      <BoButton
        key={2}
        label="Ajouter"
        primary
        keyboardFocused
        onClick={this.onClickValidate}
        disabled={amount === 0 || isNaN(amount)}
      />,
    ];

    const modes = origin.paymentModes.map((key: PaymentMode) => (
      <MenuItem
        key={key}
        value={key}
        primaryText={translatePaymentMode()[key]}
      />
    ));

    return (
      <Dialog
        actions={actions}
        title="Ajouter un remboursement"
        open={isOpen}
        onRequestClose={this.onClose}
        titleStyle={STYLE_TITLE}
      >
        {loading && (
          <div style={STYLE_LOADING_WRAPPER}>
            <CircularProgress />
          </div>
        )}
        <div style={STYLE_CONTENT_WRAPPER}>
          <TextField
            underlineFocusStyle={STYLE_INPUTS}
            floatingLabelFocusStyle={STYLE_INPUTS}
            value={amount}
            onChange={this.changeAmount}
            floatingLabelText="Montant du remboursement (€)"
            type="number"
            min={0}
            step={0.01}
            inputStyle={STYLE_PRICE_INPUT}
            style={{ height: 100 }}
          />
        </div>
        <div style={STYLE_CONTENT_WRAPPER}>
          <Checkbox
            label="Le remboursement concerne un paiement reçu par l'ANTAI"
            checked={isAntaiRefund}
            onCheck={this.handleAntaiCheck}
            style={{ margin: '10px 0' }}
            disabled={!isAntaiSync}
          />
        </div>
        <div style={STYLE_CONTENT_WRAPPER}>
          <SelectField
            underlineFocusStyle={STYLE_INPUTS}
            value={origin}
            onChange={this.changeOrigin}
            floatingLabelText="Origine du paiement"
            disabled={isAntaiRefund}
          >
            {isAntaiRefund ? originsAntai : origins}
          </SelectField>
          <div style={{ display: 'flex', alignItems: 'baseline' }}>
            <DatePicker
              DateTimeFormat={IntlPolyfill.DateTimeFormat}
              locale="fr"
              autoOk
              textFieldStyle={{ width: 125, marginRight: 5 }}
              value={date.toDate()}
              onChange={this.onChangeDate}
              floatingLabelText="Date"
              container="inline"
              formatDate={formatDate}
            />
            <TextField
              underlineFocusStyle={STYLE_INPUTS}
              floatingLabelFocusStyle={STYLE_INPUTS}
              min={0}
              max={24}
              step={1}
              floatingLabelText="Heures"
              type="number"
              style={{ width: 50, marginRight: 5 }}
              value={date.hours()}
              onChange={this.onChangeHours}
            />
            :
            <TextField
              underlineFocusStyle={STYLE_INPUTS}
              floatingLabelFocusStyle={STYLE_INPUTS}
              min={0}
              max={60}
              step={1}
              floatingLabelText="Minutes"
              type="number"
              style={{ width: 50, marginLeft: 5 }}
              value={date.minutes()}
              onChange={this.onChangeMinutes}
            />
          </div>
        </div>
        <div style={STYLE_CONTENT_WRAPPER}>
          <SelectField
            underlineFocusStyle={STYLE_INPUTS}
            value={mode}
            onChange={this.changeMode}
            floatingLabelText="Moyen de paiement"
          >
            {modes}
          </SelectField>
        </div>
        <TextField
          underlineFocusStyle={STYLE_INPUTS}
          floatingLabelFocusStyle={STYLE_INPUTS}
          value={comment || undefined}
          onChange={this.onChangeComment}
          floatingLabelText="Commentaire"
          fullWidth
          multiLine
          rows={3}
          rowsMax={3}
        />
        {error && (
          <div style={STYLE_ERROR_WRAPPER}>
            <ErrorBlock error={{ message: error }} />
          </div>
        )}
      </Dialog>
    );
  }
}

export default connect(state => {
  const { userInfo } = getApiState(state);
  return {
    userInfo,
  };
})(AddRefund);
