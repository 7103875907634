import { ControlMediaDTO } from 'api/control/types';
import { uploadFileV2 } from 'api/mediaupload';
import { validateFileSize } from 'commons/Validators';
import Services from 'commons/services';

import {
  getDirectUploadLinkControl,
  pathControlMedia,
} from '../../api/control';
import {
  ControlMediaType,
  PatchObject,
  UploadFile,
} from '../../api/commonTypes';

export const uploadControlOneFile = async (
  file: File,
  controlID: string,
  mediaType: ControlMediaType
): Promise<PatchObject<ControlMediaDTO> | null> => {
  try {
    // checking file size
    const fileSizeError = validateFileSize(file);
    if (fileSizeError) {
      return null;
    }
    const directUploadDTO = await getDirectUploadLinkControl(
      controlID,
      file.type
    );
    await uploadFileV2(directUploadDTO.signedUploadUrl, file, null);
    const media: PatchObject<ControlMediaDTO> = {
      op: 'ADD',
      path: '/medias',
      value: {
        contentUrl: '',
        url: directUploadDTO.fileUrn,
        // on fait le lowercase car si le nom de l'image contient CONTEXT en majuscule le BACK le considère l'image comme CONTEXT_HIK
        name: file.name.toLowerCase(),
        controlMediaType: mediaType,
        dateCreated: new Date().toISOString(),
      },
    };
    return media;
  } catch (e) {
    console.error("Erreur lors de l'upload du fichier");
  }
  return null;
};

export async function uploadAllControlFiles(
  files: UploadFile[],
  controlID: string
) {
  const promises = files.map(file => {
    return file.file && file.mediaType
      ? uploadControlOneFile(file.file, controlID, file.mediaType)
      : null;
  });
  const patches = (await Promise.all(promises)).filter(
    patch => patch !== null
  ) as Array<PatchObject<ControlMediaDTO>>;
  await pathControlMedia(controlID, patches);
}

export function getFileArray(files: object): UploadFile[] | undefined {
  const filesArray: UploadFile[] = new Array<UploadFile>();
  function extractFiles(obj: object, filesArraytoReturn: UploadFile[]) {
    Object.keys(obj).forEach(function(key) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const value = obj[key];
        if (typeof value === 'object' && key !== 'file') {
          extractFiles(value, filesArraytoReturn);
        } else if (key === 'file') {
          filesArraytoReturn.push(obj as UploadFile);
        }
      }
    });
    return filesArraytoReturn;
  }
  return extractFiles(files, filesArray);
}

export async function getControl(
  id: string,
  setError: React.Dispatch<React.SetStateAction<Error | undefined>>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
): Promise<void> {
  try {
    await Services.control.init(id);
    setError(undefined);
  } catch (e) {
    setError(e as Error);
  } finally {
    setLoading(false);
  }
}

export function incrementLicensePlate(plate: string, interation: number) {
  const regex = /^([A-Z]{2})-(\d{3})-([A-Z]{2})$/;
  const match = regex.exec(plate.toUpperCase());
  if (!match) {
    throw new Error('Invalid license plate format');
  }
  const prefix = match[1];
  let number = parseInt(match[2], 10);
  const suffix = match[3];
  number = (number + interation) % 1000;
  const formattedNumber = number.toString().padStart(3, '0');
  return `${prefix}-${formattedNumber}-${suffix}`;
}
