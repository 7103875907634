import React, { useEffect } from 'react';

import { ListBody, ListWrapper } from 'commons/ListWrappers';
import useWatcher from 'commons/hooks/useWatcher';
import services from 'commons/services';
import { TaoProductSearchRequestDTO } from '@cvfm-front/tefps-types';

import { MarcheNavigation } from '../../MarcheNavigation';

import ProductTable from './ProductTable';
import ProductTableSideBar from './ProductTableSideBar';

const ProductTablePage = (): JSX.Element => {
  const request: Partial<TaoProductSearchRequestDTO> = useWatcher(
    services.taoProductSearchService.watchRequest,
    {}
  );

  const products = useWatcher(
    services.taoProductSearchService.watchProducts,
    []
  );

  const isLoading = useWatcher(
    services.taoProductSearchService.watchIsLoading,
    false
  );

  useEffect(() => {
    services.taoProductSearchService.getProducts();
  }, [request]);

  return (
    <div className="product-page_container">
      <ProductTableSideBar />
      <div className="product-page_content">
        <MarcheNavigation />
        <div className="product-page_table">
          <ListWrapper
            style={{
              padding: '0px 32px',
              margin: 0,
              maxWidth: '100%',
              maxHeight: '95%',
            }}
          >
            <ListBody loading={isLoading}>
              <ProductTable pagedProducts={products} />
            </ListBody>
          </ListWrapper>
        </div>
      </div>
    </div>
  );
};

export default ProductTablePage;
