import './ControlPage.css';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
import FlatButton from 'material-ui/FlatButton';
import {
  NavigationArrowBack,
  NavigationArrowForward,
} from 'material-ui/svg-icons';

import { Container, Flex } from '@cvfm-front/commons-ui';
import useControl from 'commons/hooks/useControl';
import services from 'commons/services';
import { VersionsDisplayer } from '@cvfm-front/tefps-ui';
import BoButton from 'facade/BoButton';
import { ExemptionReason, getConfigState } from 'config/duck';

import AddControlButton from './AddControlButton';
import ControlMediaList from './ControlMediaList';
import Loading from './Loading';
import OverviewBlock from './OverviewBlock';
import ReturnButton from './ReturnButton';
import { transformControlVersionDtoToVersion } from './MapControlVersionForDisplay';
import ReprojectControlButton from './ReprojectControlButton';

const { _t, _tg } = window.loadTranslations(__filename);

type Props = {
  exemptionReasonsConfigurations: ExemptionReason[] | undefined;
};

const ControlPage = ({
  exemptionReasonsConfigurations,
}: Props): JSX.Element => {
  const controlDTO = useControl();
  const [isInit, setIsInit] = useState(false);
  const history = useHistory();
  const { getPreviousControlToGo, getNextControlToGo } = services.control;
  const [isNextControlAvailable, setIsNextControlAvailable] = useState<boolean>(
    false
  );

  const openInReview = useCallback((): void => {
    if (controlDTO) {
      history.push(`/review/${controlDTO.controlId}`);
    }
  }, [history, controlDTO]);

  const goToPreviousControl = useCallback((): void => {
    const previousControl = getPreviousControlToGo();
    if (previousControl) {
      history.push(
        `${window.location.pathname}controls/detail/${previousControl.controlId}`
      );
    }
  }, [controlDTO, history]);

  const hasNext = useMemo(() => services.control.hasNextControlToGo(), [
    controlDTO,
  ]);

  const goToNextControl = useCallback(async (): Promise<void> => {
    const nextControl = await getNextControlToGo();
    if (nextControl) {
      history.push(
        `${window.location.pathname}controls/detail/${nextControl.controlId}`
      );
    }
  }, [controlDTO, history]);

  useEffect(() => {
    void (async () => {
      await services.zoning.init();
      await services.patrolZoneService.init();
      setIsInit(true);
    })();
  }, []);

  useEffect(() => {
    void (async () => {
      const nextControl = await getNextControlToGo();
      setIsNextControlAvailable(nextControl !== null);
    })();
  }, [controlDTO]);

  if (controlDTO === null || isInit === false) {
    return <Loading />;
  }

  return (
    <Flex width100 justifyContent="center" className="control-page">
      <Container className="width-60">
        <Flex className="navigation-bar" justifyContent="space-between">
          <div className="navigation-buttons">
            <ReturnButton
              href="#/controls/list"
              label={_tg('action.backToList')}
            />
          </div>

          <Flex className="navigation-buttons">
            <div className="previous-button">
              <FlatButton
                onClick={goToPreviousControl}
                disabled={!getPreviousControlToGo()}
                label={_tg(
                  'tefps.RecoursesV2.pages.Proposal.Pagination.previous'
                )}
                icon={<NavigationArrowBack />}
              />
            </div>
            <div className="navigation-buttons">
              <FlatButton
                onClick={goToNextControl}
                disabled={!hasNext}
                label={_tg('tefps.RecoursesV2.pages.Proposal.Pagination.next')}
                labelPosition="before"
                icon={<NavigationArrowForward />}
              />
            </div>
          </Flex>

          <Flex gap={6} className="navigation-buttons" justifyContent="right">
            <ReprojectControlButton control={controlDTO} />
            <AddControlButton control={controlDTO} />

            {controlDTO.lapiReviewFetchable && (
              <BoButton
                primary
                onClick={openInReview}
                label={_t('openInReview')}
              />
            )}
          </Flex>
        </Flex>

        <OverviewBlock />
        <ControlMediaList control={controlDTO} />
        <VersionsDisplayer
          versions={controlDTO.versions
            .map((version, i) =>
              transformControlVersionDtoToVersion(
                version,
                i,
                exemptionReasonsConfigurations,
                true
              )
            )
            .reverse()}
        />
      </Container>
    </Flex>
  );
};

export default connect(state => {
  const config = getConfigState(state);
  return {
    exemptionReasonsConfigurations: config.exemptionReasonsConfigurations,
  };
})(ControlPage);
