import React from 'react';
import DatePicker from 'material-ui/DatePicker';
import IntlPolyfill from 'intl';
import TimePicker from 'material-ui/TimePicker';
import './CcspDatetimePicker.css';

const { _tg } = window.loadTranslations(__filename);

interface Props {
  name: string;
  fieldName: string;
  date?: Date;
  errorText?: string | null;
  disabled: boolean;
  onChangeDate: (event: Event | null | undefined, newDate: Date) => void;
  onChangeTime: (event: Event | null | undefined, newDate: Date) => void;
}

function CcspDatetimePicker({
  name,
  fieldName,
  date,
  errorText,
  disabled = false,
  onChangeDate,
  onChangeTime,
}: Props): JSX.Element {
  return (
    <div className="datetime-picker">
      <div>{fieldName}</div>
      <div className="datetime-picker_wrapper">
        <DatePicker
          DateTimeFormat={IntlPolyfill.DateTimeFormat}
          name={name}
          okLabel={_tg('action.ok').toUpperCase()}
          cancelLabel={_tg('action.cancel')}
          locale="fr"
          style={{ flex: 1 }}
          onChange={onChangeDate}
          value={date}
          textFieldStyle={{ textAlign: 'center', width: 130 }}
          errorText={errorText}
          disabled={disabled}
        />
        <TimePicker
          name={`${name}Time`}
          okLabel={_tg('action.ok').toUpperCase()}
          cancelLabel={_tg('action.cancel')}
          format="24hr"
          onChange={onChangeTime}
          value={date}
          textFieldStyle={{ textAlign: 'center', width: 100 }}
          style={{ marginLeft: 20 }}
          errorText={errorText}
          disabled={disabled}
        />
      </div>
    </div>
  );
}

export default CcspDatetimePicker;
