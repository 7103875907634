import React, { CSSProperties } from 'react';

import TextFieldCustom from 'commons/FormComponent/Fields/TextFieldCustom';
import SelectFieldCustom from 'commons/FormComponent/Fields/SelectFieldCustom';
import {
  validateEmail,
  validateLastName,
  validateFirstName,
} from 'commons/Validators';
import { Gender } from 'api/recourse/types';
import AddressAutocomplete from 'commons/AddressAutocomplete';
import { fieldKeyRecourseUser } from 'commons/types/fieldKeyRecourseUser';

const STYLE_CONTENT_WRAPPER: CSSProperties = {
  marginTop: '-6px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignContent: 'center',
};

const MALE = { id: 'MALE', name: 'Monsieur' };
const FEMALE = { id: 'FEMALE', name: 'Madame' };
const NOT_GIVEN = { id: 'NOT_GIVEN', name: 'Madame, Monsieur' };
const ORGA = { id: 'ORGA', name: 'Personne morale' };

const USER_GENDERS = [MALE, FEMALE, NOT_GIVEN, ORGA];

type ContactProps = {
  field: string;
  mandatoryAddress: boolean;
  styleInputs: Record<string, any>;
  contactCountryIn2Char?: boolean;
};

type ContactState = {
  gender: Gender;
};

class Contact extends React.Component<ContactProps, ContactState> {
  state: ContactState = { gender: NOT_GIVEN.id as Gender };

  onPasteMail = (e: Event) => e.preventDefault();

  onChangeGender = (gender: Gender) => this.setState({ gender });

  render() {
    const {
      field,
      mandatoryAddress,
      styleInputs,
      contactCountryIn2Char,
    } = this.props;
    const { gender } = this.state;

    return (
      <div>
        <div style={STYLE_CONTENT_WRAPPER}>
          <SelectFieldCustom
            name={`${field}.gender`}
            hint="Civilité"
            items={USER_GENDERS}
            value={gender}
            mandatory
            onChange={this.onChangeGender}
            style={{ width: '30%' }}
            underlineFocusStyle={styleInputs}
            floatingLabelFocusStyle={styleInputs}
          />
          <TextFieldCustom
            name={`${field}.lastName`}
            hint="Nom"
            mandatory
            style={{ ...styleInputs, width: '30%' }}
            underlineFocusStyle={styleInputs}
            floatingLabelFocusStyle={styleInputs}
            validators={[validateLastName]}
          />
          {(gender !== ORGA.id && (
            <TextFieldCustom
              name={`${field}.firstName`}
              hint="Prénom"
              mandatory
              style={{ ...styleInputs, width: '30%' }}
              underlineFocusStyle={styleInputs}
              floatingLabelFocusStyle={styleInputs}
              validators={[validateFirstName]}
            />
          )) || <div style={{ width: '30%' }} />}
        </div>
        <div style={STYLE_CONTENT_WRAPPER}>
          <TextFieldCustom
            name={`${field}.email`}
            hint="Email"
            validators={[validateEmail]}
            mandatory={!mandatoryAddress}
            style={{ ...styleInputs, width: '45%' }}
            underlineFocusStyle={styleInputs}
            floatingLabelFocusStyle={styleInputs}
          />
          <TextFieldCustom
            name={`${field}.phoneNumber`}
            hint="Téléphone"
            style={{ ...styleInputs, width: '30%' }}
            underlineFocusStyle={styleInputs}
            floatingLabelFocusStyle={styleInputs}
          />
        </div>
        <AddressAutocomplete
          field={field}
          fieldKeys={fieldKeyRecourseUser}
          mandatoryAddress={mandatoryAddress}
          styleInputs={styleInputs}
          illegibleOption
          isForeignAddressAllow
          countryIn2Char={contactCountryIn2Char}
        />
      </div>
    );
  }
}

export default Contact;
