import React, { CSSProperties, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import CircularProgress from 'material-ui/CircularProgress';

import Exporter from 'commons/Exporter';
import { ListBody, ListBottom, ListWrapper } from 'commons/ListWrappers';
import AdvertisingModal from 'commons/AdvertisingModal';
import { getCombinedExport, getExport, patchControl } from 'api/control';
import {
  ControlProjectionStatus,
  SivConfiguration,
} from '@cvfm-front/tefps-types';
import { getApiState } from 'api/duck';
import { ExemptionReason, getConfigState } from 'config/duck';
import { InternalAgent } from 'api/auth/types';
import BoButton from 'facade/BoButton';
import ProgressionModal from 'commons/Generics/ProgressionModal';
import services from 'commons/services';
import useControls from 'commons/hooks/useControls';
import useZoning from 'commons/hooks/useZoning';
import usePatrolZones from 'commons/hooks/usePatrolZones';
import Content from 'commons/Content';
import FlexCenter from 'commons/FlexCenter';
import ErrorBlock from 'commons/ErrorBlock';
import useWatcher from 'commons/hooks/useWatcher';

import AddControlModal from '../AddControlModal';

import ControlTable from './ControlTable';
import { translateCombinedExportCols, getExportCol } from './utils';
import ControlFilters from './ControlFilters';

const { _t, _tg } = window.loadTranslations(__filename);

const STYLE_CONTENT: CSSProperties = {
  display: 'flex',
  height: '100%',
  overflowY: 'hidden',
};

type ControlListProps = {
  canReadControl: boolean;
  canExport: boolean;
  exemptionReasonsConfigurations: Array<ExemptionReason>; // eslint-disable-line react/no-unused-prop-types
  combinedNFPSExportEnabled: boolean;
  fpsEnabled: boolean;
  plannerEnabled: boolean;
  hashPartnerPlateEnabled: boolean;
  qualityControlEnabled: boolean;
  fetchControlActivated: boolean;
  doubleCheckPlateReadingEnabled: boolean;
  useCorrectedCoordinates: boolean;
  userInfo: InternalAgent | null | undefined;
  sivConfiguration: SivConfiguration;
  canExportParkingSpaceDetails: boolean;
};

const ControlList = (props: ControlListProps): JSX.Element => {
  const controlSearchResult = useControls();
  const zoning = useZoning();
  const patrolZonesDTO = usePatrolZones();
  const isLoading = useWatcher(
    services.controls.watchLoading,
    services.controls.isLoading()
  );
  const sortParameter = useWatcher(
    services.controls.watchSortParameter,
    services.controls.getSortParameter()
  );

  const [controlId, setControlId] = useState<string | null | undefined>(null);
  const [openCreate, setOpenCreate] = useState<boolean | false>(false);
  const [multipleMode, setMultipleMode] = useState<boolean>(false);
  const [selectedControls, setSelectedControls] = useState<Map<string, string>>(
    new Map<string, string>()
  );
  const [progressionModalOpen, setProgressionModalOpen] = useState<boolean>(
    false
  );

  const zones = useMemo(() => {
    if (zoning) {
      return new Map(zoning.zones.map(z => [z.id, z.name]));
    }
    return null;
  }, [zoning]);

  const patrolZones = useMemo(() => {
    if (patrolZonesDTO) {
      return new Map(patrolZonesDTO.map(pz => [pz.patrolZoneId, pz.name]));
    }
    return null;
  }, [patrolZonesDTO]);

  const formattedFilters = useMemo(() => {
    return services.controls.getFormattedFilters();
  }, [services.controls.getFilters()]);

  const { exemptionReasonsConfigurations } = props;

  const exemptionReasons = useMemo(() => {
    return new Map(exemptionReasonsConfigurations.map(r => [r.key, r.label]));
  }, [exemptionReasonsConfigurations]);

  const switchMode = () => {
    if (multipleMode) {
      setMultipleMode(false);
      setSelectedControls(new Map<string, string>());
    } else {
      setMultipleMode(true);
    }
  };

  const toggleSelectedControl = (id: string) => {
    if (selectedControls.has(id)) {
      selectedControls.delete(id);
    } else {
      selectedControls.set(id, id); // basically a Set
    }
    setSelectedControls(new Map(selectedControls));
  };

  const openProgressionModal = () => {
    setProgressionModalOpen(true);
  };

  const closeProgressionModal = () => {
    setProgressionModalOpen(false);
  };

  const reprojectSingleControl = async (id: string) => {
    await patchControl(id, [
      {
        op: 'replace',
        path: '/projectionStatus',
        value: ControlProjectionStatus.PENDING,
      },
    ]);
  };

  const handleOpenCreate = (open: boolean): void => {
    setOpenCreate(open);
  };

  const onUpdateSort = (colNumber: number) => {
    void services.controls.updateSortParameter(colNumber);
  };

  const handleClickOnControl = (controlId: string) => {
    setControlId(controlId);
  };

  useEffect(() => {
    services.controls.init();
    services.zoning.init();
    services.patrolZoneService.init();
  }, []);

  const items =
    controlSearchResult && controlSearchResult.controls
      ? controlSearchResult.controls
      : [];

  const totalHits = controlSearchResult ? controlSearchResult.totalHits : 0;

  const {
    userInfo,
    canExport,
    hashPartnerPlateEnabled,
    qualityControlEnabled,
    doubleCheckPlateReadingEnabled,
    useCorrectedCoordinates,
    sivConfiguration,
    canExportParkingSpaceDetails,
    plannerEnabled,
    fetchControlActivated,
    combinedNFPSExportEnabled,
    fpsEnabled,
    canReadControl,
  } = props;

  if (!canReadControl) {
    return (
      <Content>
        <FlexCenter>
          <ErrorBlock message={_tg('feedback.error.fetchControl')} />
        </FlexCenter>
      </Content>
    );
  }

  return (
    <div style={STYLE_CONTENT}>
      <AdvertisingModal module="control" />
      {progressionModalOpen && (
        <ProgressionModal
          title={_tg('action.reproject')}
          objects={selectedControls}
          singleAction={reprojectSingleControl}
          onClose={closeProgressionModal}
          displayActionResponse={false}
        />
      )}
      <ControlFilters
        totalHits={totalHits}
        zoning={zoning}
        facetings={(controlSearchResult || {}).checkboxFaceting}
        patrolZones={patrolZones}
      />
      <ListWrapper>
        <ListBody loading={!controlSearchResult}>
          <ControlTable
            onClick={handleClickOnControl}
            exemptionReasons={exemptionReasons}
            items={items}
            zones={zones}
            onUpdateSort={onUpdateSort}
            loadMoreRows={services.controls.loadMoreRows}
            remoteRowCount={
              controlSearchResult ? controlSearchResult.totalHits : 0
            }
            colSorted={sortParameter.sortField}
            sortOrder={sortParameter.increasingOrder}
            multipleMode={multipleMode}
            selectedControls={selectedControls}
            toggleSelectedControl={toggleSelectedControl}
          />
        </ListBody>
        <ListBottom style={{ position: 'relative', borderTop: 'none' }}>
          {userInfo?.admin && (
            <>
              <BoButton
                style={{ margin: 10 }}
                label={_tg('action.create')}
                onClick={() => {
                  handleOpenCreate(true);
                }}
                primary
              />
              <BoButton
                style={{ margin: 10 }}
                label={_tg('action.multipleAction')}
                primary
                onClick={switchMode}
              />
              {multipleMode && (
                <BoButton
                  style={{ margin: 10 }}
                  label={_tg('action.reproject')}
                  secondary
                  onClick={openProgressionModal}
                />
              )}
            </>
          )}
          {canExport && (
            <div style={{ display: 'flex', margin: '10px 0' }}>
              <Exporter
                disabled={totalHits === 0}
                columns={getExportCol(
                  hashPartnerPlateEnabled,
                  qualityControlEnabled,
                  doubleCheckPlateReadingEnabled,
                  useCorrectedCoordinates,
                  sivConfiguration.enableControlSiv,
                  canExportParkingSpaceDetails,
                  plannerEnabled,
                  fetchControlActivated
                )}
                type="CONTROL"
                filters={formattedFilters}
                fileExport={getExport}
              />
              {combinedNFPSExportEnabled && fpsEnabled && (
                <Exporter
                  style={{ marginLeft: 10 }}
                  disabled={totalHits === 0}
                  columns={translateCombinedExportCols()}
                  type="CONTROL_FPS"
                  label={_t('element.combinedExcelExport')}
                  filters={formattedFilters}
                  fileExport={getCombinedExport}
                />
              )}
            </div>
          )}
          {isLoading && (
            <div style={{ marginTop: 8, position: 'absolute', left: '50%' }}>
              <CircularProgress />
            </div>
          )}
        </ListBottom>
      </ListWrapper>

      {openCreate && (
        <AddControlModal
          closeModal={() => handleOpenCreate(false)}
          zones={zones ?? new Map<string, string>()}
          zonesDTO={zoning?.zones ?? []}
          exemptionReasons={exemptionReasons ?? null}
        />
      )}
    </div>
  );
};

export default connect(state => {
  const { userInfo } = getApiState(state);
  const {
    exemptionReasonsConfigurations,
    combinedNFPSExportEnabled,
    hashPartnerPlateEnabled,
    lapiReviewConfigurationDTO,
    doubleCheckPlateReadingEnabled,
    useCorrectedCoordinates,
    sivConfiguration,
    geocoderConfiguration,
    controlConfiguration,
    modulesConfiguration,
  } = getConfigState(state);
  const {
    qualityControlEnabled,
    fetchControlActivated,
  } = lapiReviewConfigurationDTO;
  return {
    fpsEnabled: modulesConfiguration.fps.enabled,
    plannerEnabled: modulesConfiguration.planner.enabled,
    hashPartnerPlateEnabled,
    qualityControlEnabled,
    fetchControlActivated,
    doubleCheckPlateReadingEnabled,
    useCorrectedCoordinates,
    exemptionReasonsConfigurations,
    combinedNFPSExportEnabled,
    userInfo,
    sivConfiguration,
    canExportParkingSpaceDetails:
      geocoderConfiguration.parkingSpaceEnabled &&
      controlConfiguration.performProjection,
    canReadControl: userInfo && userInfo.rights.includes('CONTROL_READ'),
    canExport:
      userInfo && userInfo.rights.includes('BACKOFFICE_CONTROL_EXPORT'),
  };
})(ControlList);
