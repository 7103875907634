import React, { useState } from 'react';

import BoButton from 'facade/BoButton';

import DissociateFranceConnectService from './DissociateFranceConnectService';
import './DissociateFranceConnect.css';

const { _tg } = window.loadTranslations(__filename);

interface Props {
  openId: string | null;
  subscriberId: string | null;
}

const DissociateFranceConnect = ({ openId, subscriberId }: Props) => {
  const [requestAlreadySent, setRequestAlreadySent] = useState(false);

  return (
    <div className="dissociate-france-connect">
      <BoButton
        secondary
        label={_tg('field.franceconnect.dissociate')}
        disabled={openId === null || requestAlreadySent}
        onClick={() => {
          DissociateFranceConnectService.dissociate(subscriberId, openId);
          setRequestAlreadySent(true);
        }}
      />
    </div>
  );
};

export default DissociateFranceConnect;
