import React from 'react';
import AddIcon from 'material-ui/svg-icons/content/add-circle-outline';

import BoButton from 'facade/BoButton';
import {
  BKG_CYAN,
  BKG_DARK_BLUE,
  BKG_DARKER_BLUE,
  BKG_LIGHT_BLUE,
  BKG_PINK,
} from 'theme';

interface Props {
  amountToPay: number;
  canAddRecourse: boolean;
  canAddPayment: boolean;
  currentPrice: number;
  openModal: (modalName: string) => () => void;
  rights: any;
  cvfmRapoPartner: boolean;
}

const Label = ({ title }: { title: string }): React.ReactElement<any> => (
  <div style={{ display: 'inline-block' }}>
    <span style={{ display: 'flex', alignItems: 'center' }}>
      <AddIcon style={{ marginRight: 5 }} color="#ffffff" />
      {title}
    </span>
  </div>
);

const ActionButtons = ({
  openModal,
  currentPrice,
  rights,
  canAddRecourse,
  amountToPay,
  canAddPayment,
  cvfmRapoPartner,
}: Props) => {
  return (
    <div
      style={{
        marginTop: 50,
        width: '100%',
        flex: 1,
        display: 'flex',
        padding: '0 2.5%',
      }}
    >
      <BoButton
        backgroundColor={BKG_LIGHT_BLUE}
        buttonStyle={{ borderRadius: '5px 0px 0px 5px' }}
        disabled={currentPrice <= 0 || !rights.includes('FPS_UPDATE')}
        label={<Label title="Modification" />}
        labelColor="#ffffff"
        onClick={openModal('correction')}
        style={{ flex: 1, borderRadius: '5px 0px 0px 5px' }}
      />
      <BoButton
        backgroundColor={BKG_DARK_BLUE}
        buttonStyle={{ borderRadius: 0 }}
        disabled={!rights.includes('FPS_UPDATE')}
        label={<Label title="Transfert FPS" />}
        labelColor="#ffffff"
        onClick={openModal('fpsTransfer')}
        style={{ flex: 1, borderRadius: 0 }}
      />
      <BoButton
        backgroundColor={BKG_DARKER_BLUE}
        buttonStyle={{ borderRadius: 0 }}
        disabled={currentPrice <= 0 || !rights.includes('FPS_CANCEL_PROPOSAL')}
        label={<Label title="Annulation" />}
        labelColor="#ffffff"
        onClick={openModal('cancel')}
        style={{ flex: 1, borderRadius: 0 }}
      />
      {cvfmRapoPartner && (
        <BoButton
          backgroundColor={BKG_DARK_BLUE}
          buttonStyle={{ borderRadius: 0 }}
          disabled={!canAddRecourse || !rights.includes('RAPO_UPDATE')}
          label={<Label title="RAPO" />}
          labelColor="#ffffff"
          onClick={openModal('recourse')}
          style={{ flex: 1, borderRadius: 0 }}
        />
      )}
      <BoButton
        backgroundColor={BKG_CYAN}
        buttonStyle={{ borderRadius: 0 }}
        disabled={!rights.includes('FPS_REFUND')}
        label={<Label title="Remboursement" />}
        labelColor="#ffffff"
        onClick={openModal('refund')}
        style={{ flex: 1, borderRadius: 0 }}
      />
      <BoButton
        disabled={
          (currentPrice <= 0 && amountToPay <= 0) ||
          !(
            (canAddPayment && rights.includes('FPS_PAYMENT')) ||
            rights.includes('FPS_FORCE_PAYMENT')
          )
        }
        onClick={openModal('payment')}
        labelColor="#ffffff"
        backgroundColor={BKG_PINK}
        style={{ flex: 1, borderRadius: '0px 5px 5px 0px' }}
        buttonStyle={{ borderRadius: '0px 5px 5px 0px' }}
        label={<Label title="Paiement" />}
      />
    </div>
  );
};

export default ActionButtons;
