import React, { useEffect, useState } from 'react';
import { Dialog } from 'material-ui';

import BoButton from 'facade/BoButton';
import useControl from 'commons/hooks/useControl';
import { Container } from '@cvfm-front/commons-ui';
import { FnmsMediaDTO } from 'api/fps/types';
import Picture from 'commons/FpsPictures/Picture';

import { getControl } from '../../helpers';
import ErrorPage from '../../Detail/ErrorPage';
import ReturnButton from '../../Detail/ReturnButton';
import Loading from '../../Detail/Loading';

type ControlImagesProps = {
  open: boolean;
  close: () => void;
  controlId: string;
};
const ControlMediaModal = ({ open, close, controlId }: ControlImagesProps) => {
  const { _tg } = window.loadTranslations(__filename);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error>();

  useEffect(() => {
    void getControl(controlId, setError, setLoading);
  }, [controlId]);

  const control = useControl();

  const actions = [
    <BoButton
      key="annuler"
      style={{ marginRight: 10 }}
      label={_tg('action.cancel')}
      primary
      onClick={close}
    />,
  ];

  return (
    <Dialog
      title={_tg('tefps.Control.Detail.title', { controlId })}
      open={open}
      actions={actions}
      modal
      autoScrollBodyContent
    >
      {error && (
        <ErrorPage
          message={_tg('feedback.error.fetchControl')}
          error={error}
          navContent={
            <ReturnButton
              href="#/controls/list"
              label={_tg('action.backToList')}
            />
          }
        />
      )}
      {loading ? (
        <Loading />
      ) : (
        <Container>
          {control && control.medias.length > 0 ? (
            control.medias.map((media: FnmsMediaDTO, i: number) => (
              <Picture key={media.dateCreated} index={i} media={media} />
            ))
          ) : (
            <span>
              {_tg('tefps.Control.Detail.ControlMediaList.noPicturesToDisplay')}
            </span>
          )}
        </Container>
      )}
    </Dialog>
  );
};

export default ControlMediaModal;
